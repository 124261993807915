import React from 'react';
import Column from './Column'
import { DragDropContext } from 'react-beautiful-dnd'
import styled from 'styled-components'

const Container = styled.div`
  display:flex;
  margin-left:250px !important;
`

export class SetHierarchyPanel extends React.Component {

  render() {
    const { onDragEndHir, hierarchySetKeyObjData,disableDrop, hierarchySetPanelsOrder, hierarchySetPanels, disableDrag, ignoreMasterHierarchy } = this.props;
    return (
      <DragDropContext onDragEnd={onDragEndHir}>
        <Container>

          {hierarchySetPanelsOrder.map((columnId, index) => {
            if(index === 0 && ignoreMasterHierarchy){
              
            }
            else {
              const currenthierarchySetPanels = hierarchySetPanels[columnId]
              const currentfileColumns = currenthierarchySetPanels.panelObj.map((panelObjId,item)=>{
                if(hierarchySetKeyObjData == undefined){
                  return
                }else{
                  return hierarchySetKeyObjData[panelObjId]
                }
              }
               // panelObjId => hierarchySetKeyObjData[panelObjId]
              )
              return (
                <Column key={currenthierarchySetPanels.id} uploadStepColumn={currenthierarchySetPanels}
                  fileColumns={currentfileColumns} disableDrag={disableDrag} disableDrop={disableDrop}/>
              )
            }

          })}

        </Container>
      </DragDropContext>

    )
  }
}

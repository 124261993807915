import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "font-awesome/css/font-awesome.min.css";
import { initAmplitude } from './pages/util/amplitude';
export const AMPLITUDE_TOKEN = process.env.REACT_APP_AMPLITUDE_TOKEN;

// initAmplitude(AMPLITUDE_TOKEN);

function getCookie(name) {
  if (!document.cookie) {
    return null;
  }

  const xsrfCookies = document.cookie
    .split(";")
    .map(c => c.trim())
    .filter(c => c.startsWith(`${name}=`));

  if (xsrfCookies.length === 0) {
    return null;
  }

  return decodeURIComponent(xsrfCookies[0].split("=")[1]);
}

window.fetch = (function(fetch) {
  const getOptions = options => options || {};
  return function(...args) {
    const promise = new Promise((resolve, reject) => {
      fetch(
        args[0],
        {
          ...getOptions(args[1]),
          credentials: "include",
          headers: {
            ...getOptions(getOptions(args[1]).headers)
          }
        },
        args[3]
      )
        .then(response => {
          if (
            response.status === 401 ||
            response.status === 403 ||
            response.redirected
          ) {
            // window.location = "/";
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return promise;
  };
})(window.fetch);
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

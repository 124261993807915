import React from "react";
import Modal from "react-modal";
import ReactDynamicTable from "../ReactDynamicTable";
import LoaderComponent from "../util/Loader";
import Captcha from "react-numeric-captcha";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import { validate_decoded_auth_token } from "../util/util";



const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0x",
  },
};

export class DataSourceList extends React.Component {
  componentDidMount() {
    this.props.fetchDataSource();
  }
 
  render() {
    const decoded_auth_token =  validate_decoded_auth_token()
       
    const columns = [
      {
        Header: "S No",
        accessor: "serialNumber",
        width:"5%",
        disableFilters: true,
      },
      {
        Header: "Data source name",
        accessor: "dataSourceName",
        width: "20%"
      },
      {
        Header: "Created On",
        accessor: "createdOn",
        width: "20%"
      },
      {
        Header: "Type",
        accessor: "type",
        width: "20%"
      },
      {
        Header: "Status",
        accessor: "status",
        width: "18%"
      },
      {
        Header: "View",
        accessor: "view",
        width: "5%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view text-center">
              <a onClick={(e) =>
                handleFetchDataSource(value)
              }
              >
                <i className="fa fa-eye"></i>
              </a>
            </div>
          );
        }
      },
      {
        Header: "Delete",
        accessor: "delete",
        width: "5%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="delete text-center">
              {
                value.user_uuid == decoded_auth_token || this.props.isAdminUser ?
                  <a onClick={(e) =>
                    deleteDataSource(value)
                  }
                  >
                    <i className="far fa-trash-alt"></i>
                  </a>
                  :
                  <div className="ds-tooltip">
                    <a className="disabled" onClick={(e) =>
                      deleteDataSource(value)
                    }
                    >
                      <i className="far fa-trash-alt"></i>
                    </a>
                    <span class="ds-tooltiptext">You don't have permission to remove this Data Source</span>
                  </div>

              }
            </div>
          );
        }
      },
      {
        Header: "Is Active",
        accessor: "is_active",
        width:"13%",
        disableFilters:true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="is_active text-center">
              {
                value.is_active ?
                  <a onClick={(e) =>
                    setActiveDataSource(value)
                  }
                  >
                    <i class="fas fa-toggle-on"></i>
                  </a>
                  :
                  <a className="disabled" onClick={(e) =>
                    setActiveDataSource(value)
                  }
                  >
                    <i class="fas fa-toggle-off"></i>
                  </a>

              }
             
            </div>
          );
        }
      }
    ]
    const data = this.props.dataSources && this.props.dataSources.map((item,index) => {
      return {
        serialNumber: index + 1,
        dataSourceName: item.display_name,
        createdOn: item.created_date.slice(0, 16),
        type: item.data_source_type,
        status: item.data_source_status,
        delete: item,
        view: item,
        is_active : item
      }
    })
    const display_linked_anomaly_experiments = this.props.linked_anomaly_experiments && this.props.linked_anomaly_experiments.map((exp)=>{
      return (<div>{exp.experiment_name}</div>)
    })
    const display_linked_causal_experiments = this.props.linked_causal_experiments && this.props.linked_causal_experiments.map((exp)=>{
      return (<div>{exp.experiment_name}</div>)
    })
    const {
      handleAddDataSourceToggle,
      showAddDatasource,
      handleNewDataSource,
      datasourceName,
      saveDataSource,
      closeDataSourceModal,
      dataSources,
      delDataSourceIsOpen,
      closeDeleteDataSourceModal,
      deleteDataSource,
      handleFetchDataSource,
      confirmDeleteDataSource, captchaInputChange,
      setActiveDataSource , confirmSetActiveDataSource
    } = this.props;
    return (
      <React.Fragment>
        <div>
          <div className="row">
            <React.Fragment>
              <div className="breadcrumb_heading">
                <label className="table_content_heading">
                  Data Sources
                </label>
                <input
                  type="button"
                  className="btn btn-sm add-btn"
                  onClick={() => handleAddDataSourceToggle()}
                  value="Add Data Source"
                  id="data-source-create-btn"
                ></input>
              </div>
            </React.Fragment>
            <ReactDynamicTable
              columns={columns}
              data={data}
            />
            <Modal
              isOpen={showAddDatasource}
              // onRequestClose={closeDataSourceModal}
              style={customStyles}
              shouldCloseOnOverlayClick={true}
              contentLabel="Add New Experiment"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div className="modal-title h4"> Add Data Source</div>
                  <button
                    type="button"
                    className="close"
                    onClick={closeDataSourceModal}
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <ul className="pipeline-form">
                    <li className="form-row">
                      <label htmlFor="pipeline_name">
                        <span>&#10033;</span>Data Source Name:
                      </label>
                      <input
                        type="text"
                        autoComplete={"off"}
                        value={datasourceName}
                        onChange={(e) => handleNewDataSource(e)}
                        id="pipeline_name"
                      />
                    </li>
                    <li className="error-row">
                      <label></label>
                      <div className="error-msg">{this.props.nameError}</div>
                    </li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    id="data-source-submit"
                    onClick={(e) => saveDataSource(e)}
                  >
                    Save
                  </button>
                  <button
                    type="button btn btn-sm"
                    className="btn btn-sm btn-alert"
                    onClick={closeDataSourceModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
            <Modal
              isOpen={delDataSourceIsOpen}
              onRequestClose={closeDeleteDataSourceModal}
              style={customStyles}
              contentLabel="Delete Pipeline"
              ariaHideApp={false}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div className="modal-title h4"> Delete Data Source</div>
                  <button
                    type="button"
                    className="close"
                    onClick={closeDeleteDataSourceModal}
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <ul className="pipeline-form">
                    <li className="form-column">
                      
                      {
                       (this.props.linked_anomaly_experiments && this.props.linked_anomaly_experiments.length > 0) || 
                       (this.props.linked_causal_experiments && this.props.linked_causal_experiments.length > 0) ? 
                      <>
                      <p className="">
                        Are you sure want to delete Datasource? Linked
                        Projects will not work!!
                      </p>
                      <div>
                        <p>
                          The following projects are linked to this Datasource:
                        </p>
                        <div className="ds-linked-projects">
                          {
                            this.props.linked_anomaly_experiments && this.props.linked_anomaly_experiments.length > 0 ?
                            <div className="anomaly-projects">
                            <p><b>Anomaly Projects:</b></p>
                            { 
                             display_linked_anomaly_experiments 
                             }
                            </div> : ''
                          }
                          {
                            this.props.linked_causal_experiments && this.props.linked_causal_experiments.length > 0 ?
                            <div className="causal-projects">
                            <p><b>Causal Projects:</b></p>
                            {
                            display_linked_causal_experiments
                            }
                            </div> : ''
                          }
                        </div>                       
                      </div> </>  : 
                      <p className="">
                        Are you sure want to delete Datasource? There are no Projects and Experiments linked to this DS.
                    </p>
                      }
                    </li>
                  </ul>
                 
                </div>
                <div className="modal-footer">
                  <Captcha
                      onChange={captchaInputChange}
                    />
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    disabled={!this.props.captchaSuccess}
                    onClick={confirmDeleteDataSource}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-alert"
                    onClick={closeDeleteDataSourceModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
            <Modal
              isOpen={this.props.activeDataSourceModalIsOpen}
              onRequestClose={this.props.closeSetActiveDataSource}
              style={customStyles}
              contentLabel="Delete Pipeline"
              ariaHideApp={false}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div className="modal-title h4"> Set Data Source</div>
                  <button
                    type="button"
                    className="close"
                    onClick={this.props.closeSetActiveDataSource}
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <ul className="pipeline-form">
                    <li>
                      <p className="text">
                        Are you sure want to confirm set Active Data Source ?
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={this.props.confirmSetActiveDataSource}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-alert"
                    onClick={this.props.closeSetActiveDataSource}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <LoaderComponent isLoaderLoading={this.props.isLoaderLoading} />
      </React.Fragment>
    );
  }
}

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function authHeader() {
    // return authorization header with basic auth credentials
    // let user = JSON.parse(localStorage.getItem('user'));
    let user = cookies.get('Authorization') 
    if (user ) {
        return { 'Authorization': user };
    } else {
        return {};
    }
}
import React, { Component } from "react";
import { handleErrors } from "./ui_utils";
import { toast } from "react-toastify";
import ReactDynamicTable from "./ReactDynamicTable";
import { withRouter } from "react-router"
import Select from "react-select";
import { fetchCall } from "./util/util";
import AppContext from "./context/AppContext";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
    };
  }
  static contextType = AppContext
  componentDidMount() {
    this.fetchNotifications();
    this.fetchDataSources();
  }
  updateNotificationStatus = (notification_id) => {
    let toastId = null;
    toastId = toast("Marking notification as read", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/notification/${notification_id}`,'POST')
      .then((response) => handleErrors(response))
      .then((notifications) => {
        if (notifications) {
          this.setState({
            notifications: this.state.notifications && this.state.notifications.filter(obj => {
              return obj.uuid !== notification_id;
            }),
          });
          toast.update(toastId, {
            render: "Notification marked as read.",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        } else {
          toast.update(toastId, {
            render: "Unable to update notification read status",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }

      })
      .catch((error) => {
        console.log("Unable to Update Notifications");
      });
  };

  fetchNotifications = (datasource_id = null, page_size = 100, skip = 0,) => {
    fetchCall( `/app/api/notifications?user_uuid=${JSON.parse(this.context.store_user_uuid)}&datasource_id=${datasource_id}&limit=${page_size}&skip=${skip}`,'GET')
      .then((response) => handleErrors(response))
      .then((notifications) => {
        this.setState({
          notifications: notifications,
        });
      })
      .catch((error) => {
        console.log("Unable to Fetch Notifications");
      });
  };
  fetchDataSources = () => {
    let toastId = null;
    toastId = toast("Fetching Datasource", { type: toast.TYPE.INFO });
    this.setState({
      isLoaderLoading: true
    })
    Promise.all([
      fetchCall(`/app/api/datasources`,'GET')
      .then((result) => result.json()),
    ])
      .then(([datasources]) => {
        this.setState({
          isLoaderLoading: false
        })
        toast.update(toastId, {
          render: "Datasource fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        if (datasources && datasources.length > 0) {
          var datasource_option =
            datasources.map((datasource, i) => {
              return {
                value: datasource.pipeline_uuid,
                label: datasource.display_name,
              };
            });
        }
        if (datasources.length > 0) {
          this.setState({
            dataSourcesList: datasource_option,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoaderLoading: false
        })
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
        });
      });
  };
  resetNotifications = () =>{
    this.setState({
      notifications:[]
    })
  }
  setDatasource = (e) => {
    this.setState({
      selectedDatasource: e.value,
      data_source_name: e.label,
    });
    if(e.value){
      this.fetchNotifications(e.value)
    }else{
      this.resetNotifications()
    }
  };

  render() {
    const columns = [
      {
        Header: "Type",
        accessor: "type",
        width: "20%"
      },
      {
        Header: "Notification Message",
        accessor: "notificationMessage",
        width: "20%"
      },
      {
        Header: "Notification Status",
        accessor: "status",
        width: "20%"
      },

      {
        Header: "Created Date",
        accessor: "createdDate",
        width: "20%"
      },
      {
        Header: "Read",
        accessor: "uuid",
        disableFilters: true,
        width: "10%",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view">
              {
                value ? <a onClick={(e) =>
                  this.updateNotificationStatus(value)
                }>
                  <i className="fa fa-check"></i>
                </a> : "-"
              }

            </div>
          );
        }
      },
    ]
    const data = this.state.notifications && this.state.notifications.map((item, index) => {
      return {
        notificationMessage: item.notification_message,
        status: item.notification_status,
        type: item.notification_type,
        createdDate: item.created_date.slice(0, 16),
        uuid: item.uuid
      }
    })
    return (
      <div>
        <React.Fragment>
          <div className="back-btn">
            <button
              onClick={(e) => this.props.history.push(this.props.history.goBack())}
              className="btn btn-sm btn-primary back-btn"
            >
              <i className="fa fa-arrow-left"></i> Back
            </button>
          </div>
        </React.Fragment>
        <React.Fragment>
          <div className="breadcrumb_heading">
            <label className="table_content_heading">
              Notifications
            </label>
          </div>
        </React.Fragment>
        <div class="ds-dropdown">
        <label className="table_content_heading">
          DataSource
        </label>
        <Select
          maxMenuHeight={100}
          options={this.state.dataSourcesList && [{value:"", label: 'Select....' } , ...this.state.dataSourcesList]}
          onChange={this.setDatasource}
        />
        </div>
        <ReactDynamicTable
          columns={columns}
          data={data}
        />
      </div>
    );
  }
}
export default withRouter(Notifications)

import React, { Component } from 'react';
import ReactDynamicTable from "../ReactDynamicTable";
import { toast } from "react-toastify";
import { handleErrors } from "../ui_utils";
import Modal from "react-modal";
import { fetchCall } from '../util/util';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0x",
  },
};
export class ArchievedDataSourcesList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      archivedDataSources:[],
      delDataSourceIsOpen:false,
      delDataSourceId:null,
      isRestoreArchivedDSModalOpen:false
    };
  }

  componentDidMount() {
    this.fetchDataSource()
  }

  restoreDataSource = (datasource_id) => {
    this.setState({
      isRestoreArchivedDSModalOpen:true,
      restoreDatasourceId:datasource_id
    })

    // let archivedDataSources = this.state.archivedDataSources;
    // // let toastId = null;
    // // toastId = toast("Restoring Data Sources", { type: toast.TYPE.INFO });
    // const token = localStorage.getItem("Authorization")

    // return fetch(`/app/api/datasource/restore/${datasource_id}`, {
    //   method: "PUT",
    //   headers: {
    //     "Authorization": `${token}`,
    //   }
    // })
    //   .then((response) => handleErrors(response))
    //   .then((resultData) => {
    //     if(resultData){
    //       this.setState({
    //         isRestoreArchivedDSModalOpen:true,
    //         restoreDatasourceId:datasource_id
    //       })
          // archivedDataSources = archivedDataSources.filter(
          //   (obj) => obj.pipeline_uuid !== datasource_id
          // );
          // this.setState({
          //   archivedDataSources: archivedDataSources,
          // });
          // toast.update(toastId, {
          //   render: "Data Source restored successfully",
          //   type: toast.TYPE.SUCCESS,
          //   autoClose: 2000,
          // });
      //   }
       
      // })
  }

  confirmRestoreDataSource = () => {
    let archivedDataSources = this.state.archivedDataSources;
    let toastId = null;
    let id = this.state.restoreDatasourceId;
    toastId = toast("restoring Datasource. Please wait...", {
      type: toast.TYPE.INFO,
    });
    fetchCall(`/app/api/datasource/restore/${id}`,'PUT')
      .then((result) => result.json())
      .then((result) => {
        if (result) {
          archivedDataSources = archivedDataSources.filter(
             (obj) => obj.pipeline_uuid !== id
           );
          this.setState({
            archivedDataSources: archivedDataSources,
            isRestoreArchivedDSModalOpen: false,
          });
          toast.update(toastId, {
            render: "restored successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      })

      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  closeRestoreDSModal = () => {
    this.setState({
      isRestoreArchivedDSModalOpen:false
    })
  }

  fetchDataSource = () => {
    let toastId = null;
    toastId = toast("Fetching Data Sources", { type: toast.TYPE.INFO });
    const token = localStorage.getItem("Authorization")

    return fetch(`/app/api/datasources/archives`, {
      method: "GET",
      headers: {
        "Authorization": `${token}`,
      }
    })
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({
          archivedDataSources: resultData,
        });
        toast.update(toastId, {
          render: "Data Source fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        return resultData;
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  deleteDataSource = (delDataSourceObj) => {
    let dataSources = this.state.archivedDataSources;
    if (dataSources.length > 0) {
      this.setState({
        delDataSourceIsOpen: true,
        delDataSourceId: delDataSourceObj.pipeline_uuid,
        delDataSourceObj,
      });
    }
  };

  confirmDeleteDataSource = (data_source) => {
    let toastId = null;
    let archivedDataSources = this.state.archivedDataSources;
    let datasource_id = this.state.delDataSourceId;
    toastId = toast("Deleting Pipline. Please wait...", {
      type: toast.TYPE.INFO,
    });
    fetchCall(`/app/api/datasource/archive/${datasource_id}`,'DELETE')
      .then((result) => result.json())
      .then((result) => {
        if (result) {
          archivedDataSources = archivedDataSources.filter(
            (obj) => obj.pipeline_uuid !== datasource_id
          );
          this.setState({
            archivedDataSources: archivedDataSources,
            delDataSourceIsOpen: false,
          });
          toast.update(toastId, {
            render: "Deleted successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      })

      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  }

  closeDeleteDataSourceModal = () => {
    this.setState({
      delDataSourceIsOpen: false,
    });
    return;
  };

 
  render() {
    const columns = [
      {
        Header: "Data source name",
        accessor: "dataSourceName",
        width:"20%"
      },
      {
        Header: "Created On",
        accessor: "createdOn",
        width:"20%"
      },
      {
        Header: "Type",
        accessor: "type",
        width:"20%"
      },
      {
        Header: "Status",
        accessor: "status",
        width:"20%"
      },
      {
        Header: "Restore",
        accessor: "restore",
        width:"10%",
        disableFilters:true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view">
              <a onClick={() => this.restoreDataSource(value.pipeline_uuid)} >
                <i className="fas fa-trash-restore-alt"></i>
              </a>
            </div>
          );
        }
      },
      {
        Header: "Delete",
        accessor: "delete",
        width:"10%",
        disableFilters:true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="delete">
              <a onClick={(e) =>
                this.deleteDataSource(value)}
              >
                <i className="far fa-trash-alt"></i>
              </a>
            </div>
          );
        }
      }
    ]
    const data = this.state.archivedDataSources && this.state.archivedDataSources.map((item) => {
      return {
        dataSourceName: item.display_name,
        createdOn: item.created_date.slice(0, 16),
        type: item.data_source_type,
        status: item.data_source_status,
        delete: item,
        restore: item
      }
    })
    return (
      <div class="row">
        <React.Fragment>
          <div className="breadcrumb_heading">
            <label className="table_content_heading">
              Archieved Data Sources
              </label>
          </div>
        </React.Fragment>
        <ReactDynamicTable
          columns={columns}
          data={data}
        />
        <Modal
              isOpen={this.state.delDataSourceIsOpen}
              //onRequestClose={closeDeleteDataSourceModal}
              style={customStyles}
              contentLabel="Delete Pipeline"
              ariaHideApp={false}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div className="modal-title h4"> Delete Data Source</div>
                  <button
                    type="button"
                    className="close"
                    onClick={this.closeDeleteDataSourceModal}
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <ul className="pipeline-form">
                    <li className="form-column">
                      <p className="text">
                        Are you sure want to delete Datasource? Linked
                        Experiments will not work!!
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={this.confirmDeleteDataSource}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-alert"
                    onClick={this.closeDeleteDataSourceModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
            <Modal
              isOpen={this.state.isRestoreArchivedDSModalOpen}
              onRequestClose={this.closeRestoreDSModal}
              style={customStyles}
              contentLabel="Delete Pipeline"
              ariaHideApp={false}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div className="modal-title h4"> Restore Data Source</div>
                  <button
                    type="button"
                    className="close"
                    onClick={this.closeRestoreDSModal}
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                <ul className="pipeline-form">
                    <li className="form-column">
                      <p className="text">
                        Are you sure want to restore this Datasource ?
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={this.confirmRestoreDataSource}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-alert"
                    onClick={this.closeRestoreDSModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
      </div>
    )
  }
}

import React, { Component, PureComponent } from "react";
import { toast } from "react-toastify";
import { CONFIG_MS, ANOMALY_RESULT_STATE, NAME_ERROR, DESCRIPTIVE_SUMMARY_STATE } from "../../consts";
import { handleErrors, isInputNameValidated } from "../ui_utils";
import { HierarchyValueDropDown } from "../HierarchyValueDropDown";
import "./AnomalyRunView.css";
import Form from "react-jsonschema-form";
import Modal from "react-modal";
import Select, { components } from "react-select";
import { AnomalyResultTableComponent } from "../AnomalyResultTableComponent";
import createClass from "create-react-class";
import { CustomSelectBox } from "../CustomSelectBox";
import LoaderComponent from "../util/Loader";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import moment from "moment";
import { ReactCsvDownloader } from "../CsvDownloader/ReactCsvDownloader";
import AlgoComponent from "../util/AlgoComponent/AlgoComponent";
import { fetchCall, validate_decoded_auth_token, requestWithHashDigest } from "../util/util";
import AppContext from "../context/AppContext";
import { DSHierarchyValueDropDown } from "../DSHierarchyValueDropDown";
import {CSVLink} from 'react-csv';
import { CSVDownload } from "react-csv";
import Picky from "react-picky";
import ReactDynamicTable from "../ReactDynamicTable";
import CsvDownload from "react-csv-downloader";

var _loadash = require("lodash");

const customStyles = {
  content: {
    top: "3%",
    left: "10%",
    right: "auto",
    width: "80%",
    height: "85%",
    bottom: "auto",
    padding: "0px",
  },
};
const saveRunModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
const confirmDeleteModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
const autoGenerateModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
export class DescriptiveSummaryRunView extends React.Component {
  constructor(props) {
    super(props);
    this.state = DESCRIPTIVE_SUMMARY_STATE;
  }
  static contextType = AppContext
  componentDidMount() {
    if (
      typeof this.props.match.params.run_id === "undefined" ||
      this.props.match.params.run_id === "undefined"
    ) {
      let flag = this.state.showAnomalySaveRun;
      this.setState({
        showAnomalySaveRun: !flag,
      });
    }
    this.props.getRun(this.props.match.params.run_id);
    this.props.loadDataSourceData(this.props.match.params.exp_id,'ds_summary');
    this.props.resetAnomalyParams();
    // this.props.getAnomalyParams(pageIndex, pageSize,this.props.match.params.run_id)
  }
  componentWillUnmount() {

    this.props.resetAnomalyParams()
  }
  handleErrors = (response) => {
    if (!response.ok) {
      if (response.status == "409") {
        return response.json().then(function (err) {
          err.message = "Duplicates not allowed";
          throw new Error(err.message);
        });
      } else {
        return response.json().then(function (err) {
          throw new Error(err.message);
        });
      }
    }
    return response.json();
  };
  showAnomalySaveRun = () => {
    let flag = this.state.showAnomalySaveRun;
    this.setState({
      showAnomalySaveRun: !flag,
    });
  };
  closeshowAnomalySaveRun = () => {
    let run_name = this.state.anomalyRunName;
    if (!isInputNameValidated(run_name)) {
      this.props.history.push(
        `/app/anomaly/ds/experiment/${this.props.match.params.exp_id}`
      );
    }
    this.setState({
      showAnomalySaveRun: false,
    });
  };
  handleNewAnomalyRun = (e) => {
    let name = e.target.value;
    this.setState({
      anomalyRunName: name,
    });
  };

  handleSaveAnomalyRun = () => {
    let run_name = this.state.anomalyRunName;
    let current_anomaly_params = this.props.anomaly_params;
    if (current_anomaly_params && current_anomaly_params.length > 0) {
      current_anomaly_params.forEach((obj) => {
        obj.task_id = null;
      });
    }
    if (!isInputNameValidated(run_name)) {
      this.setState({
        nameError: NAME_ERROR
      })
      return;
    } else {
      let toastId = null;
      toastId = toast("Fetching Data Sources", { type: toast.TYPE.INFO });
      let anomaly_runs = this.props.anomalyRuns;
      let duplicateName = anomaly_runs.some((item) => {
        return (
          item.run_name.toLocaleLowerCase().split(" ").join("") ===
          run_name.toLocaleLowerCase().split(" ").join("")
        );
      });
      if (duplicateName) {
        const text = "Experiment name already exist!";
        this.props.togglePopup(text);
        return;
      }

      let data = {
        experiment_id: this.props.match.params.exp_id,
        user_id: JSON.parse(this.context.store_user_uuid),
        run_name: this.state.anomalyRunName,
        anomaly_state: current_anomaly_params,
      }

      const request_body_with_digest = requestWithHashDigest(data)
      fetchCall(`/app/api/anomaly/run`, 'POST', JSON.stringify(request_body_with_digest))
        .then((response) => handleErrors(response))
        .then((resultData) => {
          this.setState({
            showAnomalySaveRun: false,
          });
          this.props.history.push(
            `/app/anomaly/ds/experiment/${this.props.match.params.exp_id}/run/${resultData.run_id}`
          );
          this.props.updateAnomalyRunName(resultData.run_name, resultData.user_uuid);
          this.props.saveProgress(resultData.run_id);
          toast.update(toastId, {
            render: "Anomaly experiment created successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "ERROR: " + error.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        });
    }
  };
  downloadResultsAsCSV = () => {
    let ds_id = this.props.store_ds_id
    let toastId = null;
    toastId = toast("downloading results", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/anomaly/export/${ds_id}/${this.props.match.params.exp_id}/${this.props.match.params.run_id}/`, 'POST')
    .then((response) => handleErrors(response,'blob'))
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `descriptive_summary_parameters_${moment().format("DD-MM-YYYY_hh_mm")}.csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()

        toast.update(toastId, {
          render: "Descriptive Summary results downloaded successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: Failed to fetch Descriptive Summary Results",
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });

  }
  openClearAllSelectionModal = () => {
    this.setState({
      isClearAllSelectionModalOpen: !this.state.isClearAllSelectionModalOpen,
    });
  };
  cancelClearAllSelectionModal = () => {
    this.setState({
      isClearAllSelectionModalOpen: false,
    });
  };
  clearAllSelection = () => {

    let toastId = null;

    fetchCall(`/app/api/anomaly/clear_all_anomaly/${this.props.match.params.exp_id}/${this.props.match.params.run_id}`, 'DELETE')
      .then((result) => result.json())
      .then((result) => {
        this.setState({
          isClearAllSelectionModalOpen: false,
        });
        this.props.updateAnomalyParams([]);
        toast.update(toastId, {
          render: "Existing results deleted",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: Failed to delete Anomaly Results",
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  render() {
    const decoded_auth_token = validate_decoded_auth_token()
    const columns = [
      {
        Header: "#",
        accessor: "idx",
        disableFilters: true,
        width: "2%"
      },
      // {
      //   Header: "Task Id",
      //   accessor: "taskId",
      //   Cell: ({ cell: { value } }) => {
      //     return (
      //       <div className="task-id">
      //         {
      //           value                
      //         }
      //       </div>
      //     );
      //   }
      // },
      {
        Header: "Hierarchy",
        accessor: "hierarchy",
        width: "15%"
      },
      {
        Header: "Level Filters",
        accessor: "value",
        width: "15%",
        disableFilters:true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="params_column ds_filters">
              {
                value
              }
            </div>
          );
        }
      },
      {
        Header: "KPI",
        accessor: "kpiSelection",
        width: "10%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="kpi_section params_column ds_filters">
              {
                value
              }
            </div>
          );
        }
      },
      {
        Header: "Algo Type",
        accessor: "algorithm_family",
        width: "15%"
      },
      {
        Header: "Algorithm",
        accessor: "algorithm",
        width: "15%"
      },
      {
        Header: "Parameters",
        accessor: "parameters",
        disableFilters: true,
        width: "10%",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="params_column">
              {
                value
              }
            </div>
          );
        }
      },
      {
        Header: "View",
        accessor: "view",
        width: "6%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view text-center">
              {
                value.task_id ? <a onClick={(e) =>
                  getAnomalyResults(value, value.row_uuid, this.props.match.params.exp_id,
                    this.props.match.params.run_id)
                }>
                  <i className="fa fa-eye"></i>
                </a> : "-"
              }

            </div>
          );
        }
      },
      {
        Header: "Delete",
        accessor: "delete",
        width: "4%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="delete text-center">
              <a onClick={(e) =>
                removeParameterSel(value.row_uuid, value.task_id)
              }
              >
                <i className="far fa-trash-alt"></i>
              </a>
              {/* {
                this.props.user_uuid == decoded_auth_token || this.props.isAdminUser ?
                 <a onClick={(e) =>
                  this.props.removeParameterSel(value.row_uuid, value.task_id)
                }
                >
                   <i className="far fa-trash-alt"></i>
                </a> :
                 <div className="ds-tooltip">
                    <a className="disabled" onClick={(e) =>
                      this.props.removeParameterSel(value.row_uuid, value.task_id)
                    }
                    >
                      <i className="far fa-trash-alt"></i>
                    </a>
                    <span class="ds-tooltiptext">You don't have permission to remove this selection</span>
                  </div>
              } */}

            </div>
          );
        }
      },
      {
        Header: () => (
          <span >
            Status <i className="fa fa-bell" />
          </span>
        ),
        accessor: "status",
        width: "6%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="text-center">
              {value ? (
                <a>
                  <i className="fa fa-check-circle-o"></i>
                </a>
              ) : (
                "-"
              )}
            </div>
          );
        }
      }
    ]
    const data = this.props.anomaly_params && this.props.anomaly_params.map((item, idx) => {
      return {
        idx: idx,
        row_uuid: item.row_uuid,
        taskId: item.task_id,
        hierarchy: item.hierarchy,
        value: item.value,
        kpiSelection: item.kpi_selection,
        algorithm_family: item.algorithm_family,
        algorithm: item.algorithm,
        parameters: item.parameters,
        view: item,
        delete: item,
        status: item.status

      }
    })
    const ds_columns = [
      {
        Header: "S No",
        accessor: "serialNumber",
        width:"5%",
        disableFilters: true,
      },
      {
        Header: "Date",
        accessor: "date",
        width: "10%"
      },
      {
        Header: "Level",
        accessor: "level",
        width: "15%"
      },
      {
        Header: "Summary Type",
        accessor: "summary_type",
        width: "15%"
      },
      {
        Header: "kpi",
        accessor: "kpi",
        width: "15%"
      },
      {
        Header: "Summary",
        accessor: "summary",
        width: "40%"
      },
    ]
    const ds_data = this.props.dsSummaryResultData && this.props.dsSummaryResultData.length>0 && this.props.dsSummaryResultData.map((item,index) => {
      return {
        serialNumber: index + 1,
        date: item.date,
        summary_type: item.summary_type,
        summary: item.summary,
        kpi: item.kpi,
        level: item.level
      }
    })
    const anomaly_results_csv_columns = [
      { label: "row_uuid", key: "row_uuid" },
      { label: "algorithm", key: "algorithm" },
      { label: "hierarchy", key: "hierarchy" },
      { label: "value", key: "value" },
      { label: "algorithm_family", key: "algorithm_family" },
      { label: "kpi", key: "kpi" },
      { label: "kpi_selection", key: "kpi_selection" },
      { label: "parameters", key: "parameters" },
    ]

    const Option = createClass({
      render() {
        return (
          <div>
            <components.Option {...this.props}>
              <input
                type="checkbox"
                checked={this.props.isSelected}
                onChange={(e) => null}
              />{" "}
              <label>{this.props.value} </label>
            </components.Option>
          </div>
        );
      },
    });
    const {
      valuesofDSHierarchyselection,
      valuesDSFilterSelection,
      hierarchyfileNames,
      handleDSHierarchyValueChange,
      algoType,
      handleDSHierarchyChange,
      saveProgress,
      handleKPIChange,
      kpiList,
      handleAlgorithmTypeChange,
      handleAlgorithmChange,
      algoParam,
      algoParamData,
      anomaly_algo_types,
      userAlgo,
      handleParamChange,
      anomaly_params,
      data_to_export,
      exprotDescriptiveResults,
      getAnomalyResults,
      AnomalyResultModelIsOpen,
      selected_user_algo,
      closeAnomanlyResultModal,
      removeParameterSel,
      isdeleteParamSel,
      cancelDeleteParamSelection,
      auto_generate_anomaly_parameter,
      continueDelParamSel,
      isAutoGenerateParamOpen,
      cancelAutoGenerateParams,
      dsSummaryResultData,
      validateDSSelections,
      saveAnomalyFilesPath, handleUploadAnomalyfilePath,
      ds_hierarchy_label
    } = this.props;
    let hierarchyfileNamesList = hierarchyfileNames
    .map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    let AnomalyKPIList = kpiList.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    
    let  updated_anomaly_algo_types = anomaly_algo_types.filter((item)=>{
        return item == 'Descriptive Summary'
      })
    
    let AnomalyalgoTypes = updated_anomaly_algo_types.map((item) => {
      return {
        value: item,
        label: item,
      };
    });

    let userAlgoList = userAlgo.map((item, i) => {
      return {
        value: item.uuid,
        label: item.display_name,
      };
    });
    let selectedAnomalyValues = this.props.valuesAnomalySelection &&
      this.props.valuesAnomalySelection.map((item) => {
        return item.name
      })
    return (
      <div>
        <button
          onClick={(e) =>
            this.props.history.push(
              `/app/anomaly/ds/experiment/${this.props.match.params.exp_id}`
            )
          }
          className='btn btn-sm btn-primary back-btn'
        >
          <i className='fa fa-arrow-left'></i> Back
        </button>
        <div className='project_ds_container'>
          <div className='project_heading'>
            <h4>
              <span>Project : {localStorage.getItem('anomalyExpName')}</span>
              <span></span>(<span>Data Source:</span>
              <span>
                {this.props.store_ds_name && this.props.store_ds_name}
              </span>
              &nbsp; Experiment : {this.props.selected_anomaly_run_name}
            </h4>
          </div>
        </div>

        <React.Fragment>
          <div className='anomaly_hier_kpi_fex'>
            <div className='hier_val'>
              <DSHierarchyValueDropDown
                hierarchies={hierarchyfileNamesList}
                hierarchyValues={valuesDSFilterSelection}
                hierarchyValueOptions={valuesofDSHierarchyselection}
                hierarchyValuesOnChange={handleDSHierarchyValueChange}
                hierarchyOnchange={handleDSHierarchyChange}
                valueSelected={this.props.dsHierarchySelection}
              />
            </div>
          </div>
          <div className='anomaly-kpi-val-container'>
            <fieldset>
              <legend>Select Kpi:</legend>
              <div className='kpi-select'>
                <div>
                  <CustomSelectBox
                    selectedValues={this.props.kpi_selection}
                    multiSelectOptions={AnomalyKPIList}
                    onChangeValues={handleKPIChange}
                    isOpen={false}
                    valueKey='value'
                    labelKey='label'
                    isMultiple={true}
                    includeSelectAll={true}
                    includeFilter={true}
                    selectBoxHeight={300}
                    isDisabled={false}
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </React.Fragment>
        <AlgoComponent
          algoTypeOptions={AnomalyalgoTypes}
          algoTypeChange={handleAlgorithmTypeChange}
          algoTypeValue={algoType}
          algoOptions={userAlgoList}
          algoOnChange={handleAlgorithmChange}
          algoValue={selected_user_algo}
          schema={algoParam}
          formData={algoParamData}
          paramOnChange={handleParamChange}
          liveValidate={true}
          showErrorList={false}
          maxMenuHeight={180}
        />
        <div className='anomaly_ds_btns_run_flex'>
          <div>
            {anomaly_params && anomaly_params.length > 0 ? (
              <button
                type='button'
                onClick={(e) => saveProgress(this.props.match.params.run_id)}
                className='btn btn-sm btn-primary save_progress'
              >
                <i className='fa fa-save'></i> Save Progress
              </button>
            ) : (
              ''
            )}
            <input
              type='button'
              onClick={(e) => validateDSSelections(e)}
              className='btn btn-sm btn-primary'
              value='Add Current Selection'
            />
          </div>
        </div>
        <React.Fragment>
          <fieldset>
            <legend>Upload files:</legend>
            <div className='upload-anomaly-file'>
              <div className='upload-field'>
                <div className='m-10'>
                  <label>Upload selection file :</label>
                  <input
                    type='file'
                    accept='.xls,.xlsx,.csv'
                    onChange={(e) =>
                      this.props.handleDSUploadParamsSelections(
                        e,
                        this.props.match.params.exp_id
                      )
                    }
                  ></input>
                </div>
              </div>
              <div className='upload-or-container'>
                <hr className='hr-text' data-content='OR' />
              </div>
              <div className='upload-path'>
                <div className='m-10 upload-input-field'>
                  <input
                    className='input-field'
                    type='text'
                    onChange={(e) => saveAnomalyFilesPath(e.target.value)}
                    placeholder='Folder where all files are located at!'
                  />
                  <button
                    className='upload-btn'
                    onClick={(e) =>
                      handleUploadAnomalyfilePath(
                        this.props.match.params.exp_id,
                        this.props.match.params.run_id
                      )
                    }
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </React.Fragment>
        <React.Fragment>
          <div className='anomaly_result_table_heading'>
            <div className='selection-table-heading'>
              <h4>Parameter Selections</h4>
            </div>

            <div className='export'>
              {anomaly_params && anomaly_params.length > 0 ? (
                <button
                  onClick={() => this.openClearAllSelectionModal()}
                  className='btn btn-sm btn-alert'
                  disabled={
                    this.props.user_uuid !== decoded_auth_token ||
                    this.props.isAdminUser
                  }
                >
                  Clear All
                </button>
              ) : (
                ''
              )}
              {data_to_export && data_to_export.length > 0 && data_to_export[0].task_id || anomaly_params && anomaly_params.length > 0 && anomaly_params[0].task_id ? (
                <button
                onClick={() => this.downloadResultsAsCSV()}
                className="btn btn-sm btn-success"
              ><i className="fa fa-download"></i> &nbsp; <b>Download</b> &nbsp; <i class="fa fa-file-excel-o" aria-hidden="true"></i> </button>
              ) : (
                <></>
              )}
               {data_to_export && data_to_export.length > 0 && data_to_export[0].task_id == null || anomaly_params && anomaly_params.length > 0 && anomaly_params[0].task_id == null ? (
                  <CSVLink data={anomaly_params} headers={anomaly_results_csv_columns} filename={`descriptive_summary_parameters_${moment().format("DD-MM-YYYY_hh_mm")}.csv`} separator="|">
                    <button className='btn btn-sm btn-success'>
                    <i className='fa fa-download'></i> &nbsp; <b>Download</b> &nbsp;{' '}
                    <i class='fa fa-file-excel-o' aria-hidden='true'></i>{' '}
                    </button>
                  </CSVLink>
             
                    ) : (
                  <></>
                )}
            </div>
          </div>

          <AnomalyResultTableComponent
            columns={columns}
            data={data}
            controlledPageCount={this.props.totalAnomalyPages}
            getAnomalyParams={this.props.getAnomalyParams}
            run_id={this.props.match.params.run_id}
            manualPaginationFlag={this.props.manualPaginationFlag}
            anomalyPageSize={this.props.anomalyPageSize}
          />

          <div className='anomaly-run'>
            {this.props.match.params.run_id ? (
              anomaly_params && anomaly_params.length > 0 ? (
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-success'
                    onClick={() =>
                      this.props.handleDSExecuteRun(
                        this.props.match.params.exp_id,
                        this.props.match.params.run_id,
                        this.state.anomalyRunName
                      )
                    }
                    disabled={
                      this.props.user_uuid !== decoded_auth_token ||
                      this.props.isAdminUser
                    }
                  >
                    Execute Run
                  </button>
                </div>
              ) : (
                ''
              )
            ) : (
              ''
            )}
            {anomaly_params && anomaly_params.length > 0 ? (
              <div>    
                <button
                  type="button"
                  value="Export Results"
                  className="btn btn-sm btn-primary export"
                  disabled={this.props.user_uuid !== decoded_auth_token || this.props.isAdminUser}
                  onClick={() =>
                    exprotDescriptiveResults(
                      this.props.match.params.exp_id,
                      this.props.match.params.run_id
                    )
                  }
                >
                  Export All
                </button>
              </div>

            ) : (
              ""
            )}
          </div>
        </React.Fragment>
        <React.Fragment>
          <Modal
            isOpen={AnomalyResultModelIsOpen}
            onRequestClose={closeAnomanlyResultModal}
            style={customStyles}
            contentLabel='Result'
            ariaHideApp={false}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='modal-title h4'>
                 Descriptive Summary Results
                </div>
                <button
                  type='button'
                  className='close'
                  onClick={closeAnomanlyResultModal}
                >
                  <i className='fa fa-close'></i>
                </button>
              </div>
              <div className='modal-body'>
                <div className="pull-right">
                  <CSVLink data={ds_data} filename={`descriptive_summary_results_${moment().format("DD-MM-YYYY_hh_mm")}.csv`} separator="|">
                      <button className='btn btn-sm btn-success'>
                      <i className='fa fa-download'></i> &nbsp; <b>Download</b> &nbsp;{' '}
                      <i class='fa fa-file-excel-o' aria-hidden='true'></i>{' '}
                      </button>
                  </CSVLink>
                </div>
                <div className="ds-result-table">
                  {dsSummaryResultData && dsSummaryResultData.length > 0 &&
                    <ReactDynamicTable
                      columns={ds_columns}
                      data={ds_data}
                    />
                  }
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={this.state.showAnomalySaveRun}
            //onRequestClose={this.closeshowAnomalySaveRun}
            style={saveRunModal}
            shouldCloseOnOverlayClick={true}
            contentLabel='Add New Run'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='modal-title h4'> Add Experiment</div>
                <button
                  type='button'
                  className='close'
                  onClick={this.closeshowAnomalySaveRun}
                >
                  <i className='fa fa-close'></i>
                </button>
              </div>
              <div className='modal-body'>
                <div className='anomaly_result_table'>
                  <ul className='pipeline-form'>
                    <li className='form-row'>
                      <label htmlFor='pipeline_name'>
                        <span>&#10033;</span>Experiment Name:
                      </label>
                      <input
                        type='text'
                        autoComplete={'off'}
                        onChange={(e) => this.handleNewAnomalyRun(e)}
                      />
                    </li>
                    <li className='form-row'></li>
                    <li className='error-row'>
                      <label></label>
                      <div className='error-msg'>{this.state.nameError}</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-sm btn-primary'
                  onClick={() => this.handleSaveAnomalyRun()}
                >
                  <i className='fa fa-save'></i> Save
                </button>
                <button
                  type='button'
                  className='btn btn-sm btn-alert'
                  onClick={() => this.closeshowAnomalySaveRun()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </React.Fragment>
        <React.Fragment>
          <Modal
            isOpen={isdeleteParamSel}
            style={confirmDeleteModal}
            onRequestClose={cancelDeleteParamSelection}
            contentLabel='Result'
            ariaHideApp={false}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='modal-title h4'>Remove Selection</div>
                <button
                  type='button'
                  className='close'
                  onClick={cancelDeleteParamSelection}
                >
                  <i className='fa fa-close'></i>
                </button>
              </div>
              <div className='modal-body'>
                <ul className='pipeline-form'>
                  <li className='form-column'>
                    <p className='text'>
                      Are you sure want to remove the Selection ?
                    </p>
                  </li>
                </ul>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-sm btn-primary'
                  onClick={() =>
                    continueDelParamSel(this.props.match.params.run_id)
                  }
                >
                  Yes
                </button>
                <button
                  type='button'
                  className='btn btn-sm btn-alert'
                  onClick={cancelDeleteParamSelection}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </React.Fragment>
        <React.Fragment>
          <Modal
            isOpen={isAutoGenerateParamOpen}
            style={autoGenerateModal}
            onRequestClose={cancelAutoGenerateParams}
            contentLabel='Result'
            ariaHideApp={false}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='modal-title h4'>Auto Generate Parameters</div>
                <button
                  type='button'
                  className='close'
                  onClick={cancelAutoGenerateParams}
                >
                  <i className='fa fa-close'></i>
                </button>
              </div>
              <div className='modal-body'>
                <div className='auto_generate_modal_options'>
                  {this.props.dsHierarchySelection &&
                    Object.keys(this.props.dsHierarchySelection).length >
                      0 && (
                      <dl>
                        <dt>Hierarchy</dt>
                        <dd>{this.props.dsHierarchySelection.value}</dd>
                      </dl>
                    )}
                  {selectedAnomalyValues &&
                    selectedAnomalyValues.length > 0 && (
                      <dl>
                        <dt>Values</dt>
                        <dd>
                          {selectedAnomalyValues.length > 2
                            ? selectedAnomalyValues.length + ' selected'
                            : selectedAnomalyValues.join(',')}
                        </dd>
                      </dl>
                    )}
                  {this.props.kpi_selection &&
                    this.props.kpi_selection.length > 0 && (
                      <dl>
                        <dt>KPI</dt>
                        <dd>
                          {this.props.kpi_selection
                            .map((item) => {
                              return item.value;
                            })
                            .join(',')}
                        </dd>
                      </dl>
                    )}
                  {this.props.algoType &&
                    Object.keys(this.props.algoType).length > 0 && (
                      <dl>
                        <dt>Anomaly family</dt>
                        <dd>{this.props.algoType.value}</dd>
                      </dl>
                    )}
                </div>
                <div className='auto_generate_modal'>
                  <p className='text'>
                    Are you sure want to generate Parameters ?
                  </p>
                </div>
              </div>
              <div className='modal-footer'>
                <h5>
                  Note : Run auto for specific values by selecting options{' '}
                  <a>
                    <i
                      className='qtip tip-right'
                      data-tip='Supported Values : Hierarchy, Values, KPI, Anomaly Family'
                    >
                      <i className='fa fa-question'></i>
                    </i>
                  </a>{' '}
                  in dropdown.{' '}
                </h5>

                <button
                  type='button'
                  className='btn btn-sm btn-primary'
                  onClick={() =>
                    auto_generate_anomaly_parameter(
                      this.props.match.params.exp_id,
                      this.props.match.params.run_id
                    )
                  }
                >
                  Yes
                </button>
                <button
                  type='button'
                  className='btn btn-sm btn-alert'
                  onClick={cancelAutoGenerateParams}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </React.Fragment>
        <React.Fragment>
          <Modal
            isOpen={this.state.isClearAllSelectionModalOpen}
            style={confirmDeleteModal}
            onRequestClose={this.cancelClearAllSelectionModal}
            contentLabel='Result'
            ariaHideApp={false}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='modal-title h4'>
                  Clear All Selected Parameters
                </div>
                <button
                  type='button'
                  className='close'
                  onClick={this.cancelClearAllSelectionModal}
                >
                  <i className='fa fa-close'></i>
                </button>
              </div>
              <div className='modal-body'>
                <ul className='pipeline-form'>
                  <li className='form-column'>
                    <p className='text'>
                      Are you sure want to delete all the selections ?
                    </p>
                  </li>
                </ul>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-sm btn-primary'
                  onClick={() => this.clearAllSelection()}
                >
                  Yes
                </button>
                <button
                  type='button'
                  className='btn btn-sm btn-alert'
                  onClick={this.cancelClearAllSelectionModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </React.Fragment>
        <React.Fragment>
          <Modal
            isOpen={AnomalyResultModelIsOpen}
            onRequestClose={closeAnomanlyResultModal}
            style={customStyles}
            contentLabel='Result'
            ariaHideApp={false}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='modal-title h4'>
                 Descriptive Summary Results
                </div>
                <button
                  type='button'
                  className='close'
                  onClick={closeAnomanlyResultModal}
                >
                  <i className='fa fa-close'></i>
                </button>
              </div>
              <div className='modal-body'>
                <div className="ds-result-heading">
                  <label htmlFor='pipeline_name'>
                    <b>Hierarchy:</b>
                  </label> <label>{ds_hierarchy_label}</label>
                 
                </div>
                <div className="pull-right">
                  <CSVLink data={ds_data} filename={`descriptive_summary_results_${moment().format("DD-MM-YYYY_hh_mm")}.csv`} separator="|">
                      <button className='btn btn-sm btn-success'>
                      <i className='fa fa-download'></i> &nbsp; <b>Download</b> &nbsp;{' '}
                      <i class='fa fa-file-excel-o' aria-hidden='true'></i>{' '}
                      </button>
                  </CSVLink>
                </div>
                <div className="ds-result-table">
                  {dsSummaryResultData && dsSummaryResultData.length > 0 &&
                    <ReactDynamicTable
                      columns={ds_columns}
                      data={ds_data}
                    />
                  }
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={this.props.isExportAllModelOpen}
            onRequestClose={this.props.closeExportModal}
            style={saveRunModal}
            shouldCloseOnOverlayClick={true}
            contentLabel='Export All'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='modal-title h4'>Export All</div>
                <button
                  type='button'
                  className='close'
                  onClick={() => this.props.closeExportModal()}
                >
                  <i className='fa fa-close'></i>
                </button>
              </div>
              <div className='modal-body'>
                <div className='anomaly_result_table'>
                <ul className='pipeline-form'>
                  <li className='form-column'>
                    <p className='text'>
                      Are you sure want to download all the task id's result as a single csv file ?
                    </p>
                  </li>
                </ul>
                </div>
              </div>
              <div className='modal-footer'>
                <CSVLink data={this.props.dsSummaryResultAllData} filename={`descriptive_summary_all_parameters_${moment().format("DD-MM-YYYY_hh_mm")}.csv`} separator="|">
                    <button className='btn btn-sm btn-success'>
                    <i className='fa fa-download'></i> &nbsp; <b>Download</b> &nbsp;{' '}
                    <i class='fa fa-file-excel-o' aria-hidden='true'></i>{' '}
                    </button>
                </CSVLink>
                <button
                  type='button'
                  className='btn btn-sm btn-alert'
                  onClick={() => this.props.closeExportModal()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </React.Fragment>
        <LoaderComponent isLoaderLoading={this.props.isLoaderLoading} />
      </div>
    );
  }
}
export default DescriptiveSummaryRunView;
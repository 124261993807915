import React from "react";
import "./DataUpload.css";
import "@atlaskit/css-reset";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import Column from "./Column";
import Modal from "react-modal";
import { Popup } from "../util/popup/Popup";
import Select, { components } from "react-select";
import createClass from "create-react-class";
import { INGESTION_STATUS, INGESTION_TYPE } from "../../consts";

import { CustomSelectBox } from "../CustomSelectBox";

// const Container = styled.div`
//   display: flex;
// `;
const customStyles = {
  menu: (provided) => {
    return {
      ...provided,
      zIndex:'1001'
    };
  },
};
const alertModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0x",
  },
};
export class DataUpload extends React.Component {
  render() {
    const {
      frequency,
      togglePopup,
      showPopup,
      showHierarchyAlert,
      handleFileData,
      handleJsonData,
      jumptoStepZero,
      continueToHierarchy,
      handleKpiType,
      handleKpiRatio,
      jumptoHierarchySet,
      popuptext,
      handleFrequency,
      kpiSectionvalues,
      handleWindowSize,
      uploadedFiles,
      handleChangeGranularfile,
      IsuploadedGranularFile,
      uploadedGranularFile,
      kpiTypeMap,
      current_frequency,
      current_window,
      ratio_kpis,
      handleHierarchyPanelValues,
      handleTimePanelValues,
      hierarchy_options,
      time_options,
      kpi_selected_panel_values,
      handleKpiPanelValues,
      time_panel_value,
      kpi_options, ingestion_type, ingestion_status,
      hierarchy_selected_panel_values, data_source_type,
      uploadedJsonConfigFile, isUploadedJsonConfigFile,
    } = this.props;
    const style = IsuploadedGranularFile ? { display: "none" } : {};
    const uploaded = IsuploadedGranularFile ? {} : { display: "none" };
    const configStyle = isUploadedJsonConfigFile ? { display: "none" } : {};
    const uploadedConfigFile = isUploadedJsonConfigFile ? {} : { display: "none" };
    const ingestionStatusAndType =  false; // ingestion_status === INGESTION_STATUS && ingestion_type === INGESTION_TYPE
    var kpiSectionvaluesList =
      kpiSectionvalues.length > 0 &&
      this.props.kpiSectionvalues.map((item, i) => {
        return (
          <option key={i} value={item.name}>
            {item.name}
          </option>
        );
      }, this);

    if (kpi_selected_panel_values && kpi_selected_panel_values.length > 0) {
      var display_kpi_type_map = kpi_selected_panel_values.map((item, index) => {
        return (
          <div className="custom_row" key={index}>
            <div className="kpi_name">
             
                {item.label}
             
            </div>
            <div className="kpi_type">
              <select
                value={kpiTypeMap[item.label]}
                onChange={(e) => handleKpiType(item.label, e.target.value)}
                disabled={ingestionStatusAndType}
              >
                {kpiSectionvaluesList}
              </select>
            </div>
            <div className="kpi_checkbox">
              <input
                type="checkbox"
                checked={ratio_kpis[item.label]}
                onClick={(e) => handleKpiRatio(item.label, e)}
                disabled={ingestionStatusAndType}
              />
            </div>
          </div>
        );
      });
    }
    return (
      <React.Fragment>
        {uploadedFiles.length > 0 ? (
          <div className="file_list">
            <span className="file_uploads_heading">
              Uploaded Hierarchy Files
            </span>
            <div className="h_files">
              {uploadedFiles.map((obj, index) => {
                return (
                  <div key={index} className="uploaded_f">
                    <div className="f_name">{obj}</div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
            ""
          )}

        <div className="upload_flex_container">
          {
            data_source_type == "BATCH" ? <div className="batch-heading"><span>Batch Upload</span></div> :

              <div className="upload_csv" style={style}>
                <label><b>Most granular Data</b></label>
                <input
                  type="file"
                  id="file-upload"
                  accept=".xls,.xlsx,.csv"
                  onChange={handleFileData}
                ></input>
                <br />
                <span>*(Please upload .csv files only)</span>
              </div>
          }
          <div className="uploaded_csv" style={uploaded}>
            <span>
              Uploaded File: <b> {uploadedGranularFile} </b>{" "}
            </span>
            {
              // ingestion_status === "STATIC INGESTION COMPLETED" && ingestion_type === "HISTORICAL" ? "" :
                <span>
                  <a
                    className="change-uploadfile"
                    onClick={handleChangeGranularfile}
                  >
                    Change/edit
              </a>

                </span>
            }

          </div>
          <div className="upload_csv" style={configStyle}>
            <label><b>Selection Config</b></label>
            <input
              type="file"
              id="json-file-upload"
              accept=".json"
              onChange={handleJsonData}
              disabled={!IsuploadedGranularFile}
            ></input>
            <br />
            <span>*(Please upload .json files only)</span>
          </div>
          <div className="uploaded_csv" style={uploadedConfigFile}>
            <span>
              Uploaded config File: <b> {uploadedJsonConfigFile} </b>{" "}
            </span>
            {
              // ingestion_status === "STATIC INGESTION COMPLETED" && ingestion_type === "HISTORICAL" ? "" :
                <span>
                  <a
                    className="change-uploadfile"
                    onClick={handleChangeGranularfile}
                  >
                    Change/edit
              </a>

                </span>
            }

          </div>
          <div className="frequency">
            <label>Frequency</label>
            <Select
              onChange={handleFrequency}
              options={frequency}
              value={current_frequency}
              styles={customStyles}
              isDisabled={ingestionStatusAndType}
              id="data_frequency_value"
            />
          </div>
          <div className="window_main">
            <div className="window">
              <label>Window</label>
              <input
                type="text"
                pattern="[0-9]*"
                onInput={(e) => handleWindowSize(e)}
                value={current_window}
                id="window_size_for_data"
                disabled={ingestionStatusAndType}
              />
            </div>
          </div>
        </div>
        <div className="multiselect_container">
          <div className="mulitiselect_panels">
            <label>Hierarchy</label>
            <CustomSelectBox
              selectedValues={hierarchy_selected_panel_values}
              multiSelectOptions={hierarchy_options}
              onChangeValues={handleHierarchyPanelValues}
              isOpen={true}
              valueKey="value"
              labelKey="label"
              isMultiple={true}
              includeSelectAll={true}
              includeFilter={true}
              selectBoxHeight={300}
              isDisabled={ingestionStatusAndType}
              id="hierarchy_select_field"
            />
          </div>
          <div className="mulitiselect_panels">
            <label>Time</label>
            <CustomSelectBox
              selectedValues={time_panel_value}
              multiSelectOptions={time_options}
              onChangeValues={handleTimePanelValues}
              isOpen={true}
              valueKey="value"
              labelKey="label"
              isMultiple={false}
              includeSelectAll={true}
              includeFilter={true}
              selectBoxHeight={300}
              isDisabled={ingestionStatusAndType}
              id="time_select_field"
            />
          </div>
          <div className="mulitiselect_panels">
            <label>KPI</label>
            <CustomSelectBox
              selectedValues={kpi_selected_panel_values}
              multiSelectOptions={kpi_options}
              onChangeValues={handleKpiPanelValues}
              isOpen={true}
              valueKey="value"
              labelKey="label"
              isMultiple={true}
              includeSelectAll={true}
              includeFilter={true}
              selectBoxHeight={300}
              isDisabled={ingestionStatusAndType}
              id="hierarchy_select_field"
            />
          </div>
          <div className="kpi_section_main">
            {/* <table className="kpi_fixed_header">
              <thead>
                <tr>
                  <th className="kpi">KPI</th>
                  <th className="type">Type</th>
                  <th className="ratio">Ratio</th>
                </tr>
              </thead>
              <tbody>
                {display_kpi_type_map}
              </tbody>
            </table> */}
            <div className="table_kpi_fixed_header">
              <div className="header">
                  <div className="kpi">KPI</div>
                  <div className="type">Type</div>
                  <div className="ratio">Ratio</div>
              </div>
              <div className="table_body">
                  {display_kpi_type_map}
              </div>
               
              
            </div>
          </div>
        </div>
        <div className="hierarchy_flex_container"></div>
        <button
          className="btn btn-sm btn-primary" autoFocus
          onClick={() => jumptoHierarchySet(this.props)}
        >
          Next
        </button>

        <div>
          {showPopup ? (
            <Popup
              props={this.props.jumptoSt}
              text={popuptext}
              closePopup={togglePopup.bind(this)}
            />
          ) : null}
        </div>
        <div className="hierarchy_alert">
          {showHierarchyAlert ? (
            <Modal
              isOpen={true}
              onRequestClose={() => jumptoStepZero(this.props)}
              style={alertModalStyles}
              contentLabel="alert"
              ariaHideApp={false}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div className="modal-title h4"> Alert</div>
                  <button
                    type="button"
                    className="close"
                    onClick={() => jumptoStepZero(this.props)}
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="alert-content">
                    <h4>
                      Hierarchy values are empty, please click continue if you
                      wish to continue or cancel to select hierarchy values
                     </h4>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() => continueToHierarchy(this.props)}
                  >
                    continue
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-alert"
                    onClick={() => jumptoStepZero(this.props)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

import React, { Component } from "react";
import StepZilla from "react-stepzilla";
import { toast } from "react-toastify";
import { CONFIG_MS, UPLOAD_INITIALSTATE } from "../consts";
import { handleErrors } from "./ui_utils";
import { DataUpload } from "./UploadSteps/DataUpload";
import { KpiRatioConfiguration } from "./UploadSteps/KpiRatioConfiguration";
import { HierarchyMapping } from "./UploadSteps/HierarchyMapping";
import { HierarchySet } from "./UploadSteps/HierarchySet";
import { UploadSteps } from "./UploadSteps/UploadSteps";
import { fetchCall, groupBy } from "./util/util";
import "./Upload.css";
import { sendAmplitudeData } from "./util/amplitude";
import { HybridHierarchyMapping } from "./UploadSteps/HybridHierarchyMapping";

import {
  UPLOADED_DATA,
  HIERARCHY_SELECT,
  RATIO_KPI,
  HIERARCHY_SETS,
} from "./util/consts_amplitude";
import { compareTwoArrayObjects } from "./util/util";
import AppContext from "./context/AppContext";

var _loadash = require("lodash");
const parseJson = require('parse-json');

function UploadstepsFun(props) {
  const UploadSteps = [
    {
      name: "File Selection",
      component: (
        <DataUpload
          frequency={props.frequency}
          value={props.value}
          kpiSectionvalues={props.kpiSectionvalues}
          fileData={props.fileData}
          handleFileData={props.handleFileData}
          handleJsonData={props.handleJsonData}
          hierarchyValues={props.hierarchyValues}
          uploadstepPanelObjContent={props.uploadstepPanelObjContent}
          renderKpiSection={props.renderKpiSection}
          onloadDataupload={props.onloadDataupload}
          hierarchyModelIsOpen={props.hierarchyModelIsOpen}
          togglePopup={props.togglePopup}
          showPopup={props.showPopup}
          jumptoHierarchySet={props.jumptoHierarchySet}
          popuptext={props.popuptext}
          continueToHierarchy={props.continueToHierarchy}
          showHierarchyAlert={props.showHierarchyAlert}
          jumptoStepZero={props.jumptoStepZero}
          handleFrequency={props.handleFrequency}
          handleWindowSize={props.handleWindowSize}
          getUploadedFile={props.getUploadedFile}
          uploadedFiles={props.uploadedFiles}
          pipelineName={props.pipelineName}
          uploadedGranularFile={props.uploadedGranularFile}
          IsuploadedGranularFile={props.IsuploadedGranularFile}
          handleChangeGranularfile={props.handleChangeGranularfile}
          handleKpiType={props.handleKpiType}
          renderKpiSectionValues={props.renderKpiSectionValues}
          kpi_names={props.kpi_names}
          kpiTypeMap={props.kpiTypeMap}
          current_frequency={props.current_frequency}
          current_window={props.current_window}
          handleKpiRatio={props.handleKpiRatio}
          ratio_kpis={props.ratio_kpis}
          uploadedFileMasterValues={props.uploadedFileMasterValues}
          handleHierarchyPanelValues={props.handleHierarchyPanelValues}
          handleTimePanelValues={props.handleTimePanelValues}
          time_options={props.time_options}
          kpi_options={props.kpi_options}
          handleKpiPanelValues={props.handleKpiPanelValues}
          hierarchy_options={props.hierarchy_options}
          time_panel_value={props.time_panel_value}
          kpi_selected_panel_values={props.kpi_selected_panel_values}
          hierarchy_selected_panel_values={
            props.hierarchy_selected_panel_values
          }
          data_source_type={props.data_source_type}
          ingestion_type={props.ingestion_status_arr && props.ingestion_status_arr[0]}
          ingestion_status={props.ingestion_status_arr && props.ingestion_status_arr[1]}
          uploadedJsonConfigFile={props.uploadedJsonConfigFile}
          isUploadedJsonConfigFile={props.isUploadedJsonConfigFile}
        />
      ),
    },
    {
      name: "Hierarchy Creation",
      component: (
        <HierarchySet
          noOfSets={props.noOfSets}
          fileData={props.fileData}
          renderSets={props.renderSets}
          handleSetCreation={props.handleSetCreation}
          handleNumberofSets={props.handleNumberofSets}
          hierarchyValues={props.hierarchyValues}
          hierarchySetValues={props.hierarchySetValues}
          uploadstepPanelObjContent={props.uploadstepPanelObjContent}
          hierarchySetPanelsOrder={props.hierarchySetPanelsOrder}
          hierarchySetPanels={props.hierarchySetPanels}
          hierarchySetKeyObjData={props.hierarchySetKeyObjData}
          onDragEndHir={props.onDragEndHir}
          onloadHierarchySet={props.onloadHierarchySet}
          togglePopup={props.togglePopup}
          showPopup={props.showPopup}
          jumptoConfigureRatio={props.jumptoConfigureRatio}
          popuptext={props.popuptext}
          hierarchy_selected_panel_values={props.hierarchy_selected_panel_values}
          hierarchySets={props.hierarchySets}
          ingestion_type={props.ingestion_status_arr && props.ingestion_status_arr[0]}
          ingestion_status={props.ingestion_status_arr && props.ingestion_status_arr[1]}
        />
      ),
    },
    {
      name: "Ratio KPIs",
      component: (
        <KpiRatioConfiguration
          loadRemainingMasterValues={props.loadRemainingMasterValues}
          ratio_kpis={props.ratio_kpis}
          fileData={props.fileData}
          updateSourceKpiRatio={props.updateSourceKpiRatio}
          objective_kpi={props.objective_kpi}
          handleObjectiveKpiChange={props.handleObjectiveKpiChange}
          source_kpi_ratios={props.source_kpi_ratios}
          jumptoHybridHierarchyMap={props.jumptoHybridHierarchyMap}
          selected_objective_Kpi={props.selected_objective_Kpi}
          handleThresholdValue={props.handleThresholdValue}
          handleBenchmarkPrefixFieldChange={props.handleBenchmarkPrefixFieldChange}
          benchmark_prefix={props.benchmark_prefix}
          threshold_value={props.threshold_value}
          filefirstRow={props.filefirstRow}
          fileData={props.fileData}
          hierarchy_selected_panel_values={
            props.hierarchy_selected_panel_values
          }
          hierarchy_options={props.hierarchy_options}
          kpi_selected_panel_values={props.kpi_selected_panel_values}
          handleSelectedOtherColumns={props.handleSelectedOtherColumns}
          selected_other_columns={props.selected_other_columns}
          kpi_options={props.kpi_options}
          data_source_type={props.data_source_type}
          ingestion_type={props.ingestion_status_arr && props.ingestion_status_arr[0]}
          ingestion_status={props.ingestion_status_arr && props.ingestion_status_arr[1]}
          isEmptyObjectiveKpiOrThreshold={props.isEmptyObjectiveKpiOrThreshold}
          continueToHybridHierarchyMap={props.continueToHybridHierarchyMap}
          closeObjectiveConfirmationModal={props.closeObjectiveConfirmationModal}
          handleDriverKPIChange={props.handleDriverKPIChange}
          handleMaxPercentageThreshold={props.handleMaxPercentageThreshold}
          handleMinPercentageThreshold={props.handleMinPercentageThreshold}
          handleTopValueSelections={props.handleTopValueSelections}
          maxPercentageThreshold={props.maxPercentageThreshold}
          minPercentageThreshold={props.minPercentageThreshold}
          topValueSelections={props.topValueSelections}
          handleDriverObjectiveKPIChange={props.handleDriverObjectiveKPIChange}
          selected_driver_objective_Kpi={props.selected_driver_objective_Kpi}
          driverKpiTableRows={props.driverKpiTableRows}
          handleAddObjectiveDriverKPIRow={props.handleAddObjectiveDriverKPIRow}
          handleDeleteDriverKPI={props.handleDeleteDriverKPI}
          handleDeleteDriverKPI={props.handleDeleteDriverKPI}
          handlePersistantAlertWindow={props.handlePersistantAlertWindow}
          persistantAlertWindow={props.persistantAlertWindow}
        />
      ),
    },
    {
      name: "Hybrid hierarchy",
      component: (
        <HybridHierarchyMapping
          handleHierarchyLevel={props.handleHierarchyLevel}
          hierarchySetPanelsOrder={props.hierarchySetPanelsOrder}
          hierarchySets={props.hierarchySets}
          hierarchySetKeyObjData={props.hierarchySetKeyObjData}
          hybridHierarchy={props.hybridHierarchy}
          closeHierarchyModal={props.closeHierarchyModal}
          resetHierarchyBtn={props.resetHierarchyBtn}
          addHybridHierarchy={props.addHybridHierarchy}
          hierarchyDataPanels={props.hierarchyDataPanels}
          hierarchyDataMap={props.hierarchyDataMap}
          handlehierarchyDataMap={props.handlehierarchyDataMap}
          handleDelHybridHierarchy={props.handleDelHybridHierarchy}
          isRemoveHybridHierarchyOpen={props.isRemoveHybridHierarchyOpen}
          cancelRemoveHybridHierarchyOpen={
            props.cancelRemoveHybridHierarchyOpen
          }
          confirmRemoveHybridHierarchyOpen={
            props.confirmRemoveHybridHierarchyOpen
          }
          jumpToUploadStep={props.jumpToUploadStep}
          customHybridText={props.customHybridText}
          handleCustomHybridPayload={props.handleCustomHybridPayload}
          hierarchyCombination={props.hierarchyCombination}
          ingestion_type={props.ingestion_status_arr && props.ingestion_status_arr[0]}
          ingestion_status={props.ingestion_status_arr && props.ingestion_status_arr[1]}

          addCustomHybridPayload={props.addCustomHybridPayload}
        />
      ),
    },

    {
      name: "Data Upload",
      component: (
        <HierarchyMapping
          hierarchyDataMap={props.hierarchyDataMap}
          handleHierarchyFiles={props.handleHierarchyFiles}
          uploadData={props.uploadData}
          locationofHierarchyFiles={props.locationofHierarchyFiles}
          handleUploadedFileEdit={props.handleUploadedFileEdit}
          handlehierarchyDataMap={props.handlehierarchyDataMap}
          handleUploadBackButton={props.handleUploadBackButton}
          ingestion_mode={props.ingestion_mode}
          data_source_type={props.data_source_type}
          handleUploadBatchData={props.handleUploadBatchData}
          data_source_payload={props.data_source_payload}
          ingestion_type={props.ingestion_status_arr && props.ingestion_status_arr[0]}
          ingestion_status={props.ingestion_status_arr && props.ingestion_status_arr[1]}
          isLoaderLoading={props.isLoaderLoading}
          ingestion_upload_files={props.ingestion_upload_files}
          ingestion_id={props.ingestion_id}
          hierarchy_file_folder ={props.hierarchy_file_folder}
        />
      ),
    },
  ];
  return (
    <StepZilla
      stepsNavigation={false}
      steps={UploadSteps}
      showNavigation={props.isShowNavigation}
      preventEnterSubmission={true}
      nextButtonText={"proceed"}
      startAtStep={(props.ingestion_status_arr && props.ingestion_status_arr[0] == "INCREMENTAL" &&
        (props.ingestion_status_arr[1] == "CREATED" || props.ingestion_status_arr[1] == "STATIC INGESTION COMPLETED")) ? 4 : 0}
    />
  );
}

export class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = UPLOAD_INITIALSTATE;
  }
  static contextType = AppContext
  componentDidMount = () => { 
    if (this.props.location.state) {
      const data_source_id = this.props.match.params.datasource_id;
      const ingestion_id = this.props.match.params.ingestion_id;
      this.handleDataSourceCall(data_source_id, ingestion_id);
      this.getUploadedFile();
      if (this.state.kpi_selected_panel_values.length === 0) {
        this.setState({ kpiTypeMap: {}, ratio_kpis: {}, source_kpi_ratios: {},
        });
      }
    }
  };

  handleHierarchyPanelValues = (values) => {
    let hierarchy_selected_panel_values = this.state.hierarchy_selected_panel_values
    let filefirstRow = this.state.filefirstRow;
    let time_options = this.state.time_options
    let kpi_options = this.state.kpi_options
    let uploadedFileMasterValues = this.state.uploadedFileMasterValues
    let unSelectedHierarchies = time_options.length + kpi_options.length + hierarchy_selected_panel_values.length
    if (values.length == 0) {
      if (unSelectedHierarchies == uploadedFileMasterValues.length || hierarchy_selected_panel_values.length == 1) {
        this.setState({
          hierarchy_selected_panel_values: [],
        });
      }
      else {
        this.setState({
          hierarchy_selected_panel_values: hierarchy_selected_panel_values,
        });
        const text =
          "Selected values contains number format, Please select correct hierarchy";
        this.props.togglePopup(text);
        return;
      }
    } else {
      let data_source_type = this.state.data_source_type
      if (data_source_type == "BATCH") {
        this.setState({
          hierarchy_selected_panel_values: values,
        });
      } else {
        let time_options = this.state.time_options;
        let kpi_options = this.state.kpi_options;
        let current_hierarchy_selected_panel_values = this.state
          .hierarchy_selected_panel_values;
        let pattern = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
        let isNotHierarchy = values.some((item) => {
          return !isNaN(filefirstRow[item.value]) || pattern.test(filefirstRow[item.value])
        })
        if (isNotHierarchy) {
          const text =
            "Selected values contains number format, Please select correct hierarchy";
          this.props.togglePopup(text);
          return;
        } else {
          if (time_options && time_options.length > 0) {
            var filteredTimeValues = time_options.filter((item) => {
              return !values.some((obj) => obj.label == item.label);
            });
          }
          if (kpi_options && kpi_options.length > 0) {
            var filteredKpiValues = kpi_options.filter((item) => {
              return !values.some((obj) => obj.label == item.label);
            });
          }
          if (
            current_hierarchy_selected_panel_values &&
            current_hierarchy_selected_panel_values.length > 0
          ) {
            var uncheckedHierarchy = current_hierarchy_selected_panel_values.filter(
              (item) => {
                return !values.some((obj) => obj.label == item.label);
              }
            );
            if (values.length == 0 && uncheckedHierarchy.length == 0) {
              this.setState({
                hierarchy_selected_panel_values
              })
            } else {
              uncheckedHierarchy.forEach((item) => {
                filteredKpiValues.unshift(item);
                filteredTimeValues.unshift(item);
              });
            }
          }
        }

        this.setState({
          time_options: filteredTimeValues,
          kpi_options: filteredKpiValues,
          hierarchy_selected_panel_values: values,
        })
      }
    }

  };
  handleTimePanelValues = (values) => {
    let data_source_type = this.state.data_source_type
    let time_panel_value = this.state.time_panel_value
    if (Object.keys(time_panel_value).length > 0 && time_panel_value == values) {
      return
    }
    if (data_source_type == "BATCH") {
      this.setState({
        time_panel_value: values,
      });
    } else {
      let time_options = values;
      let time_panel_values = this.state.time_panel_value;
      let hierarchy_options = this.state.hierarchy_options;
      let kpi_options = this.state.kpi_options;
      let pattern = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
      let filefirstRow = this.state.filefirstRow;
      let selectedItem = filefirstRow[values.label];
      if (!pattern.test(selectedItem)) {
        const text = "The selected value does not contain `YYYY-MM-DD` format";
        this.props.togglePopup(text);
        return;
      }
      let filteredHierarchyValues = hierarchy_options.filter((item) => {
        return time_options.label !== item.label;
      });
      let filteredKpiValues = kpi_options.filter((item) => {
        return time_options.label !== item.label;
      });
      if (time_panel_values && Object.keys(time_panel_values).length > 0) {
        filteredHierarchyValues.unshift(time_panel_values);
        filteredKpiValues.unshift(time_panel_values);
      }
      this.setState({
        hierarchy_options: filteredHierarchyValues,
        kpi_options: filteredKpiValues,
        time_panel_value: values,
      });
    }

  };
  handleKpiPanelValues = (current_values) => {
    let current_kpi_selected_panel_values = this.state.kpi_selected_panel_values
    if (current_values.length == 0) {
      let time_options = this.state.time_options
      let hierarchy_options = this.state.hierarchy_options
      let uploadedFileMasterValues = this.state.uploadedFileMasterValues
      let unselectedKpis = time_options.length + hierarchy_options.length + current_kpi_selected_panel_values.length
      if (unselectedKpis == uploadedFileMasterValues.length || current_kpi_selected_panel_values.length == 1) {
        this.setState({
          kpi_selected_panel_values: [],
          kpiTypeMap: {}
        });
      } else {
        this.setState({
          kpi_selected_panel_values: current_kpi_selected_panel_values,
        });
        const text =
          "Selected values does not contains number format, Please select correct KPI";
        this.props.togglePopup(text);
        return;
      }
    } else {
      let data_source_type = this.state.data_source_type
      let kpiTypeMap = this.state.kpiTypeMap;
      let ratio_kpis = this.state.ratio_kpis
      let source_kpi_ratios = this.state.source_kpi_ratios
      let unselected_kpi = current_kpi_selected_panel_values && current_kpi_selected_panel_values.length > 0 &&
        current_kpi_selected_panel_values.filter((item) => {
          return !current_values.some((obj) => obj.label == item.label);
        });
      if (unselected_kpi && unselected_kpi.length > 0) {
        unselected_kpi.forEach((item) => {
          delete kpiTypeMap[item.label];
          if (Object.keys(ratio_kpis).includes(item.label)) {
            delete ratio_kpis[item.label]
            delete source_kpi_ratios[item.label]
          }
        });
      } else {
        current_values.forEach((item) => {
          kpiTypeMap[item.label] = "c";
        })
      }
      if (data_source_type == "BATCH") {
        this.setState({
          kpi_selected_panel_values: current_values,
          kpiTypeMap: kpiTypeMap
        });
      } else {
        let time_options = this.state.time_options;
        let hierarchy_options = this.state.hierarchy_options;
        let filefirstRow = this.state.filefirstRow;
        let isNotKpi = current_values.some((item) => {
          return isNaN(filefirstRow[item.value])
        })
        if (isNotKpi) {
          const text =
            "Selected values does not contains number format, Please select correct KPI";
          this.props.togglePopup(text);
          return;
        } else {
          var filteredTimeValues = time_options.filter((item) => {
            return !current_values.some((obj) => obj.label == item.label);
          });
          var filteredHierarchyValues = hierarchy_options.filter((item) => {
            return !current_values.some((obj) => obj.label == item.label);
          });
          if (
            current_kpi_selected_panel_values &&
            current_kpi_selected_panel_values.length > 0
          ) {
            var uncheckedKpis = current_kpi_selected_panel_values.filter(
              (item) => {
                return !current_values.some((obj) => obj.label == item.label);
              }
            );
            uncheckedKpis.forEach((item) => {
              filteredHierarchyValues.unshift(item);
              filteredTimeValues.unshift(item);
            });
          }
        }
        this.setState({
          hierarchy_options: filteredHierarchyValues,
          time_options: filteredTimeValues,
          kpi_selected_panel_values: current_values,
          kpiTypeMap: kpiTypeMap,
        });
      }
    }
  };

  handleChangeGranularfile = () => {
     
    document.getElementById("file-upload").value = "";
    document.getElementById("json-file-upload").value = ""
    let hierarchy_options = this.state.hierarchy_options;
    let currenthierarchySetPanels = this.state.hierarchySetPanels;
    let currenthierarchySetPanelsOrder = [
      this.state.hierarchySetPanelsOrder[0],
    ];
    currenthierarchySetPanelsOrder = [];
    currenthierarchySetPanelsOrder = ["hierarchySetMasterPanel"];
    let currenthirarchypanelObjArr =
      currenthierarchySetPanels.hierarchySetMasterPanel.panelObj;
    let currenthirData = this.state.fileData;
    let currenthierarchySetKeyObjData = {};
    var currenthirObjectData = [];
    let newcHPanel = {};
    newcHPanel[`hierarchySetMasterPanel`] = this.state.hierarchySetPanels[
      Object.keys(this.state.hierarchySetPanels)[0]
    ];
    currenthirarchypanelObjArr.forEach((element) => {
      currenthierarchySetKeyObjData[currenthirData[element].id] =
        currenthirData[element];
      currenthirObjectData.push(
        currenthierarchySetKeyObjData[currenthirData[element].id]
      );
    });
    currenthierarchySetPanels = newcHPanel;

    currenthierarchySetPanels.hierarchySetMasterPanel.panelObj = [];
    let currInputvalue = this.state.noOfSets;
    currInputvalue = 0;
    let current_window = this.state.current_window
    current_window = ""
    // this.setState(
    //   UPLOAD_INITIALSTATE
    // )
    this.setState({
      IsuploadedGranularFile: false,
      isUploadedJsonConfigFile: false,
      hierarchySetPanels: currenthierarchySetPanels,
      hierarchySetPanelsOrder: currenthierarchySetPanelsOrder,
      noOfSets: currInputvalue,
      uploadedGranularFile: "",
      uploadedJsonConfigFile: "",
      hierarchy_options: [],
      hierarchy_selected_panel_values: [],
      time_options: [],
      time_panel_value: [],
      kpi_options: [],
      kpiTypeMap: {},
      kpi_selected_panel_values: [],
      ratio_kpis: {},
      source_kpi_ratios: {},
      user_columns: {},
      uploadedFileMasterValues: [],
      hierarchySetKeyObjData: {},
      hierarchyCombination: {},
      filefirstRow: {},
      fileData: {},
      data_source_payload: {},
      current_window: current_window,
      current_heirarchy_sets: [],
      current_frequency: {}
    });
  };

  handleDelHybridHierarchy = (label, file) => {
    this.setState({
      selected_hybrid_hier_delete: label,
      isRemoveHybridHierarchyOpen: !this.state.isRemoveHybridHierarchyOpen,
    });
  };
  confirmRemoveHybridHierarchyOpen = () => {
    let hierarchyDataMap = this.state.hierarchyDataMap;
    let label = this.state.selected_hybrid_hier_delete;
    let currentHierarchy = hierarchyDataMap.filter(
      (val) => val.label === label
    );
    currentHierarchy = currentHierarchy[0];
    let currenthierarchyDataMap = hierarchyDataMap.filter(
      (val) => val !== currentHierarchy
    );
    this.setState({
      hierarchyDataMap: currenthierarchyDataMap,
      isRemoveHybridHierarchyOpen: false,
    });
  };
  cancelRemoveHybridHierarchyOpen = () => {
    this.setState({
      isRemoveHybridHierarchyOpen: false,
    });
  };
  handleKpiType = (kpi, type) => {
    let ckpiTypeMap = this.state.kpiTypeMap;
    ckpiTypeMap[kpi] = type;
    this.setState({
      kpiTypeMap: ckpiTypeMap,
    });
  };

  onloadHierarchySet = () => {
    let currenthierarchySetPagePanels = _loadash.cloneDeep(
      this.state.hierarchySetPanels
    );
    let current_hierarchySetPanelsOrder = _loadash.cloneDeep(this.state.hierarchySetPanelsOrder)
    let newHierarchySet = {}
    let filtered_hierarchySetPanelsOrder = []
    let current_no_Of_Sets = this.state.noOfSets
    let updatedSets = null
    let hierarchy_master_data = this.state.hierarchy_selected_panel_values;
    let current_heirarchy_sets = this.state.current_heirarchy_sets
    if (_loadash.isEqual(hierarchy_master_data, current_heirarchy_sets)) {
      return
    } else if (!_loadash.isEqual(hierarchy_master_data, current_heirarchy_sets) && Object.keys(currenthierarchySetPagePanels).length > 1) {
      currenthierarchySetPagePanels.hierarchySetMasterPanel.panelObj = []
      let updatedHierarchySets = Object.keys(currenthierarchySetPagePanels).filter((key) => {
        return currenthierarchySetPagePanels[key].id !== "hierarchySetMasterPanel"
      })
      newHierarchySet = _loadash.omit(currenthierarchySetPagePanels, updatedHierarchySets);
      var setObj = {};
      hierarchy_master_data.forEach((element, index) => {
        setObj[`${index}`] = { id: `${index}`, content: element.value };
        return newHierarchySet.hierarchySetMasterPanel.panelObj.push(setObj[`${index}`].id);
      });
      filtered_hierarchySetPanelsOrder = current_hierarchySetPanelsOrder.filter((item) => {
        return item === "hierarchySetMasterPanel"
      })
      updatedSets = "0"
    } else if (Object.keys(currenthierarchySetPagePanels).length === 1) {
      currenthierarchySetPagePanels.hierarchySetMasterPanel.panelObj = []
      var setObj = {};
      hierarchy_master_data.forEach((element, index) => {
        setObj[`${index}`] = { id: `${index}`, content: element.value };
        return currenthierarchySetPagePanels.hierarchySetMasterPanel.panelObj.push(setObj[`${index}`].id);
      });
      currenthierarchySetPagePanels.hierarchySetMasterPanel.panelObj = _loadash.uniq(
        currenthierarchySetPagePanels.hierarchySetMasterPanel.panelObj
      );
    } else {
      return;
    }
    if (Object.keys(newHierarchySet).length > 0) {
      currenthierarchySetPagePanels = _loadash.cloneDeep(newHierarchySet)
    }
    if (filtered_hierarchySetPanelsOrder.length > 0) {
      current_hierarchySetPanelsOrder = filtered_hierarchySetPanelsOrder
    }
    if (updatedSets !== null) {
      current_no_Of_Sets = updatedSets
    }
    this.setState({
      hierarchySetKeyObjData: setObj,
      hierarchySetPanels: currenthierarchySetPagePanels,
      current_heirarchy_sets: hierarchy_master_data,
      hierarchySetPanelsOrder: current_hierarchySetPanelsOrder,
      noOfSets: current_no_Of_Sets
    });
  };

  addHybridHierarchy = () => {
    let curHybridHierarchy = this.state.hybridHierarchy;
    let curHybridHierarchy2 = this.state.hybridHierarchy2;
    let hierarchyDataMap = this.state.hierarchyDataMap;
    let currenthierarchySets = this.state.hierarchySets;
    let currenthybridhierarchyObj = currenthierarchySets.hybridHierarchy;
    if (curHybridHierarchy === null || curHybridHierarchy === "") {
      const text = "please create hybridhierarchy";
      this.props.togglePopup(text);
      return;
    } else {
      currenthybridhierarchyObj.panelObj = [];
      let duplicatehierarchy = this.isHierarchyExist(hierarchyDataMap, {
        label: curHybridHierarchy,
        actual_label: curHybridHierarchy,
      });
      if (!duplicatehierarchy) {
        hierarchyDataMap.push({
          label: curHybridHierarchy,
          actual_label: curHybridHierarchy,
        });
      } else {
        var text =
          "This Hierarchy Level is already exist, please create new one";
        this.props.togglePopup(text);
      }
      curHybridHierarchy = null;
      curHybridHierarchy2 = [];
      currenthierarchySets = this.state.hierarchySetPanels;
      let toastId = null;
      toastId = toast("Hybrid hierarchy created successfully!", {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      });
    }

    this.setState({
      hierarchyDataMap: hierarchyDataMap,
      // hierarchyModelIsOpen: false,
      hierarchySets: currenthierarchySets,
      hybridHierarchy: curHybridHierarchy,
      hybridHierarchy2: curHybridHierarchy2,
    });
  };
  isHierarchyExist = (array, elem) => {
    return (
      typeof array.find((element) => {
        if (element["label"].includes("-**-")) {
          let hybridArr = element["label"].split("-**-");
          let selectedHybridArr = elem["label"].split("-**-");
          if (hybridArr.length === selectedHybridArr.length) {
            let duplicateArr = _loadash.difference(
              hybridArr,
              selectedHybridArr
            );
            if (duplicateArr.length === 0) {
              return true;
            }
          }
        } else {
          return element["label"] == elem["label"];
        }
      }) != "undefined"
    );
  };
  openHierarchyModal = () => {
    this.setState({ hierarchyModelIsOpen: true });
  };
  locationofHierarchyFiles = (path) => {
    this.setState({ hierarchy_location: path });
  };
  handleHierarchyFiles = (label, file) => {
    
    let hierarchyDataMap = this.state.hierarchyDataMap;
    let currentHierarchy = hierarchyDataMap.filter(
      (val) => val.label === label
    );
    currentHierarchy = currentHierarchy[0];
    let selected_file = file.target.files[0] || null;
    if (!selected_file) return;

    const time_col = this.state.time_panel_value.label;
    const kpi_names = [];

    this.state.kpi_selected_panel_values.forEach((item) => {
      kpi_names.push(item.label);
    });
    let cols = [];

    let hybrid = currentHierarchy.actual_label.split("-**-");
    if (hybrid.length > 1) {
      cols.push(...hybrid.map((items) => items.split("-*-")));
    } else {
      cols.push(...hybrid[0].split("-*-"));
    }
    this.setState({
      isLoaderLoading:false,
      hierarchy_location:""

    })
    const Papa = require("papaparse/papaparse.min.js");
    Papa.parse(selected_file, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: (result, file, file_name = file) => {
        const data = result.data;

        data.length > 0 && data.map((row) => row[currentHierarchy.label]);
        let columns = data[0] && Object.keys(data[0]);
        const kpi_tested = kpi_names.filter((kpi)=>{
          if(columns && !columns.includes(kpi)){
            return kpi
          }
        })
        if (kpi_tested.length > 0) {
          const text = `Please check if the mentioned KPI are available across: ${kpi_tested}`;
          this.props.togglePopup(text);
          return;
        }
        if (currentHierarchy.actual_label.includes("-**-")) {
            var incorrect_hybrid_file = columns && cols && cols.length > 1 && cols.filter((h_item)=>{
              if(!columns.includes(h_item[0])){
                return h_item[0]
               
              }
            })
          if(incorrect_hybrid_file.length > 0){
             currentHierarchy["filename"] = "";
             const text = `Please check if correct hybrid hierarchy files are uploaded.`;
             this.props.togglePopup(text);
             return;
          }else{
            currentHierarchy["payload"] = file;
            currentHierarchy["filename"] = file.name;
            this.handlehierarchyDataMap();
            return;
          }
        } else {
          if (
            columns.indexOf("Overall") !== -1 &&
            currentHierarchy.actual_label !== "Overall"
          ) {
            currentHierarchy["filename"] = "";
            const text = `Please check if correct hierarchy files are uploaded. \n No duplicate entries should be present for a given date & Hierarchy`;
            this.props.togglePopup(text);
            return;
          }
          if (columns && columns.includes(currentHierarchy.label)) {
            let v_count = groupBy(data, [time_col], currentHierarchy.label);
            Object.keys(v_count).every((obj) => {
              if (
                v_count &&
                !v_count[obj][0][0] &&
                v_count[obj][0].size !== v_count[obj][1]
              ) {
                currentHierarchy["filename"] = "";
                const text = `Please check if correct hierarchy files are uploaded. \n No duplicate entries should be present for a given date & Hierarchy`;
                this.props.togglePopup(text);
                this.handlehierarchyDataMap();
                return;
              } else {
                currentHierarchy["filename"] = file.name;
                currentHierarchy["payload"] = file;
                this.handlehierarchyDataMap();
                return;
              }
            });
          } else {
            const text = `Please check if ${currentHierarchy.label} column is present in selected file.`;
            this.props.togglePopup(text);
            this.handlehierarchyDataMap();
            return;
          }
        }
       
      },
    });
    this.setState({
      isLoaderLoading:false
    })
  };
  handleUploadedFileEdit = (obj) => {
    obj.filename = "";
    obj.payload = {};
    this.handlehierarchyDataMap();
    return;
  };
  closeHierarchyModal = () => {
    this.resetHierarchyBtn();
    this.setState({ hierarchyModelIsOpen: false });
  };

  handleUploadBatchData = () => {

    let data_source_id = this.state.currentDataSourceId;
    let ingestion_id = this.state.current_ingestion_id;
    this.saveProgress()
    let kpi_names = [];
    let time_col = [];

    let hierarchyDataMap = this.state.hierarchyDataMap;
    let hierarchyCombination = this.state.hierarchyCombination;
    if (Object.keys(hierarchyCombination).length > 0) {
      hierarchyCombination = hierarchyCombination;
    } else {
      hierarchyCombination = {};
    }
    this.state.kpi_selected_panel_values.forEach((obj) => {
      kpi_names.push(obj.label);
    });
    time_col.push(this.state.time_panel_value.label);

    let toastId = null;
    let selected_hierarchies = [];
    let level_mapping = {};
    Object.keys(hierarchyDataMap).forEach((key) => {
      selected_hierarchies.push(hierarchyDataMap[key].actual_label);
      level_mapping[hierarchyDataMap[key].label] =
        hierarchyDataMap[key].actual_label;
    });
    toastId = toast("Saving Progress. Please wait...", {
      type: toast.TYPE.INFO,
    });
    const token = localStorage.getItem("Authorization")
    return fetch(
      `/incoming/aggregation`,
      {
        method: "POST",
        headers: {
          "Authorization": `${token}`,
        },
        body: JSON.stringify({
          hierarchy_combination: hierarchyCombination,
          user_columns: this.props.data_source_payload.user_columns,
          hierarchy_to_aggregate: selected_hierarchies,
          level_mapping: level_mapping,
          aggregation_formula: this.props.data_source_payload.datasource_meta.aggregation_formulas,
          granular_file_path: this.props.data_source_payload.datasource_meta.granular_file_path,
          data_frequency: this.state.current_frequency,
          window_size: this.state.current_window,
          data_source_id: this.props.match.params.datasource_id,
          ingestion_id: this.props.match.params.ingestion_id,
          user_uuid: JSON.parse(this.context.store_user_uuid)
        }),
      }
    ).then((response) => handleErrors(response))
      .then((resultData) => {
        if (resultData.status_code == 202) {
          this.setState({
            showAddPipeline: false
          })
          toast.update(toastId, {
            render: "Data sent for processing. Please check notification for Data Upload Status",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
          this.props.history.push(`/app/anomaly/experiment`);
          sendAmplitudeData(UPLOADED_DATA);
        }
      })
  }

  uploadData = () => {
    let data_source_id = this.props.match.params.datasource_id;
    let ingestion_id = this.props.match.params.ingestion_id;
    let kpi_names = [];
    let time_col = [];

    let hierarchyDataMap = this.state.hierarchyDataMap;
    let hierarchyCombination = this.state.hierarchyCombination;
    if (Object.keys(hierarchyCombination).length > 0) {
      hierarchyCombination = hierarchyCombination;
    } else {
      hierarchyCombination = {};
    }
    this.state.kpi_selected_panel_values.forEach((obj) => {
      kpi_names.push(obj.label);
    });
    time_col.push(this.state.time_panel_value.label);

    const hierarchyWithData = hierarchyDataMap.filter((hierarchyData) => {
      return Object.keys(hierarchyData).includes("payload");
    });

    if (
      !this.state.hierarchy_location &&
      hierarchyWithData.length !== hierarchyDataMap.length
    ) {
      const text = "Please select all files to upload";
      this.props.togglePopup(text);
      return;
    } else {
      let filenames = [];
      hierarchyDataMap.forEach((obj) => {
        filenames.push({ name: obj.filename, label: obj.label });
      });

      let upload_filetest = [];
      Object.keys(filenames).forEach((key) => {
        if (
          filenames[key].name !== undefined &&
          filenames[key].name.trim().length === 0
        ) {
          upload_filetest.push(filenames[key].label);
        }
      });
      if (upload_filetest.length >= 1) {
        const text =
          `Please upload correct hierarchy file for. \n ` + upload_filetest;
        this.props.togglePopup(text);
        return;
      } else {
        let toastId = null;
        let selected_hierarchies = [];
        let level_mapping = {};

        let data = new FormData();
        // data.append('master_data', this.state.data)
        data.append("pipeline", this.props.match.params.datasource_id);
        data.append("hierarchy_file_folder", this.state.hierarchy_location);
        data.append("user_uuid", JSON.parse(this.context.store_user_uuid));
        data.append("window_size", this.state.current_window);
        data.append("kpis", JSON.stringify(kpi_names));
        data.append(
          "hierarchyCombination",
          JSON.stringify(hierarchyCombination)
        );
        data.append("time_column", time_col);

        let driverKpiTableRowsObjList = this.state.driverKpiTableRows && this.state.driverKpiTableRows.length>0 ?
        this.state.driverKpiTableRows.map((item,index)=>{
          let driverKPIsObj = {}
          let driverObjectiveKPI = item.selectedDriverObjectiveKPI && item.selectedDriverObjectiveKPI.value
          let driverKPIvalues = item.selectedDriverKPIList && item.selectedDriverKPIList.map((item)=>{
            return item.value
          })
          driverKPIsObj[driverObjectiveKPI] = driverKPIvalues
          return driverKPIsObj
        }) : []
        data.append("maxPercentageThreshold", this.state.maxPercentageThreshold)
        data.append("minPercentageThreshold", this.state.minPercentageThreshold)
        data.append("topValueSelections", this.state.topValueSelections)
        data.append("peristant_alert_window", this.state.persistantAlertWindow)
        data.append("DriverKPIs", JSON.stringify(driverKpiTableRowsObjList))
        

        Object.keys(hierarchyDataMap).forEach((key) => {
          data.append(
            hierarchyDataMap[key].label,
            hierarchyDataMap[key].payload
          );
          selected_hierarchies.push(hierarchyDataMap[key].label);
          level_mapping[hierarchyDataMap[key].label] =
            hierarchyDataMap[key].actual_label;
        });
        data.append(
          "selected_hierarchies",
          JSON.stringify(selected_hierarchies)
        );
        let ingestion_upload_files = this.state.ingestion_upload_files || {}
        ingestion_upload_files[ingestion_id] = hierarchyDataMap
        data.append("level_mapping", JSON.stringify(level_mapping));
        data.append("ingestion_upload_files", JSON.stringify(ingestion_upload_files));
        const cur_state = this.state;
        toastId = toast("Saving progress and Uploading data. Please wait ...", {
          type: toast.TYPE.INFO,
        });
        let user_columns = {};
        let time_panel_values = this.state.time_panel_value;
        let kpi_selected_panel_values = this.state.kpi_selected_panel_values;

        let selectedMasterValues = this.state.hierarchy_selected_panel_values;
        let allMasterValues = this.state.hierarchy_options;
        var remainingMasterValues = allMasterValues.filter(
          compareTwoArrayObjects(selectedMasterValues)
        );

        user_columns[`kpi`] = kpi_selected_panel_values.map((obj) => {
          return obj.label;
        });
        user_columns[`time`] = [time_panel_values.label];

        user_columns["hierarchy"] = this.state.hierarchyDataMap.map(
          (ob) => ob.actual_label
        );
        user_columns["hierarchy_splitted"] = this.state.hierarchyDataMap.map(
          (ob) => ob.label
        );
        user_columns[`other_columns`] = remainingMasterValues.map((obj) => {
          return obj.label;
        });
        this.setState({
          isLoaderLoading:true
        })
      
         const body =  JSON.stringify({
          hierarchyCombination: this.state.hierarchyCombination,
          user_columns: user_columns,
          ingestion_upload_files: ingestion_upload_files,
          upload_state: {
            ...cur_state,
            showAddPipeline: false,
            algoData: {},
            algoParamData:{},
            algoParam: {},
            algorithms: {},
            valueSelection: [],
            incrementalIsOpen: false,
            isdeleteParamSel: false,
            chartResult: [],
            current_causal_model: {},
            ingestion_upload_files: ingestion_upload_files,
          },
        })
        
        fetchCall(`/app/api/ui/pipeline/${this.state.currentDataSourceId}`,"POST",body)
          .then((response) => handleErrors(response))
          .then((resultData) => {
            if (resultData) {
              const token = localStorage.getItem("Authorization")
              return fetch(
                `/app/api/datasource/${data_source_id}/ingestion/${ingestion_id}/static_load`,
                {
                  method: "POST",
                  headers: {
                    "Authorization": `${token}`,
                  },
                  body: data,
                }
              )
              .then((result) => result.json())
                .then((resultData) => {
                  if (resultData.status_code == 202) {
                    this.setState({
                      showAddPipeline: false,
                      isLoaderLoading:false
                    })
                    toast.update(toastId, {
                      render: "Data sent for processing. Please check notification for Data Upload Status",
                      type: toast.TYPE.SUCCESS,
                      autoClose: 2000,
                    });
                    this.props.history.push(`/app/anomaly/experiment`);
                    sendAmplitudeData(UPLOADED_DATA);
                  } else if (resultData.status_code == 400) {
                    toast.update(toastId, {
                      render: resultData.message,
                      type: toast.TYPE.ERROR,
                      // autoClose: 2000,
                    });
                  } else if (typeof resultData == "object") {
                    toast.update(toastId, {
                      render: resultData.message,
                      type: toast.TYPE.ERROR,
                      autoClose: 2000,
                    });
                  }
                  this.setState({
                    isLoaderLoading:false
                  })
                }).catch((error) => {
                  toast.update(toastId, {
                    render: "ERROR: " + error.message,
                    type: toast.TYPE.ERROR,
                    autoClose: 2000,
                  });
                  this.setState({
                    isLoaderLoading:false
                  })
                });
            }
          }).catch((error) => {
            toast.update(toastId, {
              render: "ERROR: " + error.message,
              type: toast.TYPE.ERROR,
              autoClose: 2000,
            });
          });

      }
    }
  };

  handleSelectedOtherColumns = (columns) => {
    this.setState({
      selected_other_columns: columns,
    });
  };

  saveProgress = async () => {

    let toastId = null;
    const cur_state = this.state;
    toastId = toast("Saving Progress. Please wait...", {
      type: toast.TYPE.INFO,
    });
    let user_columns = {};
    let time_panel_values = this.state.time_panel_value;
    let kpi_selected_panel_values = this.state.kpi_selected_panel_values;

    let selectedMasterValues = this.state.hierarchy_selected_panel_values;
    let allMasterValues = this.state.hierarchy_options;
    var remainingMasterValues = allMasterValues.filter(
      compareTwoArrayObjects(selectedMasterValues)
    );

    user_columns[`kpi`] = kpi_selected_panel_values.map((obj) => {
      return obj.label;
    });
    user_columns[`time`] = [time_panel_values.label];

    user_columns["hierarchy"] = this.state.hierarchyDataMap.map(
      (ob) => ob.actual_label
    );
    user_columns["hierarchy_splitted"] = this.state.hierarchyDataMap.map(
      (ob) => ob.label
    );
    user_columns[`other_columns`] = remainingMasterValues.map((obj) => {
      return obj.label;
    });

    const data = JSON.stringify({
      hierarchyCombination: this.state.hierarchyCombination,
      user_columns: user_columns,
      upload_state: {
        ...cur_state,
        showAddPipeline: false,
        algoData: {},
        algoParamData:{},
        algoParam: {},
        algorithms: {},
        valueSelection: [],
        incrementalIsOpen: false,
        isdeleteParamSel: false,
        chartResult: [],
        current_causal_model: {},
      },
    })
    const response =     
    await fetchCall(`/app/api/ui/pipeline/${this.state.currentDataSourceId}`,'POST',data)
    const resultData = await response.json();
    if (resultData) {
      this.setState({
        showAddPipeline: false,
      });
      toast.update(toastId, {
        render: "Progress Saved",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
    }
  };


  jumptoHierarchySet = (props) => {
    let current_frequency = this.state.current_frequency;
    let current_window = this.state.current_window;
    let test = Object.keys(this.state.kpiTypeMap).length
    if (this.state.kpi_options.length === 0) {
      const text = "Please choose file to select the values";
      this.props.togglePopup(text);
      return;
    }
    if (
      this.state.kpi_options.length > 0 &&
      this.state.time_panel_value.length === 0 &&
      this.state.kpi_selected_panel_values.length === 0 &&
      this.state.hierarchy_selected_panel_values.length === 0
    ) {
      const text = "Please choose time params";
      this.props.togglePopup(text);
    } else if (this.state.time_panel_value.length === 0) {
      const text = "Please choose time params";
      this.props.togglePopup(text);
    } else if (this.state.kpi_selected_panel_values.length === 0) {
      const text = "Please choose kpi params";
      this.props.togglePopup(text);
    }else if (Object.keys(current_frequency).length === 0) {
      const text = "Please select frequency";
      this.props.togglePopup(text);
    } else if (current_window === "" || current_window === null) {
      const text = "Please select window size";
      this.props.togglePopup(text);
    }else if (this.state.hierarchy_selected_panel_values.length === 0) {
      this.setState({
        showHierarchyAlert: !this.state.showHierarchyAlert,
      });
    }
    else if(Object.keys(this.state.kpiTypeMap).length === 0){
      const text = "Please select kpi type from payload";
      this.props.togglePopup(text);
    }else {
      this.saveProgress()
      props.jumpToStep(1);
      sendAmplitudeData(HIERARCHY_SELECT);
    }
  };
  jumptoHybridHierarchyMap = (props) => {
    let current_source_kpi_ratios = this.state.source_kpi_ratios;
    let errorFlag = false;
    Object.keys(current_source_kpi_ratios).map((key) => {
      if (Object.keys(current_source_kpi_ratios[key]).length > 0) {
        if (
          current_source_kpi_ratios[key].numerator === "" ||
          current_source_kpi_ratios[key].denominator === ""
        ) {
          errorFlag = true;
          return;
        }
      }
    });
    let isThresholdObjKpi = this.state.threshold_value && this.state.selected_objective_Kpi && this.state.selected_objective_Kpi.value  ? true : false
    let ingestion_arr = this.props.location.state && this.props.location.state.ingestion_status_obj[this.props.match.params.ingestion_id]
    if (errorFlag) {
      const text = "please select all the Numerator and Denominator values";
      this.props.togglePopup(text);
      return;
    }else if(!isThresholdObjKpi && ingestion_arr[0] == "HISTORICAL" && ingestion_arr[1] == "CREATED"){
      this.setState({
      isEmptyObjectiveKpiOrThreshold:!this.state.isEmptyObjectiveKpiOrThreshold
      })
      return
    }else {
      this.saveProgress()
      props.jumpToStep(3);
      sendAmplitudeData(RATIO_KPI);
    }
  };
  continueToHybridHierarchyMap = (props) => {
    this.saveProgress()
    props.jumpToStep(3);
    sendAmplitudeData(RATIO_KPI);
    this.setState({
      isEmptyObjectiveKpiOrThreshold:!this.state.isEmptyObjectiveKpiOrThreshold
    })
  }
  closeObjectiveConfirmationModal = () =>{
    this.setState({
      isEmptyObjectiveKpiOrThreshold:!this.state.isEmptyObjectiveKpiOrThreshold
    })
  }
  jumpToUploadStep = (props) => {
    this.saveProgress()
    props.jumpToStep(4)
  }
  handleBenchmarkPrefixFieldChange = (evt) => {
    const benchmark_prefix = evt.target.validity.valid
      ? evt.target.value
      : this.state.benchmark_prefix;
    this.setState({
      benchmark_prefix,
    });
  };
  jumptoConfigureRatio = (props) => {
    let currenthierarchyPanels = this.state.hierarchySetPanels;
    let currenthierarchyVales = Object.values(currenthierarchyPanels);
    let cHSetsHybrid = this.state.hierarchySets;
    if (currenthierarchyVales.length === 1) {
      const text = "please create sets for hierarchy values";
      this.props.togglePopup(text);
    }
    for (let i = 1; i < currenthierarchyVales.length; i++) {
      if (currenthierarchyVales[i].panelObj.length === 0) {
        const text =
          "Set " +
          i +
          " is empty,  Please drag and drop hierarchy to Set " +i;
          this.props.togglePopup(text);
        break;
      }
      if (
        i === currenthierarchyVales.length - 1 &&
        currenthierarchyVales[i].panelObj.length > 0
      ) {
        if (currenthierarchyVales[0].panelObj.length > 0) {
          const text =
            "All hierarchy values should be selected for sets creation";
          this.props.togglePopup(text);
          return;
        } else {
          this.saveProgress()
          props.jumpToStep(2);
          sendAmplitudeData(HIERARCHY_SETS);
        }
      }
    }
    cHSetsHybrid = currenthierarchyPanels;
    this.setState({ hierarchySets: cHSetsHybrid });
  };

  jumptoStepZero = (props) => {
    this.setState({
      showHierarchyAlert: !this.state.showHierarchyAlert,
    });
  };
  continueToHierarchy = (props) => {
    let hierarchyDataMap = this.state.hierarchyDataMap
    let filteredHierarchyDataMap = hierarchyDataMap.filter((item) => {
      return item.label == "Overall"
    })

    this.setState({
      showHierarchyAlert: false,
      hierarchyDataMap: filteredHierarchyDataMap,
      hierarchyCombination: {},
      hierarchySetKeyObjData: {}
    })
    props.jumpToStep(4);
  };
  resetHierarchyBtn = (props) => {
    let curHybridHierarchy = this.state.hybridHierarchy;
    let curHybridHierarchy2 = this.state.hybridHierarchy2;
    let currenthierarchySets = this.state.hierarchySets;
    let hierarchySetPanels = this.state.hierarchySetPanels;
    let curhierarchyDataPanels = this.state.hierarchyDataPanels;
    let curhybridhierarchyDatapanel = curhierarchyDataPanels.hybridHierarchy;
    let currenthybridhierarchyObj = currenthierarchySets.hybridHierarchy;
    if (
      currenthybridhierarchyObj !== undefined ||
      currenthybridhierarchyObj !== null
    ) {
      curHybridHierarchy = null;
      curHybridHierarchy2 = [];
      curhybridhierarchyDatapanel.panelObj = [];
      this.setState({
        hierarchySets: hierarchySetPanels,
        hybridHierarchy: curHybridHierarchy,
        hybridHierarchy2: curHybridHierarchy2,
        hierarchyDataPanels: curhierarchyDataPanels,
      });
    }
  };
  handleFrequency = (obj) => {
    this.setState({
      current_frequency: obj,
    });
  };
  handleWindowSize = (evt) => {
    const value = evt.target.value
    const current_window = evt.target.validity.valid
      ? value
      : this.state.current_window;

    this.setState({
      current_window: current_window
    });
  };

  getUploadedFile = (result, file) => {

    let toastId = null;
  
    const data = JSON.stringify({
      pipeline_uuid: JSON.parse(localStorage.getItem("pipeline_uuid")),
      user_uuid: JSON.parse(this.context.store_user_uuid),
    })
    fetchCall(`/app/api/ui/get_file/`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {

        if (resultData) {
          this.setState({
            uploadedFiles: resultData,
          });
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
        });
      });
  };
  handleCustomHybridPayload = (payload) => {
    this.setState({
      customHybridText: payload,
    });

  }

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  addCustomHybridPayload = () => {
    let toastId = null;
    let payload = this.state.customHybridText
    if (payload == undefined || payload.trim() == "") {
      const text = "Please add hybrid hierarchies";
      this.props.togglePopup(text);
      return
    }
    let hierarchyDataMap = this.state.hierarchyDataMap
    let manualHybridValues = null
    let invalidHybrid = false
    if (!payload.includes("-**-")) {
      const text = "can only add hybrid hierarchies";
      this.props.togglePopup(text);
      return
    } else {
      let hierarchy_names = hierarchyDataMap.reduce(function (filtered, option) {
        if (!option.label.includes("-**-")) {
          filtered.push(option.label);
        }
        return filtered;
      }, []);
      
      let isJsonFlag = this.IsJsonString(payload)
      if (isJsonFlag) {
        manualHybridValues = parseJson(payload)
        invalidHybrid = manualHybridValues.some((item) =>
          item.includes('-*-') || (item.includes("-**-") && (!hierarchy_names.includes(item.split("-**-")[0])
            || !hierarchy_names.includes(item.split("-**-")[1])))
        )
      } else {
        invalidHybrid = true
      }


      if (!invalidHybrid) {
        if (Array.isArray(manualHybridValues)) {
          manualHybridValues = manualHybridValues.map(obj => {
            return { "label": obj, "actual_label": obj }
          })
        } else if (typeof manualHybridValues == "object") {
          manualHybridValues = [payload]
        }
        let duplicatehierarchy = this.isHierarchyExist(hierarchyDataMap, {
          label: manualHybridValues[0].label,
          actual_label: manualHybridValues[0].actual_label,
        });
        if (!duplicatehierarchy) {
          this.setState({
            customHybridText: payload,
            hierarchyDataMap: [...hierarchyDataMap, ...manualHybridValues]
          })
          toastId = toast("Hybrid hierarchy created successfully!", {
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        } else {
          const text =
            "This Hierarchy Level is already exist, please create new one";
          this.props.togglePopup(text);
          return
        }
      }
    }
    if (invalidHybrid) {
      const text = "can only add hybrid hierarchies";
      this.props.togglePopup(text);
      return
    }

  }
  handleJsonData = (value) => {
    let selected_file = value.target.files[0] || null;
    if (!selected_file){ 
      return
    }else{
      let uploaded_file_name = selected_file.name
      const filenameRegex = /^[a-z0-9A-Z_\s()-]{1,60}\.json$/gm
    
      if(!filenameRegex.test(uploaded_file_name)){
        this.props.togglePopup("Invalid file name");
        return
      }
    }
    const fileReader = new FileReader();
    let config_json = ""
    fileReader.readAsText(value.target.files[0], "UTF-8");
    fileReader.onload = e => {
      config_json = e.target.result
      let current_config_json = JSON.parse(config_json)
      current_config_json['uploadedJsonConfigFile'] = selected_file.name
      current_config_json[`isUploadedJsonConfigFile`] = true
      this.setState(
        current_config_json
      )
    };
  }

  handleFileData = (value) => {
    let selected_file = value.target.files[0] || null;
    if (!selected_file){ 
      return
    }else{
      let uploaded_file_name = selected_file.name
      const filenameRegex = /^[a-z0-9A-Z_\s()-]{1,60}\.csv$/gm
    
      if(!filenameRegex.test(uploaded_file_name)){
        this.props.togglePopup("Invalid file name");
        return
      }
    }
    const Papa = require("papaparse/papaparse.min.js");
    Papa.parse(selected_file, {
      header: true,
      download: true,
      skipEmptyLines: true,
      delimiter:",",
      preview: 2,
      complete: this.updateData,
    });
    this.setState({
      data: value.target.files[0],
      uploadedGranularFile: value.target.files[0].name,
      IsuploadedGranularFile: true,
    });
  };
  validate_data_columns = (columns) => {
    const validator_pattern = /^[A-Za-z\d\_\s]+$/
    columns.forEach(columns => {
      const validate_flag = validator_pattern.test(columns)
      if (columns.length > 60) {
        const text = `Invalid Column Header :- "${columns}". Column Header length cant exceed 60 characters`;
        this.props.togglePopup(text);
        throw `Invalid Column Header: "${columns}". Column Header length cant exceed 60 characters`
      }
      if (!validate_flag) {
        const text = `Invalid Column Header :- "${columns}". No special characters allowed in column names`;
        this.props.togglePopup(text);
        throw `Invalid Column Header: "${columns}". No special characters allowed in Column Names`
      }
    })
  }
  updateData = (result) => {
    if(result.errors.length > 0){
        result.errors.forEach((obj)=>{
          this.props.togglePopup(`${obj.code}: ${obj.message}`);
        })
    }

    const data = result.data;

    if(data.length == 0){
      throw "Incorrect data format"
    }
    let fileFirstRow = data[0];
    let fileFirstColumnData = Object.keys(data[0]);
    this.validate_data_columns(fileFirstColumnData)
    let taskObj = {};
    let currentuploadStepPanelObjectData = [];
    let uploadstepPanelObjContent = {};
    let uploadstepPanelObj;
    fileFirstColumnData.forEach((element, index) => {
      taskObj[`${index}`] = { id: `${index}`, content: element };
      uploadstepPanelObj = taskObj[`${index}`];
      currentuploadStepPanelObjectData.push(uploadstepPanelObj.id);
      uploadstepPanelObjContent[uploadstepPanelObj.id] =
        uploadstepPanelObj.content;
    });

    let multiSelectOptions = fileFirstColumnData.map((item) => {
      let obj = {};
      obj[`value`] = item;
      obj[`label`] = item;
      return obj;
    });

    this.setState({
      fileData: taskObj,
      uploadedFileMasterValues: multiSelectOptions,
      time_options: multiSelectOptions,
      kpi_options: multiSelectOptions,
      hierarchy_options: multiSelectOptions,
      filefirstRow: fileFirstRow,
    });
  };
  handleSetCreation = (evt) => {
    const noOfSets = evt.target.validity.valid
      ? evt.target.value
      : this.state.noOfSets;

    this.setState({
      noOfSets,
    });
  };

  handleNumberofSets = () => {
    let currenthierarchySetPanels = this.state.hierarchySetPanels;
    let hierarchy_master_data = this.state.hierarchy_selected_panel_values
    let currenthierarchySetPanelsOrder = [
      this.state.hierarchySetPanelsOrder[0],
    ];
    let currentinputVal = this.state.noOfSets;
    let filtered_hierarchySetPanelsOrder = []
    let newcHPanel = {};
    let newHierarchySet = null

    newcHPanel[`hierarchySetMasterPanel`] = this.state.hierarchySetPanels[
      Object.keys(this.state.hierarchySetPanels)[0]
    ];
    if (currentinputVal === "") {
      const text = "Please Enter value to create hierarchy sets";
      this.props.togglePopup(text);
    } else if (currentinputVal > hierarchy_master_data.length) {
      const text = "Input value should not be more than hierarchy set values";
      this.props.togglePopup(text);
      return currentinputVal
    } else {
      if (Object.keys(currenthierarchySetPanels).length == 1) {
        for (let i = 0; i < parseInt(this.state.noOfSets); i++) {
          let newhierarchyId = `hierarchySet-${i + 1}`;
          currenthierarchySetPanelsOrder.push(`hierarchySet-${i + 1}`);
          let prop = `hierarchySet-${i + 1}`;
          newcHPanel[prop] = {};
          newcHPanel[`hierarchySet-${i + 1}`] = {
            id: newhierarchyId,
            title: `Set-${i + 1}`,
            panelObj: [],
          };
        }
      } else if (Object.keys(currenthierarchySetPanels).length > 1) {

        currenthierarchySetPanels.hierarchySetMasterPanel.panelObj = []
        let filteredSets = Object.keys(currenthierarchySetPanels).filter((key) => {
          return currenthierarchySetPanels[key].id !== "hierarchySetMasterPanel"
        })
        newHierarchySet = _loadash.omit(currenthierarchySetPanels, filteredSets);
        var setObj = {};
        hierarchy_master_data.forEach((element, index) => {
          setObj[`${index}`] = { id: `${index}`, content: element.value };
          return newHierarchySet.hierarchySetMasterPanel.panelObj.push(setObj[`${index}`].id);
        });
        filtered_hierarchySetPanelsOrder = currenthierarchySetPanelsOrder.filter((item) => {
          return item === "hierarchySetMasterPanel"
        })

        for (let i = 0; i < parseInt(currentinputVal); i++) {
          let newhierarchyId = `hierarchySet-${i + 1}`;
          filtered_hierarchySetPanelsOrder.push(`hierarchySet-${i + 1}`);
          let prop = `hierarchySet-${i + 1}`;
          newHierarchySet[prop] = {};
          newHierarchySet[`hierarchySet-${i + 1}`] = {
            id: newhierarchyId,
            title: `Set-${i + 1}`,
            panelObj: [],
          };
        }

      } else {
        return
      }

    }
    if (newcHPanel && Object.keys(newcHPanel).length > 0) {
      currenthierarchySetPanels = newcHPanel;
    }
    if (filtered_hierarchySetPanelsOrder.length > 0) {
      currenthierarchySetPanelsOrder = filtered_hierarchySetPanelsOrder
    }
    if (newHierarchySet && Object.keys(newHierarchySet).length > 0) {
      currenthierarchySetPanels = newHierarchySet
    }
    this.setState({
      hierarchySetPanels: currenthierarchySetPanels,
      hierarchyDataMap: UPLOAD_INITIALSTATE.hierarchyDataMap,
      hierarchySetPanelsOrder: currenthierarchySetPanelsOrder,
      noOfSets: currentinputVal,
    });
  };

  onDragEndHir = (result) => {

    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = this.state.hierarchySetPanels[source.droppableId];
    const finish = this.state.hierarchySetPanels[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.panelObj);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        panelObj: newTaskIds,
      };
      let newState = {
        ...this.state,
        hierarchySetPanels: {
          ...this.state.hierarchySetPanels,
          [newColumn.id]: newColumn,
        },
      };

      let fin = {};
      let hs = Object.keys(newState.hierarchySetPanels).filter(
        (key) => key !== "hierarchySetMasterPanel"
      );
      hs.forEach((obj) => {
        let hObj = newState.hierarchySetPanels[obj];
        fin[hObj.title] = hObj.panelObj.map(
          (val) => this.state.hierarchySetKeyObjData[val].content
        );
      });
      newState["hierarchyCombination"] = fin;
      let current_hierarchyDataMap = this.state.hierarchyDataMap
      if (current_hierarchyDataMap.length > 1) {
        current_hierarchyDataMap = current_hierarchyDataMap.filter((item, index) => {
          return index == 0
        })
      }
      newState["hierarchyDataMap"] = current_hierarchyDataMap
      this.setState(newState);
      return;

    }

    const startTaskIds = Array.from(start.panelObj);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      panelObj: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.panelObj);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      panelObj: finishTaskIds,
    };

    let newState = {
      ...this.state,
      hierarchySetPanels: {
        ...this.state.hierarchySetPanels,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    let fin = {};
    let hs = Object.keys(newState.hierarchySetPanels).filter(
      (key) => key !== "hierarchySetMasterPanel"
    );
    hs.forEach((obj) => {
      let hObj = newState.hierarchySetPanels[obj];
      fin[hObj.title] = hObj.panelObj.map(
        (val) => this.state.hierarchySetKeyObjData[val].content
      );
    });
    newState["hierarchyCombination"] = fin;
    this.setState(newState);
  };
  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  handleDataSourceCall = (id, ingestion_id) => {
    localStorage.setItem("pipeline_uuid", JSON.stringify(id));
    let kpi_options = this.state.kpi_options
    let toastId = null;
    toastId = toast("Loading pipeline", { type: toast.TYPE.INFO });
  
    fetchCall(`/app/api/datasource/${id}`,'GET')
      .then((response) => handleErrors(response))
      .then((resultData) => {
         
        let resp = resultData.upload_state;
        let current_pipeline_state = resultData.current_state;
        let currentDataSourceName = resultData.display_name;
        let batch_load_kpi_options = []
        let batch_load_hierarchy_options = []
        let batch_load_time_options = []

        let ingestion_status_arr = this.props.location.state && this.props.location.state.ingestion_status_obj[this.props.match.params.ingestion_id]
        if (ingestion_status_arr && ingestion_status_arr[0] === "INCREMENTAL" && ingestion_status_arr[1] === "CREATED") {
          resp.hierarchyDataMap && resp.hierarchyDataMap.map((item) => {
            if (item.hasOwnProperty("filename")) {
              delete item["filename"]
              return item
            }
          })
        }
        if (Object.keys(resultData.upload_state).length > 0) {
          batch_load_kpi_options = resultData.upload_state.kpi_options
          batch_load_hierarchy_options = resultData.upload_state.hierarchy_options
          batch_load_time_options = resultData.upload_state.time_options
        } else {
          batch_load_kpi_options = resultData.user_columns.kpi && resultData.user_columns.kpi.map(obj => {
            return { "label": obj, "value": obj }
          }) || []
          batch_load_hierarchy_options = resultData.user_columns.hierarchy && resultData.user_columns.hierarchy.map(obj => {
            return { "label": obj, "value": obj }
          }) || []
          batch_load_time_options = resultData.user_columns.time && resultData.user_columns.time.map(obj => {
            return { "label": obj, "value": obj }
          }) || []
        }
        this.setState({
          ...resp,
          currentDataSourceId: id,
          current_pipeline_state,
          currentDataSourceName,
          current_ingestion_id: ingestion_id,
          data_source_status: resultData.data_source_status,
          data_source_type: resultData.data_source_type,
          user_columns: resultData.user_columns,
          kpi_options: batch_load_kpi_options,
          hierarchy_options: batch_load_hierarchy_options,
          time_options: batch_load_time_options,
          data_source_payload: resultData,
          ingestion_upload_files:resp.ingestion_upload_files,
          isLoaderLoading:false
        });
        toast.dismiss(toastId);
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handlehierarchyDataMap = () => {
    let hierarchyDataMap = this.state.hierarchyDataMap;
    if (hierarchyDataMap.length == 1) {
      let cuurenthierarchyDataMap = [];
      if (hierarchyDataMap[0].hasOwnProperty("filename")) {
        cuurenthierarchyDataMap = hierarchyDataMap;
      } else {

        const hierarchySetKeyObjData = this.state.hierarchySetKeyObjData;
        let isOverallExist = hierarchySetKeyObjData && Object.values(hierarchySetKeyObjData).some(
          (obj) => obj.content === "Overall"
        );
        if (!isOverallExist) {
          cuurenthierarchyDataMap = [
            { split_hierarchy: "Overall", actual: "Overall" },
          ];
        }
        const hierarchySetPanels = this.state.hierarchySetPanels;

        const keys = Object.keys(hierarchySetPanels).filter(
          (obj) => !obj.includes("MasterPanel")
        );
        let hierarchyCombination = this.state.hierarchyCombination;
        for (let h_set in hierarchyCombination) {
          for (let i = 0; i < hierarchyCombination[h_set].length; i++) {
            if (i === 0) {
              let h_val = {
                split_hierarchy: hierarchyCombination[h_set][i],
                actual: hierarchyCombination[h_set][i],
              };
              cuurenthierarchyDataMap.push(h_val);
            } else {
              let act_hval = hierarchyCombination[h_set].slice(0, i + 1);
              let h_val = {
                split_hierarchy: act_hval[act_hval.length - 1],
                actual: act_hval.join("-*-"),
              };
              cuurenthierarchyDataMap.push(h_val);
            }
          }
        }
        cuurenthierarchyDataMap = cuurenthierarchyDataMap.map((obj) => {
          return { label: obj.split_hierarchy, actual_label: obj.actual };
        });
      }
      this.setState({
        hierarchyDataMap: cuurenthierarchyDataMap,
        isLoaderLoading: false

      });

    } else {
      hierarchyDataMap.map((obj) => {
        if (obj.hasOwnProperty("filename")) {
          this.setState({
            hierarchyDataMap,
          });
        }
      });
    }
  };

  handleKpiRatio = (kpi, e) => {
    let ratio_kpis = this.state.ratio_kpis;
    let current_source_kpi_ratios = this.state.source_kpi_ratios;
    if (e.target.checked) {
      ratio_kpis[kpi] = true;
      let valObj = {};
      valObj[`numerator`] = "";
      valObj[`denominator`] = "";
      current_source_kpi_ratios[kpi] = valObj;
    } else {
      delete ratio_kpis[kpi]
      if (current_source_kpi_ratios[kpi] == undefined) {
        return;
      } else {
        delete current_source_kpi_ratios[kpi]
      }
    }
    this.setState({
      ratio_kpis,
      source_kpi_ratios: current_source_kpi_ratios,
    });
  };

  handleHierarchyLevel = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = this.state.hierarchySets[source.droppableId];
    const finish = this.state.hierarchyDataPanels[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.panelObj);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        panelObj: newTaskIds,
      };

      const newState = {
        ...this.state,
        hierarchySets: {
          ...this.state.hierarchySets,
          [newColumn.id]: newColumn,
        },
      };

      this.setState(newState);
      return;
    }

    const startTaskIds = Array.from(start.panelObj);
    const itemMovedIndex = startTaskIds.indexOf(draggableId);
    const movedItem = startTaskIds.splice(0, itemMovedIndex + 1);
    const newStart = {
      ...start,
      panelObj: startTaskIds,
    };

    let finishTaskIds = Array.from(finish.panelObj);
    finishTaskIds.splice(destination.index, 0, movedItem);
    let hybridHierarchy = this.state.hybridHierarchy;
    let hierarchySetKeyObjData = this.state.hierarchySetKeyObjData;
    let str = "";
    movedItem.map((key, index) => {
      let item = hierarchySetKeyObjData[key] || {};
      str = str.concat(`-*-${item.content}`);
    });
    let hybridHierarchy2 = this.state.hybridHierarchy2;
    hybridHierarchy2[source.droppableId] = str.slice(3);
    const newFinish = {
      ...finish,
      panelObj: Array.from(new Set([].concat(...finishTaskIds))),
    };

    let tmphybridHierarchy = Object.keys(hybridHierarchy2).map((key) => {
      let fhierarchy = hybridHierarchy2[key].split("-*-");
      return fhierarchy[fhierarchy.length - 1];
    });

    hybridHierarchy = tmphybridHierarchy.join("-**-");
    const newState = {
      ...this.state,
      hybridHierarchy: hybridHierarchy,
      hierarchySets: {
        ...this.state.hierarchySets,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
      hierarchyDataPanels: {
        ...this.state.hierarchyDataPanels,
        [newFinish.id]: newFinish,
      },
    };

    this.setState(newState);
  };
  handleObjectiveKpiChange = (label) => {
    this.setState({
      objective_kpi: label.value,
      selected_objective_Kpi: label,
    });
  };
  updateSourceKpiRatio = (source_kpi_ratios) => {
    this.setState({
      source_kpi_ratios: source_kpi_ratios,
    });
  };
  handleThresholdValue = (evt) => {
    const threshold_value = evt.target.validity.valid
      ? evt.target.value
      : this.state.threshold_value;
    this.setState({
      threshold_value,
    });
  };
  handleUploadBackButton = (props) => {

    let hierarchySetKeyObjData = this.state.hierarchySetKeyObjData
    if (hierarchySetKeyObjData && Object.keys(hierarchySetKeyObjData).length > 0) {
      props.jumpToStep(3)
    } else {
      props.jumpToStep(0)
    }

  }
  handleMaxPercentageThreshold = (evt) => {
    const maxPercentageThreshold = evt.target.validity.valid
      ? evt.target.value
      : this.state.maxPercentageThreshold;
    this.setState({
      maxPercentageThreshold:maxPercentageThreshold
    });
  }
  handleMinPercentageThreshold = (evt) => {
    const minPercentageThreshold = evt.target.validity.valid
      ? evt.target.value
      : this.state.minPercentageThreshold;
    this.setState({
      minPercentageThreshold:minPercentageThreshold
    });
  }
  handleTopValueSelections = (evt) => {
    const topValueSelections = evt.target.validity.valid
      ? evt.target.value
      : this.state.topValueSelections;
    this.setState({
      topValueSelections:topValueSelections
    });
  }
  handlePersistantAlertWindow = (evt) => {
    const persistantAlertWindow = evt.target.validity.valid
      ? evt.target.value
      : this.state.persistantAlertWindow;
    this.setState({
      persistantAlertWindow:persistantAlertWindow
    });
  }
  handleDriverObjectiveKPIChange = (option, idx) => {
    let driverKPITableRows = this.state.driverKpiTableRows
    driverKPITableRows[idx].selectedDriverObjectiveKPI = option
    let updatedDriverKPIs = driverKPITableRows[idx].driverKpis.filter((item)=>{
      return option.value !== item.value
    })
    driverKPITableRows[idx].driverKpis =  updatedDriverKPIs
    this.setState({
      driverKpiTableRows:driverKPITableRows
    })
  }
  getArrayDifference = (array1, array2) => {
    return array1.filter(object1 => {
        return !array2.some(object2 => {
            return object1.value === object2.value
        });
    });
  }
  handleAddObjectiveDriverKPIRow = () => {    
   
    let driverKpiTableRows = this.state.driverKpiTableRows
    let tableObjectiveKpis = driverKpiTableRows && driverKpiTableRows.length>0 && driverKpiTableRows.map((item)=>{
      return item.selectedDriverObjectiveKPI
    })

    let updated_objective_kpis = tableObjectiveKpis && tableObjectiveKpis.length>0 &&
    this.getArrayDifference(this.state.kpi_selected_panel_values,tableObjectiveKpis)
    if(tableObjectiveKpis && tableObjectiveKpis.length>0 && tableObjectiveKpis.length == this.state.kpi_selected_panel_values.length){
      const text = "Added all Objective KPIs.";
      this.props.togglePopup(text);
      return
    }
    let rowObj = {
      objectiveKpis: tableObjectiveKpis && tableObjectiveKpis.length>0 ? updated_objective_kpis: this.state.kpi_selected_panel_values,
      driverKpis: this.state.kpi_selected_panel_values,
      selectedDriverObjectiveKPI: "",
      selectedDriverKPIList: ""
    }
    this.setState({
      driverKpiTableRows: [...this.state.driverKpiTableRows, rowObj]
    });
  }

  handleDriverKPIChange = (options,idx) => {    
    let driverKPITableRows = this.state.driverKpiTableRows
    driverKPITableRows[idx].selectedDriverKPIList = options
    this.setState({
      driverKpiTableRows: driverKPITableRows
    })
  }

  handleDeleteDriverKPI = (option,index) => {
    let driverKPITableRows = this.state.driverKpiTableRows
    driverKPITableRows = driverKPITableRows.filter((item,i)=>{
      if(item.selectedDriverObjectiveKPI == "" || item.selectedDriverObjectiveKPI == ""
      ){
        return i !== index
      }else{
        return item.selectedDriverObjectiveKPI.value !== option.selectedDriverObjectiveKPI.value
      }
    })
    this.setState({
      driverKpiTableRows:driverKPITableRows
    })
  } 

  render() {
    const filtered_export_dict = {}
    const col_to_filter = ["time_panel_value", "time_options","hierarchy_options","kpi_options","user_columns","kpi_selected_panel_values", "hierarchy_selected_panel_values","kpiTypeMap","ratio_kpis", "source_kpi_ratios","current_frequency","current_window","hierarchySetPanels","hierarchySets","hierarchyCombination","hierarchySetPanelsOrder"]
    col_to_filter.forEach(key=>{
      filtered_export_dict[key] = this.state[key]
    })
    return (
      <div>
        <React.Fragment>
          <div className="back-btn">
            <button
              onClick={(e) =>
                this.props.history.push(
                  `/app/datasource/${this.props.match.params.datasource_id}`
                )
              }
              className="btn btn-sm btn-primary back-btn"
            >
              <i className="fa fa-arrow-left"></i> Back
            </button>




          </div>
        </React.Fragment>
        <div className="breadcrumb_heading">
                <label>
                  Data Source : <a
        type="button"
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(filtered_export_dict)
        )}`}
        download={`${this.state.currentDataSourceName}.json`}
        >
      {this.state.currentDataSourceName}
      </a>
 
          </label>
          <button
            className="btn btn-sm btn-primary save_progress"
            onClick={() => this.saveProgress()}
          >
            <i className="fa fa-save"></i> Save Progress
          </button>
        </div>
        <UploadstepsFun
          stepsNavigation={false}
          steps={UploadSteps}
          frequency={this.state.frequency}
          kpiSectionvalues={this.state.kpiSectionvalues}
          fileData={this.state.fileData}
          handleFileData={this.handleFileData}
          handleJsonData={this.handleJsonData}
          noOfSets={this.state.noOfSets}
          renderSets={this.state.renderSets}
          handleSetCreation={this.handleSetCreation}
          handleNumberofSets={this.handleNumberofSets}
          hierarchySetValues={this.state.hierarchySetValues}
          uploadstepPanelObjContent={this.state.uploadstepPanelObjContent}
          hierarchySetPanels={this.state.hierarchySetPanels}
          hierarchySetPanelsOrder={this.state.hierarchySetPanelsOrder}
          onDragEndHir={this.onDragEndHir}
          hierarchySetKeyObjData={this.state.hierarchySetKeyObjData}
          openModal={this.openModal}
          afterOpenModal={this.afterOpenModal}
          closeModal={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          hierarchyDataMap={this.state.hierarchyDataMap}
          handlehierarchyDataMap={this.handlehierarchyDataMap}
          handleHierarchyLevel={this.handleHierarchyLevel}
          hierarchyDataPanels={this.state.hierarchyDataPanels.hybridHierarchy}
          onloadHierarchySet={this.onloadHierarchySet}
          hybridHierarchy={this.state.hybridHierarchy}
          addHybridHierarchy={this.addHybridHierarchy}
          openHierarchyModal={this.openHierarchyModal}
          hierarchyModelIsOpen={this.state.hierarchyModelIsOpen}
          handleHierarchyFiles={this.handleHierarchyFiles}
          closeHierarchyModal={this.closeHierarchyModal}
          uploadData={this.uploadData}
          togglePopup={this.props.togglePopup}
          showPopup={this.state.showPopup}
          jumptoHierarchySet={this.jumptoHierarchySet}
          isShowNavigation={this.state.isShowNavigation}
          jumptoHybridHierarchyMap={this.jumptoHybridHierarchyMap}
          jumptoConfigureRatio={this.jumptoConfigureRatio}
          popuptext={this.state.popuptext}
          continueToHierarchy={this.continueToHierarchy}
          showHierarchyAlert={this.state.showHierarchyAlert}
          jumptoStepZero={this.jumptoStepZero}
          hierarchySets={this.state.hierarchySets}
          handleFrequency={this.handleFrequency}
          handleWindowSize={this.handleWindowSize}
          getUploadedFile={this.getUploadedFile}
          uploadedFiles={this.state.uploadedFiles}
          resetHierarchyBtn={this.resetHierarchyBtn}
          pipelineName={this.state.pipelineName}
          testupload={this.testupload}
          selectedfileName={this.state.selectedfileName}
          uploadedGranularFile={this.state.uploadedGranularFile}
          IsuploadedGranularFile={this.state.IsuploadedGranularFile}
          handleChangeGranularfile={this.handleChangeGranularfile}
          handleDelHybridHierarchy={this.handleDelHybridHierarchy}
          handleKpiType={this.handleKpiType}
          renderKpiSectionValues={this.renderKpiSectionValues}
          kpi_names={this.state.kpi_names}
          kpiTypeMap={this.state.kpiTypeMap}
          locationofHierarchyFiles={this.locationofHierarchyFiles}
          current_frequency={this.state.current_frequency}
          current_window={this.state.current_window}
          current_uploaded_file={this.state.current_uploaded_file}
          isRemoveHybridHierarchyOpen={this.state.isRemoveHybridHierarchyOpen}
          cancelRemoveHybridHierarchyOpen={this.cancelRemoveHybridHierarchyOpen}
          confirmRemoveHybridHierarchyOpen={
            this.confirmRemoveHybridHierarchyOpen
          }
          handleUploadedFileEdit={this.handleUploadedFileEdit}
          handleKpiRatio={this.handleKpiRatio}
          loadRemainingMasterValues={this.loadRemainingMasterValues}
          remainingMasterValues={this.state.remainingMasterValues}
          ratio_kpis={this.state.ratio_kpis}
          fileData={this.state.fileData}
          handleObjectiveKpiChange={this.handleObjectiveKpiChange}
          objective_kpi={this.state.objective_kpi}
          updateSourceKpiRatio={this.updateSourceKpiRatio}
          source_kpi_ratios={this.state.source_kpi_ratios}
          selected_objective_Kpi={this.state.selected_objective_Kpi}
          handleThresholdValue={this.handleThresholdValue}
          handleBenchmarkPrefixFieldChange={this.handleBenchmarkPrefixFieldChange}
          benchmark_prefix={this.state.benchmark_prefix}
          threshold_value={this.state.threshold_value}
          filefirstRow={this.state.filefirstRow}
          fileData={this.state.fileData}
          uploadedFileMasterValues={this.state.uploadedFileMasterValues}
          handleHierarchyPanelValues={this.handleHierarchyPanelValues}
          handleTimePanelValues={this.handleTimePanelValues}
          time_options={this.state.time_options}
          kpi_options={this.state.kpi_options}
          handleKpiPanelValues={this.handleKpiPanelValues}
          hierarchy_options={this.state.hierarchy_options}
          time_panel_value={this.state.time_panel_value}
          kpi_selected_panel_values={this.state.kpi_selected_panel_values}
          hierarchy_selected_panel_values={
            this.state.hierarchy_selected_panel_values
          }
          handleSelectedOtherColumns={this.handleSelectedOtherColumns}
          selected_other_columns={this.state.selected_other_columns}
          data_source_status={this.props.data_source_status}
          current_data_source_status={this.state.data_source_status}
          ingestion_type={this.state.ingestion_type}
          ingestion_status={this.state.ingestion_status}
          jumpToUploadStep={this.jumpToUploadStep}
          handleUploadBackButton={this.handleUploadBackButton}
          ingestion_mode={this.state.ingestion_mode}
          user_columns={this.state.user_columns}
          data_source_type={this.state.data_source_type}
          handleUploadBatchData={this.handleUploadBatchData}
          data_source_payload={this.props.data_source_payload || this.state.data_source_payload}
          customHybridText={this.state.customHybridText}
          handleCustomHybridPayload={this.handleCustomHybridPayload}
          ingestion_status_arr={this.props.location.state && this.props.location.state.ingestion_status_obj[this.props.match.params.ingestion_id]}
          hierarchyCombination={this.state.hierarchyCombination}
          addCustomHybridPayload={this.addCustomHybridPayload}
          uploadedJsonConfigFile={this.state.uploadedJsonConfigFile}
          isUploadedJsonConfigFile={this.state.isUploadedJsonConfigFile}
          ingestion_upload_files={this.state.ingestion_upload_files}
          isLoaderLoading={this.state.isLoaderLoading}
          ingestion_id={this.props.match.params.ingestion_id}
          isEmptyObjectiveKpiOrThreshold={this.state.isEmptyObjectiveKpiOrThreshold}
          continueToHybridHierarchyMap={this.continueToHybridHierarchyMap}
          closeObjectiveConfirmationModal={this.closeObjectiveConfirmationModal}
          hierarchy_file_folder ={this.state.hierarchy_location}
          handleDriverKPIChange={this.handleDriverKPIChange}
          handleMaxPercentageThreshold={this.handleMaxPercentageThreshold}
          handleMinPercentageThreshold={this.handleMinPercentageThreshold}
          handleTopValueSelections={this.handleTopValueSelections}
          maxPercentageThreshold={this.state.maxPercentageThreshold}
          minPercentageThreshold={this.state.minPercentageThreshold}
          topValueSelections={this.state.topValueSelections}
          handleDriverObjectiveKPIChange={this.handleDriverObjectiveKPIChange}
          selected_driver_objective_Kpi={this.state.selected_driver_objective_Kpi}
          driverKpiTableRows={this.state.driverKpiTableRows}
          handleAddObjectiveDriverKPIRow={this.handleAddObjectiveDriverKPIRow}
          handleDeleteDriverKPI={this.handleDeleteDriverKPI}
          handlePersistantAlertWindow={this.handlePersistantAlertWindow}
          persistantAlertWindow={this.state.persistantAlertWindow}
        />

      </div>
    );
  }
}
export default Upload;

import React from "react";
import * as d3 from "d3";
import "d3-selection-multi";
import "./dag.css";
var _loadash = require("lodash");

export class DAG extends React.Component {
  componentDidUpdate() {
    if (this.props.data) {
      if (this.props.label) {
        var label = this.props.label.hierarchySelection.concat(
          ",",
          this.props.label.valueSelection
        );
      }
      this.createDag(this.props.data, 0, label);
    }
  }
  componentDidMount() {
    if (this.props.label) {
      var label = this.props.label.hierarchySelection.concat(
        ",",
        this.props.label.valueSelection
      );
    }
    this.createDag(this.props.data, 0, label);
  }
  createDag = (chartPayload, id, label) => {
    d3.select(`#dag-chart${id}`).remove();
    const data = _loadash.cloneDeep(chartPayload);

    var colors = d3.scaleOrdinal(d3.schemeCategory10);
    let links = data.links;
    let nodes = data.nodes;
    var margin = { top: 20, right: 20, bottom: 20, left: 20 },
      width = 800 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;
    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip-causal")
      .style("display", "none")
      .style("opacity", 0);
    var svg = d3
        .select(".chart-wrapper")
        .append("svg")
        .attr("id", `dag-chart${id}`)
        .attr("class", "svg-graph-container")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom),
      node,
      link,
      edgepaths,
      edgelabels,
      rx,
      ry;
    var g = svg.append("g").attr("class", "everything");

    svg
      .append("text")
      .style("font-size", "12px")
      .attr("text-anchor", "start")
      .attr("transform", "rotate(0)")
      .attr("transform", "translate(10 ,20)")
      .text(label);

    svg.call(
      d3.zoom().on("zoom", function () {
        g.attr("transform", d3.event.transform);
      })
    );
    /*  svg.call(tip); */
    g.append("defs")
      .append("marker")
      .attrs({
        id: "arrowhead",
        viewBox: "-0 -5 10 10",
        refX: 23,
        refY: 0,
        orient: "auto",
        markerWidth: 10,
        markerHeight: 10,
        xoverflow: "visible",
      })
      .append("svg:path")
      .attr("d", "M 0,-5 L 10 ,0 L 0,5")
      .attr("fill", "#999")
      .attr("class", "arrow")
      .style("stroke", "none");
    var simulation = d3
      .forceSimulation()
      .force(
        "link",
        d3
          .forceLink()
          .id(function (d) {
            return d.id;
          })
          .distance(200)
          .strength(1)
      )
      .force("charge_force", d3.forceManyBody().strength(-100))
      .force("center_force", d3.forceCenter(width / 2, height / 2));
    link = g
      .selectAll(".link")
      .data(links)
      .enter()
      .append("line")
      .attr("class", "link")
      .style("cursor", "pointer")
      .on("mouseover.tooltip", function (d) {
        edge_mouse_over(d);
      })
      .on("mouseout.tooltip", function (d) {
        edge_mouse_out();
      })
      .on("mouseout.fade", fade(1))
      .on("mousemove", function (d) {
        edge_mouse_move();
      })
      .attr("marker-end", "url(#arrowhead)");
    link
      .append("title")
      .attr("class", "edge-title")
      .text(function (d) {
        return d.type;
      });
    edgepaths = g
      .selectAll(".edgepath")
      .data(links)
      .enter()
      .append("path")
      .attrs({
        class: "edgepath",
        "fill-opacity": 0,
        "stroke-opacity": 0,
        id: function (d, i) {
          return "edgepath" + i;
        },
      })
      .style("pointer-events", "none");
    edgelabels = g
      .selectAll(".edgelabel")
      .data(links)
      .enter()
      .append("text")
      .attrs({
        class: "edgelabel",
        id: function (d, i) {
          return "edgelabel" + i;
        },
        "font-size": 10,
        fill: "#aaa",
      });
    edgelabels
      .append("textPath")
      .attr("xlink:href", function (d, i) {
        return "#edgepath" + i;
      })
      .style("text-anchor", "middle")
      .style("cursor", "pointer")
      .attr("startOffset", "50")
      .text(function (d) {
        return d.type;
      })
      .on("mouseover.tooltip", function (d) {
        edge_mouse_over(d);
      })
      .on("mouseout.tooltip", function (d) {
        edge_mouse_out();
      })
      .on("mouseout.fade", fade(1))
      .on("mousemove", function (d) {
        edge_mouse_move();
      });
    node = g
      .selectAll(`.node`)
      .data(nodes)
      .enter()
      .append("g")
      .attr("class", "node")
      .call(
        d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", drag_end)
      );
    node
      .append("circle")
      .attr("r", 15)
      .style("fill", function (d, i) {
        return colors(i);
      })
      .style("cursor", "pointer")
      .on("mouseover.tooltip", function (d) {
        mouse_over_node(d);
      })
      .on("mouseover.fade", fade(0.1))
      .on("mouseout.tooltip", function (d) {
        mouse_down_node(d);
      })
      .on("mouseout.fade", fade(1))
      .on("mousemove", function () {
        tooltip
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY + 10 + "px");
      });
    simulation.nodes(nodes).on("tick", tickActions);
    simulation.force("link").links(links);

    function tickActions() {
      node.attr("transform", function (d) {
        return "translate(" + d.x + ", " + d.y + ")";
      });
      link
        .attr("x1", function (d) {
          return d.source.x;
        })
        .attr("y1", function (d) {
          return d.source.y;
        })
        .attr("x2", function (d) {
          return d.target.x;
        })
        .attr("y2", function (d) {
          return d.target.y;
        });
      edgepaths.attr("d", function (d) {
        return (
          "M " +
          d.source.x +
          " " +
          d.source.y +
          " L " +
          d.target.x +
          " " +
          d.target.y
        );
      });
      edgelabels.attr("transform", function (d) {
        if (d.target.x < d.source.x) {
          var bbox = this.getBBox();
          rx = bbox.x + bbox.width / 2;
          ry = bbox.y + bbox.height / 2;
          return "rotate(180 " + rx + " " + ry + ")";
        } else {
          return "rotate(0)";
        }
      });
    }
    function dragstarted(d) {
      if (!d3.event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }
    function dragged(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }
    function drag_end(d) {
      if (!d3.event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }
    const linkedByIndex = {};
    links.forEach((d) => {
      linkedByIndex[`${d.source.index},${d.target.index}`] = 1;
    });
    function isConnected(a, b) {
      return (
        linkedByIndex[`${a.index},${b.index}`] ||
        linkedByIndex[`${b.index},${a.index}`] ||
        a.index === b.index
      );
    }
    function fade(opacity) {
      return (d) => {
        node.style("stroke-opacity", function (o) {
          const thisOpacity = isConnected(d, o) ? 1 : opacity;
          this.setAttribute("fill-opacity", thisOpacity);
          return thisOpacity;
        });
        svg
          .selectAll(".edgelabel")
          .style("opacity", (o) =>
            o.source === d || o.target === d ? 1 : opacity
          );
        link.style("opacity", (o) =>
          o.source === d || o.target === d ? 1 : opacity
        );
      };
    }
    function mouse_over_node(d) {
      tooltip.transition().duration(300).style("opacity", 0.8);
      tooltip
        .html("KPI : " + d.name)
        .style("display", "block")
        .style("left", d3.event.pageX + "px")
        .style("top", d3.event.pageY - 200 + "px")
        .attr("height", height);
    }
    function mouse_down_node(d) {
      tooltip
        .transition()
        .duration(100)
        .style("opacity", 0)
        .style("display", "none");
    }
    function edge_mouse_over(d) {
      tooltip
        .transition()
        .duration(300)
        .style("opacity", 0.8)
        .style("display", "block");
      tooltip
        .html(
          "<p/>Source: " +
            d.source.name +
            "<p/>Target: " +
            d.target.name +
            "<p/>Lag: " +
            d.lag +
            "<p/>Strength: " +
            d.strength
        )
        .style("left", d3.event.pageX + "px")
        .style("top", d3.event.pageY + 10 + "px");
    }
    function edge_mouse_out() {
      tooltip
        .transition()
        .duration(100)
        .style("opacity", 0)
        .style("display", "none");
    }
    function edge_mouse_move() {
      tooltip
        .style("left", d3.event.pageX + "px")
        .style("top", d3.event.pageY + 10 + "px");
    }
  };
  render() {
    const { label } = this.props;
    return <div className="chart-wrapper"></div>;
  }
}

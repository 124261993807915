import React from 'react'
import { HierarchySet } from './HierarchySet'
import { HierarchyMapping } from './HierarchyMapping'
import { DataUpload } from './DataUpload'

const UploadSteps = 
    [
      {name: 'File Selection', component: <DataUpload/>},
      {name: 'Hierarchy Creation', component: <HierarchySet/>},
      {name: 'Hierarchy Mapping & Data Upload', component: <HierarchyMapping/>},
    ]

export { UploadSteps }
import React from 'react'
import styled from 'styled-components'
import Task from './task'
import { Droppable } from 'react-beautiful-dnd'

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 220px;
  max-height:300px;
  height:400px;
  display: flex;
  flex-direction: column;
  overflow-y:auto;
  margin:0px 10px 0px 0px;
`
const Title = styled.h5`
  padding: 4px;
  text-align:center;
`
const TaskList = styled.div`
  padding: 4px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDraggingOver ? 'skyblue' : 'white'}
  flex-grow: 1;
  max-height: 300px;
  overflow-y:auto;
`

export default class Column extends React.Component {

  render() {

    const {disableDrag , disableDrop} = this.props;
    
    return (
     
      <Container className="droppable-column">
        <Title>{this.props.uploadStepColumn.title}</Title>
        <hr></hr>
        <Droppable droppableId={this.props.uploadStepColumn.id}  isDropDisabled = {disableDrop} type="TASK">
          {(provided, snapshot) => (
            <TaskList
              innerRef={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {this.props.fileColumns.map((fileColumn, index) => (
                fileColumn ? <Task key={fileColumn.id} fileColumn={fileColumn} index={index} disableDrag={disableDrag} /> : ""
              ))}
              {provided.placeholder}
            </TaskList>
          )}
        </Droppable>
      </Container>
    )
  }
}

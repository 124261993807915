import React, { Component, PureComponent } from "react";
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import "./CausalReactTableComponent.css"
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import { validate_decoded_auth_token } from "./util/util";
var _loadash = require("lodash");


export function AllParams({
  causal_params,
  handleDelCausalParams,
  getCausalResults,
  exp_id,
  run_id,
  user_uuid,
  isAdminUser
}) {
  
  const decoded_auth_token =  validate_decoded_auth_token()
  return (
    <ReactTable
      filterable
      defaultFilterMethod={(filter, row) =>
        row[filter.id].includes(filter.value)
      }
      data={causal_params}
      columns={[
        {
          Header: "#",
          accessor: "row_index",
          filterable: false,
          minWidth: 5,
          Cell: (props) => {
            return <div>{props.value}</div>;
          },
        },
        // {
        //   Header: "ID",
        //   accessor: "task_id",
        //   filterable: false,
        //   show: false,
        //   minWidth: 40,
        // },
        {
          Header: "Hierarchy",
          accessor: "causalhierarchySelection",
          minWidth:30,
        },
        {
          Header: "Values",
          accessor: "causalvalueSelection",
          minWidth:30,
        },
        {
          Header: "KPI",
          accessor: "kpiList",
          minWidth:30,
          // style: { 'whiteSpace': 'unset' }
        },
        {
          Header: "KPI & Lag",
          accessor: "kpiLagVals",
          minWidth: 30,
        },

        {
          Header: "Algorithm",
          accessor: "selected_causal_algo_label",
          minWidth: 30,
        },
        {
          Header: "Parameters",
          accessor: "params_causal",
          minWidth: 40,
        },
        {
          id: "Discard Edges",
          Header: "Discard Edges",
          accessor: (d) => JSON.stringify(d.causaldiscardEdges),
          minWidth: 30,
        },
        {
          id: "Fixed Edges",
          Header: "Fixed Edges",
          accessor: (d) => JSON.stringify(d.causalfixedEdges),
          minWidth: 30,
        },
        {
          id: "User Graph Edges",
          Header: "User Graph Edges",
          accessor: (d) => JSON.stringify(d.causaluserEdges),
          minWidth: 30,
        },
        {
          Header: "Replicated From",
          accessor: "replicate_from",
          minWidth:20,
        },
        {
          Header: "View",
          accessor: "",
          minWidth: 20,
          filterable: false,
          Cell: (props) => {
            return (
              <div className="">
                {props.value.task_id ? (
                  <a
                    onClick={(e) =>
                      getCausalResults(props.index, props.value, exp_id, run_id)
                    }
                  >
                    <i className="fa fa-eye"></i>
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },
        {
          Header: "Delete",
          accessor: "",
          minWidth: 20,
          filterable: false,
          Cell: (props) => {
            return (
              <div className="delete causal-selection-delete-icon">
                {
                user_uuid == decoded_auth_token || isAdminUser ?
                <a onClick={(e) => handleDelCausalParams(props.index,run_id,props.value)}>
                <i className="far fa-trash-alt"></i>
                </a> :
                 <div className="ds-tooltip">
                    <a className="disabled" onClick={(e) => handleDelCausalParams(props.index,run_id)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </a>
                    <span class="ds-tooltiptext">You don't have permission to remove this selection</span>
                  </div>
              }
            
              </div>
            );
          },
        },
      ]}
      pageSizeOptions={[50, 100, 200, 300, 400, 500]}
      defaultPageSize={50}
      showPagination={true}
    />
  );
}

export class CausalReactTableComponent extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (_loadash.isEqual(nextProps.causal_params, this.props.causal_params)) {
      return false;
    }
    return true;
  }
  render() {
    const {
      causal_params,
      handleDelCausalParams,
      getCausalResults,
      exp_id,
      run_id,
      user_uuid,
      isAdminUser
    } = this.props;
    return (
      <div>
        <AllParams
          causal_params={causal_params}
          handleDelCausalParams={handleDelCausalParams}
          getCausalResults={getCausalResults}
          exp_id={exp_id}
          run_id={run_id}
          user_uuid={user_uuid}
          isAdminUser={isAdminUser}
        />
      </div>
    );
  }
}

export default CausalReactTableComponent;

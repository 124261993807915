import React from "react";
import "react-picky/dist/picky.css";
import "./HierarchyValueDropDown.css";
import Select , {components} from 'react-select';
import MySelect from "./MySelect";



const customStyles = {
  option: (defaultStyles, state) => ({
      ...defaultStyles,
      fontSize: "14px"
  }),

  control: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: "14px"
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, fontSize: "15px" }),
};

const Option = props => {
  return (
      <div>
          <components.Option {...props}>
              <input
                  type="checkbox"
                  checked={props.isSelected}
                  onChange={() => null}
              />{" "}
              <label>{props.label}</label>
          </components.Option>
      </div>
  );
};

const allOption = {
  label: "Select all",
  value: "*"
};

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {  
      let isSelectAllSelected = values.some(o => o.key === '*');
      const plural = values.length === 1 ? "" : "s";
      if(isSelectAllSelected){
          values = `${values.length - 1} item${plural} selected`;
      }else{
          values = `${values.length} item${plural} selected`;
      }
  }

  return (
      <components.ValueContainer {...props}>
          {values}
          {input}
      </components.ValueContainer>
  );
};

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All is selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

export class DSHierarchyValueDropDown extends React.Component {
  render() {
    const {
      hierarchies,
      hierarchyValues,
      hierarchyValueOptions,
      hierarchyValuesOnChange,
      hierarchyOnchange,
      valueSelected
    } = this.props;
    return (
      <React.Fragment>
        <fieldset>
          <legend>Select Hierarchy and Values:</legend>
          <div className="hierarchy-value-container">
            <div className="hierarchy_list">
              <label>Select Hierarchy </label> &nbsp;
          <div className="hier_select">
                <Select value={valueSelected} onChange={hierarchyOnchange}
                  options={hierarchies}
                />
              </div>
            </div>
            <div className="hierarchy_values">
              <label>Level Hierarchies </label> &nbsp;
               <MySelect
                  options={hierarchyValueOptions}
                  isMulti
                  components={{
                    Option,
                    MultiValue,
                    ValueContainer,
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(option) => hierarchyValuesOnChange(option)}
                  allowSelectAll={true}
                  value={hierarchyValues}
                  isSearchable={true}
                  maxMenuHeight={450}
              />
            </div>
          </div>
        </fieldset>
      </React.Fragment>
    );
  }
}
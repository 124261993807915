import React from "react";
import Select from "react-select";
import { SetHierarchyPanel } from "../UploadSteps/SetHierarchyPanel";
import "./HierarchySet.css";
import { HierarchyDataMap } from "../UploadSteps/HierarchyDataMap";
import Modal from "react-modal";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import Column from "./Column";
import { Droppable } from "react-beautiful-dnd";
import Task from "./task";
import "./HybridHierarchyMapping.css";
import { INGESTION_STATUS, INGESTION_TYPE } from "../../consts";
var _loadash = require("lodash");

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 220px;
  max-height: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 0px 0px;
`;
const Title = styled.h5`
  padding: 4px;
  text-align: center;
`;
const TaskList = styled.div`
  padding: 4px;
  transition: background-color 0.2s ease;
  background-color: ${(props) => (props.isDraggingOver ? "skyblue" : "white")}
  flex-grow: 1;
  max-height: 300px;
  overflow-y:auto;
`;

const confirmDeleteModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
export class HybridHierarchyMapping extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.handlehierarchyDataMap();
  }
  render() {
    const {
      handleHierarchyLevel,
      hierarchySetPanelsOrder,
      hierarchySets,
      hierarchySetKeyObjData,
      hybridHierarchy,
      closeHierarchyModal,
      resetHierarchyBtn,
      addHybridHierarchy,
      hierarchyDataPanels,
      confirmRemoveHybridHierarchyOpen,
      hierarchyDataMap,
      handleDelHybridHierarchy,
      isRemoveHybridHierarchyOpen,
      cancelRemoveHybridHierarchyOpen,
      jumpToUploadStep,
      data_source_type,
      handleCustomHybridPayload,
      addCustomHybridPayload,ingestion_status,
      ingestion_type,customHybridText
    } = this.props;
    const ingestionStatusAndType = ingestion_status === INGESTION_STATUS && ingestion_type === INGESTION_TYPE
    return (
      <div>
        <div className="hybrid-hierarchy-container">
          {Object.keys(hierarchySets).length > 2 ? (
            <>
              <div className="hierarchySet_Creation_flex-container">
                <div className="hybrid_set_creation_main">
                  <DragDropContext onDragEnd={handleHierarchyLevel}>
                    {hierarchySetPanelsOrder.map((columnId, index) => {
                      if (index === 0 && true) {
                        return;
                      } else {
                        const currenthierarchySetPanels =
                          hierarchySets[columnId];
                        const currentfileColumns = currenthierarchySetPanels.panelObj.map(
                          (panelObjId) => hierarchySetKeyObjData[panelObjId]
                        );
                        return (
                          <Container className="hybrid-set-column" key={index}>
                            <Title>{currenthierarchySetPanels.title}</Title>
                            <hr></hr>
                            <Droppable
                              droppableId={currenthierarchySetPanels.id}
                              isDropDisabled={true}
                              type="TASK"
                            >
                              {(provided, snapshot) => (
                                <TaskList className="draggable-hybrid-set"
                                  innerRef={provided.innerRef}
                                  {...provided.droppableProps}
                                  isDraggingOver={snapshot.isDraggingOver}
                                >
                                  {currentfileColumns.map((fileColumn, index) =>
                                    fileColumn ? (
                                      <Task
                                        key={fileColumn.id}
                                        fileColumn={fileColumn}
                                        index={index}
                                        disableDrag={ingestionStatusAndType}
                                      />
                                    ) : (
                                        ""
                                      )
                                  )}
                                  {provided.placeholder}
                                </TaskList>
                              )}
                            </Droppable>
                          </Container>
                        );
                      }
                    })}
                    <Container className="cusom-hybrid-droppabe-container" key={"hybridSet"}>
                      <Title>Hybrid combination</Title>
                      <Droppable 
                        droppableId={"hybridHierarchy"}
                        isDropDisabled={false}
                        type="TASK"
                        direction="horizontal"
                      >
                        {(provided, snapshot) => (
                          <TaskList className="cusom-hybrid-droppabe-set"
                            innerRef={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                          >
                            {hierarchyDataPanels.panelObj.map((itemId, index) =>
                              hierarchySetKeyObjData[itemId] ? (
                                <Task
                                  key={hierarchySetKeyObjData[itemId].id}
                                  fileColumn={hierarchySetKeyObjData[itemId]}
                                  index={index}
                                />
                              ) : (
                                  ""
                                )
                            )}

                            {provided.placeholder}
                          </TaskList>
                        )}
                      </Droppable>
                    </Container>
                  </DragDropContext>
                </div>
                <div className="selected_hybrid">
                  <p>{hybridHierarchy}</p>
                </div>
              </div>
              <div className="hybrid-btns">
                <button
                  type="button"
                  className="btn btn-sm btn-alert"
                  onClick={closeHierarchyModal}
                  disabled={ingestionStatusAndType}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={resetHierarchyBtn}
                  disabled={ingestionStatusAndType}
                >
                  Reset
                </button>
                <div>


                </div>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={addHybridHierarchy}
                  disabled={ingestionStatusAndType}
                >
                  Create Hierarchy
                </button>
              </div>
            </>
          ) : (
              <div className="no-sets">Only One Set found. Cannot Create Hybrid Hierarchy</div>
            )}
        </div>

        <div className="h-map-container">

          <div className="or-container">
            <hr className="hr-text" data-content="OR" />
          </div>
          {Object.keys(hierarchySets).length > 2 ? (
            <div className="datamap-container">
              <div className="input-container">
                <a>
                  <i
                    className="qtip tip-right"
                    data-tip='hybrid hierarchy should be one of these format: ["Dimension-**-Dimension2","Dimension2-**-Dimension3"]'
                  >
                    <i className="fa fa-question"></i>
                  </i>
                </a>
                <textarea
                  value={customHybridText}
                  onChange={(e) => handleCustomHybridPayload(e.target.value)}
                  id="hybrid_custom_field"
                  name="hybrid_custom_field"
                  placeholder="Manually add in the hybrid hierarchy combinations"
                />
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={(e) => addCustomHybridPayload()}
                >
                  Add Hybrid
              </button>
              </div>
            </div>) : ("")}
          <div className="or-container">
            <hr className="hr-text" data-content="HIERARCHY SUMMARY" />
          </div>
          <table className="default-h-map">
            <thead>
              <th>Hierarchy File</th>
            </thead>
            <tbody>
              {hierarchyDataMap.map((obj, i) => {
                return obj.actual_label ? (
                  obj.actual_label.split("-**-").length > 1 ? (
                    ""
                  ) : (
                      <tr>
                        <td>
                          {" "}
                          {obj.actual_label} [ {obj.label} ]
                      </td>
                      </tr>
                    )
                ) : (
                    ""
                  );
              })}
            </tbody>
          </table>
          {Object.keys(hierarchySets).length > 2 ? (
            <table className="hybrid-h-map">
              <thead>
                <th>Hybrid Hierarchies</th>
              </thead>
              <tbody>
                {hierarchyDataMap.map((obj, i) => {
                  return obj.actual_label ? (
                    obj.actual_label.split("-**-").length > 1 ? (
                      <tr>
                        <td>
                          {obj.actual_label} [ {obj.label} ]
                        </td>
                        <td>
                          {
                            ingestionStatusAndType ?
                              " " :
                              <a
                                className="del-hybrid"
                                onClick={(e) =>
                                  handleDelHybridHierarchy(obj.label, e)
                                }
                              >
                                <i className="fa fa-remove"></i>
                              </a>
                          }

                        </td>
                      </tr>
                    ) : (
                        ""
                      )
                  ) : (
                      ""
                    );
                })}
              </tbody>
            </table>
          ) : (
              ""
            )}
        </div>
        <Modal
          isOpen={isRemoveHybridHierarchyOpen}
          style={confirmDeleteModal}
          onRequestClose={cancelRemoveHybridHierarchyOpen}
          contentLabel="Result"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4">Remove Selection</div>
              <button
                type="button"
                className="close"
                onClick={cancelRemoveHybridHierarchyOpen}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li className="form-column">
                  <p className="text">
                    Are you sure want to remove the hybrid Selection ?
                  </p>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={confirmRemoveHybridHierarchyOpen}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={cancelRemoveHybridHierarchyOpen}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => this.props.jumpToStep(2)}
          >
            Previous
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => jumpToUploadStep(this.props)} autoFocus
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import Select from 'react-select';
import { CustomSelectBox } from './CustomSelectBox';
import Form from 'react-jsonschema-form';
import { toast } from 'react-toastify';
import { handleErrors } from './ui_utils';
import { fetchCall } from './util/util';
import Modal from 'react-modal';
import ReactDynamicTable from './ReactDynamicTable';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const customStyles = {
  content: {
    top: '3%',
    left: '10%',
    right: 'auto',
    width: '80%',
    height: '85%',
    bottom: 'auto',
    padding: '0px'
  }
};

export class WhatIfScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kpi_selected_panel_values: [],
      whatif_result_data: [],
      WhatIfResultModelIsOpen: false
    };
  }
  componentDidMount = () => {
    this.props.fetchDataSources();
    this.props.fetchAnomalyExperiments();
    this.props.fetchCausalExperiments();
    this.props.getAllAnomalyAlgorithms();
  };
  handleDataSourceChange = (data_source) => {
    let data_source_id_obj =
      this.props.datasourcesList &&
      this.props.datasourcesList.filter((item) => {
        return item.display_name == data_source.label;
      });
    this.props.handleDataSourceChangeRCA(data_source);
    if (data_source.value == '') {
      this.setState({
        driverKpiTableRows: []
      });
    }
    this.setState({
      kpi_selected_panel_values:
        data_source && data_source.value.length > 0
          ? data_source_id_obj[0].upload_state.kpi_selected_panel_values
          : [],
      whatif_ds_id:
        data_source && data_source.value.length > 0
          ? data_source_id_obj[0].pipeline_uuid
          : null
    });
  };
  changeUploadOption = (value) => {
    this.props.updateUploadModelOption(value);
  };
  handleObjectiveKPIChange = (obj) => {
    debugger
   let allKpis = this.state.kpi_selected_panel_values
   let filtered_driver_kpis = allKpis.filter((item)=>{
    return item.value !== obj.value
   })
   this.props.handleObjectiveKpiChange(obj)
   this.setState({
    whatif_driver_kpis:filtered_driver_kpis
   })
  }
  view_whatIf_Algo = (ds_id) => {
    let toastId = null;
    toastId = toast('Fetching What If Results', {
      type: toast.TYPE.INFO,
      autoClose: 3000
    });
    const data = JSON.stringify({
      ds_id: ds_id
    });
    fetchCall(`/app/api/anomaly/whatIf/result`, 'POST', data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        if (Object.keys(resultData).length == 0) {
          toast.update(toastId, {
            render: 'Algorithm is not executed.',
            type: toast.TYPE.ERROR,
            autoClose: 2000
          });
        } else {
          let whatif_result_data = JSON.parse(resultData[ds_id].data);
          this.setState({
            whatif_result_data: whatif_result_data,
            WhatIfResultModelIsOpen: true
          });
          toast.update(toastId, {
            render: 'Fetched Successfully',
            type: toast.TYPE.SUCCESS,
            autoClose: 2000
          });
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: 'ERROR: ' + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000
        });
      });
  };

  closeWhatIfResultModal = () => {
    this.setState({
      WhatIfResultModelIsOpen: false
    });
  };

  render() {
    const whatif_columns = [
      {
        Header: 'S No',
        accessor: 'serialNumber',
        width: '5%',
        disableFilters: true
      },
      {
        Header: 'objective KPI',
        accessor: 'objective_kpi',
        width: '10%'
      },
      {
        Header: 'objective_kpi_hierarchy',
        accessor: 'objective_kpi_hierarchy',
        width: '15%'
      },
      {
        Header: 'objective_kpi_hierarchy_value',
        accessor: 'objective_kpi_hierarchy_value',
        width: '15%'
      },
      {
        Header: 'inference',
        accessor: 'inference',
        width: '15%'
      },
      {
        Header: 'driver_warning_statement',
        accessor: 'driver_warning_statement',
        width: '15%'
      }
    ];
    const whatif_data =
      this.state.whatif_result_data &&
      this.state.whatif_result_data.length > 0 &&
      this.state.whatif_result_data.map((item, index) => {
        return {
          serialNumber: index + 1,
          objective_kpi: item.objective_kpi,
          objective_kpi_hierarchy: item.objective_kpi_hierarchy,
          objective_kpi_hierarchy_value: item.objective_kpi_hierarchy_value,
          inference: item.inference,
          driver_warning_statement: item.driver_warning_statement
        };
      });
    const {
      datasourcesList,
      rcaDisplayCausalProjects,
      causalRunsRCA,
      handleCausalExpCallRCA,
      handleSelectCausalRunRCA
    } = this.props;
    const display_datasourcesList =
      datasourcesList &&
      datasourcesList
        .filter((ds) => ds.data_source_status == 'INITIAL INGESTION SUCCESS')
        .map((ds) => {
          return {
            value: ds.display_name,
            label: ds.display_name
          };
        });
    let causalExperiments_display = [];
    if (rcaDisplayCausalProjects && rcaDisplayCausalProjects.length > 0) {
      causalExperiments_display =
        rcaDisplayCausalProjects.length > 0 &&
        rcaDisplayCausalProjects.map((obj, i) => {
          return {
            value: obj.experiment_id,
            label: obj.experiment_name
          };
        });
    }
    let causal_runs_display = [];
    if (causalRunsRCA && causalRunsRCA.length > 0) {
      causal_runs_display = causalRunsRCA.map((obj, i) => {
        return {
          value: obj.run_id,
          label: obj.run_name
        };
      });
    }
    let predictiveAlgos =
      this.props.anomaly_algorithms &&
      this.props.anomaly_algorithms.filter(
        (obj) => obj.algorithm_category === 'WhatIf'
      );
    let whatIfAlgosList =
      predictiveAlgos &&
      predictiveAlgos.map((item, i) => {
        return {
          value: item.algorithm_name,
          label: item.display_name
        };
      });
    return (
      <div className='feedback_mining'>
        <div className='rca_heading'>What If Scenario</div>
        <fieldset className='DS-dropdown'>
          <legend>Select Data Source :</legend>
          <div className='exp_run_container'>
            <div className='exp_select'>
              <label>Data Source</label> &nbsp;
              <div className='experiment-select'>
                <Select
                  options={
                    display_datasourcesList && [
                      { value: '', label: 'Select....' },
                      ...display_datasourcesList
                    ]
                  }
                  //value={selected_rca_anomaly_label}
                  onChange={this.handleDataSourceChange}
                />
              </div>
            </div>
          </div>
        </fieldset>
        <div className='App'>
          <div className='radio_buttons'>
            <input
              type='radio'
              className='whatIfRadio'
              name='upload'
              value='model'
              id='create'
              checked={this.props.whatIf_upload_or_model === 'model'}
              onChange={(e) => this.changeUploadOption(e.target.value)}
            />
            <label htmlFor='model'>Create Model</label>

            <input
              type='radio'
              value='upload'
              className='whatIfRadio'
              id='upload_files'
              checked={this.props.whatIf_upload_or_model === 'upload'}
              name='upload'
              onChange={(e) => this.changeUploadOption(e.target.value)}
            />
            <label htmlFor='upload'>Upload model files</label>
          </div>
          {this.props.whatIf_upload_or_model == 'upload' ? (
            <div className='pt-15'>
              <fieldset>
                <legend>Upload files:</legend>
                <div className='exp_run_container'>
                  <div className='exp_select'>
                    <label>Upload regression model file :</label> &nbsp;
                    <div className='experiment-select pt-10'>
                      <input
                        placeholder='regression model file(.sav) located at!'
                        type='text'
                        onChange={(e) =>
                          this.props.saveRegressionFilePath(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className='exp_select'>
                    <label className='text-right pt-15'>
                      Upload model param file :
                    </label>{' '}
                    &nbsp;
                    <div className='experiment-select pt-10'>
                      <input
                        placeholder='fit param file(.json) located at!'
                        type='text'
                        onChange={(e) =>
                          this.props.saveModelParamFile(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='exp_run_container'>
                  <div className='exp_select'>
                    <label>Upload fit param file :</label> &nbsp;
                    <div className='experiment-select pt-10'>
                      <input
                        placeholder='regression model file(.json) located at!'
                        type='text'
                        onChange={(e) =>
                          this.props.saveFitParamFile(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className='exp_select'>
                    <label className='text-right pt-15'></label> &nbsp;
                    <div className='experiment-select pt-10'></div>
                  </div>
                </div>
              </fieldset>
            </div>
          ) : (
            <div className='pt-15'>
              <fieldset>
                <legend>Select Causal Project & Experiment:</legend>
                <div className='exp_run_container'>
                  <div className='exp_select'>
                    <label>Causal Project</label> &nbsp;
                    <div className='experiment-select'>
                      <Select
                        options={[
                          { value: '', label: 'Select....' },
                          ...causalExperiments_display
                        ]}
                        value={this.props.selected_rca_causal_exp_label}
                        onChange={handleCausalExpCallRCA}
                      />
                    </div>
                  </div>
                  <div className='run_select'>
                    <label>Causal Experiment</label> &nbsp;
                    <div className='experiment-select'>
                      <Select
                        options={[
                          { value: '', label: 'Select....' },
                          ...causal_runs_display
                        ]}
                        value={this.props.selected_causal_run}
                        onChange={handleSelectCausalRunRCA}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Select Objective and driver KPIs:</legend>
                <div className='exp_run_container'>
                  <div className='exp_select'>
                    <label>Objective KPIs:</label> &nbsp;
                    <div className='experiment-select'>
                      <Select
                        options={[
                          { value: '', label: 'Select....' },
                          ...this.state.kpi_selected_panel_values
                        ]}
                        value={this.props.selected_objective_kpi}
                        onChange={(e) => {
                          this.handleObjectiveKPIChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className='run_select'>
                    <label>Driver KPIs</label> &nbsp;
                    <div className='experiment-select'>
                      <CustomSelectBox
                        selectedValues={
                          this.props.selected_driver_kpis &&
                          this.props.selected_driver_kpis.length > 0
                            ? this.props.selected_driver_kpis
                            : []
                        }
                        multiSelectOptions={
                          this.state.whatif_driver_kpis
                        }
                        onChangeValues={(e) =>
                          this.props.handleDriverKPIChange(e)
                        }
                        isOpen={false}
                        valueKey='value'
                        labelKey='label'
                        isMultiple={true}
                        includeSelectAll={true}
                        includeFilter={true}
                        selectBoxHeight={300}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Algorithm:</legend>
                <div className='exp_run_container'>
                  <div className='exp_select'>
                    <label>Select Algorithm</label> &nbsp;
                    <div className='experiment-select'>
                      <Select
                        options={whatIfAlgosList}
                        onChange={this.props.handleWhatIfAlgoChange}
                        value={this.props.selected_whatif_algo}
                        maxMenuHeight={100}
                      />
                    </div>
                  </div>
                  <div className='run_select'>
                    {this.props.whatIfAlgoParam &&
                      this.props.whatIfAlgoParamData && (
                        <>
                          <label>Algo Model Params</label> &nbsp;
                          <div className='experiment-select'>
                            <div className='anomaly_algo_param predictive-params'>
                              <Form
                                schema={this.props.whatIfAlgoParam}
                                formData={this.props.whatIfAlgoParamData}
                                // onChange={
                                //   this.props.handleUnivariateParamChange
                                // }
                                liveValidate={true}
                                showErrorList={false}
                              >
                                <div>
                                  <button type='submit' className='hidden'>
                                    Submit
                                  </button>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </fieldset>
            </div>
          )}
        </div>
        <div className='anomaly-run'>
          {
            <div>
              <button
                type='button'
                value='View Results'
                className='btn btn-sm btn-success export'
                onClick={() => this.view_whatIf_Algo(this.state.whatif_ds_id)}
              >
                View Results
              </button>
              <button
                type='button'
                className='btn btn-sm btn-primary'
                onClick={() =>
                  this.props.run_whatIf_Algo(this.state.whatif_ds_id)
                }
              >
                Execute
              </button>
            </div>
          }
        </div>
        <React.Fragment>
          <Modal
            isOpen={this.state.WhatIfResultModelIsOpen}
            onRequestClose={this.closeWhatIfResultModal}
            style={customStyles}
            contentLabel='Result'
            ariaHideApp={false}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <div className='modal-title h4'>What If Scenario Results</div>
                <button
                  type='button'
                  className='close'
                  onClick={this.closeWhatIfResultModal}
                >
                  <i className='fa fa-close'></i>
                </button>
              </div>
              <div className='modal-body'>
                <div className='ds-result-heading'>
                  <label htmlFor='pipeline_name'>
                    <b></b>
                  </label>{' '}
                  <label>{''}</label>
                </div>
                <div className='pull-right'>
                  <CSVLink
                    data={whatif_data}
                    filename={`what_if_data_results_${moment().format(
                      'DD-MM-YYYY_hh_mm'
                    )}.csv`}
                    separator="|"
                  >
                    <button className='btn btn-sm btn-success'>
                      <i className='fa fa-download'></i> &nbsp; <b>Download</b>{' '}
                      &nbsp;{' '}
                      <i class='fa fa-file-excel-o' aria-hidden='true'></i>{' '}
                    </button>
                  </CSVLink>
                </div>
                <div className='ds-result-table'>
                  {this.state.whatif_result_data &&
                    this.state.whatif_result_data.length > 0 && (
                      <ReactDynamicTable
                        columns={whatif_columns}
                        data={whatif_data}
                      />
                    )}
                </div>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      </div>
    );
  }
}

import React from "react";
import Modal from "react-modal";
import "./DataIngestion.css";
import { toast } from "react-toastify";
import { handleErrors, isInputNameValidated } from "../ui_utils";
import { CONFIG_MS, DATA_INGESTION, NAME_ERROR } from "../../consts";
import ReactDynamicTable from "../ReactDynamicTable";
import {INGESTION_STATUS,INGESTION_TYPE } from "../../consts";
import { fetchCall } from "../util/util";
import jwt_decode from "jwt-decode";
import { validate_decoded_auth_token , requestWithHashDigest} from "../util/util";
import AppContext from "../context/AppContext";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
const INGESTION_MODE = {
  file_upload: "STATIC LOAD",
};
export class DataIngestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = DATA_INGESTION;
  }
  static contextType = AppContext
  componentDidMount() {
    const data_source_id = this.props.match.params.datasource_id;
    const data_source_name = this.props.currentDataSourceName
    this.fetchAllIngestions(data_source_id);
  }
  fetchAllIngestions = (id) => {
    let toastId = null;
    toastId = toast("Loading Ingestions", { type: toast.TYPE.INFO });
    
    fetchCall(`/app/api/datasource/${id}/ingestions`,"GET")
      .then((result) => result.json())
      .then((resultData) => {
        this.setState({
          dataIngestions: resultData,
        });
        toast.update(toastId, {
          render: "Ingestions fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handleNewDataIngestion = (e) => {
    let name = e.target.value;
    this.setState({
      dataIngestionName: name,
    });
  };
  deleteDataIngestion = (ingestionObj) => {
    this.setState({
      delDataIngestionIsOpen: true,
      delDataSourceId: ingestionObj.datasource_uuid,
      delDataIngestionId: ingestionObj.ingestion_uuid,
    });
  };
  handleCreateIngestion = () => {
    this.setState({
      showAddIngestionModal: true,
      dataIngestionName:"",
      nameError:""
    });
  };
  saveDataIngestion = (id) => {
    let current_data_ingestion_name = this.state.dataIngestionName;
    let dataIngestions = this.state.dataIngestions
    let ingestions_names = dataIngestions.map((item) => {
      return item.display_name
    })
    if (ingestions_names.includes(current_data_ingestion_name)) {
      const text = "Data ingestion name already exist";
      this.props.togglePopup(text);
      return;
    }
    if(!isInputNameValidated(current_data_ingestion_name)){
      this.setState({
        nameError:NAME_ERROR
      })
      return;
    }else {
      let datasource_id = this.props.match.params.datasource_id;
      let toastId = null;
      toastId = toast("Fetching Data ingestions", { type: toast.TYPE.INFO });
     
      let request_body = {
        display_name: current_data_ingestion_name,
        user_uuid: JSON.parse(this.context.store_user_uuid),
        ingestion_mode: INGESTION_MODE.file_upload,
        ingestion_meta: {
          hierarchies: [],
          ingestion_paths: {},
        },
      }
      const request_body_with_digest = requestWithHashDigest(request_body)
      fetchCall(`/app/api/datasource/${datasource_id}/ingestion`,'POST',JSON.stringify(request_body_with_digest))
        .then((response) => handleErrors(response))
        .then((resultData) => {
          this.setState({
            dataIngestions: [...this.state.dataIngestions, resultData],
            showAddIngestionModal: false,
            dataIngestionName: "",
          });
          toast.update(toastId, {
            render: "Data Ingestion created successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "ERROR: " + error.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        });
    }
  };
  closeAddIngestionModal = () => {
    this.setState({
      showAddIngestionModal: false,
    });
  };
  closeDeleteIngestionModal = () => {
    this.setState({
      delDataIngestionIsOpen: false,
    });
  };
  confirmDeleteDataIngestion = () => {
    let dataIngestions = this.state.dataIngestions;
    let toastId = null;
    let ingestion_id = this.state.delDataIngestionId;
    let datasource_id = this.props.match.params.datasource_id;
    toastId = toast("Deleting Pipline. Please wait...", {
      type: toast.TYPE.INFO,
    });

    fetchCall(`/app/api/datasource/${datasource_id}/ingestion/${ingestion_id}`,'DELETE')
      .then((result) => handleErrors(result))
      .then((resultData) => {
        if (resultData) {
          let filteredIngestions = dataIngestions.filter(
            (obj) => obj.ingestion_uuid !== ingestion_id
          );
          this.setState({
            dataIngestions: filteredIngestions,
            delDataIngestionIsOpen: false,
            dataIngestionName: "",
            delDataIngestionId: null,
          });
          toast.update(toastId, {
            render: "Deleted successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      })

      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  render() {
    const decoded_auth_token = validate_decoded_auth_token();
    const columns = [
      {
        Header: "Ingestion Name",
        accessor: "ingestionName",
        width: "15%",
      },
      {
        Header: "Ingestion Mode",
        accessor: "ingestionMode",
        width: "15%",
      },
      {
        Header: "Type",
        accessor: "ingestionType",
        width: "10%",
      },
      {
        Header: "Status",
        accessor: "ingestionStatus",
        width: "10%",
      },
      {
        Header: "Created On",
        accessor: "createdOn",
        width: "15%",
      },
      {
        Header: "View",
        accessor: "view",
        width: "5%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view">
              <a onClick={(e) =>
                this.props.handleFetchIngestion(value)
              }
              >
                <i className="far fa-eye"></i>
              </a>
            </div>
          );
        }
      },
      {
        Header: "Delete",
        accessor: "delete",
        width: "5%",
        disableFilters: true,
        Cell: ({ cell: { value }, index }) => {
          return (
            <div className="delete">
              {
                this.state.dataIngestions && this.state.dataIngestions.length > 1 &&
                  value.ingestion_status === INGESTION_STATUS &&
                  value.ingestion_type === INGESTION_TYPE ? <span>{ }</span> :
                  value.user_uuid == decoded_auth_token || this.props.isAdminUser ?
                    <a onClick={(e) =>
                      this.deleteDataIngestion(value)
                    }
                    >
                      <i className="far fa-trash-alt"></i> {value.datasource_uuid, index}
                    </a> :
                    <div className="ds-tooltip">
                      <a className="disabled" onClick={(e) =>
                        this.deleteDataIngestion(value)
                      }
                      >
                        <i className="far fa-trash-alt"></i>
                      </a>
                      <span class="ds-tooltiptext">You don't have permission to remove this Ingestion</span>
                    </div>
              }

            </div>
          );
        }
      },
      {
        Header: "Incremental",
        accessor: "is_active",
        width: "7%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
            return (
              <div className="is_active">
                {
                 value.ingestion_status == "STATIC INGESTION COMPLETED" ?
                 <button className="btn btn-sm btn-primary" onClick={(e) =>
                    this.props.setIngestionRunIncremental(value)
                      }
                  >
                  Run
                  </button>
                   :
                   "-"
                 }
               </div>
            );
        }
      }
    ]
    const data = this.state.dataIngestions && this.state.dataIngestions.map((item, index) => {
      return {
        ingestionName: item.display_name,
        ingestionMode: item.ingestion_mode,
        ingestionType: item.ingestion_type,
        ingestionStatus: item.ingestion_status,
        createdOn: item.created_date.slice(0, 16),
        delete: item, index,
        view: item,
        is_active: item
      }
    })
    return (
      <div>
        <React.Fragment>
          <div className="back-btn">
            <button
              onClick={(e) => this.props.history.push("/app/datasource/")}
              className="btn btn-sm btn-primary back-btn"
            >
              <i className="fa fa-arrow-left"></i> Back
            </button>
          </div>
        </React.Fragment>
        <React.Fragment>
          <div className="breadcrumb_heading">
            <label className="pipelinename">
              Data Ingestions   (Datasource : {localStorage.getItem("currentDataSourceName")} )
            </label>
            <input
              type="button"
              className="btn btn-sm add-btn"
              onClick={() => this.handleCreateIngestion()}
              value="Add Data Ingestion"
              id="add_ingestion_btn"
            ></input>
          </div>
        </React.Fragment>
        <React.Fragment>
          <ReactDynamicTable
            columns={columns}
            data={data}
          />
        </React.Fragment>
        <Modal
          isOpen={this.state.showAddIngestionModal}
          onRequestClose={this.closeAddIngestionModal}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          contentLabel="Upload Data"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4"> Create Data Ingestion </div>
              <button
                type="button"
                className="close"
                onClick={this.closeAddIngestionModal}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <ul className="pipeline-form">
                  <li className="form-row">
                    <label htmlFor="pipeline_name">
                      <span>&#10033;</span>Data Ingestion Name:
                    </label>
                    <input
                      type="text"
                      autoComplete={"off"}
                      value={this.state.dataIngestionName}
                      id="data_ingestion_name_field"
                      onChange={(e) => this.handleNewDataIngestion(e)}
                    />
                  </li>
                  <li className="error-row">
                      <label></label>
                      <div className="error-msg">{this.state.nameError}</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                id="create_data_ingestion_btn"
                className="btn btn-sm btn-primary"
                onClick={() =>
                  this.saveDataIngestion(this.props.match.params.datasource_id)
                }
              >
                Save
              </button>
              <button
                type="button btn btn-sm"
                className="btn btn-sm btn-alert"
                onClick={this.closeAddIngestionModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.delDataIngestionIsOpen}
          onRequestClose={this.closeDeleteIngestionModal}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          contentLabel="Upload Data"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4"> Remove Data Ingestion</div>
              <button
                type="button"
                className="close"
                onClick={this.closeDeleteIngestionModal}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li className="form-column">
                  <p className="text">
                    Are you sure want to delete Data Ingestion?
                  </p>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button btn btn-sm"
                className="btn btn-sm btn-primary"
                onClick={this.confirmDeleteDataIngestion}
              >
                Yes
              </button>
              <button
                type="button btn btn-sm"
                className="btn btn-sm btn-alert"
                onClick={this.closeDeleteIngestionModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.props.activeIngestionModalIsOpen}
          onRequestClose={this.props.closeSetIngestionIncremental}
          style={customStyles}
          contentLabel="Delete Pipeline"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4"> Ingesion Incremental Run</div>
              <button
                type="button"
                className="close"
                onClick={this.props.closeSetIngestionIncremental}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li>
                  <p className="text">
                    Are you sure want to confirm run incremental ?
                  </p>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={this.props.confirmIngestionIncrementalRun}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={this.props.closeSetIngestionIncremental}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

import React from "react";
import Modal from "react-modal";
import "./PrivacyPolicyModal.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "28%",
    padding: "0x",
  },
};

export class PrivacyPolicyModal extends React.Component {
  render() {
    const {
      isPrivacyPolicyModalOpen,
      closePrivacyPolicyModal
    } = this.props;
    return (
      <Modal
        isOpen={isPrivacyPolicyModalOpen}
        onRequestClose={closePrivacyPolicyModal}
        style={customStyles}
        contentLabel="Delete Pipeline"
        ariaHideApp={false}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4"><b>Legal Notice</b></div>
              <button
                type="button"
                className="close"
                onClick={closePrivacyPolicyModal}
                >
                <i className="fa fa-close"></i>
              </button>
          </div>
          <div className="modal-body">
              <div className="privacy-info">       
               <p>
                This system is the property of Accenture and is to be used in accordance with applicable Accenture Policies.  Unauthorized access or activity is a violation of Accenture Policies and may be a violation of law.  Use of this system constitutes consent to monitoring for unauthorized use, in accordance with Accenture Policies, local laws, and regulations.  Unauthorized use may result in penalties including, but not limited to, reprimand, dismissal, financial penalties, and legal action.
               </p>
           
              </div>

          </div>
          <div className="modal-footer privacy-modal-footer">
              {/* <button
                type="button"
                className="btn btn-sm btn-primary"
              >
                Save
              </button> */}
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={closePrivacyPolicyModal}
              >
                Close
              </button>
            </div>
          </div>
      </Modal>
    );
  }
}

import React, { Component, PureComponent } from "react";
import { useTable, usePagination, useFilters, useGlobalFilter } from 'react-table'
import './ReactDynamicTable.css'
import { DYNAMIC_TABLE_PAGINATION_RANGE } from "../consts";

var _loadash = require("lodash");


function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}


function Table({ columns, data }) {

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )
    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 },
        defaultColumn,
        filterTypes,
    },
        useFilters,
        useGlobalFilter,
        usePagination,
    )

    return (
        <div className="table-container">
            <table className="react-table" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <React.Fragment key={index}>
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, headerIndex) => (
                                    <th key={headerIndex} className="heading" width={column.width} {...column.getHeaderProps()}>
                                        <div className="header-label">{column.render('Header')}</div>
                                        <div className="filter">
                                            {column.canFilter ? column.render('Filter') : null}
                                        </div>
                                    </th>

                                ))}
                            </tr>
                        </React.Fragment>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.length > 0 ? page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr key={i} {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    return <td key={index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    }) : <tr><td colSpan="7">No data found, Please create new one</td></tr>}
                </tbody>
            </table>
            {
                data && data.length > 10 ?
                    <div className="pagination">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </button>{' '}
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </button>{' '}
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </button>{' '}
                        <button id="jump_to_last_page" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </button>{' '}
                        <span>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                        <span>
                            | Go to page:{' '}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{ width: '100px' }}
                            />
                        </span>{' '}
                        <select
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {DYNAMIC_TABLE_PAGINATION_RANGE.map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div> : ' '
            }

        </div>
    )
}

export class ReactDynamicTable extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        if (_loadash.isEqual(nextProps.data, this.props.data)) {
            return false;
        }
        return true;
    }
    render() {
        const { columns, data } = this.props;
        return (
            <div>
                <Table columns={columns} data={data}
                />
            </div>
        );
    }
}

export default ReactDynamicTable;

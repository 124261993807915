import React from "react";
import Modal from "react-modal";
import "./ConsolidatedDataSourceView.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
    padding: "0x",
  },
};

export class ConsolidatedDataSourceView extends React.Component {
  render() {
    const {
      isDSConsolidatedViewOpen,
      consolidatedDSDetails
    } = this.props;
    return (
      <Modal
        isOpen={isDSConsolidatedViewOpen}
        onRequestClose={this.props.closeDSConsolidatedModal}
        style={customStyles}
        contentLabel="Delete Pipeline"
        ariaHideApp={false}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">Data Source Details</div>
              <button
                type="button"
                className="close"
                onClick={this.props.closeDSConsolidatedModal}
                >
                <i className="fa fa-close"></i>
              </button>
          </div>
          <div className="modal-body">
              <div className="ds-info-details">
                <div className="ds-info">
                  <div className="ds-label">
                      <label><b>Data Source Name</b> </label>
                  </div>
                  <div className="ds-colon">:</div>
                  <div className="ds-value">
                      {consolidatedDSDetails && consolidatedDSDetails.display_name}
                  </div>
                </div>
                <div className="ds-info">
                  <div className="ds-label">
                      <label><b>Hierarchies</b></label>
                  </div>
                  <div className="ds-colon">:</div>
                  <div className="ds-value">
                      {consolidatedDSDetails && consolidatedDSDetails.user_columns.hierarchy_splitted.filter((hierarchy)=>{
                        return !hierarchy.includes("-**-")
                      }).join(", ")}
                  </div>
                </div>
                <div className="ds-info">
                  <div className="ds-label">
                      <label><b>Kpi</b></label>
                  </div>
                  <div className="ds-colon">:</div>
                  <div className="ds-value">
                      {consolidatedDSDetails && consolidatedDSDetails.user_columns.kpi.map((kpi)=>{
                        return kpi
                      }).join(", ")}
                  </div>
                </div>
                <div className="ds-info">
                  <div className="ds-label">
                      <label><b>Time</b></label>
                  </div>
                  <div className="ds-colon">:</div>
                  <div className="ds-value">
                      {consolidatedDSDetails && consolidatedDSDetails.user_columns.time}
                  </div>
                </div>
                <div className="ds-info">
                  <div className="ds-label">
                      <label><b>Window Size</b></label>
                  </div>
                  <div className="ds-colon">:</div>
                  <div className="ds-value">
                      {consolidatedDSDetails && consolidatedDSDetails.upload_state.current_window}
                  </div>
                </div>
                <div className="ds-info">
                  <div className="ds-label">
                      <label><b>Frequency</b></label>
                  </div>
                  <div className="ds-colon">:</div>
                  <div className="ds-value">
                      {consolidatedDSDetails && consolidatedDSDetails.upload_state.current_frequency.value}
                  </div>
                </div>
                <div className="ds-info">
                  <div className="ds-label">
                      <label><b>Hierarchy Sets</b></label>
                  </div>
                  <div className="ds-colon">:</div>
                  <div className="ds-value">
                      {consolidatedDSDetails && Object.keys(consolidatedDSDetails.hierarchy_combination).map((key)=>{
                        return <div><span>{key}</span>: &nbsp; <span>{consolidatedDSDetails.hierarchy_combination[key].join(",")}</span></div>
                      })                      
                      }
                  </div>
                </div>
                <div className="ds-info">
                  <div className="ds-label">
                      <label><b>Hybrid Hierarchies</b></label>
                  </div>
                  <div className="ds-colon">:</div>
                  <div className="ds-value">
                      {consolidatedDSDetails && consolidatedDSDetails.user_columns.hierarchy_splitted.filter((hierarchy)=>{
                        return hierarchy.includes("-**-")
                      }).join(", ") || "No"}
                  </div>
                </div>

              </div>

          </div>
          </div>
      </Modal>
    );
  }
}

export const DATA_SOURCE = "Data source created"
export const UPLOADED_DATA = "Uploaded data successfully"
export const HIERARCHY_SELECT = "Data selection of Hierarchy and kpis done"
export const RATIO_KPI = "Ration kpis created"
export const HIERARCHY_SETS = 'Hierarchy sets created'
export const ANOMALY_PROJECT = "Aomaly project created"
export const ANOMALY_EXPERIMENT = "Aomaly experiment created"
export const AUTO_GENERATE = "Generated auto parameters successfully"
export const EXECUTE_RUN = "Anomaly selected parameters executed"
export const EXPORT_ANOMALY = "Anomaly Algo Params has exported"

export const CAUSAL_PROJECT = "Causal project created"
export const CAUSAL_EXPERIMENT = "Causal experiment created"
export const CAUSAL_UPLOAD = "Manual causal file upload and Graph generated"
export const EXECUTE_CAUSAL_RUN = "Causal algo combinations executed"
export const EXECUTE_CAUSAL_ESTIMATION = "Causal Estimation combinations executed"
export const CAUSAL_REPLICATE = "Causal result replicate selections added"
export const ESTIMATE_REPLICATE = "Causal estimate replicate selections added"
export const RUN_RCA = "RCA is executed"

//event properties
export const DATASOURCE_NAME = "Data source name"
export const TOTAL_ANOMALY_PARAMS = "Total number of Anomaly combinations executed"
export const ANOMALY_ALGO = "Algo type,Algo name"
export const ANOMALY_PROJECT_NAME = "Anomaly project name"
export const ANOMALY_EXP_NAME = "Anomaly experiment name"
export const CAUSAL_PROJECT_NAME = "Causal project name"
export const CAUSAL_EXP_NAME = "Causal experiment name"
export const TOTAL_CAUSAL_EXECUTE_PARAMS = "Total number of Causal combinations executed"
export const CAUSAL_ALGORITHMS = "Causal algorithms"
export const CAUSAL_ESTIMATIONS = "Causal estimation name"
export const TOTAL_CAUSAL_ESTIMATE_PARAMS  = "Total number of estimation combinations executed"

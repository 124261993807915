import React from 'react';
import Select from "react-select";
import Form from "react-jsonschema-form";

export class AlgoComponent extends React.Component {
    render() {
        const {algoTypeOptions,algoTypeChange,algoTypeValue,algoOptions,algoOnChange,algoValue,schema,formData,paramOnChange,liveValidate,showErrorList,maxMenuHeight} = this.props
        return (
            <fieldset>
            <legend>Select Default Algorithm:</legend>
            <div className="anomaly_algo_flex_container">
              <div className="algo_values">
                <label>Select Algorithm Type </label> &nbsp;
                <Select
                  options={algoTypeOptions}
                  onChange={algoTypeChange}
                  value={algoTypeValue}
                  maxMenuHeight={maxMenuHeight}
                />
              </div>
              <div className="algo">
                <label>Select Algorithm </label> &nbsp;
                <Select
                  options={algoOptions}
                  onChange={algoOnChange}
                  value={algoValue}
                  maxMenuHeight={maxMenuHeight}
                />
              </div>
              <div className="anomaly_algo_param">
               {schema && formData && 
               <Form
                   schema={schema}
                   formData={formData}
                   onChange={paramOnChange}
                   liveValidate={liveValidate}
                   showErrorList={showErrorList}
                  >
                    <div>
                      <button type="submit" className="hidden">
                        Submit
                      </button>
                    </div>
                  </Form>
              }
              </div>
            </div>
          </fieldset>

        )
    }
}

export default AlgoComponent;
import React, { Component } from 'react';
import Form from 'react-jsonschema-form';
import { fetchCall } from './util/util';
import { toast } from 'react-toastify';
import { handleErrors } from './ui_utils';
import AppContext from "./context/AppContext";
import Select from 'react-select';

const customStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "#4caf50",
    color: state.isSelected  ? '#fff' : '#fff',
    fontSize:'15px',
    borderColor: "#4caf50",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
    }
  }),
  menu: (base, state)  => ({
    ...base,
    borderRadius: 0,
    fontSize:'15px',
    marginTop: 0
  }),
  singleValue: (base, state)  => ({
    ...base,
    color: state.isSelected  ? '#fff' : '#fff',
    borderRadius: 0,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
  }),
  placeholder: base => ({
    ...base,
    color:'#fff',
    padding: 0
  })
};

export class FeedbackMining extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReportOption:null
    }
  }
  static contextType = AppContext
  componentDidMount() {
    this.getAllAnomalyAlgorithms();
  }
  getAllAnomalyAlgorithms = () => {
    fetchCall(`/app/api/algorithm/feedback`,'GET')
      .then((result) => handleErrors(result))
      .then((resultData) => {
        let feedback_algo_params = resultData && resultData.filter((obj) => obj.algorithm_name == 'feedback_mining');
        this.setState({
          formData : JSON.parse(feedback_algo_params[0].algorithm_defaults),
          schema : JSON.parse(feedback_algo_params[0].algorithm_parameters)
        });
      })
      .catch((error) => {
        toast("ERROR: " + error, {
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
  };
  handleParamOnChange = (form_event) => {
    this.setState({
      formData: form_event.formData,
       formErrors:form_event.errors
    });
  };
  downloadCSV(data) {
    const headers = Object.keys(data[0]);
    const csvContent = "data:text/csv;charset=utf-8,"
        + [headers].concat(data.map(row => Object.values(row).join(','))).join('\n');
    const encodedUri = encodeURI(csvContent);
  
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'report.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  changeOrFeedbackReports = (label) => {
    if (label.value === null || label.value === "") {
      this.setState({
         selectedReportOption:null
      });
      return;
    }
    this.setState({
      selectedReportOption:label
   });
    let toastId = null;
    toastId = toast("Processing Feedback Reports", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
     
    const data =  JSON.stringify({
      user_uuid: JSON.parse(this.context.store_user_uuid),
      
      report_type:label.value
    })
      fetchCall(`/app/api/graph/feedback/report`,'POST',data)
      .then((result) => handleErrors(result))
        .then((resultData) => {
          this.downloadCSV(resultData)
          
          if (resultData) {
            toast.update(toastId, {
              render: "Processed Successfully",
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "ERROR: " + error.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        });
  }
  render() {
    return (
      <div className='feedback_mining'>
        <div className='rca_heading'>Feedback Mining</div>
        <fieldset>
          <legend>Select Feedback Parameters:</legend>
          <div className='anomaly_algo_flex_container feedback_flex_container'>
            <label>Feedback Parameters:</label>
            <div className='feedback_algo_param'>
              {this.state.schema && this.state.formData && (
                <Form
                  schema={this.state.schema}
                  formData={this.state.formData}
                  onChange={this.handleParamOnChange}
                  liveValidate={true}
                  showErrorList={false}
                >
                  <div>
                    <button type='submit' className='hidden'>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </fieldset>
        <div className='change-report'>
          <Select 
          styles={
            customStyles
          }        
          placeholder="Select Report..."
          value={this.state.selectedReportOption} onChange={this.changeOrFeedbackReports}
          options={[
            {label:"Change Report",value:"feedbck_mining_change_report"},
            {label:"Feedback Responses",value:"feedback_responses"},
            {label:"Guided Questions Report",value:"guided_questions_feedbck_report"},
            {label:"User Text Feedback Report",value:"user_text_feedbck_report"},
            {label:"Thumbs Down Report",value:"thumbs_up_down_report"}
        ]}
          />
        </div>
      </div>
    );
  }
}

import { toast } from "react-toastify";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const toastTrigger = (text, type, timeout = 2000) => {
  return toast(text, { type: type });
};

export const handleErrors = (response,type) => {
  if (!response.ok) {
    if (response.status == "409") {
      return response.json().then(function (err) {
        err.message = "Duplicates not allowed";
        throw new Error(err.message);
      });
    }else if(response.status >= 500){
      throw new Error("Issue in connecting with server. please check the backend");
    }else if(response.status == 404){
      throw new Error("Invalid URL called. please check the backend");
    }else if(response.status == 422){
      return response.json().then(function (err) {
        if("message" in err){
          throw new Error(err.message);
        }else if("detail" in err){
          throw new Error(err.detail);
        }
        
      });
    }
    else if(response.status == 401 || response.statusText == "Unauthorized"){
      return handleLogout()
    }else if (response.status == 400) {
      return response.json().then(function (err) {
        if("message" in err){
          throw new Error(err.message);
        }else if("detail" in err){
          throw new Error(err.detail);
        }
        
      });
    } else {
      return response.json().then(function (err) {
        throw new Error(err.message);
      });
    }
  }
  else if(type == 'blob'){
    return response.blob();
  }else{
    return response.json();
  }
  
};


export const handleLogout = () => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  }
  localStorage.clear()
  cookies.remove("Authorization")
  cookies.remove("session")
  cookies.remove("uid")
  return fetch(`/app/api/auth/logout`, requestOptions).then(response => {
    window.location.href = '/'
  })
  .catch(error => {
    console.log("Request Failed");
  });
}
export const authHeader = () => {
  // return authorization header with basic auth credentials
  // let user = JSON.parse(localStorage.getItem('user'));
  let user = cookies.get('Authorization')
  if (user ) {
      return { 'Authorization': user };
  } else {
      return {};
  }
}

export const isInputNameValidated = (name) => {
  let usernameRegex = /^[a-zA-Z0-9_]{1,30}$/
  return usernameRegex.test(name)
};

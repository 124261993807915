import React from "react";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import "./HierarchyValueDropDown.css";
import Select from 'react-select';

export class HierarchyValueDropDown extends React.Component {
  render() {
    const {
      hierarchies,
      hierarchyValues,
      hierarchyValueOptions,
      hierarchyValuesOnChange,
      hierarchyOnchange,
      valueSelected
    } = this.props;
    return (
      <React.Fragment>
        <fieldset>
          <legend>Select Hierarchy and Values:</legend>
          <div className="hierarchy-value-container">
            <div className="hierarchy_list">
              <label>Select Hierarchy </label> &nbsp;
          <div className="hier_select">
                <Select value={valueSelected} onChange={hierarchyOnchange}
                  options={hierarchies}
                />
              </div>
            </div>
            <div className="hierarchy_values">
              <label>Values </label> &nbsp;
          <Picky
                value={hierarchyValues}
                options={hierarchyValueOptions}
                onChange={hierarchyValuesOnChange}
                open={false}
                valueKey="id"
                labelKey="name"
                multiple={true}
                includeSelectAll={true}
                includeFilter={true}
                dropdownHeight={300}
                numberDisplayed={2}
              />
            </div>
          </div>
        </fieldset>

      </React.Fragment>
    );
  }
}

// export default HierarchyValueDropDown;

import React, { Component } from "react";
import { BrowserRouter , Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import { LoginPage } from "../src/pages/LoginPage";
import { ToastContainer } from "react-toastify";
import IdleTimer from 'react-idle-timer'
import "react-toastify/dist/ReactToastify.css";
import { IdleTimeOutModal } from "./pages/util/Modal/IdleTimeOutModal";
import Cookies from 'universal-cookie';
import { handleErrors } from "./pages/ui_utils";

const cookies = new Cookies();

const LoginContainer = (handleCookieData) => (
  <div className="container">
    <Route exact={true} path="/" render={(props)=> <LoginPage {...props} />} />
  </div>
);
class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null
    this.state = {
      user: {},
      isTimedOut: false,
      showModal: false,
      idleTimerTimeout:1000 * 60 * 20,
      isCookieAvailable:false,
      isAuthenticated: false
    };
  }
  
  authValidate = () => {
    const requestOptions = {
      method: "GET",
    };
  
    return fetch(`/app/api/auth/auth-validate`, requestOptions) 
    .then((response) => handleErrors(response))
    .then((user) => {
      if (user) {

          localStorage.setItem("email", user.data.email);
          localStorage.setItem("username", user.data.username);
          localStorage.setItem("uid", JSON.stringify(user.data.user_uuid));
          localStorage.setItem("is_auth", true);
          this.setState({ isAuthenticated: true })

    }

  })
    .catch((error) => {
        throw error
      });
  }

  
 handleOnActive = (event) => {
    this.setState({ isTimedOut: false })
  }

  handleOnIdle = (event) => {
    console.log('user is idle')
    const isTimedOut = this.state.isTimedOut
    const isUserLoggedIn = localStorage.getItem("Authorization") && JSON.parse(localStorage.getItem("is_auth"))
    if(isUserLoggedIn){
      if (isTimedOut) {
        this.setState({showModal: false})
        this.handleLogout()
      } else {
        this.setState({ showModal: true })
        this.idleTimer.reset();
        this.setState({ isTimedOut: true })
      }
    }
  }
  handleClose = () => {
    this.setState({showModal: false})
  }

  handleLogout = () => {
    const requestOptions = {
      method: "POST",
      headers: this.authHeader(),
    }
    localStorage.clear()
    this.setState({ isAuthenticated: false })
    cookies.remove("Authorization")
    cookies.remove("session")
    return fetch(`/app/api/auth/logout`, requestOptions).then(response => { 
      window.location.href = '/' 
    })
    .catch(error => {
      console.log("Request Failed");
    });
  }

  authHeader = () => {
    // return authorization header with basic auth credentials
    let user = cookies.get('Authorization') 
    if (user ) {
        return { 'Authorization': user };
    } else {
        return {};
    }
}

handleCookieData = () => {
  if(cookies.get('Authorization')){
    this.setState({isCookieAvailable:true})
  }
}
  

  render() {
    let login_tag = <p>please <a onClick={()=>window.location.href = '/' }>login</a> here</p>

    
     return (
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          timeout={this.state.idleTimerTimeout}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          debounce={250}
        />
        <div className="App">
          <BrowserRouter>
            <Switch>
              <Route exact={true} path="/" render={(props)=><LoginContainer {...props}  />} />
              {
                 
                <Home />
              }
           
            </Switch>
            </BrowserRouter> 
          <ToastContainer autoClose={false} position="bottom-left" />
          <IdleTimeOutModal
            showModal={this.state.showModal}
            handleClose={this.handleClose}
            handleLogout={this.handleLogout}
          />         
        </div>
      </div>
    );
  }
}

export default App;

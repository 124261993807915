import React from "react";
import { toast } from "react-toastify";
import { CONFIG_MS } from "../../consts";
import "./AnomalyRun.css";
import { handleErrors } from "../ui_utils";
import ReactDynamicTable from "../ReactDynamicTable";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import Modal from "react-modal";
import { fetchCall, validate_decoded_auth_token } from "../util/util";

const confirmDeleteModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
export class AnomalyRun extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.handleAnomalyExpCall(
      this.props.match.params.exp_id,
      localStorage.getItem("anomalyExpName"),
      this.props.match.params.data_source_id,
      localStorage.getItem("data_source_name"),
      this.props.location.pathname
    );
  }

  handleBackBtn = () => {
    if(this.props.location.pathname.includes('/app/anomaly/ds/experiment')){
      this.props.history.push(
        "/app/anomaly/ds/experiment"
      )
    }else{
      this.props.history.push(
        "/app/anomaly/experiment"
      )
    }  
  }

  handleAnomalyRunCall = (run_id,pathName) => {
    if(pathName.includes('/app/anomaly/ds/experiment/')){
      this.props.history.push(
        `/app/anomaly/ds/experiment/${this.props.match.params.exp_id}/run/${run_id}`
      );
    }else{
      this.props.history.push(
        `/app/anomaly/experiment/${this.props.match.params.exp_id}/run/${run_id}`
      );
    }
   
    this.getRun();
  };
  handleErrors = (response) => {
    if (response == null) {
      toast("Unable to fetch Runs", { type: toast.TYPE.ERROR });
      throw new Error("Unable to fetch Runs");
    } else if (!response.ok) {
      return response.json().then(function (err) {
        throw new Error(err.message);
      });
    }
    return response.json();
  };
  getRun = (run_id) => {
    if (!run_id) return;
    let toastId = null;
    toastId = toast("Fetching Anomaly Experiments", { type: toast.TYPE.INFO });
  
    fetchCall(`/app/api/anomaly/run/${run_id}`,'GET')
      .then((response) => this.handleErrors(response))
      .then((resultData) => {
        toast.update(toastId, {
          render: "Run fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        if (resultData) {
          this.setState({
            // selectedRun: resultData,
            anomaly_params: JSON.parse(resultData.run_parameters),
            anomalyResults: JSON.parse(resultData.run_selection),
            estimate_params: JSON.parse(resultData.estimation_selection) || [],
            run_id: run_id,
          });
          this.props.updateAnomalyRunName(resultData.run_name);
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  render() {
    const decoded_auth_token =  validate_decoded_auth_token()
    const columns = [
      {
        Header: "S No",
        accessor: "sNo",
        width:"5%",
        disableFilters: true,
      },
      {
        Header: "Experiment Name",
        accessor: "experimentName",
        width:"25%",
      },
      {
        Header: "Created On",
        accessor: "createdOn",
        width:"25%",
      },
      {
        Header: "Status",
        accessor: "status",
        width:"25%",
      },
      {
        Header: "View",
        accessor: "view",
        width:"10%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view">
              <a onClick={(e) =>
                this.handleAnomalyRunCall(value, this.props.location.pathname)
              }>
                <i className="fa fa-eye"></i>
              </a>
            </div>
          );
        }
      },
      {
        Header: "Delete",
        accessor: "delete",
        width:"10%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="delete">
              {
                 value.user_uuid == decoded_auth_token || this.props.isAdminUser ?
                 <a onClick={(e) =>
                  handleDelAnomalyRun(value.run_id)
                }
                >
                   <i className="far fa-trash-alt"></i>
                </a> :
                 <div className="ds-tooltip">
                    <a className="disabled" onClick={(e) =>
                      handleDelAnomalyRun(value.run_id)
                    }
                    >
                      <i className="far fa-trash-alt"></i>
                    </a>
                    <span class="ds-tooltiptext">You don't have permission to remove this Experiment</span>
                  </div>
              }
            </div>
          );
        }
      }
    ]
    const data = this.props.anomalyRuns && this.props.anomalyRuns.map((item, index) => {
      return {
        sNo: index + 1,
        experimentName: item.run_name,
        createdOn: item.created_date.slice(0, 16),
        status: item.run_status,
        delete: item,
        view: item.run_id
      }
    })
    const pathName = this.props.location.pathname.includes("/app/anomaly/ds/experiment") ? 'Descriptive Summary' : 'Anomaly'
    const {
      anomalyExpName,
      anomalyRuns,
      handleDelAnomalyRun,
      handleAddAnomalyRunToggle,
      isDeleteAnomalyRun,
      confirmDeleteAnomalyRun,
      cancelDeleteAnomalyRunSelection,
    } = this.props;

    return (
      <div>
        <button
          //onClick={(e) => this.props.history.push("/app/anomaly/experiment")}
          onClick={()=>this.handleBackBtn()}
          className="btn btn-sm btn-primary back-btn"
        >
          <i className="fa fa-arrow-left"></i> Back
        </button>
        <div className="row">
          <div className="text-btn-align">
            <span className="heading">{pathName} Experiments</span>
            <input
              type="button"
              className="btn btn-sm add-btn"
              onClick={() => handleAddAnomalyRunToggle(this.props.location.pathname)}
              value="Add Experiment"
            ></input>
          </div>
          <div className="causal_exp_heading">
            <span>
              Project Name : {localStorage.getItem("anomalyExpName")} &nbsp; (
              <span>Data Source:</span>
              <span>{localStorage.getItem("data_source_name")}</span>)
            </span>
          </div>
          <ReactDynamicTable
            columns={columns}
            data={data}
          />
        </div>
        <Modal
          isOpen={isDeleteAnomalyRun}
          style={confirmDeleteModal}
          onRequestClose={cancelDeleteAnomalyRunSelection}
          contentLabel="Result"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div class="modal-header">
              <div class="modal-title h4">Delete Experiment</div>
              <button
                type="button"
                className="close"
                onClick={cancelDeleteAnomalyRunSelection}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li className="form-column">
                  <p className="text">
                    Are you sure want to delete the selected Experiment ?
                  </p>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={confirmDeleteAnomalyRun}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={cancelDeleteAnomalyRunSelection}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

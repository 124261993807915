import React from "react";
import { Route, Redirect } from "react-router-dom";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return ( 
    <Route
      {...rest}
      render={props => {
        if (
          JSON.parse(localStorage.getItem("is_auth")) === true && localStorage.getItem('uid')
        ) {
          return <Component {...props} {...rest} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

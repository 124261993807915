import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import { CAUSAL_INIT_STATE, CONFIG_MS, NAME_ERROR } from "../consts";
import { CausalEstimation } from "./CausalSteps/CausalEstimation";
import { CausalProject } from "./CausalSteps/CausalProject";
import { CausalUpload } from "./CausalSteps/CausalUpload";
import { CreateCausalGraph } from "./CausalSteps/CreateCausalGraph";
import { CausalExperiment } from "./CausalSteps/CausalExperiment";
import { handleErrors, isInputNameValidated } from "../pages/ui_utils";
import "./Causal.css";
import { sendAmplitudeData } from './util/amplitude';
import {
  CAUSAL_PROJECT, CAUSAL_UPLOAD, EXECUTE_CAUSAL_RUN, CAUSAL_PROJECT_NAME, DATASOURCE_NAME,
  CAUSAL_ALGORITHMS, TOTAL_CAUSAL_EXECUTE_PARAMS
} from './util/consts_amplitude'
import { fetchCall, requestWithHashDigest } from "./util/util";
var _loadash = require("lodash");

export class Causal extends Component {
  constructor(props) {
    super(props);
    this.state = CAUSAL_INIT_STATE;
  }
  handleCausalExpCall = (
    exp_id,
    causal_exp_display_name,
    data_source_id,
    data_source_name
  ) => {
    this.setState({
      isLoaderLoading:true
    })
    let toastId = null;
    toastId = toast("Fetching Causal Experiments", { type: toast.TYPE.INFO });
    // const selectedExperiment = this.state.causalExperiments.filter(
    //   obj => obj.experiment_id === exp_id
    // );
    // const data_source_id = selectedExperiment.data_source_id;
    fetchCall(`/app/api/causal/experiment/${exp_id}/runs`,'GET')
      .then((response) => handleErrors(response))
      .then((resultData) => {
        toast.update(toastId, {
          render: "Experiments fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.setState({
          causalRuns: resultData,
          causalexp_id: exp_id,
          causalPipeline: data_source_id,
          causalResults: null,
          estimate_params: [],
          causal_params: [],
          causalData: [],
          causal_models: [],
          params_causal: [],
          causalhierarchySelection: {},
          selectedValuesOfCausalHierarchy: [],
          causalGraphData: [],
          data_source_name: data_source_name,
          isLoaderLoading:false
        });
        localStorage.setItem("causalExpName", causal_exp_display_name);
        localStorage.setItem("dataSourceName", data_source_name);
        this.props.history.push(`/app/causal/experiment/${exp_id}`);
      })
      .catch((error) => {
        this.setState({
          isLoaderLoading:false
        })
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  fetchCausalRuns = (
    exp_id
  ) => {
    this.setState({
      isLoaderLoading:true
    })
    let toastId = null;
    toastId = toast("Fetching Causal Experiments", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/causal/experiment/${exp_id}/runs`,'GET')
      .then((response) => handleErrors(response))
      .then((resultData) => {
        toast.update(toastId, {
          render: "Experiments fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.setState({
          causalRunsList: resultData,
          isLoaderLoading:false
        });
      })
      .catch((error) => {
        this.setState({
          isLoaderLoading:false
        })
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handleAddCausalExpToggle = () => {
    let flag = this.state.showAddCusalExp;
    this.setState({
      showAddCusalExp: !flag,
      causalExpName: "",
      nameError:""
    });
  };
  closeCausalProjectModal = () => {
    this.setState({
      showAddCusalExp: false,
    });
  };
  saveCausalProject = () => {
    let ccausalExpName = this.state.causalExpName;
    let data_source_name = this.state.data_source_name;
    ccausalExpName = ccausalExpName.trim();
    let causal_experiments = this.state.causalExperiments;
    let duplicateName = causal_experiments.some((item) => {
      return (
        item.experiment_name.toLocaleLowerCase().split(" ").join("") ===
        ccausalExpName.toLocaleLowerCase().split(" ").join("")
      );
    });
    if(!isInputNameValidated(ccausalExpName)){
      this.setState({
        nameError:NAME_ERROR
      })
      return;
    }else if (duplicateName) {
      const text = "Project name already exists!";
      this.props.togglePopup(text);
      return;
    } else if (
      data_source_name == null ||
      data_source_name === undefined ||
      data_source_name === ""
    ) {
      this.setState({
        nameError:""
      })
      const text = "Please select Data Source";
      this.props.togglePopup(text);
      return;
    } else {
      let toastId = null;
      toastId = toast("Fetching Causal Projects", { type: toast.TYPE.INFO });

      let  data = {
        experiment_name: ccausalExpName,
        user_id: JSON.parse(localStorage.getItem("uid")),
        data_source_id: this.state.causalPipeline,
        data_source_name: data_source_name,
      }

      const request_body_with_digest = requestWithHashDigest(data)

      fetchCall(`/app/api/causal/experiment`,'POST',JSON.stringify(request_body_with_digest))
        .then((response) => handleErrors(response))
        .then((resultData) => {
          if (resultData.status === 200) {

            let causalExperiments = this.state.causalExperiments;
            causalExperiments.push(resultData.payload);
            let causal_project_event = {}
            causal_project_event[CAUSAL_PROJECT_NAME] = resultData.payload.experiment_name
            causal_project_event[DATASOURCE_NAME] = resultData.payload.data_source_name
            sendAmplitudeData(CAUSAL_PROJECT, causal_project_event)
            this.setState({
              causalExperiments,
              showAddCusalExp: false,
              data_source_name: null,
            });

          }
          toast.update(toastId, {
            render: "Causal Project created successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "ERROR: " + error.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        });
    }
  };
  handleNewCausalProject = (e) => {
    let name = e.target.value;
    this.setState({
      causalExpName: name,
    });
  };

  fetchCausalExperiments = () => {
    this.setState({
      isLoaderLoading:true
    })
    let toastId = null;
    toastId = toast("Fetching Experiments", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/causal/experiment`,'GET')
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({
          causalExperiments: resultData,
          isLoaderLoading:false
        });
        toast.update(toastId, {
          render: "Projects fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        return resultData;
      })
      .catch((error) => {
        this.setState({
          isLoaderLoading:false
        })
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
        });
      });
  };
  handleCausalPipelineMap = (e) => {
    const pipeline_uuid = e.value;
    localStorage.setItem("causalPipeline", pipeline_uuid);
    const selected_data_source = this.props.pipeline.filter((pip) => {
      return pip.pipeline_uuid === pipeline_uuid;
    })[0];
    this.setState({
      causalPipeline: pipeline_uuid,
      data_source_name: selected_data_source.display_name,
    });
  };
  updateCausalRunName = (run_name,user_uuid) => {
    this.setState({
      displayCausalRunName: run_name,
      user_uuid:user_uuid
    });
  };
  resetStateParams = () => {
    this.setState({
      params_causal: [],
      causal_params: [],
      causalResults: null,
      estimate_params: [],
      causal_models: [],
      causalData: []
    });
  }
  handleAddCausalRunToggle = () => {
    this.resetStateParams()
    this.props.history.push(
      `/app/causal/experiment/${this.state.causalexp_id}/run/`
    );
  };
  backtocausalRuns = () => {
    this.resetStateParams()
    this.props.history.push(`/app/causal/experiment/${this.state.causalexp_id}`);
  };

  handleHierarchyChange = (label) => {
    if (label === null || label === "") {
      this.setState({
        hierarchySelection: "",
        valuesofHierarchyselection: [],
        valueSelection: [],
      });
      return;
    } else {
      let toastId = null;
      toastId = toast("Fetching hierarchy values", {
        type: toast.TYPE.INFO,
        autoClose: true,
      });
      const data = JSON.stringify({
        selected_label: label,
        pipeline_uuid: this.state.causalPipeline,
        user_uuid: JSON.parse(localStorage.getItem("uid")),
        hierarchy_sets: this.state.hierarchyCombination,
      })
      fetchCall(`/app/api/algorithm/hierarchy`,'POST',data)
        .then((response) => handleErrors(response))
        .then((resultData) => {
          this.setState({
            hierarchySelection: label,
            valueSelection: [],
            valuesofHierarchyselection: resultData.map((obj, i) => {
              return { id: i, name: obj };
            }),
          });
          toast.update(toastId, {
            render: "Hierarchy Values fetched Successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "Error in Uploading Data",
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        });
    }
  };
  multiSelectionOfCausalHierarchyValues = (value) => {
    this.setState({ selectedValuesOfCausalHierarchy: value });
  };
  handleCausalRunCall = (run_id, run_name) => {
    this.props.history.push(
      `/app/causal/experiment/${this.state.causalexp_id}/run/${run_id}`
    );
    this.getRun(run_id);
  };
  onloadAlgoParams = () => {
    let currenthierarchyLevels = this.state.hierarchyDataMap;
    // this.getAllAlgorithm()
    // this.getAllKpis()
    this.setState({
      hierarchyfileNames: currenthierarchyLevels,
      kpi_selection: "",
      algoType: "",
      current_algo: "",
      current_algo_id: "",
      hierarchySelection: "",
      valuesofHierarchyselection: [],
    });
  };

  handleCausalExpDelete = (exp_id) => {
    this.fetchCausalRuns(exp_id)
    this.setState({
      isDeleteCausalExp: true,
      deleteCausalExpId: exp_id,
    });
  };

  confirmCausalExpDelete = () => {
    let causalExperiments = this.state.causalExperiments;
    let exp_id = this.state.deleteCausalExpId;
    let toastId = null;
    toastId = toast("Deleting Experiment. Please wait...", {
      type: toast.TYPE.INFO,
    });
    fetchCall(`/app/api/causal/experiment/${exp_id}`,'DELETE')
      .then((result) => result.json())
      .then((result) => {
        //if (result) {
        causalExperiments = causalExperiments.filter(
          (obj) => obj.experiment_id !== exp_id
        );
        this.setState({ causalExperiments: causalExperiments });
        this.setState({
          causalExperiments,
          isDeleteCausalExp: false,
        });
        toast.update(toastId, {
          render: "Deleted successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });

        // }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  cancelDeleteCausalExpSelection = () => {
    this.setState({
      isDeleteCausalExp: false,
    });
  };

  getRun = (run_id) => {
    if (!run_id) return;
    this.setState({
      isLoaderLoading:true
    })
    let toastId = null;
    toastId = toast("Fetching Causal Experiments", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/causal/run/${run_id}`,'GET')
      .then((response) => handleErrors(response))
      .then((resultData) => {
        const run_parameters = resultData.run_parameters || [];
        const causal_state = resultData.causal_state || [];
        // const causalResults = resultData.causalResults || {}
        // const estimation_state = resultData.estimation_state || []
        if (resultData) {
          this.setState({
            ...JSON.parse(causal_state),
            // ...JSON.parse(estimation_state),
            // causal_params: JSON.parse(run_parameters),
            // estimate_params: JSON.parse(estimation_state),
            run_id: run_id,
            user_uuid:resultData.user_uuid,
            isLoaderLoading:false
          });
          this.updateCausalRunName(resultData.run_name,resultData.user_uuid);
        }
        toast.update(toastId, {
          render: "Experiments fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        this.setState({
          isLoaderLoading:false
        })
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  loadDataSourceData = (exp_id) => {
    let toastId = null;
    toastId = toast("Loading pipeline", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/causal/experiment/${exp_id}/data_source`,'GET')
      .then((result) => result.json())
      .then((resultData) => {
        const kpiList = resultData.user_columns.kpi || [];
        const pipeline_id = resultData.pipeline_uuid;
        let resp = JSON.parse(resultData.upload_state);
        localStorage.setItem("causalPipeline", pipeline_id);
        const kpiTypeMap = resp.kpiTypeMap;
        const hierarchyfileNames = resultData.user_columns.hierarchy_splitted || [];
        const hierarchyCombination = JSON.parse(
          resultData.hierarchy_combination
        );
        this.setState({
          hierarchyfileNames,
          kpiList,
          hierarchyCombination,
          kpiTypeMap,
          pipeline_id
        });
        toast.dismiss(toastId);
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  handleCausalHierarchyChange = (label) => {
    if (label.value === null || label.value === "") {
      this.setState({
        causalhierarchySelection: {},
        valuesofCausalHierarchyselection: [],
        selectedValuesOfCausalHierarchy: [],
      });
      return;
    } else {
      let toastId = null;
      toastId = toast("Fetching hierarchy values", {
        type: toast.TYPE.INFO,
        autoClose: true,
      });
      const data = JSON.stringify({
        selected_label: [label.value],
        //pipeline_uuid: JSON.parse(localStorage.getItem("pipeline_uuid")),
        pipeline_uuid: localStorage.getItem("causalPipeline"),
        user_uuid: JSON.parse(localStorage.getItem("uid")),
        hierarchy_sets: this.state.hierarchyCombination,
      })
      fetchCall(`/app/api/algorithm/hierarchy`,'POST',data)
        .then((response) => handleErrors(response))
        .then((resultData) => {
          if (resultData) {
            const hierarchySelection = Object.values(resultData)[0]
            this.setState({
              causalhierarchySelection: label,
              selectedValuesOfCausalHierarchy: [],
              valuesofCausalHierarchyselection: hierarchySelection.map((obj, i) => {
                return { id: i, name: obj };
              }),
            });
          }
          toast.update(toastId, {
            render: "Hierarchy Values fetched Successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "Error in Uploading Data",
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        });
    }
  };

  handleDelCausalRun = (id) => {
    this.setState({
      isDeleteCausalRun: true,
      deleteCausalRunId: id,
    });
  };

  confirmDeleteCausalRun = () => {
    let causalRuns = this.state.causalRuns;
    let id = this.state.deleteCausalRunId;
    let toastId = null;
    toastId = toast("Deleting run. Please wait...", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/causal/run/${id}`,'DELETE')
      .then((result) => handleErrors(result))
      .then((result) => {
        if (result) {
          causalRuns = causalRuns.filter((obj) => obj.run_id !== id);
          this.setState({ causalRuns: causalRuns, isDeleteCausalRun: false });
          toast.update(toastId, {
            render: "Deleted successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      })

      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  cancelDeleteCausalRunSelection = () => {
    this.setState({
      isDeleteCausalRun: false,
    });
  };
  generateDAGPayload = (data) => {
    let nodes = new Set();
    let links = [];
    data.forEach((element, index) => {
      nodes.add(element.fromKPI);
      nodes.add(element.toKPI);
      links.push({
        source: element.fromKPI,
        target: element.toKPI,
        type: "Causal",
        lag: element.lag,
        strength: element.strength,
      });
    });
    let kpi_id = {};
    const node_obj = Array.from(nodes).map((obj, index) => {
      kpi_id[obj] = index;
      return { name: obj, label: obj, id: index };
    });
    links = links.map((obj, index) => {
      let source = obj.source;
      let target = obj.target;
      return {
        source: kpi_id[source],
        target: kpi_id[target],
        type: `Causal / Lag: ${obj.lag}`,
        lag: obj.lag,
        strength: obj.strength,
      };
    });
    return [{ nodes: node_obj, links: links }];
  };
  renderCausal = (result, file) => {
    const data = result.data;
    if (
      !Object.keys(data[0]).every((val) =>
        [
          "from_kpi",
          "to_kpi",
          "type",
          "hierarchy",
          "value",
          "lag",
          "estimate",
        ].includes(val)
      )
    ) {
      const text = `Please check if all columns are present in causal file ${[
        "from_kpi",
        "to_kpi",
        "type",
        "hierarchy",
        "value",
        "lag",
        "estimate",
      ]}`;
      this.props.togglePopup(text);
      return;
    }
    let hierarchySelection = this.state.causalhierarchySelection;
    let valueSelection = this.state.valueSelection;

    let causal_models = this.state.causal_models;
    let file_name = file.name;

    let causal_hierarchy = this.state.causal_hierarchy;
    causal_hierarchy = causal_hierarchy.concat(data);
    let causalData = [];

    let causalModelData = [];

    valueSelection.forEach((obj) => {
      if (!causal_models.some((ob) => ob.valueSelection === obj.name)) {
        //causalModelData.push({ nodes: node_obj, links: links })
        causalModelData = this.generateDAGPayload(data);
        causal_models.push({
          hierarchySelection: hierarchySelection,
          valueSelection: obj.name,
          causal: file_name,
          payload: data,
        });
      }
    });

    this.setState({
      causalData: causalModelData,
      causal_hierarchy: causal_hierarchy,
      causal_models: causal_models,
    });
  };

  saveProgress = (run_id) => {
    let toastId = null;
    toastId = toast("Saving Progress. Please wait...", {
      type: toast.TYPE.INFO,
    });
    const payload = {};
    const causalResults = this.state.causalResults;
    const causal_params = this.state.causal_params;
    const estimate_params = this.state.estimate_params;
    const replicate_index_map = this.state.replicate_index_map;
    const causal_models = this.state.causal_models;
    const causalData = this.state.causalData;
    payload["causalResults"] = causalResults;
    payload["causal_params"] = causal_params;
    payload["estimate_params"] = estimate_params;
    payload["replicate_index_map"] = replicate_index_map;
    payload["causalData"] = causalData;
    payload["causal_models"] = causal_models;
    const data = JSON.stringify({
      causal_state: payload,
    })
    fetchCall(`/app/api/causal/run/${run_id}`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        toast.update(toastId, {
          render: "Progress Saved",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handleExecuteRun = (
    exp_id,
    run_id,
    causalRunName,
    kpiAutoCorr,
    kpiLagValList
  ) => {
    let cur_causal_params = _loadash.cloneDeep(this.state.causal_params)

    let new_causal_params = cur_causal_params.filter((obj) => {
      return (
        obj.task_id === "" || obj.task_id === null || obj.task_id === undefined
      );
    });
    let toastId = null;
    toastId = toast("Running Algorithm", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    const data = JSON.stringify({
      experiment_id: exp_id,
      user_id: JSON.parse(localStorage.getItem("uid")),
      run_name: causalRunName,
      run_parameters: new_causal_params,
      run_selection: {},
      auto_c: kpiAutoCorr.map((kpis) => kpis.name),
      kpi_lag: kpiLagValList,
      variable_type: this.state.kpiTypeMap,
    })
    fetchCall(`/app/api/causal/run/${run_id}/execute`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {


        new_causal_params.forEach((paramObj) => {
          const task_key = paramObj.causalhierarchySelection
            .concat("_", paramObj.causalvalueSelection)
            .toLowerCase();
          paramObj.task_id = resultData[task_key];
        });
        let params_with_taskids = cur_causal_params.filter((obj) => {
          return !new_causal_params.includes(obj);
        });
        let updatec_causal_params = [...params_with_taskids, ...new_causal_params]
        let algoArr = []
        updatec_causal_params.forEach((obj) => {
          if (algoArr.length == 0) {
            algoArr.push(obj.causal_algo)
          } else if (algoArr.length > 0 && !algoArr.includes(obj.causal_algo)) {
            algoArr.push(obj.causal_algo)
          }
          return algoArr;
        })
        let causal_execute_event = {}
        causal_execute_event[CAUSAL_ALGORITHMS] = algoArr.join(",")
        causal_execute_event[TOTAL_CAUSAL_EXECUTE_PARAMS] = updatec_causal_params.length
        sendAmplitudeData(EXECUTE_CAUSAL_RUN, causal_execute_event)
        this.setState({
          // estimate_params,
          causalResults: resultData,
          selectedValuesOfCausalHierarchy: [],
          causalhierarchySelection: {},
          causal_params: [...params_with_taskids, ...new_causal_params],
        });

        toast.update(toastId, {
          render: "Running.. Please click on View to see results",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.saveProgress(run_id)
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  updateCausalParams = (causal_params) => {
    this.setState({
      causal_params,
    });
  };
  updateCausalResults = (causalResults) => {
    this.setState({
      causalResults
    });
  }
  updateEstimateParams = (estimate_params) => {
    this.setState({
      estimate_params,
    });
  };
  updateEstimateReplicates = (
    estimate_replicate_params,
    replicate_for_hier,
    replicate_for_value,
    replicate_index_map
  ) => {
    this.setState({
      replicate_index_map,
      replicate_for_hier,
      replicate_for_value,
      selected_estimate_replicates: estimate_replicate_params,
    });
  };
  updatecausalResulteplicates = (
    result_replicate_params,
    replicate_for_hier,
    replicate_for_value
  ) => {
    this.setState({
      selected_result_replicates: result_replicate_params,
      replicate_for_hier: replicate_for_hier,
      replicate_for_value: replicate_for_value,
    });
  };
  handleDelEstimateParams = (index,run_id) => {
    if (this.state.causal_params.length > 0) {
      this.setState({
        confirmDelEStimateParamSelectionModalIsOpen: !this.state
          .confirmDelEStimateParamSelectionModalIsOpen,
        delEstimateSelectionIndex: index,
        current_run_id:run_id
      });
  };
};
  confirmDeleteEstimateParamSelection = () => {
    const estimate_params = _loadash.cloneDeep(this.state.estimate_params);
    const selected_estimate_index = this.state.delEstimateSelectionIndex;
    estimate_params.splice(selected_estimate_index,1)
    this.setState({
      estimate_params : estimate_params,
      confirmDelEStimateParamSelectionModalIsOpen: false,
    },() => {
      this.saveProgress(this.state.current_run_id)
    });
  };
  closeDelEstimateParamSelectionModal = () => {
    this.setState({
      confirmDelEStimateParamSelectionModalIsOpen: false,
    });
  };
  handleDelCausalParams = (index, run_id,value) => {
    if(this.state.estimate_params.length>0){
      let searchObj = this.state.estimate_params.find((obj) => obj.estimatehierarchySelection == value.causalhierarchySelection && 
                      obj.estimatevalueSelection == value.causalvalueSelection);
      if(searchObj && Object.keys(searchObj).length > 1){
        const text = "The selected hierarchy has estimates. Please delete the combination from estimation page before deleting here.";
        this.props.togglePopup(text);
        return;
      }
    }
    if (this.state.causal_params.length > 0) {
      this.setState({
        confirmDelCausalParamSelectionModalIsOpen: !this.state
          .confirmDelCausalParamSelectionModalIsOpen,
        delCausalSelectionIndex: index,
        delete_param_run_id: run_id
      });
    }
  };
  confirmDeleteCausalParamSelection = () => {
    let index = this.state.delCausalSelectionIndex;
    const causal_params = _loadash.cloneDeep(this.state.causal_params);
    let current_run_id = this.state.delete_param_run_id
    let causalResults = this.state.causalResults;
    const causalhierarchySelection =
      causal_params[index]["causalhierarchySelection"];
    const causalvalueSelection = causal_params[index]["causalvalueSelection"];
    const task_key = causalhierarchySelection
      .concat("_", causalvalueSelection)
      .toLowerCase();
    if (causalResults && typeof causalResults !== "undefined") {
      delete causalResults[task_key];
    }

    causal_params.splice(index, 1);
    this.setState({
      causal_params,
      causalResults,
      confirmDelCausalParamSelectionModalIsOpen: !this.state
        .confirmDelCausalParamSelectionModalIsOpen,
    }, () => {
      this.saveProgress(current_run_id)
    });
  };
  closeDelCausalParamSelectionModal = () => {
    this.setState({
      confirmDelCausalParamSelectionModalIsOpen: false,
    });
  };
  updateHierarchyValues = () => {
    this.setState({
      causalhierarchySelection: {},
      selectedValuesOfCausalHierarchy: [],
    });
  };

  updateCausalUploadState = (causalData, causal_models,run_id) => {
    sendAmplitudeData(CAUSAL_UPLOAD)
    this.setState({
      causalData,
      causal_models,
    }, ()=>{
      this.saveProgress(run_id)
    }
    );
  };

  render() {
    const { fetchDataSources, pipeline, togglePopup } = this.props;
    return (
      <React.Fragment>
        <Switch>
          <Route
            exact={true}
            path="/app/causal/experiment"
            render={(props) => (
              <CausalProject
                {...props}
                pipelines={pipeline}
                fetchDataSources={fetchDataSources}
                handleAddCausalExpToggle={this.handleAddCausalExpToggle}
                showAddCusalExp={this.state.showAddCusalExp}
                causalExpName={this.state.causalExpName}
                saveCausalProject={this.saveCausalProject}
                handleNewCausalProject={this.handleNewCausalProject}
                handleCausalExpCall={this.handleCausalExpCall}
                causalExperiments={this.state.causalExperiments}
                handleCausalExpDelete={this.handleCausalExpDelete}
                fetchCausalExperiments={this.fetchCausalExperiments}
                handleCausalPipelineMap={this.handleCausalPipelineMap}
                togglePopup={togglePopup}
                closeCausalProjectModal={this.closeCausalProjectModal}
                pipeline_id={this.state.pipeline_id}
                isDeleteCausalExp={this.state.isDeleteCausalExp}
                confirmCausalExpDelete={this.confirmCausalExpDelete}
                cancelDeleteCausalExpSelection={
                  this.cancelDeleteCausalExpSelection
                }
                isLoaderLoading={this.state.isLoaderLoading}
                causalRunsList={this.state.causalRunsList}
                isAdminUser = {this.state.isAdminUser}
                handleDataSourceView={this.props.handleDataSourceView}
                isDSConsolidatedViewOpen={this.props.isDSConsolidatedViewOpen}
                closeDSConsolidatedModal={this.props.closeDSConsolidatedModal}
                consolidatedDSDetails={this.props.consolidatedDSDetails}
                nameError={this.state.nameError}
              />
            )}
          ></Route>
          <Route
            exact={true}
            path="/app/causal/experiment/:exp_id"
            render={(props) => (
              <CausalExperiment
                {...props}
                handleAddCausalRunToggle={this.handleAddCausalRunToggle}
                handleCausalRunCall={this.handleCausalRunCall}
                causalRuns={this.state.causalRuns}
                handleCausalExpCall={this.handleCausalExpCall}
                causalExpName={this.state.causalExpName}
                togglePopup={togglePopup}
                handleDelCausalRun={this.handleDelCausalRun}
                pipeline_id={this.state.pipeline_id}
                isDeleteCausalRun={this.state.isDeleteCausalRun}
                cancelDeleteCausalRunSelection={
                  this.cancelDeleteCausalRunSelection
                }
                confirmDeleteCausalRun={this.confirmDeleteCausalRun}
                data_source_name={this.state.data_source_name}
                isLoaderLoading={this.state.isLoaderLoading}
                isAdminUser = {this.state.isAdminUser}
              />
            )}
          ></Route>
          <Route
            exact={true}
            path="/app/causal/experiment/:exp_id/run/:run_id?"
            render={(props) => (
              <CreateCausalGraph
                {...props}
                backtocausalRuns={this.backtocausalRuns}
                hierarchyfileNames={this.state.hierarchyfileNames}
                handleCausalHierarchyChange={this.handleCausalHierarchyChange}
                causalEdges={this.state.causalEdges}
                causalvalueSelection={this.state.causalvalueSelection}
                valuesofCausalHierarchyselection={
                  this.state.valuesofCausalHierarchyselection
                }
                causalhierarchySelection={this.state.causalhierarchySelection}
                causalExpName={this.state.causalExpName}
                toKpiList={this.state.toKpiList}
                showCausalRun={this.state.showCausalRun}
                closeCausalRunModal={this.closeCausalRunModal}
                getCausalEgesModal={this.getCausalEgesModal}
                closeCommonEdgeModal={this.closeCommonEdgeModal}
                selectedEdge={this.state.selectedEdge}
                handlesubmitEdges={this.handlesubmitEdges}
                causalUserEdges={this.state.causalUserEdges}
                handleDiscardEdge={this.handleDiscardEdge}
                handleFixedEdge={this.handleFixedEdge}
                causalDiscardEdges={this.state.causalDiscardEdges}
                causalFixedEdges={this.state.causalFixedEdges}
                discardFixedEdgesShow={this.state.discardFixedEdgesShow}
                userGraphEdgesShow={this.state.userGraphEdgesShow}
                kpiList={this.state.kpiList}
                loadDataSourceData={this.loadDataSourceData}
                getRun={this.getRun}
                multiSelectionOfCausalHierarchyValues={
                  this.multiSelectionOfCausalHierarchyValues
                }
                selectedValuesOfCausalHierarchy={
                  this.state.selectedValuesOfCausalHierarchy
                }
                kpiTypeMap={this.state.kpiTypeMap}
                handlekpiLagVal={this.handlekpiLagVal}
                handleExecuteRun={this.handleExecuteRun}
                togglePopup={togglePopup}
                causalGraphData={this.state.causalGraphData}
                saveProgress={this.saveProgress}
                pipeline_id={this.state.pipeline_id}
                causal_params={this.state.causal_params}
                causalResults={this.state.causalResults}
                updateCausalParams={this.updateCausalParams}
                handleDelCausalParams={this.handleDelCausalParams}
                hierarchyCombination={this.state.hierarchyCombination}
                updatecausalResulteplicates={this.updatecausalResulteplicates}
                replicate_for_hier={this.state.replicate_for_hier}
                replicate_for_value={this.state.replicate_for_value}
                addToCausalResultReplicateSelection={
                  this.addToCausalResultReplicateSelection
                }
                updateHierarchyValues={this.updateHierarchyValues}
                updateCausalRunName={this.updateCausalRunName}
                displayCausalRunName={this.state.displayCausalRunName}
                confirmDeleteCausalParamSelection={
                  this.confirmDeleteCausalParamSelection
                }
                closeDelCausalParamSelectionModal={
                  this.closeDelCausalParamSelectionModal
                }
                confirmDelCausalParamSelectionModalIsOpen={
                  this.state.confirmDelCausalParamSelectionModalIsOpen
                }
                causalRuns={this.state.causalRuns}
                updateCausalResults={this.updateCausalResults}
                user_uuid={this.state.user_uuid}
                isAdminUser = {this.state.isAdminUser}
              />
            )}
          ></Route>
          <Route
            exact={true}
            path="/app/causal/experiment/:exp_id/run/:run_id/estimation"
            render={(props) => (
              <CausalEstimation
                {...props}
                hierarchyfileNames={this.state.hierarchyfileNames}
                estimation={this.state.estimation}
                strength={this.state.strength}
                getAllCausalEstimations={this.getAllCausalEstimations}
                causalEstimations={this.state.causalEstimations}
                handleCausalEstimateChange={this.handleCausalEstimateChange}
                estimatevalueSelection={this.state.estimatevalueSelection}
                valueSelection={this.state.valueSelection}
                valuesofEstimateHierarchyselection={
                  this.state.valuesofEstimateHierarchyselection
                }
                display_estimate_hierarchy={
                  this.state.display_estimate_hierarchy
                }
                display_estimate_value={this.state.display_estimate_value}
                loadDataSourceData={this.loadDataSourceData}
                getRun={this.getRun}
                causalhierarchySelection={this.state.causalhierarchySelection}
                valuesofCausalHierarchyselection={
                  this.state.valuesofCausalHierarchyselection
                }
                causalResults={this.state.causalResults}
                hierarchyCombination={this.state.hierarchyCombination}
                kpiTypeMap={this.state.kpiTypeMap}
                togglePopup={togglePopup}
                handleEstimateHierarchyChange={
                  this.handleEstimateHierarchyChange
                }
                saveProgress={this.saveProgress}
                pipeline_id={this.state.pipeline_id}
                updateEstimateParams={this.updateEstimateParams}
                estimate_params={this.state.estimate_params}
                handleDelEstimateParams={this.handleDelEstimateParams}
                updateEstimateReplicates={this.updateEstimateReplicates}
                selected_estimate_replicates={
                  this.state.selected_estimate_replicates
                }
                replicate_for_hier={this.state.replicate_for_hier}
                replicate_for_value={this.state.replicate_for_value}
                handleCausalHierarchyChange={this.handleCausalHierarchyChange}
                causalhierarchySelection={this.state.causalhierarchySelection}
                selectedValuesOfCausalHierarchy={
                  this.state.selectedValuesOfCausalHierarchy
                }
                multiSelectionOfCausalHierarchyValues={
                  this.multiSelectionOfCausalHierarchyValues
                }
                updateHierarchyValues={this.updateHierarchyValues}
                causal_params={this.state.causal_params}
                replicate_index_map={this.state.replicate_index_map}
                confirmDeleteEstimateParamSelection={
                  this.confirmDeleteEstimateParamSelection
                }
                confirmDelEStimateParamSelectionModalIsOpen={
                  this.state.confirmDelEStimateParamSelectionModalIsOpen
                }
                closeDelEstimateParamSelectionModal={
                  this.closeDelEstimateParamSelectionModal
                }
                displayCausalRunName={this.state.displayCausalRunName}
              />
            )}
          ></Route>
          <Route
            exact={true}
            path="/app/causal/experiment/:exp_id/run/:run_id/upload"
            render={(props) => (
              <CausalUpload
                {...props}
                handleCausalFiles={this.handleCausalFiles}
                hierarchyDataMap={this.state.hierarchyDataMap}
                createCausalList={this.createCausalList}
                causalData={this.state.causalData}
                current_causal={this.state.current_causal}
                filter_causal_data={this.filter_causal_data}
                multiSelectionOfCausalHierarchyValues={
                  this.multiSelectionOfCausalHierarchyValues
                }
                valuesofHierarchyselection={
                  this.state.valuesofHierarchyselection
                }
                selectedValuesOfCausalHierarchy={
                  this.state.selectedValuesOfCausalHierarchy
                }
                handleHierarchyChange={this.handleHierarchyChange}
                hierarchyfileNames={this.state.hierarchyfileNames}
                causal_models={this.state.causal_models}
                removeCausalSel={this.removeCausalSel}
                addCausalSelection={this.addCausalSelection}
                handleOverallCausal={this.handleOverallCausal}
                onloadAlgoParams={this.onloadAlgoParams}
                togglePopup={togglePopup}
                getRun={this.getRun}
                loadDataSourceData={this.loadDataSourceData}
                handleCausalHierarchyChange={this.handleCausalHierarchyChange}
                causalhierarchySelection={this.state.causalhierarchySelection}
                pipeline_id={this.state.pipeline_id}
                valuesofCausalHierarchyselection={
                  this.state.valuesofCausalHierarchyselection
                }
                renderCausal={this.renderCausal}
                causalData={this.state.causalData}
                generateDAGPayload={this.generateDAGPayload}
                filter_causal_data={this.filter_causal_data}
                causalhierarchySelection={this.state.causalhierarchySelection}
                updateHierarchyValues={this.updateHierarchyValues}
                displayCausalRunName={this.state.displayCausalRunName}
                causal_models={this.state.causal_models}
                causalData={this.state.causalData}
                updateCausalUploadState={this.updateCausalUploadState}
                saveProgress={this.saveProgress}
                kpiList={this.state.kpiList}
              />
            )}
          ></Route>
        </Switch>
      </React.Fragment>
    );
  }
}
export default Causal;

import React from "react";
import "./Anomaly.css";
import { Route, Switch } from "react-router-dom";
import { AnomalyExperiment } from "./AnomalySteps/AnomalyExperiment";
import { AnomalyRun } from "./AnomalySteps/AnomalyRun";
import { AnomalyRunView } from "./AnomalySteps/AnomalyRunView";
import { toast } from "react-toastify";
import { ANOMALY_INIT_STATE, NAME_ERROR } from "../consts";
import { handleErrors, isInputNameValidated } from "./ui_utils";
import "./AnomalySteps/AnomalyRunView.css";
import { fetchCall, find_level_parents_flatten,requestWithHashDigest } from "./util/util";
import { sendAmplitudeData } from "./util/amplitude";
import { v4 as uuidv4 } from 'uuid';
import { PAGINATION_RANGE } from "../consts";
import {
  ANOMALY_PROJECT,
  ANOMALY_EXPERIMENT,
  AUTO_GENERATE,
  EXPORT_ANOMALY,
  EXECUTE_RUN,
  TOTAL_ANOMALY_PARAMS,
  ANOMALY_ALGO,
  ANOMALY_PROJECT_NAME,
  ANOMALY_EXP_NAME,
} from "./util/consts_amplitude";
import AppContext from "./context/AppContext";
import DescriptiveSummaryRunView from "./AnomalySteps/DescriptiveSummaryRunView";
var _loadash = require("lodash");
var msgpack = require("msgpack-lite");


var globalToastId = null;
export class Anomaly extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = ANOMALY_INIT_STATE;
  }
  static contextType = AppContext
  componentDidMount() {
    this.getAllAnomalyAlgorithms();
  }
  getAnomalyResults = (obj, index, exp_id, run_id) => {
    let selection = this.state.anomalyResults[index] || {};
    let cSelectedAnomalyHierarchyVal = this.state.selectedAnomalyHierarchyVal;
    cSelectedAnomalyHierarchyVal = obj.value;
    let label = Object.keys(selection).filter((key) => key !== "kpi");
    let chart_label = obj.kpi_selection.toLowerCase().replace(/ /g, "_");
    let chart_label_obj = obj.hierarchy;
    const algorithm_name = obj.algorithm
    const task_id = obj.task_id
    let toastId = null;
    toastId = toast("Fetching Anomaly Results", {
      type: toast.TYPE.INFO,
      autoClose: 3000,
    });
    const data =  JSON.stringify({
      experiment_id: exp_id,
      run_id: run_id,
      task_id: obj.task_id,
    })
    fetchCall(`/app/api/anomaly/result`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        if (resultData == null || resultData == undefined) {
          let toastId = null;
          toastId = toast("Algorithm is Running", {
            type: toast.TYPE.INFO,
            autoClose: 3000,
          });
          return;
        }
        if (Object.keys(resultData).length == 0) {
          toast.update(toastId, {
            render: "Algorithm is not executed.",
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        } else {
          let chart_data = resultData[task_id].data;
          if (["fs_peer_to_peer", "peer_to_peer_type_one"].includes(algorithm_name)) {
            const hierarchyCombination = _loadash.cloneDeep(
              this.state.hierarchyCombination
            );
            let hierarchy_values = cSelectedAnomalyHierarchyVal.split("||");
            let filtered_data = new Array();
            let parents = [];
            parents = find_level_parents_flatten(
              chart_label_obj,
              hierarchyCombination
            );
            chart_data = JSON.parse(chart_data);
            if (hierarchy_values.length == 1) {
              filtered_data = [
                {
                  chart_payload: chart_data,
                  chart_label: hierarchy_values[0],
                },
              ];
            } else {
              parents = parents && parents.map((val) => val.toLowerCase());

              filtered_data = hierarchy_values.map((hierarchy) => {
                let hybrid_case = hierarchy.split("-**-");
                let hybrid_vannila_split = hybrid_case.map((hybrid) =>
                  hybrid.split("-*-")
                );

                hybrid_vannila_split = [].concat.apply(
                  [],
                  hybrid_vannila_split
                );
                if (hybrid_case.length == 1) {
                  let vannila_hierarchy = hierarchy.split("-*-");
                  let filter_condition = _loadash.zipObject(
                    parents,
                    vannila_hierarchy
                  );
                  return {
                    chart_label: hierarchy,
                    chart_payload: chart_data.filter((rows) => {
                      return _loadash.isMatch(rows, filter_condition);
                    }),
                  };
                } else {
                  let filter_condition = _loadash.zipObject(
                    parents,
                    hybrid_vannila_split
                  );
                  return {
                    chart_label: hierarchy,
                    chart_payload: chart_data.filter((rows) => {
                      return _loadash.isMatch(rows, filter_condition);
                    }),
                  };
                }
              });
            }
            this.setState({
              chartResult: filtered_data,
              chart_label,
              chart_level_parents: parents,
              chart_label_obj,
              anomaly_algo_type: obj.algorithm,
              anomaly_algo_param: obj.parameters,
              selectedAnomalyHierarchyVal: cSelectedAnomalyHierarchyVal,
              AnomalyResultModelIsOpen: true,
            });

            toast.update(toastId, {
              render: "Fetched Successfully",
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }else if(resultData[task_id].type == 'Anomaly'){
            let singleChartArr = [];
            let chartObj = {};
            let chart_data = resultData[task_id].data;
            chartObj["chart_payload"] = JSON.parse(chart_data);
            singleChartArr.push(chartObj);
            //let status = resultData[obj.task_id].status;

            this.setState({
              chartResult: singleChartArr,
              chart_label,
              chart_label_obj,
              anomaly_algo_type: obj.algorithm,
              anomaly_algo_param: obj.parameters,
              selectedAnomalyHierarchyVal: cSelectedAnomalyHierarchyVal,
              AnomalyResultModelIsOpen: true,
            });

            toast.update(toastId, {
              render: "Fetched Successfully",
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }else {
            let ds_summary_data = resultData[task_id].data[0]
            this.setState({
              dsSummaryResultData:ds_summary_data,
              AnomalyResultModelIsOpen: true,
              ds_hierarchy_label: obj.hierarchy
            })

            toast.update(toastId, {
              render: "Fetched Successfully",
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  poll_results = () => {
    console.log("POLL DISABLED")
  };
  getAllAnomalyAlgorithms = () => {
    fetchCall(`/app/api/algorithm/anomaly`,'GET')
      .then((result) => handleErrors(result))
      .then((resultData) => {
        let algo_types = resultData && resultData.map((obj) => obj.algorithm_category);
        let anomaly_algos = algo_types && algo_types.length>0 && algo_types.filter((item)=>{
          return item !== "Predictive Alert" && item !== "Multivariate" && item !== "WhatIf"
        })
        this.setState({
          anomaly_algorithms: resultData,
          anomaly_algo_types: [...new Set(anomaly_algos)],
        });
      })
      .catch((error) => {
        toast("ERROR: " + error, {
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
  };
  getAnomalyParams = (exp_id, pageIndex, pageSize, pageCount) => {
    let anomaly_parameter_data_size = this.state.anomaly_params && this.state.anomaly_params.length || 0
    let anomaly_pages = this.state.anomaly_pages
    let client_side_anomaly_load = this.state.client_side_anomaly_load || []
    let client_side_anomaly_load_length = client_side_anomaly_load.length
    let filteredData = client_side_anomaly_load.slice(pageIndex * pageSize, (pageIndex * pageSize) + pageSize);
    let anomalyPages = Math.ceil(client_side_anomaly_load_length / pageSize);
    if (client_side_anomaly_load_length > 0) {
      this.setState({
        anomaly_pages: anomalyPages,
        anomaly_params: filteredData
      })
      return
    } else {
      if (exp_id == undefined) {
        this.setState({
          anomaly_params: []
        })
      } else {
        fetchCall( `/app/api/anomaly/anomaly_user_parameters/${exp_id}?page=${pageIndex}&count=${pageSize}`,'GET')
          .then((result) => result.json())
          .then((resultData) => {
            if (resultData) {

              const data = resultData.data;
              const total_pages = resultData.total_pages;

              this.setState({
                anomaly_params: data,
                anomaly_pages: total_pages,
                //anomaly_params_loading_flag: false,
              });
            }
          })
          .catch((error) => {
            toast("ERROR: " + error, {
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
      }
    }
  };
  handleAlgorithmTypeChange = (type) => {
    let userAlgo = this.state.anomaly_algorithms.filter(
      (obj) => obj.algorithm_category === type.value
    );
    let selected_user_algo = {};
    selected_user_algo["label"] = "Select...";
    selected_user_algo["value"] = "";
    this.setState({
      userAlgo: userAlgo,
      algoType: type,
      algoParam: {},
      algoData: {},
      algoParamData:{},
      formErrors:[],
      current_algo: "",
      current_algo_id: "",
      selected_user_algo: selected_user_algo,
    });
  };
  get_group_by_level = (label, algorithm_name) => {
    let toastId = null;
    toastId = toast("Fetching Group by Level values", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    const data = JSON.stringify({
      selected_hierarchy: label,
      pipeline_uuid: this.state.pipeline_id,
      algorithm_name: algorithm_name,
      user_uuid: JSON.parse(this.context.store_user_uuid),
      hierarchy_sets: this.state.hierarchyCombination,
    })
    fetchCall(`/app/api/anomaly/group_by_level`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        toast.update(toastId, {
          render: "Group by level values fetched Successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        return resultData;
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "Error in Uploading Data",
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        return [];
      });
  };
  handleAlgorithmChange = (label) => {
    let paramObj = this.state.userAlgo.filter(
      (obj) => obj.uuid === label.value
    );
    let param_val = JSON.parse(paramObj[0].algorithm_parameters);
    let default_params = JSON.parse(paramObj[0].algorithm_defaults);
    let required_params = param_val.required;
    let all_param_values = Object.keys(param_val.properties)
    let algo_param_all_values = {}
    all_param_values.forEach((item)=>{
      algo_param_all_values[item] =  default_params[item] !== undefined ?  default_params[item] : null
    })
    
    
    let dynamic_drop_down_option_p2p = []
    if (paramObj && paramObj[0].algorithm_name !== "no_anomaly") {
      if (paramObj && paramObj[0].algorithm_category.includes("Peer to Peer")) {
        if (!this.state.anomalyhierarchySelection.label) {
          alert("No Hierarchy Selected");
          return;
        }
        let group_by_level_vals = this.get_group_by_level(
          this.state.anomalyhierarchySelection.label, paramObj[0].algorithm_name
        )
        dynamic_drop_down_option_p2p = group_by_level_vals;
          if (paramObj && paramObj[0].algorithm_name == "fs_peer_to_peer") {
            param_val.properties.Group_by.enum = dynamic_drop_down_option_p2p;
          } else if (paramObj && paramObj[0].algorithm_name == "peer_to_peer_type_two") {
            param_val.properties.baseline_hierarchy.enum = dynamic_drop_down_option_p2p;
          }
          else if (paramObj && paramObj[0].algorithm_name == "peer_to_peer_type_one") {
            param_val.properties.group_by_column.enum = dynamic_drop_down_option_p2p;
          }
          this.setState({
            algoParam: param_val,
            algoData: JSON.parse(paramObj[0].algorithm_defaults),
            algoParamData:algo_param_all_values,
            required_params:required_params,
            formErrors:[]
          });

      } else {
        this.setState({
          algoParam: param_val,
          algoData: JSON.parse(paramObj[0].algorithm_defaults),
          algoParamData:algo_param_all_values,
          required_params:required_params,
          formErrors:[]
        });
      }
    }
    let selected_user_algo = {};
    selected_user_algo["label"] = paramObj[0].display_name;
    selected_user_algo["value"] = paramObj[0].uuid;
    this.setState({
      current_algo_id: paramObj[0].uuid,
      current_algo: paramObj[0].algorithm_name,
      current_algo_display_name: paramObj[0].display_name,
      selected_user_algo: selected_user_algo,
    });
  };
  handleParamChange = (form_event) => {
    this.setState({
       algoParamData: form_event.formData,
       formErrors:form_event.errors
    });
  };
  validateAnomalySelections = () => {
    let hierarchySelection = this.state.anomalyhierarchySelection.value;
    let valueSelection = this.state.valuesAnomalySelection;
    let algoType = this.state.algoType;
    let current_algo = this.state.current_algo;
    let kpi_selection = this.state.kpi_selection;
    let algoParamData = this.state.algoParamData;
    let required_params = this.state.required_params;
    if (hierarchySelection === "" || hierarchySelection === undefined) {
      const text = "Please select hierarchy";
      this.props.togglePopup(text);
      return;
    }
    if (valueSelection.length == 0) {
      const text = "Please select Values";
      this.props.togglePopup(text);
      return;
    }
    if (kpi_selection.length === 0) {
      const text = "Please select  KPI";
      this.props.togglePopup(text);
      return;
    }
    if (algoType.value == null || algoType.value == "") {
      const text = "Please select Algo Type";
      this.props.togglePopup(text);
      return;
    }
    if (current_algo == null || current_algo == "") {
      const text = "Please select Algo";
      this.props.togglePopup(text);
      return;
    }
    let formErrors = this.state.formErrors
    if (algoParamData) {
      var required_fields =  Object.keys(algoParamData).filter((param) => {
         return required_params && required_params.includes(param) && (algoParamData[param] === undefined || algoParamData[param] === null)
           
       });
     }
     
     let formErrorsList = []
     if(formErrors && formErrors.length > 0){
       formErrors.forEach((error)=>{
         let paramName  = error.property.split("").slice(1).join("")
         if(required_params.includes(paramName)){
          let errorObj = {}
          errorObj['param'] = error.property
          errorObj['message'] =  error.message
          formErrorsList.push(errorObj)
         }        
       })
     }
     let errortext = [
      "The following list of algorithm parameters are required",
    ];       
     if(required_fields && required_fields.length > 0){
       let htmlResult = required_fields.map((item) => {
         return <div> <br/>
               <div className="text-danger"><span> {item}</span></div>
            </div>
       });
       const text = [...errortext, ...htmlResult];
       this.props.togglePopup(text);
       return;
     }
       if(formErrorsList && formErrorsList.length > 0){
       let htmlResult = formErrorsList.map((item) => {
         return <div> <br/>
               <div className="text-danger"><span> {item.param}</span><span> : &nbsp; &nbsp; &nbsp;
               </span><span>{item.message}</span></div>
            </div>
       });
       const text = [...errortext, ...htmlResult];
       this.props.togglePopup(text);
       return;
     }
     this.addSelection()
  }
  addSelection = () => {
    let anomaly_params = _loadash.cloneDeep(this.state.anomaly_params);
    let hierarchySelection = this.state.anomalyhierarchySelection.value;
    let valueSelection = this.state.valuesAnomalySelection;
    let algo_types = this.state.algo_types;
    let algoType = this.state.algoType;
    let current_algo = this.state.current_algo;
    let current_algo_display_name = this.state.current_algo_display_name;
    let kpi_selection = this.state.kpi_selection;
    let client_side_anomaly_load = _loadash.cloneDeep(this.state.client_side_anomaly_load);
    if (hierarchySelection === "" || hierarchySelection === undefined) {
      const text = "Please select hierarchy";
      this.props.togglePopup(text);
      return;
    }
    if (valueSelection.length == 0) {
      const text = "Please select Values";
      this.props.togglePopup(text);
      return;
    }
    if (kpi_selection.length === 0) {
      const text = "Please select  KPI";
      this.props.togglePopup(text);
      return;
    }
    if (algoType.value == null || algoType.value == "") {
      const text = "Please select Algo Type";
      this.props.togglePopup(text);
      return;
    }
    if (current_algo == null || current_algo == "") {
      const text = "Please select Algo";
      this.props.togglePopup(text);
      return;
    }

    let params = JSON.stringify(this.state.algoParamData);

    let set = algoType.value.concat(",", current_algo).concat(",", params);

    let set_valid = this.state.set_valid;
    let cur_val = set_valid[set] || [];

    let flag;

    if (["fs_peer_to_peer", "peer_to_peer_type_one"].includes(current_algo)) {
      let is_exist = false;
      kpi_selection.forEach((kpi) => {
        let to_cancat = [
          hierarchySelection,
          kpi.value,
          valueSelection.map((obj) => obj.name).join("||"),
        ];
        let final_str = to_cancat.join(",");
        let algo_concat = [algoType.value, current_algo, params].join(",");
        let final_str_algo = final_str.concat(",", algo_concat);
        let hierarchySplit = hierarchySelection.split("-**-");
        let final_hierar = [];
        hierarchySplit.forEach((hierarchy) => {
          let sub_hier = hierarchy.split("-*-");
          if (sub_hier.length > 1) {
            final_hierar.push(sub_hier[sub_hier.length - 1]);
          } else {
            final_hierar.push(sub_hier[0]);
          }
        });
        cur_val.push(final_str);
        set_valid[set] = cur_val;

        const parameters_obj = {
          hierarchy: final_hierar.join("-**-"),
          kpi_selection: kpi.value,
          value: valueSelection.map((obj) => obj.name).join("||"),
          algorithm: current_algo,
          algorithm_family: algoType.value,
          parameters: params,
          resultFlag: false,
          current_algo_display_name: current_algo_display_name,
          task_id: null,
          row_uuid: uuidv4(),
        }
        anomaly_params && anomaly_params.unshift(parameters_obj);
        client_side_anomaly_load && client_side_anomaly_load.unshift(parameters_obj)

        flag = true;
        return flag;
      })
      this.setState({
        // anomaly_params: anomaly_params,
        anomaly_params: anomaly_params.slice(0, PAGINATION_RANGE[0]),
        client_side_anomaly_load: client_side_anomaly_load,
        anomaly_pages: Math.ceil(client_side_anomaly_load.length / PAGINATION_RANGE[0])
      });
    } else {

      valueSelection.forEach((obj) => {
        let is_exist = false;
        kpi_selection.forEach((kpi) => {
          let to_cancat = [hierarchySelection, kpi.value, obj.name];
          let final_str = to_cancat.join(",");
          let algo_concat = [algoType.value, current_algo, params].join(",");
          let final_str_algo = final_str.concat(",", algo_concat);

          let hierarchySplit = hierarchySelection.split("-**-");
          let final_hierar = [];
          hierarchySplit.forEach((hierarchy) => {
            let sub_hier = hierarchy.split("-*-");
            if (sub_hier.length > 1) {
              final_hierar.push(sub_hier[sub_hier.length - 1]);
            } else {
              final_hierar.push(sub_hier[0]);
            }
          });
          cur_val.push(final_str);
          set_valid[set] = cur_val;

          const parameters_obj = {
            hierarchy: final_hierar.join("-**-"),
            kpi_selection: kpi.value,
            value: obj.name,
            algorithm: current_algo,
            algorithm_family: algoType.value,
            parameters: params,
            resultFlag: false,
            current_algo_display_name: current_algo_display_name,
            task_id: null,
            row_uuid: uuidv4(),
          }
          anomaly_params && anomaly_params.unshift(parameters_obj);
          client_side_anomaly_load && client_side_anomaly_load.unshift(parameters_obj)
          flag = true;
          return flag;
        })
      });

      this.setState({
        // anomaly_params: anomaly_params,
        anomaly_params: anomaly_params.slice(0, PAGINATION_RANGE[0]),
        client_side_anomaly_load: client_side_anomaly_load,
        anomaly_pages: Math.ceil(client_side_anomaly_load.length / PAGINATION_RANGE[0])
      });
    }
    if (flag) {
      let toastId = null;
      toastId = toast(valueSelection.length + " " + "selections added", {
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });
    } else {
      return;
    }
  };
  handleKPIChange = (kpi_selection) => {
    this.setState({
      kpi_selection: kpi_selection,
    });
  };
  selectMultipleOptionAnomaly = (value) => {
    this.setState({ valuesAnomalySelection: value });
  };

  handleAnomalyHierarchyChange = (label) => {
    let algoType = this.state.algoType.label || "";
    if (label.value === null || label.value === "") {
      this.setState({
        anomalyhierarchySelection: {},
        valuesofAnomalyHierarchyselection: [],
        valuesAnomalySelection: [],
      });
      return;
    } else {
      if (algoType.includes("Peer to Peer")) {
        this.setState({
          algoType: "",
          selected_user_algo: {},
          algoParam: {},
        });
      }
      let toastId = null;
      toastId = toast("Fetching hierarchy values", {
        type: toast.TYPE.INFO,
        autoClose: true,
      });
     
      const data = JSON.stringify({
        selected_label: [label.value],
        pipeline_uuid: this.state.pipeline_id,
        user_uuid: JSON.parse(this.context.store_user_uuid),
        hierarchy_sets: this.state.hierarchyCombination,
      })
      fetchCall(`/app/api/algorithm/hierarchy`,'POST',data)
        .then((response) => handleErrors(response))
        .then((resultData) => {
          const hierarchySelection = Object.values(resultData)[0]
          this.setState({
            anomalyhierarchySelection: label,
            valuesofAnomalyHierarchyselection: hierarchySelection.map((obj, i) => {
              return { id: i, name: obj };
            }),
            valuesAnomalySelection: [],
          });
          toast.update(toastId, {
            render: "Hierarchy Values fetched Successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "Error in fetching Hierarchy Values",
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        });
    }
  };
  getPossibleHierarcyLevels = (hierarchyCombination,label) => {
    let selectedHierarchySet = Object.entries(hierarchyCombination).filter(([key, value]) => {
      return value.includes(label)
    });
    let otherHierarchySets = Object.entries(hierarchyCombination).filter(([key, value]) => {
      return !value.includes(label)
    });
    let filteredHierarchies = []
    if(selectedHierarchySet.length == 0 & label == "Overall"){
      filteredHierarchies.push(label)
    }else{
      const hierarchySetArr = selectedHierarchySet[0][1]
      for(let i=0;i<hierarchySetArr.length;i++){
        filteredHierarchies.push(hierarchySetArr[i])
        if (hierarchySetArr[i] == label) break;  
      }
    }
    let allOtherHierarchiesArr = otherHierarchySets.map((item)=>{
        return item[1]
      })
      let allOtherHierarchies = [].concat.apply([], allOtherHierarchiesArr);
      let level_filer_hierarchies = allOtherHierarchies && allOtherHierarchies.length>0 && label !== "Overall" ? [...filteredHierarchies, ...allOtherHierarchies] : filteredHierarchies
      return level_filer_hierarchies
  }
  handleDSHierarchyChange = (label) => {
    if (label.value === null || label.value === "") {
      this.setState({
        anomalyhierarchySelection: {},
        valuesofDSHierarchyselection: [],
        valuesDSFilterSelection: [],
      });
      return;
    } else {
    //let level_filer_hierarchies = this.getPossibleHierarcyLevels(this.state.hierarchyCombination,label.value)
    let level_filer_hierarchies = []
    if(label.value.includes('-**-')){
      let hybridHierarchiesList = label.value.split("-**-")
      let allPossibleHierarchyLevelsArr = hybridHierarchiesList.map((hierarchy)=>{
        return this.getPossibleHierarcyLevels(this.state.hierarchyCombination,hierarchy)
      })
      level_filer_hierarchies = _loadash.intersection.apply(_loadash,allPossibleHierarchyLevelsArr);
    }else{
       level_filer_hierarchies = this.getPossibleHierarcyLevels(this.state.hierarchyCombination,label.value)
    }

    let unique_level_filer_hierarchies = [...new Set(level_filer_hierarchies)];
    this.setState({
        dsHierarchySelection: label,
        valuesofDSHierarchyselection: unique_level_filer_hierarchies.map((obj, i) => {
         return { value: obj, label: obj };
       }),
        valuesAnomalySelection: [],
        valuesDSFilterSelection:[],
      });
    }
  };

  handleDSHierarchyValueChange = (value) => {
    if (value.length == 0) {
      this.setState({ valuesDSFilterSelection: value });
      return;
    }
    this.setState({ valuesDSFilterSelection: value });
  };

  validateDSSelections = () => {
    let hierarchySelection = this.state.dsHierarchySelection;
    let valueSelection = this.state.valuesDSFilterSelection;
    let algoType = this.state.algoType;
    let current_algo = this.state.current_algo;
    let kpi_selection = this.state.kpi_selection;
    let algoParamData = this.state.algoParamData;
    let required_params = this.state.required_params;
    if (hierarchySelection === "" || hierarchySelection === undefined || hierarchySelection === null) {
      const text = "Please select hierarchy";
      this.props.togglePopup(text);
      return;
    }
    if (valueSelection.length == 0) {
      const text = "Please select Values";
      this.props.togglePopup(text);
      return;
    }
    if (kpi_selection.length === 0) {
      const text = "Please select  KPI";
      this.props.togglePopup(text);
      return;
    }
    if (algoType.value == null || algoType.value == "") {
      const text = "Please select Algo Type";
      this.props.togglePopup(text);
      return;
    }
    if (current_algo == null || current_algo == "") {
      const text = "Please select Algo";
      this.props.togglePopup(text);
      return;
    }
    if(algoParamData.agg_type == "formula" && algoParamData.formula == null ){
      const text = "Please select Formula from Parameters";
      this.props.togglePopup(text);
      return;
    }
    if(algoParamData.agg_type !== 'formula' && algoParamData.formula !== null && algoParamData.formula !== undefined){
      const text = "Please select Formula from agg_type";
      this.props.togglePopup(text);
      return;
    }
    if(algoParamData.agg_type == "formula" && kpi_selection.length >1){
      const text = "Only single kpi selection allowed for agg_type as formula.";
      this.props.togglePopup(text);
      return;
    }
    let formErrors = this.state.formErrors
    if (algoParamData) {
      var required_fields =  Object.keys(algoParamData).filter((param) => {
         return required_params && required_params.includes(param) && (algoParamData[param] === undefined || algoParamData[param] === null)
           
       });
     }
     
     let formErrorsList = []
     if(formErrors && formErrors.length > 0){
       formErrors.forEach((error)=>{
         let paramName  = error.property.split("").slice(1).join("")
         if(required_params.includes(paramName)){
          let errorObj = {}
          errorObj['param'] = error.property
          errorObj['message'] =  error.message
          formErrorsList.push(errorObj)
         }        
       })
     }
     let errortext = [
      "The following list of algorithm parameters are required",
    ];       
     if(required_fields && required_fields.length > 0){
       let htmlResult = required_fields.map((item) => {
         return <div> <br/>
               <div className="text-danger"><span> {item}</span></div>
            </div>
       });
       const text = [...errortext, ...htmlResult];
       this.props.togglePopup(text);
       return;
     }
       if(formErrorsList && formErrorsList.length > 0){
       let htmlResult = formErrorsList.map((item) => {
         return <div> <br/>
               <div className="text-danger"><span> {item.param}</span><span> : &nbsp; &nbsp; &nbsp;
               </span><span>{item.message}</span></div>
            </div>
       });
       const text = [...errortext, ...htmlResult];
       this.props.togglePopup(text);
       return;
     }
     this.addDSSelection()
  }
  addDSSelection = () => {
    let anomaly_params = _loadash.cloneDeep(this.state.anomaly_params);
    let hierarchySelection = this.state.dsHierarchySelection.value;
    let valuesDSFilterSelection = this.state.valuesDSFilterSelection
    let algo_types = this.state.algo_types;
    let algoType = this.state.algoType;
    let current_algo = this.state.current_algo;
    let current_algo_display_name = this.state.current_algo_display_name;
    let kpi_selection = this.state.kpi_selection;
    let client_side_anomaly_load = _loadash.cloneDeep(this.state.client_side_anomaly_load);
    if (hierarchySelection === "" || hierarchySelection === undefined) {
      const text = "Please select hierarchy";
      this.props.togglePopup(text);
      return;
    }
    if (kpi_selection.length === 0) {
      const text = "Please select  KPI";
      this.props.togglePopup(text);
      return;
    }
    if (algoType.value == null || algoType.value == "") {
      const text = "Please select Algo Type";
      this.props.togglePopup(text);
      return;
    }
    if (current_algo == null || current_algo == "") {
      const text = "Please select Algo";
      this.props.togglePopup(text);
      return;
    }
    let hierarchiesValuesArr = []
    if(valuesDSFilterSelection.find((item)=>{
      return item.label == 'Select all'
    })){
      hierarchiesValuesArr = valuesDSFilterSelection.filter((item)=>item.label !== 'Select all').map((item)=>item.value)
    }else{
      hierarchiesValuesArr = valuesDSFilterSelection.map((item)=>{
        return item.value
      })
    }
    let params = JSON.stringify(this.state.algoParamData);
    let set = algoType.value.concat(",", current_algo).concat(",", params);
    let set_valid = this.state.set_valid;
    let cur_val = set_valid[set] || [];
    let flag;
      
        let to_cancat = [hierarchySelection];
        let final_str = to_cancat.join(",");
        let hierarchySplit = hierarchySelection.split("-**-");
        let final_hierar = [];
        hierarchySplit.forEach((hierarchy) => {
          let sub_hier = hierarchy.split("-*-");
          if (sub_hier.length > 1) {
            final_hierar.push(sub_hier[sub_hier.length - 1]);
          } else {
            final_hierar.push(sub_hier[0]);
          }
        });
        let updated_kpi_selection = kpi_selection.map((item)=>{
          return item.value
        })
        cur_val.push(final_str);
        set_valid[set] = cur_val;
        const parameters_obj = {
          hierarchy: final_hierar.join("-**-"),
          kpi_selection:JSON.stringify(updated_kpi_selection),
          value: JSON.stringify(hierarchiesValuesArr),
          algorithm: current_algo,
          algorithm_family: algoType.value,
          parameters: params,
          resultFlag: false,
          current_algo_display_name: current_algo_display_name,
          task_id: null,
          row_uuid: uuidv4(),
        }
        anomaly_params && anomaly_params.unshift(parameters_obj);
        client_side_anomaly_load && client_side_anomaly_load.unshift(parameters_obj)
        flag = true;
        //return flag;
      
      this.setState({
        // anomaly_params: anomaly_params,
        anomaly_params: anomaly_params.slice(0, PAGINATION_RANGE[0]),
        client_side_anomaly_load: client_side_anomaly_load,
        anomaly_pages: Math.ceil(client_side_anomaly_load.length / PAGINATION_RANGE[0])
      });
    if (flag) {
      let toastId = null;
      toastId = toast("selections added", {
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });
    } else {
      return;
    }
  };

  exprotDescriptiveResults = (exp_id, run_id) => {
      let descriptive_params = JSON.parse(JSON.stringify(this.state.anomaly_params))
      let task_ids = descriptive_params.map((item)=>item.task_id)
      if(task_ids.every(element => element === null)){
        const text = "No results to export";
        this.props.togglePopup(text);
        return;
      }
      let valid_task_ids = task_ids.filter((item)=>item !== null)
      let toastId = null;
      toastId = toast("Fetching Anomaly Export", {
        type: toast.TYPE.INFO,
        autoClose: 3000,
      });
      const data =  JSON.stringify({
        experiment_id: exp_id,
        run_id: run_id,
        taskIds: valid_task_ids
      })
      fetchCall(`/app/api/anomaly/ds_export`,'POST',data)
        .then((response) => {
          return handleErrors(response)
        })
        .then((resultData) => {
          let ds_summary_all_data = []
           Object.entries(resultData).forEach(([key, value]) => {
            ds_summary_all_data.push(JSON.parse(value.data[0]))

        });
         const combined_all_data = ds_summary_all_data.flat();
           this.setState({
            dsSummaryResultAllData:combined_all_data,
            isExportAllModelOpen:true
          })
          toast.update(toastId, {
            render: "Results Fetched Successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "ERROR: " + error.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        })
  }

  closeExportModal = () => {
    this.setState({
      isExportAllModelOpen:false
    })
  }


  exportResult = (anomaly_project_id, anomaly_experiment_id) => {
    let anomaly_params = this.state.anomaly_params;
    let result = anomaly_params.reduce(function (acc, row) {
      let key = row.hierarchy.concat(",", row.value).concat(",", row.kpi_selection).concat(",", row.algorithm_family)
      acc[key] = ++acc[key] || 1;
      return acc;
    }, {});
    const pipeline_id = this.state.pipeline_id;
    let duplicateCombination = [];
    let duplicateFlag = false;
    Object.keys(result).forEach((key) => {
      let count = result[key];
      if (count > 1) {
        duplicateCombination.push(key);
        duplicateFlag = true;
      }
    });

    if (duplicateFlag) {
      let errortext = [
        "The following list of hierarchy combinations are duplicated",
      ];
      let htmlResult = duplicateCombination.map((item) => {
        return <p className="algo_combinations">{item}</p>;
      });
      const text = [...errortext, ...htmlResult];
      this.props.togglePopup(text);
      return;
    } else {
      let toastId = null;
      toastId = toast("Exporting Results", {
        type: toast.TYPE.INFO,
        autoClose: true,
      });
     
      const data =  JSON.stringify({
        user_uuid: JSON.parse(this.context.store_user_uuid),
      })
      fetchCall(`/app/api/etl/anomaly/export/${pipeline_id}/${anomaly_project_id}/${anomaly_experiment_id}`,'POST',data)
        .then((response) => handleErrors(response))
        .then((resultData) => {
          toast.update(toastId, {
            render: "Anomaly Export has started.",
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          });
          sendAmplitudeData(EXPORT_ANOMALY);
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "ERROR: " + error.message,
            type: toast.TYPE.ERROR,
          });
        });
    }
  };

  closeAnomanlyResultModal = () => {
    this.setState({ AnomalyResultModelIsOpen: false });
  };

 fetchAnomalyRuns = (
    exp_id
  ) => {
    let toastId = null;
    toastId = toast("Fetching Anomaly Runs", { type: toast.TYPE.INFO });
    const selectedExperiment = this.state.anomalyExperiments.filter(
      (obj) => obj.experiment_id === exp_id
    )[0];
    const data_source_id =
      selectedExperiment && selectedExperiment.data_source_id;
    localStorage.setItem("anomalyPipeline", JSON.stringify(data_source_id));
   
    fetchCall(`/app/api/anomaly/experiment/${exp_id}/runs`,'GET')
      .then((response) => handleErrors(response))
      .then((resultData) => {
        toast.update(toastId, {
          render: "Experiments fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.setState({
          anomalyRunsList: resultData,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  getAlertCount = (ds_id) => {
    let toastId = null;
    toastId = toast("Fetching Alert counts", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/anomaly/alert_count/${ds_id}/`,'GET')
      .then((response) => handleErrors(response))
      .then((resultData) => {
        toast.update(toastId, {
          render: "Alert counts fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.setState({
          alert_report: resultData,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  }

  handleAnomalyExpCall = (
    exp_id,
    exp_name,
    datasource_id,
    data_source_name,
    pathName
  ) => {
    let toastId = null;
    toastId = toast("Fetching Anomaly Runs", { type: toast.TYPE.INFO });
    const selectedExperiment = this.state.anomalyExperiments.filter(
      (obj) => obj.experiment_id === exp_id
    )[0];
    const data_source_id =
      selectedExperiment && selectedExperiment.data_source_id;
    localStorage.setItem("anomalyPipeline", JSON.stringify(data_source_id));
   
    fetchCall(`/app/api/anomaly/experiment/${exp_id}/runs`,'GET')
      .then((response) => handleErrors(response))
      .then((resultData) => {
        toast.update(toastId, {
          render: "Experiments fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.setState({
          anomalyRuns: resultData,
          anomalyexp_id: exp_id,
          anomalyPipeline: data_source_id,
          set_valid: {},
        });
        localStorage.setItem("anomalyExpName", exp_name);
        localStorage.setItem("data_source_name", data_source_name);
        if(pathName.includes("/app/anomaly/ds/experiment")){
          this.props.history.push(`/app/anomaly/ds/experiment/${exp_id}`);
        }else{
          this.props.history.push(`/app/anomaly/experiment/${exp_id}`);
        }
        
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handleAddAnomalyExperimentToggle = () => {
    let flag = this.state.showAddAnomalyExp;
    this.setState({
      showAddAnomalyExp: !flag,
      anomalyExperimentName: "",
      nameError:""
    });
  };
  closeAnomalyExperimentModal = () => {
    this.setState({
      showAddAnomalyExp: false,
    });
  };
  saveAnomalyExperiment = (pathName) => {
    let anomalyExperimentName = this.state.anomalyExperimentName;
    let data_source_name = this.state.data_source_name;
    anomalyExperimentName = anomalyExperimentName.trim();
    let anomaly_experiments = this.state.anomalyExperiments;
    let duplicateName = anomaly_experiments.some((item) => {
      return (
        item.experiment_name.toLocaleLowerCase().split(" ").join("") ===
        anomalyExperimentName.toLocaleLowerCase().split(" ").join("")
      );
    });
    if(!isInputNameValidated(anomalyExperimentName)){
      this.setState({
        nameError:NAME_ERROR
      })
      return;
    }else if (
      data_source_name == null ||
      ((data_source_name === undefined) === data_source_name) === ""
    ) {
      this.setState({
        nameError:""
      })
      const text = "Please select Data source";
      this.props.togglePopup(text);
      return;
    } else if (duplicateName) {
      const text = "Project name already exist!";
      this.props.togglePopup(text);
      return;
    } else {
      let toastId = null;
      toastId = toast("Fetching Anomaly Project", {
        type: toast.TYPE.INFO,
      });
      
      let request_body = {
        experiment_name: anomalyExperimentName,
        user_id: JSON.parse(this.context.store_user_uuid),
        data_source_id: this.state.anomalyPipeline,
        data_source_name: data_source_name,
        experiment_type: pathName == 'Descriptive Summary' ? 'Descriptive Summary' : 'Anomaly'
        
      }
      const request_body_with_digest = requestWithHashDigest(request_body)


      fetchCall(`/app/api/anomaly/experiment`,'POST',JSON.stringify(request_body_with_digest))
        .then((response) => handleErrors(response))
        .then((resultData) => {
          if (resultData.status === 200) {
            let anomalyExperiments = this.state.anomalyExperiments;
            anomalyExperiments.push(resultData.payload);
            let anomaly_project_event = {};
            anomaly_project_event[ANOMALY_PROJECT_NAME] =
              resultData.payload.experiment_name;
            sendAmplitudeData(ANOMALY_PROJECT, anomaly_project_event);
            this.setState({
              anomalyExperiments,
              showAddAnomalyExp: false,
              data_source_name: null,
            });
          }
          toast.update(toastId, {
            render: "Anomaly project created successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "ERROR: " + error.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        });
    }
  };
  handleNewAnomalyExperiment = (e) => {
    let name = e.target.value;
    this.setState({
      anomalyExperimentName: name,
    });
  };

  fetchAnomalyExperiments = () => {
    let toastId = null;
    toastId = toast("Fetching Experiments", { type: toast.TYPE.INFO });
    
    fetchCall(`/app/api/anomaly/experiment`,'GET')
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({
          anomalyExperiments: resultData,
          anomalyResults: {},
          algo_status: {},
        });
        toast.update(toastId, {
          render: "Experiments fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        return resultData;
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
        });
      });
  };
  handleAnomalyPipelineMap = (e) => {
    const pipeline_uuid = e.value;
    const selected_data_source = this.state.dataSourcesList.filter((pip) => {
      if (pip.pipeline_uuid === pipeline_uuid) return pip.display_name;
    })[0];
    // localStorage.setItem("anomalyPipeline", JSON.stringify(pipeline_uuid));
    this.setState({
      anomalyPipeline: pipeline_uuid,
      data_source_name: selected_data_source.display_name,
    });
  };
  handleAddAnomalyRunToggle = (pathName) => {
    this.setState({
      params_anomaly: [],
      anomaly_params: [],
    });
    if(pathName.includes('/app/anomaly/ds/experiment')){
      this.props.history.push(
        `/app/anomaly/ds/experiment/${this.state.anomalyexp_id}/run/`
      );
    }else{
      this.props.history.push(
        `/app/anomaly/experiment/${this.state.anomalyexp_id}/run/`
      );
    }
  };
  handleAnomalyRunCall = (run_id) => {
    this.props.history.push(
      `/app/anomaly/experiment/${this.state.anomalyexp_id}/run/${run_id}`
    );
    this.getRun();
  };
  onloadAlgoParams = () => {
    let currenthierarchyLevels = this.state.hierarchyDataMap;
    this.setState({
      hierarchyfileNames: currenthierarchyLevels,
      kpi_selection: [],
      algoType: {},
      current_algo: "",
      current_algo_id: "",
      hierarchySelection: "",
      valuesofHierarchyselection: [],
    });
  };
  get_anomaly_params = (id) => {
    let toastId = toast("Loading anomaly param", {
      type: toast.TYPE.INFO,
      autoClose: 2000,
    });
   
    fetchCall(`/app/api/ui/anomaly_params/`,'GET')
      .then((result) => result.json())
      .then((resultData) => {
        let resp = resultData || {};
        const anomaly_params = resp.anomaly_params || [];
        const anomaly_result = resp.anomaly_result || {};
        this.setState({
          anomaly_params,
          anomalyResults: anomaly_result,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handleAnomalyExperimentDelete = (exp_id) => {
    this.fetchAnomalyRuns(exp_id)
    this.setState({
      isDeleteAnomalyExp: true,
      deleteAnomalyExpId: exp_id,
    });
  };

  confirmAnomalyExperimentDelete = () => {
    let anomalyExperiments = this.state.anomalyExperiments;
    let exp_id = this.state.deleteAnomalyExpId;
    let toastId = null;
    toastId = toast("Deleting Experiment. Please wait...", {
      type: toast.TYPE.INFO,
    });
    
    fetchCall(`/app/api/anomaly/experiment/${exp_id}`,'DELETE')
      .then((result) => handleErrors(result))
      .then((result) => {
        anomalyExperiments = anomalyExperiments.filter(
          (obj) => obj.experiment_id !== exp_id
        );
        this.setState({
          anomalyExperiments,
          isDeleteAnomalyExp: false,
        });
        toast.update(toastId, {
          render: "Deleted successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  getRun = (run_id) => {

    if (!run_id) return;
    let toastId = null;
    toastId = toast("Fetching Anomaly Experiments", { type: toast.TYPE.INFO });
  
    fetchCall(`/app/api/anomaly/run/${run_id}`,'GET')
      .then((response) => handleErrors(response))
      .then((resultData) => {
        // const run_parameters = resultData.run_parameters || [];
        const anomaly_state = resultData.anomaly_state || [];

        if (resultData) {
          this.setState({
            // anomaly_params: [],
            ...JSON.parse(anomaly_state),
            run_id: run_id,
            user_uuid:resultData.user_uuid,
            selected_anomaly_run_name: resultData.run_name,
          });
        }
        toast.update(toastId, {
          render: "Experiment fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  loadDataSourceData = (exp_id,module) => {
    let toastId = null;
    toastId = toast("Loading pipeline", { type: toast.TYPE.INFO });
   
    fetchCall(`/app/api/anomaly/experiment/${exp_id}/data_source`,'GET')
      .then((result) => result.json())
      .then((resultData) => {
        if (!resultData) {
          toast.update(toastId, {
            render: "Unable to load pipelines, No records found",
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          })
          return
        }
        const kpiList = resultData.user_columns.kpi  || [];
        const pipeline_id = resultData.pipeline_uuid;
        let resp = JSON.parse(resultData.upload_state);
        localStorage.setItem("anomalyPipeline", JSON.stringify(pipeline_id));
        const kpiTypeMap = resp.kpiTypeMap;
        const hierarchyfileNames = resultData.user_columns.hierarchy_splitted  || [];
        const hierarchyCombination = JSON.parse(
          resultData.hierarchy_combination
        );
        const time_column = resultData.user_columns.time && resultData.user_columns.time[0].toLowerCase() || "";
        this.setState({
          hierarchyfileNames,
          kpiList,
          hierarchyCombination,
          kpiTypeMap,
          pipeline_id,
          time_column,
          store_ds_name:resultData.display_name,
          store_ds_id:pipeline_id
        });
        if(module !== 'ds_summary'){
          this.getAlertCount(pipeline_id)
        }
        toast.dismiss(toastId);
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handleDelAnomalyRun = (id) => {
    this.setState({
      isDeleteAnomalyRun: true,
      deleteAnomalyRunId: id,
    });
  };

  confirmDeleteAnomalyRun = () => {
    let anomalyRuns = this.state.anomalyRuns;
    let id = this.state.deleteAnomalyRunId;
    let toastId = null;
    toastId = toast("Deleting run. Please wait...", { type: toast.TYPE.INFO });
   
    fetchCall(`/app/api/anomaly/run/${id}`,'DELETE')
      .then((result) => handleErrors(result))
      .then((result) => {
        if (result) {
          anomalyRuns = anomalyRuns.filter((obj) => obj.run_id !== id);
          this.setState({
            anomalyRuns: anomalyRuns,
            isDeleteAnomalyRun: false,
          });
          toast.update(toastId, {
            render: "Deleted successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      })

      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  filter_anomaly_data = (index) => {
    this.setState({
      current_anomaly: index,
    });
  };
  saveProgress = (run_id) => {
    let toastId = null;
    toastId = toast("Saving Progress. Please wait...", {
      type: toast.TYPE.INFO,
    });
    const payload = {};
    const anomalyResults = this.state.anomalyResults;
    const anomaly_params =
      this.state.anomaly_params &&
      this.state.anomaly_params.filter((obj) => obj.task_id == null);

    console.log("Anomaly Params Length = ");
    console.log(anomaly_params.length);

    // const algo_status = this.state.algo_status;
    // payload["anomalyResults"] = anomalyResults;
    // payload["anomaly_params"] = anomaly_params;
    // payload["algo_status"] = algo_status;
   
    const data =  JSON.stringify({
      anomaly_params: anomaly_params,
    })
    fetchCall(`/app/api/anomaly/anomaly_user_parameters/${run_id}`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        toast.update(toastId, {
          render: "Progress Saved",
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  rerunAllAlgo = (exp_id, run_id) => {
    let rerun_all = this.state.rerun_all;
    this.setState({
      rerun_all: !rerun_all,
    });
  };
  handleSingleLoadRun = (data, experiment_id) => {
    /**
     *  This method is called if the no of items are less than the specified threshold
     */
    let toastId = toast("Running Algorithm", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    fetchCall(`/app/api/anomaly/run/${experiment_id}/execute`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        if (resultData) {
          // anomaly_params.forEach((obj) => {
          //   obj.resultFlag = true;
          // });
          this.setState({
            // anomalyResults: resultData,
            // anomaly_params: anomaly_params,
            rerun_all: false,
            algo_status: {},
          });
          this.saveProgress(experiment_id);
          toast.update(toastId, {
            render: "Running.. Please click on View to see results",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        } else {
          toast.update(toastId, {
            render: "Unable to run the algorithm due to some issue in backend",
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        this.saveProgress(experiment_id);
      });
  }

  lockAnomalyScreen = () => {
    console.log("[+] Locking screen...");
    const div = document.createElement("div");
    div.style.width = "100vw";
    div.style.height = "400vh";
    div.style.zIndex = 99999;
    div.style.opacity=0.20;
    div.style.position = "absolute";
    div.style.top ="0px";
    div.style.left ="0px";
    div.style.background = "black";
    div.style.color = "white";
    div.id="anomalylock";
    document.body.appendChild(div);
  }
  unlockAnomalyScreen = () => {
    var textRemove = document.getElementById("anomalylock");
    textRemove.remove();
  }
  handleBatchLoadRun = (data, anomaly_run_parameters, threshold, experiment_id) => {
    /**
     * 
     * When we have a lot of pages to run. Its better to run them as batches to avoid timeout and overflow
     * Client size upload limit params in NGINX
     * 
     */
    let toastId = toast("Started Algo Batch of "+threshold+" per Chunk", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    console.log("Running as BATCH LOAD")
    console.log("No of items to run = ")
    const anomaly_params =
      this.state.anomaly_params &&
      this.state.anomaly_params.filter((obj) => obj.task_id == null);
    console.log("anomaly_params length = ")
    console.log(anomaly_params.length)
    console.log(anomaly_run_parameters.length);
    // this.lockAnomalyScreen();
    const chunkSize = threshold;
    for (let i = 0; i < anomaly_run_parameters.length; i += chunkSize) {
      const chunk = anomaly_run_parameters.slice(i, i + chunkSize);
      console.log("No of parameters running = ")
      console.log(chunk.length);
      data.anomaly_run_parameters = chunk;
      let all_data =  JSON.stringify(data);
      let numerator = (i+chunkSize)<anomaly_run_parameters.length?(i+chunkSize):anomaly_run_parameters.length;
      
      fetchCall(`/app/api/anomaly/run/${experiment_id}/execute`,'POST',all_data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        if (resultData) {
          // let number = "Ran Algorithm Batch : "+numerator+" / "+anomaly_run_parameters.length
          // let toastId = toast(number, {
          //   type: toast.TYPE.INFO,
          //   autoClose: true,
          // });    
        } else {
          
        }
      })
      .catch((error) => {
      });
      
    }// end for loop
    
    this.setState({
      rerun_all: false,
      algo_status: {},
    });
    
    this.saveProgress(experiment_id);
    
    toast.update(toastId, {
      render: "Running.. Please click on View to see results",
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
    });

  }
  handleExecuteRun = (project_id, experiment_id, anomalyRunName) => {
    let toastId = null;
    let anomaly_params = this.state.anomaly_params;
    let client_side_anomaly_load = this.state.client_side_anomaly_load;
    if (client_side_anomaly_load.length > anomaly_params.length) {
      anomaly_params = client_side_anomaly_load
    }
    let rerun_all = this.state.rerun_all;
    let filtered_anomaly_params = anomaly_params.filter((obj) => {
      return (
        rerun_all || !obj.task_id || obj.task_id === null || obj.task_id === ""
      );
    });
    if(filtered_anomaly_params.length == 0 && !rerun_all){
      const text = "No selections to run";
      this.props.togglePopup(text);
      return;
    }
    
    const THRESHOLD = Math.ceil(filtered_anomaly_params.length/100);

    if(filtered_anomaly_params.length > 5000){
      let data =  {
        project_id: project_id,
        user_id: JSON.parse(this.context.store_user_uuid),
        run_name: anomalyRunName,
        run_selection: {},
        variable_type: this.state.kpiTypeMap,
        rerun_all: rerun_all,
      }
      this.handleBatchLoadRun(data, filtered_anomaly_params,THRESHOLD, experiment_id);

    }
    else{
      const data =  JSON.stringify({
        project_id: project_id,
        user_id: JSON.parse(this.context.store_user_uuid),
        run_name: anomalyRunName,
        anomaly_run_parameters: filtered_anomaly_params,
        run_selection: {},
        variable_type: this.state.kpiTypeMap,
        rerun_all: rerun_all,
      })
      this.handleSingleLoadRun(data, experiment_id);
    }  
  };
  handleDSExecuteRun = (project_id, experiment_id, anomalyRunName) => {
    let toastId = null;
    let anomaly_params = this.state.anomaly_params;
    let client_side_anomaly_load = this.state.client_side_anomaly_load;
    if (client_side_anomaly_load.length > anomaly_params.length) {
      anomaly_params = client_side_anomaly_load
    }
    let rerun_all = this.state.rerun_all;
    let filtered_anomaly_params = anomaly_params.filter((obj) => {
      return (
        rerun_all || !obj.task_id || obj.task_id === null || obj.task_id === ""
      );
    });
    if(filtered_anomaly_params.length == 0 && !rerun_all){
      const text = "No selections to run";
      this.props.togglePopup(text);
      return;
    }
    // var anomaly_run_parameters_packed = msgpack.encode(filtered_anomaly_params);
    toastId = toast("Running Algorithm", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
   
    const data =  JSON.stringify({
      project_id: project_id,
      user_id: JSON.parse(this.context.store_user_uuid),
      run_name: anomalyRunName,
      anomaly_run_parameters: filtered_anomaly_params,
      run_selection: {},
      variable_type: this.state.kpiTypeMap,
      rerun_all: rerun_all,
    })
    fetchCall(`/app/api/anomaly/run/${experiment_id}/execute`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        if (resultData) {
          // anomaly_params.forEach((obj) => {
          //   obj.resultFlag = true;
          // });
          this.setState({
            // anomalyResults: resultData,
            // anomaly_params: anomaly_params,
            rerun_all: false,
            algo_status: {},
          });
          this.saveProgress(experiment_id);
          toast.update(toastId, {
            render: "Running Descriptive Summary",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        } else {
          toast.update(toastId, {
            render: "Unable to run the algorithm due to some issue in backend",
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
        this.saveProgress(experiment_id);
      });
  };
  updateAnomalyParams = (anomaly_params) => {
    this.setState({
      anomaly_params,
      client_side_anomaly_load: [],
      dsSummaryResultAllData:[],
      anomaly_pages: 0
    });
  };
  updateAnomalyPages = () => {
    this.setState({
      anomaly_pages: 0
    })
  }
  updateEstimateParams = (estimate_params) => {
    this.setState({
      estimate_params,
    });
  };
  updateEstimateReplicates = (
    estimate_replicate_params,
    replicate_for_hier,
    replicate_for_value
  ) => {
    this.setState({
      selected_estimate_replicates: estimate_replicate_params,
      replicate_for_hier: replicate_for_hier,
      replicate_for_value: replicate_for_value,
    });
  };
  updateanomalyResulteplicates = (
    result_replicate_params,
    replicate_for_hier,
    replicate_for_value
  ) => {
    this.setState({
      selected_result_replicates: result_replicate_params,
      replicate_for_hier: replicate_for_hier,
      replicate_for_value: replicate_for_value,
    });
  };
  handleDelEstimateParams = (index) => {
    const estimate_params = this.state.estimate_params;
    estimate_params.splice(index, 1);
    this.setState({
      estimate_params,
    });
  };
  handleDelAnomalyParams = (index) => {
    const anomaly_params = this.state.anomaly_params;
    anomaly_params.splice(index, 1);
    //this.saveProgress()
    this.setState({
      anomaly_params,
    });
  };
  removeParameterSel = (index, task_id) => {
    const anomaly_params = this.state.anomaly_params;
    if (anomaly_params.length > 0) {
      this.setState({
        isdeleteParamSel: true,
        delParmIndex: index,
        deletedTask_id: task_id
      });
    }
  };

  continueDelParamSel = (run_id) => {
    const anomaly_params = this.state.anomaly_params || [];
    const client_side_anomaly_load = this.state.client_side_anomaly_load || [];
    let row_uuid = this.state.delParmIndex;
    let taskIdtoDelete = this.state.deletedTask_id;
    var toastId = null;
    toastId = toast("Deleting anomaly parameter", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    if (taskIdtoDelete) {
    
      fetchCall(`/app/api/anomaly/anomaly_task/${run_id}/${taskIdtoDelete}`,'DELETE')
        .then((response) => handleErrors(response))
        .then((resultData) => {
          if (resultData && resultData > 0) {
            let filtered_anomaly_params = anomaly_params.filter((params) => {
              return params && params.task_id !== taskIdtoDelete;
            });
            if (anomaly_params.length == 0) {
              this.setState({});
            }
            this.setState({
              anomaly_params: filtered_anomaly_params,
              isdeleteParamSel: false,
            });
            toast.update(toastId, {
              render: "Deleted Successfully ",
              type: toast.TYPE.SUCCESS,
              autoClose: 1000,
            });
          } else {
            toast.update(toastId, {
              render: "Unable to delete parameters ",
              type: toast.TYPE.ERROR,
              autoClose: 2000,
            });
          }
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "ERROR: " + error.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        });
    } else {
      let filtered_anomaly_params = anomaly_params.filter((params) => {
        return params && params.row_uuid !== row_uuid;
      });
      let filtered_client_side_anomaly_load = client_side_anomaly_load.filter((params) => {
        return params && params.row_uuid !== row_uuid;
      });
      if (anomaly_params.length == 0) {
        this.setState({});
      }
      this.setState({
        anomaly_params: filtered_anomaly_params,
        client_side_anomaly_load: filtered_client_side_anomaly_load,
        isdeleteParamSel: false,
      });
      toast.update(toastId, {
        render: "Deleted Successfully ",
        type: toast.TYPE.SUCCESS,
        autoClose: 1000,
      });
    }
  }
  cancelDeleteParamSelection = () => {
    this.setState({
      isdeleteParamSel: false,
    });
  };
  resetAnomalyParams = () => {
    this.setState({
      client_side_anomaly_load: [],
      anomalyhierarchySelection: {},
      valuesofAnomalyHierarchyselection: [],
      valuesAnomalySelection: [],
      algoType: {},
      userAlgo: [],
      algoData: null,
      algoParamData:null,
      formErrors:[],
      algoParam: {},
      current_algo: "",
      selected_user_algo: {},
      anomaly_pages: "",
      kpi_selection:[],
      dsHierarchySelection:null,
      valuesDSFilterSelection:[],
      valuesofDSHierarchyselection:[],
      dsSummaryResultAllData:[]
    });
  };
  updateAnomalyRunName = (run_name,user_uuid) => {
    let anomalyExpName = localStorage.getItem("anomalyExpName");
    let anomaly_exp_event = {};
    anomaly_exp_event[ANOMALY_EXP_NAME] = run_name;
    anomaly_exp_event[ANOMALY_PROJECT_NAME] = anomalyExpName;
    sendAmplitudeData(ANOMALY_EXPERIMENT, anomaly_exp_event);
    this.setState({
      selected_anomaly_run_name: run_name,
      user_uuid:user_uuid
    });
  };
  openAutoGenerateParamModal = () => {
    let algorithm_family = this.state.algoType
    if (algorithm_family === undefined || Object.keys(algorithm_family).length === 0) {
      const text = "Please select algorithm family";
      this.props.togglePopup(text);
      return;
    }
    if(algorithm_family.value == 'Change Distribution'){
      const text = "Auto Generate Parameters can not be run for 'Change Distribution' Algorithm";
      this.props.togglePopup(text);
      return
    }
    this.setState({
      isAutoGenerateParamOpen: true,
    });
  };
  cancelAutoGenerateParams = () => {
    this.setState({
      isAutoGenerateParamOpen: false,
    });
  };

  auto_generate_anomaly_parameter = (project_id, experiment_id) => {
    let toastId = toast("Auto Generating Parameters", {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    this.setState({
      algo_status: [],
      isAutoGenerateParamOpen: false,
      isLoaderLoading:true
    });
  
    const data =  JSON.stringify({
      algorithm_family: this.state.algoType,
      "hierarchy": this.state.anomalyhierarchySelection,
      "kpi": this.state.kpi_selection,
      "hierarchy_value_selection": this.state.valuesAnomalySelection,
      user_uuid: JSON.parse(this.context.store_user_uuid),
    })
    fetchCall(`/app/api/anomaly/auto_generate/${project_id}/${experiment_id}`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({
          isLoaderLoading:false
        })
        toast.update(toastId, {
          render: "Auto Generate Started Successfully, please check notification for updates.",
          type: toast.TYPE.INFO,
          autoClose: 4000,
        });
      })
      .catch((error) => {
        this.setState({
          isLoaderLoading:false
        })
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });

  };



  cancelDeleteAnomalyExpSelection = () => {
    this.setState({
      isDeleteAnomalyExp: false,
    });
  };
  cancelDeleteAnomalyRunSelection = () => {
    this.setState({
      isDeleteAnomalyRun: false,
    });
  };

  checkSubset = (parentArray, subsetArray) => {
    return subsetArray.every((el) => {
        return parentArray.includes(el)
    })
  }

  handleDSUploadParamsSelections = (value) => {
    let total_pages = 0
    var finishLoadingMainData = false;
    let selected_file = value.target.files[0] || null;
    let client_side_anomaly_load = []
    let pageIndex = this.state.pageIndex || 0
    const pageSize = PAGINATION_RANGE[0]
    if (!selected_file){ 
      return
    }else{
      let uploaded_file_name = selected_file.name
      const filenameRegex = /^[a-z0-9A-Z_\s()-]{1,60}\.csv$/gm
    
      if(!filenameRegex.test(uploaded_file_name)){
        this.props.togglePopup("Invalid file name");
        return
      }
    }
    var toastId = null;
    toastId = toast("uploading anomaly csv file", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    const Papa = require("papaparse/papaparse.min.js");
    Papa.parse(selected_file, {
      worker: true,
      header: true,
      download: true,
      //quoteChar:"'",
      //escapeChar: "'",
      delimiter: "",
      skipEmptyLines: true,
      chunkSize: 1024 * 1024 * 1,
      chunk: (chunks) => {
        const data = chunks.data;
        let resultFirstRow = data[0]
        let anomaly_columns = ["row_uuid", "algorithm", "hierarchy", "value", "algorithm_family", "kpi", "kpi_selection", "parameters"].sort()
          if(this.checkSubset(Object.keys(resultFirstRow),anomaly_columns)){
          client_side_anomaly_load = [...client_side_anomaly_load, ...data]
          total_pages = total_pages + Math.ceil(data.length / pageSize)
          this.setState({
            client_side_anomaly_load: client_side_anomaly_load,
            anomaly_params: client_side_anomaly_load.slice(0, pageSize),
            manualPaginationFlag: true,
            anomaly_pages: total_pages,
          });
          toast.update(toastId, {
            render: "data uploaded Successfully ",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        } else {
          toast.update(toastId, {
            render: "Please upload valid anomaly params file!",
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          const text = "Please upload valid anomaly params file!";
          this.props.togglePopup(text);
          return;
        }

      },
    });
  };

  handleUploadParamsSelections = (value) => {
    let total_pages = 0
    let selected_file = value.target.files[0] || null;
    let client_side_anomaly_load = []
    let pageIndex = this.state.pageIndex || 0
    const pageSize = PAGINATION_RANGE[0]
    if (!selected_file){ 
      return
    }else{
      let uploaded_file_name = selected_file.name
      const filenameRegex = /^[a-z0-9A-Z_\s()-]{1,60}\.csv$/gm
    
      if(!filenameRegex.test(uploaded_file_name)){
        this.props.togglePopup("Invalid file name");
        return
      }
    }
    var toastId = null;
    toastId = toast("uploading anomaly csv file", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    const Papa = require("papaparse/papaparse.min.js");
    Papa.parse(selected_file, {
      worker: true,
      header: true,
      download: true,
      //quoteChar:"'",
      //escapeChar: "'",
      delimiter: "",
      skipEmptyLines: true,
      chunkSize: 1024 * 1024 * 1,
      chunk: (chunks) => {
        const data = chunks.data;
        let resultFirstRow = data[0]
        let anomaly_columns = ["row_uuid", "algorithm", "hierarchy", "value", "algorithm_family", "kpi", "kpi_selection", "parameters"].sort()
        if(this.checkSubset(Object.keys(resultFirstRow),anomaly_columns)){
          client_side_anomaly_load = [...client_side_anomaly_load, ...data]
          total_pages = total_pages + Math.ceil(data.length / pageSize)
          this.setState({
            client_side_anomaly_load: client_side_anomaly_load,
            anomaly_params: client_side_anomaly_load.slice(0, pageSize),
            manualPaginationFlag: true,
            anomaly_pages: total_pages,
          });
          toast.update(toastId, {
            render: "data uploaded Successfully ",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        } else {
          toast.update(toastId, {
            render: "Please upload valid anomaly params file!",
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          const text = "Please upload valid anomaly params file!";
          this.props.togglePopup(text);
          return;
        }

      },
    });
  };
  clearAllSelections = () => {
    this.setState({
      anomalyhierarchySelection: {},
      valuesAnomalySelection: [],
      algoType: {},
      selected_user_algo: {},
      algoData: {},
      algoParamData:{},
      formErrors:[],
      algoParam: {},
      kpi_selection: [],
    })
  }

  fetchAnomalyExpDataSourcesandResetParams = () => {
    let toastId = null;
    toastId = toast("Fetching Projects", { type: toast.TYPE.INFO });
    this.setState({
      isLoaderLoading:true
    })
    Promise.all([
      fetchCall(`/app/api/anomaly/experiment`,'GET')
      .then((result) => result.json()),
      fetchCall(`/app/api/datasources`,'GET')
      .then((result) => result.json()),
    ])
      .then(([experiments, datasources]) => {
        this.setState({
          isLoaderLoading:false
        })
        toast.update(toastId, {
          render: "Projects fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        if (datasources.length > 0) {
          this.setState({
            anomalyExperiments: experiments,
            dataSourcesList: datasources,
            anomalyResults: {},
            algo_status: {},

            anomalyhierarchySelection: {},
            valuesofAnomalyHierarchyselection: [],
            valuesAnomalySelection: [],
            algoType: {},
            userAlgo: [],
            algoData: null,
            formErrors:[],
            algoParam: {},
            current_algo: "",
            selected_user_algo: {},
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoaderLoading:false
        })
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
        });
      });
  };

  saveAnomalyFilesPath = (path) => {
    this.setState({ anomaly_location: path });
  }
  handleUploadAnomalyfilePath = (exp_id, run_id) => {
    let currentAnomalyFilesPath = this.state.anomaly_location
    if (!currentAnomalyFilesPath) {
      const text = "Please select file path";
      this.props.togglePopup(text);
      return;
    }
    let toastId = null;
    toastId = toast("uploading anomaly csv file", { type: toast.TYPE.INFO, autoClose: true });
   
    const data = JSON.stringify({
      user_uuid: JSON.parse(this.context.store_user_uuid),
      anomaly_parameter_file_path: currentAnomalyFilesPath
    })
    fetchCall(`/app/api/anomaly/batch_load/${exp_id}/${run_id}`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        if (resultData == null) {
          toast.update(toastId, {
            render: "File uploaded successfully, Please refresh the page to view results",
            type: toast.TYPE.SUCCESS,
            autoClose: 4000,
          });
        } else {
          toast.update(toastId, {
            render: "ERROR: " + resultData.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  }
  render() {
    const { pipeline, togglePopup } = this.props;
    return (
      <React.Fragment>
        <Switch>
          {
            ['/app/anomaly/experiment', '/app/anomaly/ds/experiment'].map((path) => (
              <Route exact={true} path={path}  render={(props) => (
                <AnomalyExperiment
                {...props}
                pipelines={pipeline}
                dataSourcesList={this.state.dataSourcesList}
                fetchAnomalyExpDataSourcesandResetParams={
                  this.fetchAnomalyExpDataSourcesandResetParams
                }
                handleAddAnomalyExperimentToggle={
                  this.handleAddAnomalyExperimentToggle
                }
                showAddAnomalyExp={this.state.showAddAnomalyExp}
                anomalyExpName={this.state.anomalyExpName}
                saveAnomalyExperiment={this.saveAnomalyExperiment}
                handleNewAnomalyExperiment={this.handleNewAnomalyExperiment}
                handleAnomalyExpCall={this.handleAnomalyExpCall}
                anomalyExperiments={this.state.anomalyExperiments}
                handleAnomalyExperimentDelete={
                  this.handleAnomalyExperimentDelete
                }
                fetchAnomalyExperiments={this.fetchAnomalyExperiments}
                handleAnomalyPipelineMap={this.handleAnomalyPipelineMap}
                togglePopup={togglePopup}
                closeAnomalyExperimentModal={this.closeAnomalyExperimentModal}
                pipeline_id={this.state.pipeline_id}
                resetAnomalyParams={this.resetAnomalyParams}
                isDeleteAnomalyExp={this.state.isDeleteAnomalyExp}
                cancelDeleteAnomalyExpSelection={
                  this.cancelDeleteAnomalyExpSelection
                }
                confirmAnomalyExperimentDelete={
                  this.confirmAnomalyExperimentDelete
                }
                anomalyRunsList={this.state.anomalyRunsList}
                isLoaderLoading={this.state.isLoaderLoading}
                isAdminUser={this.state.isAdminUser}
                handleDataSourceView={this.props.handleDataSourceView}
                isDSConsolidatedViewOpen={this.props.isDSConsolidatedViewOpen}
                closeDSConsolidatedModal={this.props.closeDSConsolidatedModal}
                consolidatedDSDetails={this.props.consolidatedDSDetails}
                nameError={this.state.nameError}
              />
              )} />
            ))
          }
          {['/app/anomaly/experiment/:exp_id', '/app/anomaly/ds/experiment/:exp_id'].map((path) => (
            <Route exact={true} path={path}  render={(props) => (
              <AnomalyRun
                {...props}
                handleAddAnomalyRunToggle={this.handleAddAnomalyRunToggle}
                callAnomalyRun={this.callAnomalyRun}
                anomalyRuns={this.state.anomalyRuns}
                callAnomalyExperiment={this.callAnomalyExperiment}
                anomalyExpName={this.state.anomalyExpName}
                togglePopup={togglePopup}
                handleAnomalyExpCall={this.handleAnomalyExpCall}
                deleteAnomalyRun={this.deleteAnomalyRun}
                pipeline_id={this.state.pipeline_id}
                handleDelAnomalyRun={this.handleDelAnomalyRun}
                updateAnomalyRunName={this.updateAnomalyRunName}
                confirmDeleteAnomalyRun={this.confirmDeleteAnomalyRun}
                isDeleteAnomalyRun={this.state.isDeleteAnomalyRun}
                cancelDeleteAnomalyRunSelection={
                  this.cancelDeleteAnomalyRunSelection
                }
                isAdminUser={this.state.isAdminUser}
                getAlertCount={this.getAlertCount}
              />
            )} />
          ))}
          <Route
            exact={true}
            path="/app/anomaly/experiment/:exp_id/run/:run_id?"
            render={(props) => (
              <AnomalyRunView
                {...props}
                onloadAlgoParams={this.onloadAlgoParams}
                userAlgo={this.state.userAlgo}
                selectMultipleOptionAnomaly={this.selectMultipleOptionAnomaly}
                valueSelection={this.state.valueSelection}
                algoParam={this.state.algoParam}
                algoData={this.state.algoData}
                algoParamData={this.state.algoParamData}
                handleAlgorithmChange={this.handleAlgorithmChange}
                handleAlgorithmTypeChange={this.handleAlgorithmTypeChange}
                anomaly_algo_types={this.state.anomaly_algo_types}
                anomaly_params={
                  this.state.anomaly_params || []
                }
                addSelection={this.addSelection}
                validateAnomalySelections={this.validateAnomalySelections}
                handleKPIChange={this.handleKPIChange}
                removeParameterSel={this.removeParameterSel}
                anomalyResults={this.state.anomalyResults}
                getAnomalyResults={this.getAnomalyResults}
                chartResult={this.state.chartResult}
                handleParamChange={this.handleParamChange}
                exportResult={this.exportResult}
                chart_label={this.state.chart_label}
                anomaly_algo_type={this.state.anomaly_algo_type}
                anomaly_algo_param={this.state.anomaly_algo_param}
                chart_label_obj={this.state.chart_label_obj}
                AnomalyResultModelIsOpen={this.state.AnomalyResultModelIsOpen}
                closeAnomanlyResultModal={this.closeAnomanlyResultModal}
                pipelineName={this.state.pipelineName}
                isdeleteParamSel={this.state.isdeleteParamSel}
                continueDelParamSel={this.continueDelParamSel}
                cancelDeleteParamSelection={this.cancelDeleteParamSelection}
                algo_status={this.state.algo_status}
                current_algo={this.state.current_algo}
                selected_user_algo={this.state.selected_user_algo}
                selectedAnomalyHierarchyVal={
                  this.state.selectedAnomalyHierarchyVal
                }
                chart_level_parents={this.state.chart_level_parents}
                get_anomaly_params={this.get_anomaly_params}
                saveProgress={this.saveProgress}
                hierarchyfileNames={this.state.hierarchyfileNames || []}
                getRun={this.getRun}
                loadDataSourceData={this.loadDataSourceData}
                handleAnomalyHierarchyChange={this.handleAnomalyHierarchyChange}
                valuesofAnomalyHierarchyselection={
                  this.state.valuesofAnomalyHierarchyselection
                }
                valuesAnomalySelection={this.state.valuesAnomalySelection}
                kpiList={this.state.kpiList}
                getAllAnomalyAlgorithms={this.getAllAnomalyAlgorithms}
                handleExecuteRun={this.handleExecuteRun}
                poll_results={this.poll_results}
                auto_generate_anomaly_parameter={
                  this.auto_generate_anomaly_parameter
                }
                algoType={this.state.algoType}
                resetAnomalyParams={this.resetAnomalyParams}
                selected_anomaly_run_name={this.state.selected_anomaly_run_name}
                updateAnomalyRunName={this.updateAnomalyRunName}
                togglePopup={togglePopup}
                anomalyRuns={this.state.anomalyRuns}
                handleUploadParamsSelections={this.handleUploadParamsSelections}
                rerun_all={this.state.rerun_all}
                changePointRmse={this.changePointRmse}
                rerunAllAlgo={this.rerunAllAlgo}
                updateAnomalyParams={this.updateAnomalyParams}
                isAutoGenerateParamOpen={this.state.isAutoGenerateParamOpen}
                openAutoGenerateParamModal={this.openAutoGenerateParamModal}
                cancelAutoGenerateParams={this.cancelAutoGenerateParams}
                getAnomalyParams={this.getAnomalyParams}
                totalAnomalyPages={this.state.anomaly_pages || 0}
                anomaly_params_loading_flag={
                  this.state.anomaly_params_loading_flag
                }
                isClearAllSelectionModalOpen={this.state.isClearAllSelectionModalOpen}
                manualPaginationFlag={this.state.manualPaginationFlag}
                handleAutoParameterFamilyChange={this.handleAutoParameterFamilyChange}
                anomaly_family_to_auto_generate={this.state.anomaly_family_to_auto_generate}
                updateAnomalyPages={this.updateAnomalyPages}
                anomalyPageSize={PAGINATION_RANGE[0]}
                anomalyhierarchySelection={this.state.anomalyhierarchySelection}
                clearAllSelections={this.clearAllSelections}
                time_column={this.state.time_column}
                kpi_selection={this.state.kpi_selection}
                saveAnomalyFilesPath={this.saveAnomalyFilesPath}
                handleUploadAnomalyfilePath={this.handleUploadAnomalyfilePath}
                data_to_export={this.state.client_side_anomaly_load}
                isLoaderLoading={this.state.isLoaderLoading}
                isAdminUser={this.state.isAdminUser}
                user_uuid={this.state.user_uuid}
                alert_report={this.state.alert_report}
                pipeline_id={this.state.pipeline_id}
                store_ds_id={this.state.store_ds_id}
                store_ds_name={this.state.store_ds_name}
              />
            )}
          />
          <Route
            exact={true}
            path="/app/anomaly/ds/experiment/:exp_id/run/:run_id?"
            render={(props) => (
              <DescriptiveSummaryRunView
                {...props}
                onloadAlgoParams={this.onloadAlgoParams}
                userAlgo={this.state.userAlgo}
                handleDSHierarchyValueChange={this.handleDSHierarchyValueChange}
                valueSelection={this.state.valueSelection}
                algoParam={this.state.algoParam}
                algoParamData={this.state.algoParamData}
                handleAlgorithmChange={this.handleAlgorithmChange}
                handleAlgorithmTypeChange={this.handleAlgorithmTypeChange}
                anomaly_algo_types={this.state.anomaly_algo_types}
                anomaly_params={
                  this.state.anomaly_params || []
                }
                validateAnomalySelections={this.validateAnomalySelections}
                handleKPIChange={this.handleKPIChange}
                removeParameterSel={this.removeParameterSel}
                anomalyResults={this.state.anomalyResults}
                getAnomalyResults={this.getAnomalyResults}
                handleParamChange={this.handleParamChange}
                exprotDescriptiveResults={this.exprotDescriptiveResults}
                anomaly_algo_type={this.state.anomaly_algo_type}
                chart_label_obj={this.state.chart_label_obj}
                AnomalyResultModelIsOpen={this.state.AnomalyResultModelIsOpen}
                closeAnomanlyResultModal={this.closeAnomanlyResultModal}
                pipelineName={this.state.pipelineName}
                isdeleteParamSel={this.state.isdeleteParamSel}
                continueDelParamSel={this.continueDelParamSel}
                cancelDeleteParamSelection={this.cancelDeleteParamSelection}
                current_algo={this.state.current_algo}
                selected_user_algo={this.state.selected_user_algo}
                chart_level_parents={this.state.chart_level_parents}
                get_anomaly_params={this.get_anomaly_params}
                saveProgress={this.saveProgress}
                hierarchyfileNames={this.state.hierarchyfileNames || []}
                getRun={this.getRun}
                loadDataSourceData={this.loadDataSourceData}
                handleDSHierarchyChange={this.handleDSHierarchyChange}
                valuesofDSHierarchyselection={
                  this.state.valuesofDSHierarchyselection
                }
                valuesAnomalySelection={this.state.valuesAnomalySelection}
                valuesDSFilterSelection={this.state.valuesDSFilterSelection}
                kpiList={this.state.kpiList}
                getAllAnomalyAlgorithms={this.getAllAnomalyAlgorithms}
                handleDSExecuteRun={this.handleDSExecuteRun}
                poll_results={this.poll_results}
                auto_generate_anomaly_parameter={
                  this.auto_generate_anomaly_parameter
                }
                algoType={this.state.algoType}
                resetAnomalyParams={this.resetAnomalyParams}
                selected_anomaly_run_name={this.state.selected_anomaly_run_name}
                updateAnomalyRunName={this.updateAnomalyRunName}
                togglePopup={togglePopup}
                anomalyRuns={this.state.anomalyRuns}
                handleDSUploadParamsSelections={this.handleDSUploadParamsSelections}
                rerun_all={this.state.rerun_all}
                changePointRmse={this.changePointRmse}
                rerunAllAlgo={this.rerunAllAlgo}
                updateAnomalyParams={this.updateAnomalyParams}
                isAutoGenerateParamOpen={this.state.isAutoGenerateParamOpen}
                openAutoGenerateParamModal={this.openAutoGenerateParamModal}
                cancelAutoGenerateParams={this.cancelAutoGenerateParams}
                getAnomalyParams={this.getAnomalyParams}
                totalAnomalyPages={this.state.anomaly_pages || 0}
                anomaly_params_loading_flag={
                  this.state.anomaly_params_loading_flag
                }
                isClearAllSelectionModalOpen={this.state.isClearAllSelectionModalOpen}
                manualPaginationFlag={this.state.manualPaginationFlag}
                handleAutoParameterFamilyChange={this.handleAutoParameterFamilyChange}
                anomaly_family_to_auto_generate={this.state.anomaly_family_to_auto_generate}
                updateAnomalyPages={this.updateAnomalyPages}
                anomalyPageSize={PAGINATION_RANGE[0]}
                dsHierarchySelection={this.state.dsHierarchySelection}
                time_column={this.state.time_column}
                kpi_selection={this.state.kpi_selection}
                saveAnomalyFilesPath={this.saveAnomalyFilesPath}
                handleUploadAnomalyfilePath={this.handleUploadAnomalyfilePath}
                data_to_export={this.state.client_side_anomaly_load}
                isLoaderLoading={this.state.isLoaderLoading}
                isAdminUser={this.state.isAdminUser}
                user_uuid={this.state.user_uuid}
                alert_report={this.state.alert_report}
                pipeline_id={this.state.pipeline_id}
                store_ds_id={this.state.store_ds_id}
                store_ds_name={this.state.store_ds_name}
                moduleType={this.state.moduleType}
                level_hierarcy_values_obj_selectedArr={this.state.level_hierarcy_values_obj_selectedArr}
                validateDSSelections={this.validateDSSelections}
                dsSummaryResultData={this.state.dsSummaryResultData}
                dsSummaryResultAllData={this.state.dsSummaryResultAllData}
                isExportAllModelOpen={this.state.isExportAllModelOpen}
                closeExportModal={this.closeExportModal}
                ds_hierarchy_label={this.state.ds_hierarchy_label}
              />
            )}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Anomaly;

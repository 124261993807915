import React from 'react';
import './popup.css';
import Modal from "react-modal";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        width: "50%",
        padding: "0x",
    },
};

export class Popup extends React.Component {
    render() {
        const { text, closePopup } = this.props;
        return (
            <Modal
                isOpen={true}
                onRequestClose={closePopup}
                style={customStyles}
                contentLabel="alert"
                ariaHideApp={false}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4"> Alert</div>
                        <button
                            type="button"
                            className="close"
                            onClick={closePopup}
                        >
                            <i className="fa fa-close"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="alert-content">
                            <h4>{text}</h4>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default Popup;
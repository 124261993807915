import React from 'react';
import CsvDownloader from 'react-csv-downloader';
import './ReactCsvDownloader.css';

export class ReactCsvDownloader extends React.Component {
  render() {
    const {
      csvColumns,
      data,
      csvLabelText,
      csvFileName,
      wrapColumnChar,
      separator
    } = this.props;
    return (
      <>
        <CsvDownloader
          filename={csvFileName}
          separator={separator}
          //wrapColumnChar={wrapColumnChar}
          columns={csvColumns}
          datas={data}
          text={csvLabelText}
        >
          <button className='btn btn-sm btn-success'>
            <i className='fa fa-download'></i> &nbsp; <b>Download</b> &nbsp;{' '}
            <i class='fa fa-file-excel-o' aria-hidden='true'></i>{' '}
          </button>
        </CsvDownloader>
        {/* <div className='csv_download btn btn-sm btn-success'>
          <i className='fa fa-download'></i>
          <CsvDownloader
            filename={csvFileName}
            separator={separator}
            //wrapColumnChar={wrapColumnChar}
            columns={csvColumns}
            datas={data}
            text={csvLabelText}
          />
          <i class='fa fa-file-excel-o' aria-hidden='true'></i>
        </div> */}
      </>
    );
  }
}

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { DataSourceList } from "./DataSourceComponents/DataSourceList";
import { DATASOURCE_INIT_STATE, CONFIG_MS, NAME_ERROR } from "../consts";
import { toast } from "react-toastify";
import { handleErrors, isInputNameValidated } from "./ui_utils";
import { DataIngestion } from "./DataSourceComponents/DataIngestion";
import Upload from "./Upload";
import { UploadIncremental } from "./DataSourceComponents/UploadIncremental";
import { sendAmplitudeData } from "./util/amplitude";
import { DATA_SOURCE, DATASOURCE_NAME } from "./util/consts_amplitude";
import { fetchCall, requestWithHashDigest } from "./util/util";
import AppContext from "./context/AppContext";

export class DataSource extends Component {
  constructor(props) {
    super(props);
    this.state = DATASOURCE_INIT_STATE;
  }
  
  static contextType = AppContext
  componentDidMount = () => {
      this.props.fetchCausalExperiments();
      this.props.fetchAnomalyExperiments();
  };
  fetchDataSource = () => {
    this.setState({
      isLoaderLoading:true,
    })
    let toastId = null;
    toastId = toast("Fetching Data Sources", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/datasources`,"GET")
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({
          dataSources: resultData,
          isLoaderLoading:false
        });
        toast.update(toastId, {
          render: "Data Source fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        return resultData;
      })
      .catch((error) => {
        this.setState({
          isLoaderLoading:false
        });
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  handleErrors = (response) => {
    if (!response.ok) {
      if (response.status == "409") {
        return response.json().then(function (err) {
          err.message = "Duplicates not allowed";
          throw new Error(err.message);
        });
      } else {
        return response.json().then(function (err) {
          throw new Error(err.message);
        });
      }
    }
    return response.json();
  };
  handleAddDataSourceToggle = () => {
    let flag = this.state.showAddDatasource;
    this.setState({
      showAddDatasource: !flag,
      datasourceName: "",
      nameError:""
    });
  };

  handleNewDataSource = (e) => {
    let name = e.target.value;
    this.setState({
      datasourceName: name,
    });
  };

  saveDataSource = () => {
    let cdatasourceName = this.state.datasourceName;
    if(!isInputNameValidated(cdatasourceName)){
      this.setState({
        nameError:NAME_ERROR
      })
      return;
    }else {
      let toastId = null;
      toastId = toast("Fetching Data Sources", { type: toast.TYPE.INFO });
      let request_body = {
        display_name: cdatasourceName,
        user_uuid: JSON.parse(this.context.store_user_uuid),
        status: "",
        level_table_map: {},
        anomaly_summary_chart_payload: {},
        hierarchy_combination: {},
        user_columns: {},
        upload_state: {},
        state: {},
        anomaly_state: {},
        causal_state: {},
        current_state: {},
        last_ingestion: "",
        datasource_meta: {},
      }

      const request_body_with_digest = requestWithHashDigest(request_body)

      fetchCall(`/app/api/datasource`,"POST", JSON.stringify(request_body_with_digest))
        .then((response) => handleErrors(response))
        .then((resultData) => {
          this.setState({
            dataSources: [...this.state.dataSources, resultData],
            showAddDatasource: false,
            nameError:""
          });
          toast.update(toastId, {
            render: "Data Source fetched successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
          let datasourceEvent = {};
          datasourceEvent[DATASOURCE_NAME] = resultData.display_name;
          sendAmplitudeData(DATA_SOURCE, datasourceEvent);
          return resultData;
        })

        .catch((error) => {
          toast.update(toastId, {
            render: "ERROR: " + error.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        });
    }
  };

  closeDataSourceModal = () => {
    this.setState({
      showAddDatasource: false,
    });
  };
  captchaInputChange = (status) => {
    this.setState({ captchaSuccess: status })
  }
  deleteDataSource = (delDataSourceObj) => {
    let dataSources = this.state.dataSources;
    let anomaly_experiments = this.props.anomalyExprimentsRCA
    let causal_experiments = this.props.causalExprimentsRCA
    let linked_anomaly_experiments =  anomaly_experiments.filter((exp)=>{return exp.data_source_id == delDataSourceObj.pipeline_uuid})
    let linked_causal_experiments =   causal_experiments.filter((exp)=>{return exp.data_source_id == delDataSourceObj.pipeline_uuid})
    if (dataSources.length > 0) {
      this.setState({
        delDataSourceIsOpen: true,
        delDataSourceId: delDataSourceObj.pipeline_uuid,
        delDataSourceObj,
        linked_anomaly_experiments,
        linked_causal_experiments
      });
    }
  };
  
  setIngestionRunIncremental = (ds) => {
    this.setState({
      activeIngestionModalIsOpen:true,
      activeDataSourceIdForIngestion:ds.datasource_uuid,
      activeIngestionId:ds.ingestion_uuid
    })
  }
  closeSetIngestionIncremental = (ds) => {
    this.setState({
      activeIngestionModalIsOpen:false,
      activeDataSourceIdForIngestion:null,
      activeIngestionId:null
    })
  }

  setActiveDataSource = (ds) => {
    this.setState({
      activeDataSourceModalIsOpen:true,
      activeDataSourceId:ds.pipeline_uuid
    })
  }
  closeSetActiveDataSource = () => {
    this.setState({
      activeDataSourceModalIsOpen:false,
      activeDataSourceId:null
    })
  }
  confirmSetActiveDataSource = () => {
    let toastId = null;
    let id = this.state.activeDataSourceId;
    toastId = toast("Setting Active Datasource.", {
      type: toast.TYPE.INFO,
    });
    fetchCall(`/app/api/datasource/${id}`,'PATCH')
      .then((result) => result.json())
      .then((result) => {        
        if (result) {
          this.setState({
            dataSources: result,
            activeDataSourceModalIsOpen: false,
            datasourceName: "",
            activeDataSourceId: null,
          });
          toast.update(toastId, {
            render: "Datasource updated successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      })

      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  confirmDeleteDataSource = () => {
    let selectedDataSource = this.state.dataSources;
    let toastId = null;
    let id = this.state.delDataSourceId;
    toastId = toast("Deleting Pipline. Please wait...", {
      type: toast.TYPE.INFO,
    });
    fetchCall(`/app/api/datasource/${id}`,'DELETE')
      .then((result) => handleErrors(result))
      .then((result) => {
        if (result) {
          selectedDataSource = selectedDataSource.filter(
            (obj) => obj.pipeline_uuid !== id
          );
          this.setState({
            dataSources: selectedDataSource,
            delDataSourceIsOpen: false,
            datasourceName: "",
            delDataSourceId: null,
          });
          toast.update(toastId, {
            render: "Deleted successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      })

      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  closeDeleteDataSourceModal = () => {
    this.setState({
      delDataSourceIsOpen: false,
    });
    return;
  };

  confirmIngestionIncrementalRun = () => {
    let toastId = null;
    let ds_id = this.state.activeDataSourceIdForIngestion;
    let ingestion_id = this.state.activeIngestionId
    toastId = toast("Setting Active Datasource.", {
    type: toast.TYPE.INFO,
    });
    fetchCall(`/app/api/graph/incremental/${ds_id}/${ingestion_id}`,'POST')
      .then((result) => result.json())
      .then((result) => {        
        if (result) {
          this.setState({
            activeIngestionModalIsOpen: false,
            activeIngestionId: null,
            });
        toast.update(toastId, {
        render: "Intiated Ingestion incremental run ",
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
        });
        }
       })
      .catch((error) => {
        toast.update(toastId, {
        render: "ERROR: " + error.message,
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        });
    });
  };
  

  handleFetchDataSource = (dataSourceObj) => {
    let toastId = null;
    toastId = toast("Fetching Data Sources", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/datasource/${dataSourceObj.pipeline_uuid}`,"GET")
      .then((response) => handleErrors(response))
      .then((resultData) => {
        localStorage.setItem("currentDataSourceName", dataSourceObj.display_name)
        this.setState({
          currentDataSourceName: dataSourceObj.display_name,
          currentDataSourceId: dataSourceObj.pipeline_uuid,
          current_state: resultData.data_source_type,
          data_source_status: resultData.data_source_status,
          user_columns: resultData.user_columns,
          data_source_payload: resultData,
        });
        toast.update(toastId, {
          render: "Data Source fetched successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.props.history.push(`/app/datasource/${dataSourceObj.pipeline_uuid}`);
      })

      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handleAddDataIngestion = () => {
    this.setState({
      showAddIngestionModal: true,
    });
  };
  showIncrementalUpload = (id) => {
    this.setState({
      showAddIngestionModal: false,
    });
    this.props.history.push(`/app/datasource/${id}/incremental`);
  };
  handleFetchIngestion = (ingestionObj) => {
    let toastId = null;
    toastId = toast("Fetching Ingestion. Please wait...", {
      type: toast.TYPE.INFO,
    });
    fetchCall(`/app/api/datasource/${ingestionObj.datasource_uuid}/ingestion/${ingestionObj.ingestion_uuid}`, "GET")
      .then((result) => result.json())
      .then((resultData) => {
        if (resultData) {
          let current_ingestion_status = {}
          current_ingestion_status[resultData.ingestion_uuid] = [resultData.ingestion_type , resultData.ingestion_status]
          
          toast.update(toastId, {
            render: "fetched successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
          this.props.history.push({
            state: {
              ingestion_status_obj: current_ingestion_status,
              isLoaderLoading:false
            },
            pathname: `/app/datasource/${ingestionObj.datasource_uuid}/ingestion/${ingestionObj.ingestion_uuid}/upload`
          },

          );
        }
      })

      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });    
  };
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            exact={true}
            path="/app/datasource/"
            render={(props) => (
              <DataSourceList
                {...props}
                handleAddDataSourceToggle={this.handleAddDataSourceToggle}
                showAddDatasource={this.state.showAddDatasource}
                handleNewDataSource={this.handleNewDataSource}
                datasourceName={this.state.datasourceName}
                saveDataSource={this.saveDataSource}
                closeDataSourceModal={this.closeDataSourceModal}
                fetchDataSource={this.fetchDataSource}
                dataSources={this.state.dataSources}
                delDataSourceId={this.state.delDataSourceId}
                delDataSourceIsOpen={this.state.delDataSourceIsOpen}
                closeDeleteDataSourceModal={this.closeDeleteDataSourceModal}
                continueDelDataSource={this.continueDelDataSource}
                deleteDataSource={this.deleteDataSource}
                handleFetchDataSource={this.handleFetchDataSource}
                confirmDeleteDataSource={this.confirmDeleteDataSource}
                isLoaderLoading={this.state.isLoaderLoading}
                captchaInputChange={this.captchaInputChange}
                captchaSuccess={this.state.captchaSuccess}
                anomalyExprimentsRCA={this.props.anomalyExprimentsRCA}
                linked_causal_experiments={this.state.linked_causal_experiments}
                linked_anomaly_experiments={this.state.linked_anomaly_experiments}
                isAdminUser={this.state.isAdminUser}
                setActiveDataSource = {this.setActiveDataSource}
                confirmSetActiveDataSource={this.confirmSetActiveDataSource}
                activeDataSourceModalIsOpen={this.state.activeDataSourceModalIsOpen}
                closeSetActiveDataSource={this.closeSetActiveDataSource}
                nameError={this.state.nameError}
              />
            )}
          ></Route>
          <Route
            exact={true}
            path="/app/datasource/:datasource_id"
            render={(props) => (
              <DataIngestion
                {...props}
                handleAddDataIngestion={this.handleAddDataIngestion}
                currentDataSourceName={this.state.currentDataSourceName}
                currentDataSourceId={this.state.currentDataSourceId}
                showIncrementalUpload={this.showIncrementalUpload}
                showAddIngestionModal={this.state.showAddIngestionModal}
                dataSources={this.state.dataSources}
                current_state={this.state.current_state}
                deleteDataIngestion={this.deleteDataIngestion}
                togglePopup={this.props.togglePopup}
                handleFetchIngestion={this.handleFetchIngestion}
                isAdminUser={this.state.isAdminUser}
                setIngestionRunIncremental={this.setIngestionRunIncremental}
                closeSetIngestionIncremental={this.closeSetIngestionIncremental}
                activeIngestionModalIsOpen={this.state.activeIngestionModalIsOpen}
                confirmIngestionIncrementalRun={this.confirmIngestionIncrementalRun}
              />
            )}
          ></Route>
          <Route
            exact={true}
            path="/app/datasource/:datasource_id/ingestion/:ingestion_id/upload"
            render={(props) => (
              <Upload
                {...props}
                currentDataSourceName={this.state.currentDataSourceName}
                currentDataSourceId={this.state.currentDataSourceId}
                togglePopup={this.props.togglePopup}
                data_source_status={this.state.data_source_status}
                user_columns={this.state.user_columns}
                data_source_payload={this.state.data_source_payload}
              />
            )}
          ></Route>
          <Route
            exact={true}
            path="/app/datasource/:datasource_id/incremental"
            render={(props) => (
              <UploadIncremental
                {...props}
                currentDataSourceName={this.state.currentDataSourceName}
                handleIncrementalFiles={this.handleIncrementalFiles}
                togglePopup={this.props.togglePopup}
              />
            )}
          ></Route>
        </Switch>
      </React.Fragment>
    );
  }
}
export default DataSource;

import React, { Component } from "react";
import { toast } from "react-toastify";
import { CONFIG_MS, UPLOAD_INCREMENTAL_STATE } from "../../consts";
import { fetchCall, groupBy } from "../util/util";

import "./UploadIncremental.css";

export class UploadIncremental extends Component {
  constructor(props) {
    super(props);
    this.state = UPLOAD_INCREMENTAL_STATE;
  }
  componentDidMount() {
    const data_source_id = this.props.match.params.datasource_id;
    this.handleDataSourceCall(data_source_id);
  }

  handleDataSourceCall = (id) => {
    localStorage.setItem("pipeline_uuid", JSON.stringify(id));
    let toastId = null;
    toastId = toast("Loading pipeline", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/datasource/${id}`,'GET')
      .then((result) => result.json())
      .then((resultData) => {
        const resp = JSON.parse(resultData.upload_state);
        const current_pipeline_state = resultData.current_state;
        const currentDataSourceName = resultData.display_name;
        this.setState({
          ...resp,
          currentDataSourceId: id,
          current_pipeline_state,
          currentDataSourceName,
        });
        toast.dismiss(toastId);
        this.props.history.push(`/app/datasource/${id}/incremental`);
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handleIncrementalFiles = (label, file) => {
    let hierarchyDataMap = this.state.hierarchyDataMap;
    let currentHierarchy = hierarchyDataMap.filter(
      (val) => val.label === label
    );
    currentHierarchy = currentHierarchy[0];
    let selected_file = file.target.files[0] || null;
    if (!selected_file) return;
    const time_col = this.state.time_panel_value.label;
    const kpi_names = [];
    this.state.kpi_selected_panel_values.forEach((item) => {
      kpi_names.push(item.label);
    });

    let cols = [];
    let hybrid = currentHierarchy.actual_label.split("-**-");
    if (hybrid.length > 1) {
      cols.push(...hybrid.map((items) => items.split("-*-")));
    } else {
      cols.push(...hybrid[0].split("-*-"));
    }
    const Papa = require("papaparse/papaparse.min.js");
    Papa.parse(selected_file, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: (result, file) => {
        const data = result.data;

        data.map((row) => row[currentHierarchy.label]);
        let columns = Object.keys(data[0]);
        let kpi_tested = [];
        kpi_names.every((kpi) => {
          let flag = columns.includes(kpi);
          if (!flag) {
            kpi_tested.push(kpi);
          }
          return flag;
        });
        if (kpi_tested.length > 0) {
          const text = `Please check if the mentioned KPI are available across: ${kpi_tested}`;
          this.props.togglePopup(text);
          return;
        }

        if (currentHierarchy.actual_label.includes("-**-")) {
          currentHierarchy["incremental_payload"] = file;
          currentHierarchy["incremental_filename"] = file.name;
          return;
        } else {
          if (
            columns.indexOf("Overall") !== -1 &&
            currentHierarchy.actual_label !== "Overall"
          ) {
            currentHierarchy["incremental_filename"] = "";
            const text = `Please check if correct hierarchy files are uploaded. \n No duplicate entries should be present for a given date & Hierarchy`;
            this.props.togglePopup(text);
            return;
          }
          let v_count = groupBy(data, [time_col], currentHierarchy.label);
          Object.keys(v_count).every((obj) => {
            if (
              v_count &&
              !v_count[obj][0][0] &&
              v_count[obj][0].size !== v_count[obj][1]
            ) {
              currentHierarchy["incremental_filename"] = "";
              const text = `Please check if correct hierarchy files are uploaded. \n No duplicate entries should be present for a given date & Hierarchy`;
              this.props.togglePopup(text);
              this.handleIncrementalHierarchyDataMap();
              return;
            } else {
              currentHierarchy["incremental_filename"] = file.name;
              currentHierarchy["incremental_payload"] = file;
              this.handleIncrementalHierarchyDataMap();
              return;
            }
          });
        }
      },
    });
  };
  handleErrors = (response) => {
    if (response == null) {
      toast("Unable to Upload Incremental Data", { type: toast.TYPE.ERROR });
      throw new Error("Unable to Upload Incremental Data");
    } else if (!response.ok) {
      return response.json().then(function (err) {
        throw new Error(err.message);
      });
    }
    return response.json();
  };
  uploadIncrementalFiles = () => {
    let hierarchyDataMap = this.state.hierarchyDataMap;
    const hierarchyWithData = hierarchyDataMap.filter((hierarchyData) => {
      return Object.keys(hierarchyData).includes("incremental_payload");
    });
    if (hierarchyWithData.length !== hierarchyDataMap.length) {
      const text = "Please select all files to upload";
      this.props.togglePopup(text);
      return;
    } else {
      let toastId = null;
      toastId = toast("Uploading Data. Please wait..", {
        type: toast.TYPE.INFO,
        autoClose: true,
      });
      let data = new FormData();
      data.append("pipeline", this.props.match.params.datasource_id);
      data.append("user_uuid", JSON.parse(localStorage.getItem("uid")));
      Object.keys(hierarchyDataMap).forEach((key) => {
        data.append(
          hierarchyDataMap[key].label,
          hierarchyDataMap[key].incremental_payload
        );
      });
 
      fetchCall(`/app/api/upload/incremental_upload`,'POST',data)
        .then((response) => this.handleErrors(response))
        .then((resultData) => {
          const pipeline = resultData;
          const uploadDone = true;
          toast.update(toastId, {
            render: "Data Uploaded Successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
            progress: 1,
          });
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "ERROR: " + error.message,
            type: toast.TYPE.ERROR,
          });
        });
    }
  };
  handleIncrementalUploadedFileEdit = (obj) => {
    obj.incremental_filename = "";
    obj.incremental_payload = {};
    this.handleIncrementalHierarchyDataMap();
    return;
  };
  handleIncrementalHierarchyDataMap = () => {
    let hierarchyDataMap = this.state.hierarchyDataMap;
    if (hierarchyDataMap.length == 1) {
      let cuurenthierarchyDataMap = [
        { split_hierarchy: "Overall", actual: "Overall" },
      ]; //{ "split_hierarchy": "overall", "actual":  "overall" }
      const hierarchySetPanels = this.state.hierarchySetPanels;
      const hierarchySetKeyObjData = this.state.hierarchySetKeyObjData;
      const keys = Object.keys(hierarchySetPanels).filter(
        (obj) => !obj.includes("MasterPanel")
      );
      let hierarchyCombination = this.state.hierarchyCombination;
      for (let h_set in hierarchyCombination) {
        for (let i = 0; i < hierarchyCombination[h_set].length; i++) {
          if (i === 0) {
            let h_val = {
              split_hierarchy: hierarchyCombination[h_set][i],
              actual: hierarchyCombination[h_set][i],
            };
            cuurenthierarchyDataMap.push(h_val);
          } else {
            let act_hval = hierarchyCombination[h_set].slice(0, i + 1);
            let h_val = {
              split_hierarchy: act_hval[act_hval.length - 1],
              actual: act_hval.join("-*-"),
            };
            cuurenthierarchyDataMap.push(h_val);
          }
        }
      }
      cuurenthierarchyDataMap = cuurenthierarchyDataMap.map((obj) => {
        return { label: obj.split_hierarchy, actual_label: obj.actual };
      });
      this.setState({
        hierarchyDataMap: cuurenthierarchyDataMap,
      });
    } else {
      hierarchyDataMap.map((obj) => {
        if (obj.hasOwnProperty("filename")) {
          this.setState({
            hierarchyDataMap,
          });
        }
      });
    }
  };

  runIncremental = () => {
    let toastId = null;
    let data = new FormData();
    data.append("pipeline", this.props.match.params.datasource_id);
    toastId = toast("Running incremental job. Please wait..", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
 
    fetchCall( `/app/api/algorithm/incremental/run/${this.props.match.params.datasource_id}`,'POST',data)
      .then((response) => this.handleErrors(response))
      .then((resultData) => {
        toast.update(toastId, {
          render: "Dashboard refreshed Successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
          //progress: 1
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
        });
      });
  };

  render() {
    const { uploadIncrementalFiles, runIncremental } = this.props;
    let incrementalDataMap = this.state.hierarchyDataMap;

    if (
      incrementalDataMap.some((obj) => {
        return obj.incremental_payload;
      })
    ) {
      console.log("increment load");
    }
    return (
      <div>
        <React.Fragment>
          <div className="back-btn">
            <button
              onClick={(e) =>
                this.props.history.push(
                  `/app/datasource/${this.props.match.params.datasource_id}`
                )
              }
              className="btn btn-sm btn-primary back-btn"
            >
              <i className="fa fa-arrow-left"></i> Back
            </button>
          </div>
        </React.Fragment>
        <React.Fragment>
          <div className="breadcrumb_heading">
            <label>
              Data Source : {this.state.currentDataSourceName}
            </label>
          </div>
        </React.Fragment>
        <h4>Upload Incremental Data</h4>
        <hr></hr>
        <br></br>
        <div className="datamap-container">
          <div className="hierarchy-data table-header">
            <div className="h-row">Plain Hierarchy</div>
            <div className="h-file">Incremental Hierarchy File</div>
          </div>
          {this.state.hierarchyDataMap.length > 0 &&
            this.state.hierarchyDataMap.map((obj, i) => {
              if (
                obj.hasOwnProperty("incremental_filename") &&
                obj.incremental_filename !== ""
              ) {
                return (
                  <div className="hierarchy-data" key={i}>
                    <div className="h-row">
                      {obj.actual_label} [ {obj.label} ]
                    </div>
                    <div className="h-file">
                      Uploaded File: <b> {obj.filename} </b>
                      <span>
                        <a
                          onClick={(e) =>
                            this.handleIncrementalUploadedFileEdit(obj)
                          }
                        >
                          edit
                        </a>
                      </span>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="hierarchy-data" key={i}>
                    <div className="h-row">
                      {obj.actual_label} [ {obj.label} ]
                    </div>
                    <div className="h-file">
                      <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={(e) =>
                          this.handleIncrementalFiles(obj.label, e)
                        }
                        required
                      />
                    </div>
                  </div>
                );
              }
            })}
        </div>
        <div className="incremental_run_btns">
          <button
            className="btn btn-sm btn-primary run-inc"
            onClick={this.runIncremental}
          >
            Run Incremental
          </button>
          <button
            className="btn btn-sm btn-primary upload-inc"
            onClick={this.uploadIncrementalFiles}
          >
            Upload
          </button>
        </div>
      </div>
    );
  }
}

import React from "react";
import { toast } from "react-toastify";
import { CAUSAL_EXPERIMENT_STATE } from "../../consts";
import "./CausalRun.css";
import Modal from "react-modal";
import ReactDynamicTable from "../ReactDynamicTable";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import LoaderComponent from "../util/Loader";
import { fetchCall, validate_decoded_auth_token } from "../util/util";

const confirmDeleteModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
export class CausalExperiment extends React.Component {
  constructor(props) {
    super(props);
    this.state = CAUSAL_EXPERIMENT_STATE;
  }
  componentDidMount() {
    this.props.handleCausalExpCall(
      this.props.match.params.exp_id,
      localStorage.getItem("causalExpName"),
      localStorage.getItem("causalPipeline"),
      localStorage.getItem("dataSourceName")
    );
  }

  handleDelCausalRun = (id) => {
    let causalRuns = this.state.causalRuns;
    let toastId = null;
    toastId = toast("Deleting run. Please wait...", { type: toast.TYPE.INFO });
    fetchCall(`/app/api/causal/run/${id}`,'DELETE')
      .then((result) => result.json())
      .then((result) => {
        if (result) {
          toast.update(toastId, {
            render: "Deleted successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      })

      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  render() {
    const decoded_auth_token =  validate_decoded_auth_token()

    const columns = [
      {
        Header: "S No",
        accessor: "sNo",
        width:"5%",
        disableFilters: true,
      },
      {
        Header: "Experiment Name",
        accessor: "experimentName",
        width:"25%",
      },
      {
        Header: "Created On",
        accessor: "createdOn",
        width:"25%",
      },
      {
        Header: "Status",
        accessor: "status",
        width:"25%",
      },
      {
        Header: "View",
        accessor: "view",
        width:"10%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view">
              <a onClick={(e) =>
                this.props.handleCausalRunCall(value.run_id,
                  value.run_name)
              }>
                <i className="fa fa-eye"></i>
              </a>
            </div>
          );
        }
      },
      {
        Header: "Delete",
        accessor: "delete",
        width:"10%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="delete">
              {
                 value.user_uuid == decoded_auth_token || this.props.isAdminUser ?
                 <a onClick={(e) =>
                  this.props.handleDelCausalRun(value.run_id)
                }
                >
                   <i className="far fa-trash-alt"></i>
                </a> :
                 <div className="ds-tooltip">
                    <a className="disabled" onClick={(e) =>
                      this.props.handleDelCausalRun(value.run_id)
                    }
                    >
                      <i className="far fa-trash-alt"></i>
                    </a>
                    <span class="ds-tooltiptext">You don't have permission to remove this Experiment</span>
                  </div>
              }
            </div>
          );
        }
      }
    ]
    const data = this.props.causalRuns && this.props.causalRuns.map((item, index) => {
      return {
        sNo: index + 1,
        experimentName: item.run_name,
        createdOn: item.created_date.slice(0, 16),
        status: item.run_status,
        delete: item,
        view: item
      }
    })
    const {
      causalExpName,
      causalRuns,
      handleAddCausalRunToggle,
      handleDelCausalRun,
      isDeleteCausalRun,
      cancelDeleteCausalRunSelection,
      confirmDeleteCausalRun,
    } = this.props;

    return (
      <div>
        <button
          onClick={(e) => this.props.history.push(`/app/causal/experiment/`)}
          className="btn btn-sm btn-primary back-btn"
        >
          <i className="fa fa-arrow-left"></i> Back
        </button>
        <div className="row causal_runs_container">
          <div className="causal_exp_heading">
            <span className="heading">Causal Experiments</span>
          </div>
          <div className="causal-project-heading_container">
            <div className="causal-project-heading">
              <span>
                Project Name : {localStorage.getItem("causalExpName")}{" "}
              </span>
              <span>(Data Source: {this.props.data_source_name})</span>
            </div>
            <div className="project-add-run">
              <input
                type="button"
                className="btn btn-sm add-btn"
                onClick={() => handleAddCausalRunToggle()}
                value="Add Experiment"
              ></input>
            </div>
          </div>
          <ReactDynamicTable
            columns={columns}
            data={data}
          />
        </div>
        <Modal
          isOpen={isDeleteCausalRun}
          style={confirmDeleteModal}
          onRequestClose={cancelDeleteCausalRunSelection}
          contentLabel="Result"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div class="modal-header">
              <div class="modal-title h4">Delete Experiment</div>
              <button
                type="button"
                class="close"
                onClick={cancelDeleteCausalRunSelection}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li className="form-column">
                  <p className="text">
                    Are you sure want to delete the selected Experiment ?
                  </p>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={confirmDeleteCausalRun}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={cancelDeleteCausalRunSelection}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <LoaderComponent isLoaderLoading={this.props.isLoaderLoading}/>
      </div>
    );
  }
}

import React from "react";
import "./HierarchyDataMap.css";

export class HierarchyDataMap extends React.Component {
  componentDidMount() {
  }
  render() {
    return (
      <div>
      
      </div>
    );
  }
}

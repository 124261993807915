import React from "react";
import Modal from "react-modal";
import { CAUSAL_PROJECT_STATE } from "../../consts";
import "./CausalExperiment.css";
import ReactDynamicTable from "../ReactDynamicTable";
import Select from "react-select";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import LoaderComponent from "../util/Loader";
import { ConsolidatedDataSourceView } from "../util/Modal/ConsolidatedDataSourceView";
import { validate_decoded_auth_token } from "../util/util";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
const confirmDeleteModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
export class CausalProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = CAUSAL_PROJECT_STATE;
  }

  componentDidMount() {
    this.props.fetchCausalExperiments();
    this.props.fetchDataSources();
  }

  // handleCausalPipelineMap = e => {
  //   console.log(e);
  //   const pipeline_uuid = e.target.value;
  //   localStorage.setItem("causalPipeline", JSON.stringify(pipeline_uuid));
  //   this.setState({
  //     causalPipeline: pipeline_uuid
  //   });
  // };
  render() {
    const decoded_auth_token =  validate_decoded_auth_token()
    const columns = [
      {
        Header: "S No",
        accessor: "serialNumber",
        width:"5%",
        disableFilters: true,
      },
      {
        Header: "Project Name",
        accessor: "experimentName",
        width:"15%",
      },
      {
        Header: "Data Source",
        accessor: "datasourceName",
        width:"15%",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view">
              <a onClick={() => this.props.handleDataSourceView(value.data_source_id)}>
                  {value.data_source_name}
              </a>
            </div>
          );
        }
      },
      {
        Header: "Created On",
        accessor: "createdDate",
        width:"25%",
      },
      {
        Header: "Status",
        accessor: "status",
        width:"20%",
      },
      {
        Header: "View",
        accessor: "view",
        width:"10%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view">
              <a onClick={() => this.props.handleCausalExpCall(value.experiment_id,
                value.experiment_name,
                value.data_source_id,
                value.data_source_name)}>
                <i className="fa fa-eye"></i>
              </a>
            </div>
          );
        }
      },
      {
        Header: "Delete",
        accessor: "delete",
        width:"10%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="delete">
              {
                 value.user_id == decoded_auth_token || this.props.isAdminUser ?
                 <a onClick={(e) =>
                  this.props.handleCausalExpDelete(value.experiment_id)
                }
                >
                   <i className="far fa-trash-alt"></i>
                </a> :
                 <div className="ds-tooltip">
                    <a className="disabled" onClick={(e) =>
                      this.props.handleCausalExpDelete(value.experiment_id)
                    }
                    >
                      <i className="far fa-trash-alt"></i>
                    </a>
                    <span class="ds-tooltiptext">You don't have permission to remove this Project</span>
                  </div>
              }
            </div>
          );
        }
      }
    ]
    const data = this.props.causalExperiments &&
      this.props.causalExperiments.map((item, index) => {
        return {
          serialNumber: index + 1,
          experimentName: item.experiment_name,
          datasourceName: item,
          createdDate: item.created_date.slice(0, 16),
          status: item.experiment_status,
          type: item.type,
          view: item,
          delete: item
        }
      })
    const {
      pipelines,
      causalExperiments,
      closeCausalProjectModal,
      showAddCusalExp,
      handleAddCausalExpToggle,
      saveCausalProject,
      handleNewCausalProject,
      handleCausalPipelineMap,
      isDeleteCausalExp,
      confirmCausalExpDelete,
      cancelDeleteCausalExpSelection,
    } = this.props;

    if (pipelines && pipelines.length > 0) {
      var current_datasources =
        pipelines.length > 0 &&
        pipelines.filter((val) => {
          return val.data_source_status == "INCREMENTAL INGESTION SUCCESS" || val.data_source_status == "INITIAL INGESTION SUCCESS";
     
        });
    }
    if (current_datasources && current_datasources.length > 0) {
      var pipeline_option =
        current_datasources.length > 0 &&
        current_datasources.map((pipeline, i) => {
          return {
            value: pipeline.pipeline_uuid,
            label: pipeline.display_name,
          };
        });
    }

    let display_causal_linked_runs = this.props.causalRunsList && this.props.causalRunsList.map((item)=>{
      return (<div>{item.run_name}</div>)
    }) 

    return (
      <div>
        <div className="row">
          <div className="text-btn-align">
            <span className="heading">Causal Projects</span>
            <input
              type="button"
              className="btn btn-sm add-btn"
              onClick={() => handleAddCausalExpToggle()}
              value="Add Project"
            ></input>
          </div>
          <ReactDynamicTable
            columns={columns}
            data={data}
          />
        </div>
        <Modal
          isOpen={showAddCusalExp}
          onRequestClose={closeCausalProjectModal}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          contentLabel="Add New Project"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4"> Add Causal Project</div>
              <button
                type="button"
                className="close"
                onClick={closeCausalProjectModal}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li className="form-row">
                  <label htmlFor="pipeline_name">
                    <span>&#10033;</span>Project Name:
                  </label>
                  <input
                    type="text"
                    autoComplete={"off"}
                    onChange={(e) => handleNewCausalProject(e)}
                  />
                </li>
                <li className="form-row">
                  <label htmlFor="pipeline_list">
                    <span>&#10033;</span>Data Source:
                  </label>
                  <div className="data-source-select">
                    <Select
                      maxMenuHeight={100}
                      options={pipeline_option}
                      onChange={handleCausalPipelineMap}
                    />
                  </div>
                  {/*  <select
                    id="pipeline_list"
                    onChange={e => handleCausalPipelineMap(e)}
                  >
                    <option value="" selected>
                      Select a Pipeline
                    </option>
                    {pipeline_option}
                  </select> */}
                </li>
                <li className="error-row">
                      <label></label>
                      <div className="error-msg">{this.props.nameError}</div>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={(e) => saveCausalProject()}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={() => closeCausalProjectModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={isDeleteCausalExp}
          style={confirmDeleteModal}
          onRequestClose={cancelDeleteCausalExpSelection}
          contentLabel="Result"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div class="modal-header">
              <div class="modal-title h4">Delete Project</div>
              <button
                type="button"
                class="close"
                onClick={cancelDeleteCausalExpSelection}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li className="form-column">
                  <p className="text">
                    Are you sure want to delete the selected project ? <br/>
                    {this.props.causalRunsList && this.props.causalRunsList.length > 0 ?<>
                      <p>
                      Following Experiments are linked to this project!
                    </p>
                     <div className="ds-linked-projects">
                       <div className="anomaly-projects margin-auto">
                         <div class="text-left">
                          <p><b>Causal Experiments:</b></p>
                           {display_causal_linked_runs}
                         </div>
                       </div>
                     </div>
                     </>
                     : ""
                   }
                  </p>
                 
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={confirmCausalExpDelete}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={cancelDeleteCausalExpSelection}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <LoaderComponent isLoaderLoading={this.props.isLoaderLoading}/>
        <ConsolidatedDataSourceView
            isDSConsolidatedViewOpen={this.props.isDSConsolidatedViewOpen}
            closeDSConsolidatedModal={this.props.closeDSConsolidatedModal}
            consolidatedDSDetails={this.props.consolidatedDSDetails}
        />
      </div>
    );
  }
}

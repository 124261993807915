import React, { Component } from 'react';
import './FAQ.css';
import exceldata from '../assets/faqDocs/exceldata.jpg';
import faq3 from '../assets/faqDocs/faq3.jpg';
import faq2 from '../assets/faqDocs/faq2.jpg';
import faq4 from '../assets/faqDocs/faq4.jpg';
import faq5 from '../assets/faqDocs/faq5.jpg';
import faq6 from '../assets/faqDocs/faq6.jpg';
import faq7 from '../assets/faqDocs/faq7.jpg';
 import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion'; 

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
export class FAQ extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <Accordion allowZeroExpanded = {true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              1.What is format of data to be uploaded in first upload screen?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>The first upload screen should be inputted with the most granular data (Which will contain columns for Time, Dimensions and Hierarchies along with the KPI’s used) in the form of csv. </p>
            <p>For example: <span><br /></span>
              The granular data can look like this, where we have 4 Hierarchies-Retailer, Category (with dimensions being Category, Segment and Product), Brand and States (with dimensions being States and Zone) along with the KPIs (Markdown Dollars, Dollar Sales, Unit Sales, Volume Sales, Stat Case Volume).
            </p>
            <img className="faq-exceldata-img" src={exceldata} />
            <p>In current setup only file with header will also work however user can upload most granular file. In future when tool have ETL functionality most granular data will be aggregated at desired level of hierarchies. </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              2.What should be date format for time column?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>The data has to be in the “dd-mm-yyyy” or “dd-mm-yyyy HH:MM:SS” format. For example: ‘23-10-2016’ or ’23-10-2016 12:10:03’.</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              3.Can I use discrete variables in KPIs?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>No, the current version deals only with continuous variables. But will soon be supported in the forthcoming versions.</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              4.What is hierarchy? Why do I need to define hierarchy?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>Hierarchy is basically the business hierarchies by which the data is dissected for analysis. It is provided so that the data is analysed at all those hierarchies and the Root Cause Analysis can pin point the source of an anomaly with high precision.</p>
            <p>For example: A sales data can be dissected into Retailer level, Product level and State 	level.</p>
            <p>1) Product level corresponds to the product information along with the category and 	segment information. Here, Product would be the granular level in its hierarchy followed by 	segment and category. All the aforementioned levels belong to one hierarchy.</p>
            <p>2) Retailer level pertains to any facts or figures that you can collect about your retail 	business. So, Retailer is a hierarchy.</p>
            <p>3) State level corresponds to the region information which include state and zone level of 	the data. Here, Zone (Granular level of this hierarchy) could be the something like Bengaluru 	or Mysuru while State would be Karnataka. Hence, state and zone belong to one hierarchy</p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              5.What is simple hierarchy vs what is hybrid hierarchy?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>A simple hierarchy (only one hierarchy) can just be a simple dimension or a combination of dimensions. For example, Category, Segment and Product are three dimensions of a simple hierarchy.
              </p><p>  A hybrid hierarchy is a combination of multiple such simple hierarchies and inside each hierarchy, there may or may not be multiple dimensions.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              6.How are time, dimension and KPIs different?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>Time column gives the dates or times corresponding to the date or time at which the metrics were recorded. So each data point should have a time component attached to it.
                Dimensions are the different levels present within a simple hierarchical data.
                KPIs (Key Performance Indicators) are the metrics that are used for analysing the data. This may contain information such as units sold, growth in revenue, percentage of market share etc.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              7.What is input data format to upload data by hierarchy?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>The input data should contain the Time, Corresponding level and the KPIs associated to the data. The following is the data for Product level which should essentially contain the dimensions above it (Here its category and segment)</p>
            <p>
              <img src={faq2} />
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              8.What does overall level mean in anomaly detection vs causal model?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>Overall level means data aggregated at topmost level.<br />
              Anomaly detection finds the anomaly at topmost level.<br />
              Causal model finds the relationship between the KPI at that level.<br />
              Below is the format for Overall Level.
            </p>
            <p>
              <img width="1000" src={faq3} />
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              9.How to create hybrid hierarchy?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>Upload the files and segregate the Hierarchies, Time and KPI columns. The next page corresponds to Hierarchy Creation. In that page, first define the number of sets/hierarchies that are present in the uploaded data.
            </p>
            <p>
              <img width="1000" src={faq4} />
            </p>
            <p>
              If the data is of one hierarchy, then provide 1.<br />
              If the data is of hybrid hierarchy, then provide the number of hierarchies present in the data.
              faq
            </p>
            <p>
              <img width="1000" src={faq5} />
            </p>
            <p>
              Here, sets are parallel hierarchies i.e. they can be used to analyse data independently. For example, Geography can be one sets which contains Region, States and Cities while other set can be related to product hierarchy i.e. Product Category, Product Segment and Product. Within sets there is always hierarchy and one to many mappings from top to bottom i.e. one segment cannot belong to multiple categories while one category can have multiple segments
            </p>
            <p>
              <b>
                If business hierarchy is Category > Segment > Product then Category should be first entry in sets box, segment being second while product being third.
              </b>
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              10.How do I configure anomaly detection algorithm for KPIs? Can I select different algorithm for same KPI for different level of hierarchy?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              <img width="1000" src={faq6} />
            </p>
            <p>
              Select hierarchy and hierarchy value and KPI (if you want to run Point based anomaly algorithm for sales KPI in bleach Category then select Category in hierarchy, Bleach in Values, Sales inn KPI, Point based anomaly from algorithm family and algorithm. Enter parameter and add current selection
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              11.I have to detect trend, which type of algorithm can be suitable?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>After the file uploads, In the anomaly tab there’s a drop down for the choice of type of anomaly. </p>
            <p>
              <img width="1000" src={faq7} />
            </p>
            <p>Choose Trend Detection under algorithm type and Trend under algorithm. The parameters for the same are to be provided in the adjacent cells.
               </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    )
  }
}

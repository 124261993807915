import React from "react";
import Picky from "react-picky";

export class CustomSelectBox extends React.Component {

  render() {
    const {
      selectBoxHeight,
      multiSelectOptions,
      onChangeValues,
      selectedValues,
      isDisabled,
      isMultiple,
      isOpen,
      id,valueKey,labelKey
    } = this.props;
    return (
      <div>
        <Picky
          value={selectedValues}
          options={multiSelectOptions}
          onChange={(e) => onChangeValues(e)}
          open={isOpen}
          valueKey={valueKey}
          labelKey={labelKey}
          multiple={isMultiple}
          includeSelectAll={true}
          includeFilter={true}
          dropdownHeight={selectBoxHeight}
          disabled={isDisabled}
          id={id}
        />
      </div>
    );
  }
}

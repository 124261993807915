import React, { Component } from "react";
import * as d3 from "d3";
import "./LineChart.css";
import { roundOffDecimal } from "../util/util";

export class LineChart extends Component {
  componentDidMount() {
    this.createLineChart(
      this.props.data,
      this.props.chart_label,
      this.props.lineLegend,
      this.props.time_column
    );
  }

  componentDidUpdate() {
    // this.createLineChart(
    //   this.props.data,
    //   this.props.chart_label,
    //   this.props.lineLegend,
    //   this.props.time_column
    // );
  }

  createLineChart = (data_all, label_a, lineLegend,time_column="date") => {
    const label = label_a.replace(/ /g, "_");
    const add_lables = ["_upper", "_lower"];
    var categories = [];
    let all_points = [];
    categories.push(label);
    let data = data_all.filter((row) => {
      if (
        row[label + add_lables[0]] !== null &&
        row[label + add_lables[1]] !== null
      ) {
        if (!categories.includes(label + add_lables[0]))
          categories.push(label + add_lables[0]);
        if (!categories.includes(label + add_lables[1]))
          categories.push(label + add_lables[1]);
        all_points.push(row[label + add_lables[0]]);
        all_points.push(row[label + add_lables[1]]);
      }
      all_points.push(row[label]);
      return row[label] !== null;
    });
    if (categories.length > 1) {
      data = data.filter((rows) => {
        return (
          rows[label + add_lables[0]] !== null ||
          rows[label + add_lables[1]] !== null
        );
      });
    }

    // d3.select(`#anomaly-chart`).remove();
    var margin = { top: 20, right: 90, bottom: 20, left: 120 },
      width = 1000,
      height = 450 - margin.top - margin.bottom;

    var div = d3
      .select(`body`)
      .append(`div`)
      .attr("class", `tooltip-anomaly`)
      .style("display", "none");

    var svg = d3
      .select(`.a-chart-wrapper`)
      .append("svg")
      .attr("width", width)
      .style("margin-top", "30px")
      .attr("height", height + margin.top + margin.bottom)
      .attr("id", `anomaly-chart${this.props.id}`);
     

    var x = d3.scaleTime().rangeRound([0, width - margin.left - margin.right]);
    var x_axis = d3.axisBottom(x);
    var y = d3.scaleLinear().rangeRound([height, 0]);
    var y_axis = d3.axisBottom(y);

    var xFormat = "%Y-%m-%d";
    var parseTime = d3.timeParse("%Y-%m-%d");
    x.domain(
      d3.extent(data, function (d) {
        return parseTime(d[time_column]);
      })
    );

    const min_global = d3.min(all_points);

    const max_global = d3.max(all_points);
    y.domain([min_global, max_global]).range([
      height - margin.bottom,
      margin.top,
    ]);
    var a = function (d) {
      return d[label];
    };
    var multiline = function (category) {
      var line = d3
        .line()
        .x(function (d) {
          return x(parseTime(d[time_column]));
        })
        .y(function (d) {
          return y(d[category]);
        });

      return line;
    };

    var g = svg
      .append("g")
      .attr("class", "everything")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    g.append("text")
      .attr("x", 500)
      .attr("y", 0 - margin.top / 2)
      .attr("text-anchor", "left")
      .style("font-size", "14px")
      .text(lineLegend);

    for (var i in categories) {
      var lineFunction = multiline(categories[i]);
      g.append("path")
        .datum(data)

        .attr("class", function (d) {
          if (categories[i].includes("upper")) {
            return "upper";
          } else if (categories[i].includes("lower")) {
            return "lower";
          } else {
            return "primary";
          }
        })
        .attr("d", lineFunction);
    }
    g.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickFormat(d3.timeFormat(xFormat)));

    g.append("text")
      .style("font-size", "14px")
      .attr("text-anchor", "start")
      .attr(
        "transform",
        "translate(" +
          (width - margin.left - margin.right) +
          "," +
          (height + margin.bottom) +
          ")"
      )
      .text("Date");

    g.append("g").call(d3.axisLeft(y));

    g.append("text")
      .style("font-size", "14px")
      .attr("text-anchor", "left")
      .attr("transform", "rotate(0)")
      .attr("transform", "translate(0 ,-10)")
      .text(label);

    g.selectAll(".dot")
      .data(data)
      .enter()
      .append("circle")
      .style("display", function (d) {
        if (d[label + "_outlier"] == 1) {
          return "";
        } else {
          return "none";
        }
      })
      .style("fill", "red")
      .attr("class", "dot")
      .attr("cx", function (d, i) {
        return x(parseTime(d[time_column]));
      })
      .attr("cy", function (d) {
        return y(d[label]);
      })
      .attr("r", 3)
      .on("mouseover", function (d) {
        div.transition().duration(200).style("display", "block");
        div
          .html(d[time_column] + "<br/>" + roundOffDecimal(d[label]))
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("mouseout", function (d) {
        div.transition().duration(500).style("display", "none");
      });
    svg.call(
      d3.zoom().on("zoom", function () {
        g.attr("transform", d3.event.transform);
      })
    );
  };

  render() {
    return <div className={`a-chart-wrapper`}></div>;
  }
}

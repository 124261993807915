import React from "react";
import "react-picky/dist/picky.css";
import Form from "react-jsonschema-form";
import Modal from "react-modal";
import { CAUSAL_RESULT_STATE, CONFIG_MS, NAME_ERROR } from "../../consts";
import { toast } from "react-toastify";
import "./CausalResult.css";
import { handleErrors, isInputNameValidated } from "../ui_utils";
import { HierarchyValueDropDown } from "../HierarchyValueDropDown";
import Select from "react-select";
import { DAG } from "./DAG";
import { DebounceInput } from "react-debounce-input";
import { CausalReactTableComponent } from "../CausalReactTableComponent";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import "./CreateCausalGraph.css";
import { sendAmplitudeData } from "../util/amplitude";
import {
  CAUSAL_REPLICATE,
  CAUSAL_EXPERIMENT,
  CAUSAL_EXP_NAME,
  CAUSAL_PROJECT_NAME,
} from "../util/consts_amplitude";
import { CustomSelectBox } from "../CustomSelectBox";
import { ReactCsvDownloader } from "../CsvDownloader/ReactCsvDownloader";
import { fetchCall, validate_decoded_auth_token, requestWithHashDigest } from "../util/util";

var _loadash = require("lodash");

let validation_toast_id = "validate_lags";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "auto",
    padding: "0px",
  },
};
const causalResult = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    padding: "0px",
  },
};
const causalGraph = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "90%",
    padding: "0px",
  },
};
const edgesModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "60%",
    padding: "0px",
  },
};
const causalRun = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
const kpiRowAdd = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "70%",
    padding: "0px",
  },
};
const confirmDelete = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
export class CreateCausalGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = CAUSAL_RESULT_STATE;
  }
  componentDidMount() {
    if (
      typeof this.props.match.params.run_id === "undefined" ||
      this.props.match.params.run_id === "undefined"
    ) {
      let flag = this.state.showCausalSaveRun;
      this.setState({
        showCausalSaveRun: !flag,
      });
    }
    this.props.getRun(this.props.match.params.run_id);
    this.props.loadDataSourceData(this.props.match.params.exp_id);
    this.getAllCausalAlgorithms();
    this.resetCausalEdges();
  }
  componentWillUnmount() {
    this.props.updateHierarchyValues();
  }
  resetCausalEdges = () => {
    this.setState({
      causalUserEdges: [],
      causalDiscardEdges: [],
      causalFixedEdges: [],
    });
  };
  getAllCausalAlgorithms = () => {
    fetchCall(`/app/api/algorithm/causal`,'GET')
      .then((result) => result.json())
      .then((resultData) => {
        this.setState({ causalAlgoList: resultData });
      })
      .catch((error) => {
        toast("ERROR: " + error.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  loadCausalEstimation = (exp_id, run_id) => {
    this.props.history.push(
      `/app/causal/experiment/${exp_id}/run/${run_id}/estimation`
    );
  };
  uploadCausalScreen = (exp_id, run_id) => {
    this.props.history.push(
      `/app/causal/experiment/${exp_id}/run/${run_id}/upload`
    );
  };
  updateCausalGraphEdge = (exp_id, run_id) => {
    let toastId = null;
    toastId = toast("Updating Graph Edges. Please wait...", {
      type: toast.TYPE.INFO,
    });
    const data = JSON.stringify({
      experiment_id: exp_id,
      user_id: JSON.parse(localStorage.getItem("uid")),
      selected_causal_graph: this.state.selected_causal_graph,
      causal_edges: this.state.selected_causal_graph_result,
    })
    fetchCall(`/app/api/causal/update_graph/${exp_id}/${run_id}`,'POST',data)
      .then((result) => result.json())
      .then((result) => {
        if (result) {
          toast.update(toastId, {
            render: "Graph Updated successfully",
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        }
      })

      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  handlekpiAutoCorrelation = (kpi, e) => {
    let ckpiAutoCorr = this.state.kpiAutoCorr;
    if (ckpiAutoCorr.length > 0 && ckpiAutoCorr.includes(kpi)) {
      ckpiAutoCorr = ckpiAutoCorr.filter((item) => {
        return kpi.name !== item.name;
      });
    } else {
      ckpiAutoCorr.push(kpi);
    }
    this.setState({
      kpiAutoCorr: ckpiAutoCorr,
    });
  };

  handleNewCausalRun = (e) => {
    let name = e.target.value;
    this.setState({
      causalRunName: name,
    });
  };
  handleAddKpiRowForGrpah = () => {
    let selectCausalEdgeValue = this.state.from_to_kpi_obj_for_graph;
    let selectedToEdge = this.state.to_kpi_selection;
    let selectedFromEdge = this.state.from_kpi_selction;
    let selected_causal_graph_result = this.state.selected_causal_graph_result;
    if (selectedToEdge == undefined || selectedFromEdge == undefined) {
      const text = "select all combinations!";
      this.props.togglePopup(text);
      return;
    }
    let duplicate;
    let lowerCaseSelectCausalEdgeValue = {};
    lowerCaseSelectCausalEdgeValue[
      `from_kpi`
    ] = selectCausalEdgeValue.from_kpi.toLowerCase().split(" ").join("_");
    lowerCaseSelectCausalEdgeValue[
      `to_kpi`
    ] = selectCausalEdgeValue.to_kpi.toLowerCase().split(" ").join("_");
    lowerCaseSelectCausalEdgeValue[`lag`] = selectCausalEdgeValue.lag;
    selected_causal_graph_result.length > 0 &&
      selected_causal_graph_result.map((obj) => {
        if (
          obj.from_kpi == lowerCaseSelectCausalEdgeValue.from_kpi &&
          obj.to_kpi == lowerCaseSelectCausalEdgeValue.to_kpi &&
          (obj.lag === JSON.parse(lowerCaseSelectCausalEdgeValue.lag) ||
            obj.lag === lowerCaseSelectCausalEdgeValue.lag)
        ) {
          duplicate = true;
          return;
        }
      });
    if (duplicate) {
      const text = "combination already exists!";
      this.props.togglePopup(text);
      return;
    } else {
      lowerCaseSelectCausalEdgeValue["update_code"] = 1;
      selected_causal_graph_result.push(lowerCaseSelectCausalEdgeValue);
    }
    this.setState({
      from_to_kpi_obj_for_graph: {},
      to_kpi_selection: null,
      from_kpi_selction: null,
      showNewFromToKPi: false,
    });
  };
  closeCausalFromToKPIModal = () => {
    this.setState({
      showNewFromToKPi: false,
    });
  };
  handleNewFromToKpi = () => {
    let resultkpis = this.state.selected_causal_graph_result;
    let flag = this.state.showNewFromToKPi;
    this.setState({
      showNewFromToKPi: !flag,
      to_kpi_selection: null,
      from_kpi_selction: null,
    });
  };
  handleSaveCausalRun = () => {
    let run_name = this.state.causalRunName;
    let current_causal_params = this.props.causal_params;
    run_name = run_name.trim();
    let causalRuns = this.props.causalRuns;
    let duplicateName = causalRuns.some((item) => {
      return (
        item.run_name.toLocaleLowerCase().split(" ").join("") ===
        run_name.toLocaleLowerCase().split(" ").join("")
      );
    });
    if(!isInputNameValidated(run_name)){
      this.setState({
        nameError:NAME_ERROR
      })
      return;
    }else if (duplicateName) {
      const text = "Experiment name already exist!";
      this.props.togglePopup(text);
      return;
    }
    if (current_causal_params.length > 0) {
      current_causal_params.forEach((obj) => {
        obj.task_id = null;
      });
    }
    let toastId = null;
    toastId = toast("Creating Experiment", { type: toast.TYPE.INFO });
    let data = {
      experiment_id: this.props.match.params.exp_id,
      user_id: JSON.parse(localStorage.getItem("uid")),
      run_name: this.state.causalRunName,
      run_parameters: current_causal_params,
      run_selection: {},
    }

    const request_body_with_digest = requestWithHashDigest(data)

    fetchCall(`/app/api/causal/run`,'POST',JSON.stringify(request_body_with_digest))
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({
          showCausalSaveRun: false
        });
        let causalExpName = localStorage.getItem("causalExpName");
        let causal_exp_event = {};
        causal_exp_event[CAUSAL_EXP_NAME] = run_name;
        causal_exp_event[CAUSAL_PROJECT_NAME] = causalExpName;
        sendAmplitudeData(CAUSAL_EXPERIMENT, causal_exp_event);
        this.props.updateCausalRunName(resultData.run_name,resultData.user_uuid);
        this.props.history.push(
          `/app/causal/experiment/${this.props.match.params.exp_id}/run/${resultData.run_id}`
        );
        this.props.saveProgress(
          resultData.run_id
        );
        toast.update(toastId, {
          render: "Causal experiment created successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  handleSwapKPis = (index) => {
    let resultKpis = this.state.selected_causal_graph_result;
    let selectedObj = resultKpis[index];
    let tokpi = selectedObj["to_kpi"];
    let fromkpi = selectedObj["from_kpi"];
    let lag = selectedObj["lag"];
    let swapObj = {};
    swapObj["to_kpi"] = fromkpi;
    swapObj["from_kpi"] = tokpi;
    swapObj["lag"] = lag;

    swapObj["update_code"] = 2;
    resultKpis[index] = swapObj;
    this.setState({
      selected_causal_graph_result: resultKpis,
    });
  };
  removeFromToKPi = (index) => {
    let resultkpis = this.state.selected_causal_graph_result;
    let removedSelection = resultkpis[index];
    removedSelection["update_code"] = -1;
    resultkpis[index] = removedSelection;
    this.setState({
      selected_causal_graph_result: resultkpis,
    });
  };
  handleCausalAlgoChange = (item) => {
    let paramObj = this.state.causalAlgoList.filter(
      (obj) => obj.algorithm_name === item.value
    );
    this.setState({
      causalAlgoParam: JSON.parse(paramObj[0].algorithm_parameters),
      causalAlgoData: JSON.parse(paramObj[0].algorithm_defaults),
      current_causal_algo: paramObj[0].algorithm_name,
      causal_algo_display_name: item,
    });
  };
  handleCausalParamChange = (form_event) => {
    if (Object.keys(form_event.formData).includes("file")) {
      let selected_file = form_event.formData.file;
      const Papa = require("papaparse/papaparse.min.js");
      Papa.parse(selected_file, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: (result, file = file) => {
          const causalFileData = result.data;
          this.setState({
            causalAlgoData: causalFileData,
          });
        },
      });
    } else {
      this.setState({
        causalAlgoData: form_event.formData,
      });
    }
  };
  addCausalParamSelection = () => {
    let causal_params = _loadash.clone(this.props.causal_params);
    let causalhierarchySelection;
    let calculated_row_index = 0;
    if (this.props.causalhierarchySelection) {
      causalhierarchySelection = this.props.causalhierarchySelection.value;
    } else {
      const text = "Please Select Hierarchy Name";
      this.props.togglePopup(text);
      return;
    }
    let causalvalueSelection = this.props.selectedValuesOfCausalHierarchy;
    if (causalvalueSelection.length === 0) {
      const text = "Please Select Hierarchy values";
      this.props.togglePopup(text);
      return;
    }
    let causalAlgo = this.state.current_causal_algo;
    let causal_algo_display_name = this.state.causal_algo_display_name;
    if (
      causal_algo_display_name.label === undefined ||
      causal_algo_display_name.label === ""
    ) {
      const text = "Please Select Algorithm";
      this.props.togglePopup(text);
      return;
    }
    let kpiErrorFlag = false;
    if (this.state.valueskpiSelection.length > 0) {

      let kpiTypeMap = this.props.kpiTypeMap;
      var currentCausalKpis = this.state.valueskpiSelection.map((item) => {
        return item.name;
      });
      var causalKpiTypes = {};
      currentCausalKpis.forEach((kpi) => {
        return causalKpiTypes[kpi] = "c"
      });
      if (Object.keys(causalKpiTypes).length == 1) {
        kpiErrorFlag = true;
      }
    } else {
      kpiErrorFlag = true;
    }
    if (kpiErrorFlag) {
      const text = "Please Select Kpi and  It should have minimum two kpis";
      this.props.togglePopup(text);
      return;
    }

    let causalUserEdges = this.state.causalUserEdges;
    let causalDiscardEdges = this.state.causalDiscardEdges;
    let causalFixedEdges = this.state.causalFixedEdges;
    let kpiLagVal = this.state.kpiLagValList;
    for (var val in kpiLagVal) {
      if (kpiLagVal[val] === "") {
        delete kpiLagVal[val];
      }
    }
    let auto_c = this.state.kpiAutoCorr;
    let replicate_from = "";
    let causalResults = this.props.causalResults;
    let auto_c_flag = false;
    if (auto_c.length > 0) {
      auto_c.forEach((item) => {
        let kpiValues = Object.keys(kpiLagVal);
        if (!kpiValues.includes(item.name)) {
          auto_c_flag = true;
          const text =
            "Please add Lag value for all selected auto correlated kpis";
          this.props.togglePopup(text);
          return;
        }
      });
    }
    if (auto_c_flag) {
      return;
    }
    let causalDiscardEdgesList = causalDiscardEdges.map((obj, index) => {
      return <div>{JSON.stringify(obj, null, "    ")}</div>;
    });

    let causaluserEdgesList = causalUserEdges.map((obj, index) => {
      return <div>{JSON.stringify(obj)}</div>;
    });

    let causalfixedEdgesList = causalFixedEdges.map((obj, index) => {
      return <div>{JSON.stringify(obj)}</div>;
    });
    let kpiLagDisplay = [];
    Object.entries(kpiLagVal).forEach(([key, value]) => {
      kpiLagDisplay.push(`${key}: ${value}`);
    });

    let params = this.state.causalAlgoData;

    if (Object.keys(params).length === 0) {
      const text = "Please select the File or Params  ";
      this.props.togglePopup(text);
      return;
    }

    if (causal_params.length > 0) {
      let set = causal_params.map((obj, i) => {
        return obj.causalhierarchySelection.concat(
          ",",
          obj.causalvalueSelection,
          ",",
          obj.causal_algo
        );
      });
      var cur_val = set;
    }
    let prev_val = cur_val;

    let bulk_load_flag = this.state.bulk_load_flag;
    let noOfSelectionsFlag = false
    let noOfValuesSelection = 0
    if (bulk_load_flag === "") {
      const text = "Please select type of load  ";
      this.props.togglePopup(text);
      return;
    } else if (bulk_load_flag === true) {
      let hierarchyValues = causalvalueSelection.map((item) => {
        return item.name;
      });
      let final_str = causalhierarchySelection.concat(
        ",",
        hierarchyValues.join(",")
      );
      let filteredCausalParams = causal_params.filter((obj) => {
        return obj.bulk_load === true;
      });
      let is_exist = false;
      if (filteredCausalParams.length > 0) {
        filteredCausalParams.forEach((params_obj) => {
          let combination_to_compare = [
            params_obj.causalhierarchySelection,
            params_obj.causalvalueSelection,
          ];
          let combination_to_compare_str = combination_to_compare.join(",");
          if (combination_to_compare_str.indexOf(final_str) >= 0) {
            const text = "Some combination already exists in selection!";
            is_exist = true;
            this.props.togglePopup(text);
            return;
          }
        });
      }
      let normalLoadParams = causal_params.filter((obj) => {
        return obj.bulk_load === false;
      });
      if (normalLoadParams.length > 0) {
        let bulkLoadValues = [];
        hierarchyValues.map((item) => {
          let hier_val = causalhierarchySelection.concat(",", item);
          if (
            normalLoadParams.some(
              (params_obj) =>
                params_obj.causalhierarchySelection.concat(
                  ",",
                  params_obj.causalvalueSelection
                ) === hier_val
            )
          ) {
            const text = "Some combination already exists in selection!";
            //is_exist = true;
            this.props.togglePopup(text);
            return;
          } else {
            return bulkLoadValues.push(item);
          }
        });
        if (bulkLoadValues.length > 0) {
          hierarchyValues = bulkLoadValues;
        } else {
          return;
        }
      }
      if (is_exist) {
        return;
      } else {
        if (causal_params.length > 0) {
          calculated_row_index =
            parseInt(causal_params[causal_params.length - 1].row_index) + 1;
        }
        noOfSelectionsFlag = true
        noOfValuesSelection = 1
        causal_params.push({
          causalhierarchySelection: causalhierarchySelection,
          causalvalueSelection: hierarchyValues.join("||"),
          params_causal: JSON.stringify(params),
          causal_algo: causalAlgo,
          causaluserEdges: _loadash.clone(causalUserEdges),
          causaldiscardEdges: _loadash.clone(causalDiscardEdges),
          causalfixedEdges: _loadash.clone(causalFixedEdges),
          kpiList: JSON.stringify(causalKpiTypes),
          kpiLagVals: JSON.stringify(kpiLagVal),
          auto_c: auto_c,
          replicate_from: "",
          task_id: null,
          selected_causal_algo_label: causal_algo_display_name.label,
          bulk_load: bulk_load_flag,
          row_index: calculated_row_index,
        });
        this.props.updateCausalParams(causal_params);
      }
    } else {
      causalvalueSelection.forEach((obj) => {
        let is_exist = false;
        let final_str = causalhierarchySelection.concat(",", obj.name);
        const task_key = causalhierarchySelection
          .concat("_", obj.name)
          .toLowerCase();
        let task_id;

        if (causalResults && Object.keys(causalResults).length > 0) {
          task_id = causalResults[task_key];
        } else {
          task_id = null;
        }
        let bulkLoadCausalParams = causal_params.filter((obj) => {
          return obj.bulk_load === true;
        });
        if (bulkLoadCausalParams.length > 0) {
          bulkLoadCausalParams.forEach((params_obj) => {
            let valuesArr = params_obj.causalvalueSelection.split(",");
            valuesArr.map((item) => {
              let hier_value_comb = params_obj.causalhierarchySelection.concat(
                ",",
                item
              );
              if (hier_value_comb.indexOf(final_str) >= 0) {
                const text = "Some combination already exists in selection!";
                is_exist = true;
                this.props.togglePopup(text);
                return;
              }
            });
          });
          if (is_exist) {
            return;
          }
        }

        causal_params.forEach((params_obj) => {
          let combination_to_compare = [
            params_obj.causalhierarchySelection,
            params_obj.causalvalueSelection,
          ];
          let combination_to_compare_str = combination_to_compare.join(",");
          if (combination_to_compare_str.indexOf(final_str) >= 0) {
            const text = "Some combination already exists in selection!";
            is_exist = true;
            this.props.togglePopup(text);
            return;
          }
        });
        if (is_exist) {
          return;
        }
        if (
          prev_val !== undefined &&
          prev_val.filter((val) => val == final_str).length > 0
        ) {
          const text = "Some combination already exists in selection!!";
          this.props.togglePopup(text);
          return;
        } else {
          if (causal_params.length > 0) {
            calculated_row_index =
              parseInt(causal_params[causal_params.length - 1].row_index) + 1;
          }
          noOfSelectionsFlag = true
          noOfValuesSelection = causalvalueSelection.length
          causal_params.push({
            causalhierarchySelection: causalhierarchySelection,
            causalvalueSelection: obj.name,
            params_causal: JSON.stringify(params),
            causal_algo: causalAlgo,
            causaluserEdges: _loadash.clone(causalUserEdges),
            causaldiscardEdges: _loadash.clone(causalDiscardEdges),
            causalfixedEdges: _loadash.clone(causalFixedEdges),
            kpiList: JSON.stringify(causalKpiTypes),
            kpiLagVals: JSON.stringify(kpiLagVal),
            auto_c: auto_c,
            replicate_from: "",
            task_id: task_id,
            selected_causal_algo_label: causal_algo_display_name.label,
            bulk_load: bulk_load_flag,
            row_index: calculated_row_index,
          });
          this.props.updateCausalParams(causal_params);
        }
      });
    }
    if(noOfSelectionsFlag){
      let toastId = null;
      toastId = toast(noOfValuesSelection + " " + "selections added", {
        type: toast.TYPE.INFO,
        autoClose: 2000,
      });
    }
  };
  updateCausalAlgoFileData = (result) => {
    let data = result.data;
  };
  handlekpiLagVal = (kpi, e) => {
    let evt = e.trim();

    let ckpiLagValList = this.state.kpiLagValList;
    let numberPattern = /^[1-9]*$/;
    let arrayPattern = /\[[1-9],[1-9]\]{1}/;

    if (numberPattern.test(evt) || arrayPattern.test(evt)) {
      if (Object.keys(ckpiLagValList).length > 0) {
        let selectedKpi = Object.keys(ckpiLagValList).filter((obj) => {
          return obj === kpi;
        });
        if (selectedKpi.length > 0) {
          ckpiLagValList[selectedKpi[0]] = evt;
        } else {
          ckpiLagValList[kpi] = evt;
        }
      } else {
        ckpiLagValList[kpi] = evt;
      }
    } else {
      let val = "";
      let toastId = null;
      toastId = toast('Please enter valid Lag value. Ex: "3" or "[1,4]" ', {
        type: toast.TYPE.ERROR,
        autoClose: 4000,
      });

      if (Object.keys(ckpiLagValList).length > 0) {
        let selectedKpi1 = Object.keys(ckpiLagValList).filter((obj) => {
          return obj === kpi;
        });
        if (selectedKpi1.length > 0) {
          ckpiLagValList[selectedKpi1[0]] = val;
        } else {
          ckpiLagValList[kpi] = val;
        }
      } else {
        ckpiLagValList[kpi] = val;
      }
    }
    this.setState({
      kpiLagValList: ckpiLagValList,
    });
  };
  runCausal = () => {
    let flag = this.state.showCausalSaveRun;
    this.setState({
      showCausalSaveRun: !flag,
    });
  };
  closeshowCausalSaveRun = () => {
    let run_name = this.state.causalRunName;
    if(!isInputNameValidated(run_name)){
      this.props.history.push(
        `/app/causal/experiment/${this.props.match.params.exp_id}`
      );
    }
    this.setState({
      showCausalSaveRun: false,
    });
  };
  generateDAGPayload = (data) => {
    let nodes = new Set();
    let links = [];
    data.forEach((element, index) => {
      nodes.add(element.from_kpi);
      nodes.add(element.to_kpi);
      links.push({
        source: element.from_kpi,
        target: element.to_kpi,
        type: "Causal",
        lag: element.lag,
        strength: element.strength,
      });
    });
    let kpi_id = {};
    const node_obj = Array.from(nodes).map((obj, index) => {
      kpi_id[obj] = index;
      return { name: obj, label: obj, id: index };
    });
    links = links.map((obj, index) => {
      let source = obj.source;
      let target = obj.target;
      return {
        source: kpi_id[source],
        target: kpi_id[target],
        type: `Causal / Lag: ${obj.lag}`,
        lag: obj.lag,
        strength: obj.strength,
      };
    });
    return [{ nodes: node_obj, links: links }];
  };

  getCausalResults = (index, rowObj, exp_id, run_id) => {
    const causalResult = this.props.causalResults;
    const user_selection = this.props.causal_params[index];
    const causalhierarchySelection = user_selection.causalhierarchySelection;
    const causalvalueSelection = user_selection.causalvalueSelection;
    const task_key = causalhierarchySelection
      .concat("_", causalvalueSelection)
      .toLowerCase();
    //let task_id = causalResult[task_key]
    //  let task_id = task_id

    let toastId = null;
    toastId = toast("Fetching Causal Results", {
      type: toast.TYPE.INFO,
      autoClose: 3000,
    });
    const data = JSON.stringify({
      experiment_id: exp_id,
      user_id: JSON.parse(localStorage.getItem("uid")),
      task_id: rowObj.task_id,
    })
    fetchCall(`/app/api/causal/result/${exp_id}/${run_id}`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        if (resultData == null || resultData == undefined) {
          let toastId = null;
          toastId = toast("Algorithm is Running", {
            type: toast.TYPE.INFO,
            autoClose: 3000,
          });
          return;
        }
        if (resultData) {
          let selected_causal_graph_result = JSON.parse(resultData.payload);
          selected_causal_graph_result = selected_causal_graph_result.map(
            (obj) => {
              obj["update_code"] = 0;

              return obj;
            }
          );

          this.setState({
            selected_causal_graph_result,
            showCausalRun: !this.state.showCausalRun,
            selected_causal_graph: rowObj.task_id,
            selected_causal_grpah_rowIndx: index,
            causal_result_hier_label: rowObj.causalhierarchySelection,
            causal_result_value_label: rowObj.causalvalueSelection,
            selected_kpi_Obj: rowObj.kpiList && JSON.parse(rowObj.kpiList),
          });
        }
        toast.update(toastId, {
          render: "Fetched Successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  // getCausalResults = (
  //   index,
  //   exp_id,
  //   run_id,
  //   hierarchy,
  //   value,
  //   kpisObj,
  //   task_id
  // ) => {
  //   const causalResult = this.props.causalResults;
  //   const user_selection = this.props.causal_params[index];
  //   const causalhierarchySelection = user_selection.causalhierarchySelection;
  //   const causalvalueSelection = user_selection.causalvalueSelection;
  //   const task_key = causalhierarchySelection
  //     .concat("_", causalvalueSelection)
  //     .toLowerCase();
  //   //let task_id = causalResult[task_key]
  //   //  let task_id = task_id

  //   let toastId = null;
  //   toastId = toast("Fetching Causal Results", {
  //     type: toast.TYPE.INFO,
  //     autoClose: 3000,
  //   });

  //   return fetch(`/app/api/causal/result/${exp_id}/${run_id}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       experiment_id: exp_id,
  //       user_id: JSON.parse(localStorage.getItem("uid")),
  //       task_id: task_id,
  //     }),
  //   })
  //     .then((response) => handleErrors(response))
  //     .then((resultData) => {
  //       if (resultData == null || resultData == undefined) {
  //         let toastId = null;
  //         toastId = toast("Algorithm is Running", {
  //           type: toast.TYPE.INFO,
  //           autoClose: 3000,
  //         });
  //         return;
  //       }
  //       if (resultData.status == 200) {
  //         let selected_causal_graph_result = JSON.parse(resultData.payload);
  //         selected_causal_graph_result = selected_causal_graph_result.map(
  //           (obj) => {
  //             obj["update_code"] = 0;

  //             return obj;
  //           }
  //         );
  //         this.setState({
  //           selected_causal_graph_result,
  //           showCausalRun: !this.state.showCausalRun,
  //           selected_causal_graph: task_id,
  //           selected_causal_grpah_rowIndx: index,
  //           causal_result_hier_label: hierarchy,
  //           causal_result_value_label: value,
  //           selected_kpi_Obj: kpisObj,
  //         });
  //       }
  //       toast.update(toastId, {
  //         render: "Fetched Successfully",
  //         type: toast.TYPE.SUCCESS,
  //         autoClose: 2000,
  //       });
  //     })
  //     .catch((error) => {
  //       toast.update(toastId, {
  //         render: "ERROR: " + error.message,
  //         type: toast.TYPE.ERROR,
  //         autoClose: 2000,
  //       });
  //       console.log("Error in Fetching Result", error);
  //     });
  // };
  closeCausalRunModal = () => {
    this.setState({
      showCausalRun: false,
    });
  };
  handlediscardChange = (e) => {
    this.setState({
      causalUserEdges: [],
      causaltempEdges: {},
      discardFixedEdgesShow: true,
      userGraphEdgesShow: false,
    });
  };
  handleuserGraphChange = (e) => {
    let cedgeName = this.state.selectedEdge;
    let causalUserEdges = this.state.causalUserEdges;
    cedgeName = e;
    let flag = this.state.showCommonEdgeModal;

    this.setState({
      showCommonEdgeModal: !flag,
      selectedEdge: cedgeName,
      causalDiscardEdges: [],
      causalFixedEdges: [],
      causaltempEdges: causalUserEdges,
      discardFixedEdgesShow: false,
      userGraphEdgesShow: true,
    });
  };
  handleDiscardEdge = (e) => {
    let cedgeName = this.state.selectedEdge;
    let causalDiscardEdges = this.state.causalDiscardEdges;
    cedgeName = e;
    let flag = this.state.showCommonEdgeModal;
    this.setState({
      showCommonEdgeModal: !flag,
      selectedEdge: cedgeName,
      causaltempEdges: causalDiscardEdges,
    });
  };
  handleFixedEdge = (e) => {
    let cedgeName = this.state.selectedEdge;
    let causalAlgoLists = this.state.causalAlgoList.map((item, i) => {
      return (
        <option key={i} value={item.algorithm_name}>
          {item.display_name}
        </option>
      );
    });
    let causalFixedEdges = this.state.causalFixedEdges;
    let causalDiscardEdges = this.state.causalDiscardEdges;
    cedgeName = e;
    let flag = this.state.showCommonEdgeModal;
    this.setState({
      showCommonEdgeModal: !flag,
      selectedEdge: cedgeName,
      causaltempEdges: causalFixedEdges,
    });
  };
  handlesubmitEdges = () => {
    const selectedEdge = this.state.selectedEdge;
    let selectedToEdge = this.state.selectedToEdge;
    let ccausaluserEdges = this.state.causaltempEdges;
    let selectedFromEdge = this.state.selectedFromEdge;
    if (selectedEdge == "usergraph") {
      this.setState({
        causalUserEdges: ccausaluserEdges,
      });
    }
    if (selectedEdge == "Discard Edges") {
      this.setState({
        causalUserEdges: [],
        causalDiscardEdges: ccausaluserEdges,
      });
    }
    if (selectedEdge == "Fixed Edges") {
      this.setState({
        causalUserEdges: [],
        causalFixedEdges: ccausaluserEdges,
      });
    }
    this.setState({
      showCommonEdgeModal: false,
      causaltempEdges: {},
      selectedToEdge: null,
      selectedFromEdge: null,
    });
  };
  closeCommonEdgeModal = () => {
    this.setState({
      showCommonEdgeModal: false,
    });
  };
  handleFromKpiChange = (label) => {
    let selectCausalEdgeValue = this.state.selectCausalEdgeValue;
    let ckpiList = this.state.valueskpiSelection.map((item) => {
      return item.name;
    });
    let toKpiList = ckpiList.filter((e) => {
      return e !== label.value;
    });
    selectCausalEdgeValue["from_kpi"] = label.value;
    this.setState({
      selectCausalEdgeValue,
      toKpiList,
      selectedFromEdge: label,
      selectedToEdge: null,
    });
  };
  handleFromKpiChangeForGraph = (label) => {
    let from_to_kpi_obj_for_graph = this.state.from_to_kpi_obj_for_graph;
    let ckpiList = Object.keys(this.state.selected_kpi_Obj).map((item) => {
      return item;
    });
    let toKpiListForGraph = ckpiList.filter((e) => {
      return e !== label.value;
    });
    from_to_kpi_obj_for_graph["from_kpi"] = label.value;
    this.setState({
      from_to_kpi_obj_for_graph,
      toKpiListForGraph,
      from_kpi_selction: label,
      to_kpi_selection: null,
    });
  };
  handleToKpiChangeForGraph = (label) => {
    let from_to_kpi_obj_for_graph = this.state.from_to_kpi_obj_for_graph;
    from_to_kpi_obj_for_graph["to_kpi"] = label.value;
    this.setState({
      from_to_kpi_obj_for_graph,
      to_kpi_selection: label,
    });
  };
  handleToKpiChange = (label) => {
    let selectCausalEdgeValue = this.state.selectCausalEdgeValue;
    selectCausalEdgeValue["to_kpi"] = label.value;
    this.setState({
      selectCausalEdgeValue,
      selectedToEdge: label,
    });
  };
  handleLagVal = (e) => {
    let lag = e.target.value;
    let selectCausalEdgeValue = this.state.selectCausalEdgeValue;
    selectCausalEdgeValue["lag"] = lag;
    this.setState({
      selectCausalEdgeValue,
      selectedEdgeLagVal: lag,
    });
  };
  handleLagValForResultGrpah = (e) => {
    let lag = e.target.value;
    let from_to_kpi_obj_for_graph = this.state.from_to_kpi_obj_for_graph;
    from_to_kpi_obj_for_graph["lag"] = lag;
    this.setState({
      from_to_kpi_obj_for_graph,
      selectedEdgeLagValForGraph: lag,
    });
  };
  handlefromToKpituple = () => {
    let selectCausalEdgeValue = this.state.selectCausalEdgeValue;
    let causalDiscardEdges = this.state.causalDiscardEdges;
    let causalFixedEdges = this.state.causalFixedEdges;
    let selectedFromEdge = this.state.selectedFromEdge;
    let selectedToEdge = this.state.selectedToEdge;
    let selectedEdgeLagVal = this.state.selectedEdgeLagVal;
    if (selectedToEdge == undefined || selectedFromEdge == undefined) {
      const text = "slect all combinations!";
      this.props.togglePopup(text);
      return;
    } else {
      selectCausalEdgeValue["from_kpi"] = selectedFromEdge.value;
      selectCausalEdgeValue["to_kpi"] = selectedToEdge.value;
      selectCausalEdgeValue["lag"] = selectedEdgeLagVal;
    }
    const currentcausaltempEdges = this.state.causaltempEdges;
    let duplicate;
    //let duplicate = JSON.stringify(currentcausaltempEdges).includes(JSON.stringify(selectCausalEdgeValue))
    currentcausaltempEdges.length > 0 &&
      currentcausaltempEdges.map((obj) => {
        if (
          (obj.from_kpi == selectCausalEdgeValue.from_kpi &&
            obj.to_kpi == selectCausalEdgeValue.to_kpi) ||
          (obj.from_kpi == selectCausalEdgeValue.to_kpi &&
            obj.to_kpi == selectCausalEdgeValue.from_kpi)
        ) {
          duplicate = true;
          return;
        }
      });
    causalDiscardEdges.length > 0 &&
      causalDiscardEdges.map((obj) => {
        if (
          obj.from_kpi == selectCausalEdgeValue.from_kpi &&
          obj.to_kpi == selectCausalEdgeValue.to_kpi
        ) {
          duplicate = true;
          return;
        }
      });
    causalFixedEdges.length > 0 &&
      causalFixedEdges.map((obj) => {
        if (
          obj.from_kpi == selectCausalEdgeValue.from_kpi &&
          obj.to_kpi == selectCausalEdgeValue.to_kpi
        ) {
          duplicate = true;
          return;
        }
      });
    if (duplicate) {
      const text = "combination already exists!";
      this.props.togglePopup(text);
      return;
    } else {
      currentcausaltempEdges.push(selectCausalEdgeValue);
    }

    this.setState({
      causaltempEdges: currentcausaltempEdges,
      selectCausalEdgeValue: {},
    });
  };

  handleDelSelectedEdge = (index) => {
    const causaltempEdges = this.state.causaltempEdges;
    causaltempEdges.splice(index, 1);
    //this.saveProgress()
    this.setState({
      causaltempEdges,
    });
  };
  handleSaveProgress = () => {
    this.props.saveProgress();
  };

  showCausalResultReplicate = () => {
    let flag = this.state.showCausalResultReplicateModal;
    this.setState({
      showCausalResultReplicateModal: !flag,
    });
  };

  closeCausalResultReplicate = () => {
    this.setState({
      showCausalResultReplicateModal: false,
      causal_result_replicate_params: {},
    });
    this.props.updateHierarchyValues();
  };
  addToCausalResultReplicateSelection = () => {
    let replicate_params = this.state.causal_result_replicate_params;
    let current_causal_params = this.props.causal_params;
    let replicatehierarchySelection = this.props.causalhierarchySelection;
    let replicatevalueSelection = this.props.selectedValuesOfCausalHierarchy;
    if (
      replicatehierarchySelection === null ||
      replicatehierarchySelection === undefined
    ) {
      const text = "Please select Hierarchy";
      this.props.togglePopup(text);
      return;
    }
    if (replicatevalueSelection.length === 0) {
      const text = "Please select Values";
      this.props.togglePopup(text);
      return;
    }
    let newreplicateObj = {};
    let newhierArr = [];
    const selected_estimation_task = this.state.selected_estimation_task;

    var prev_val = [];

    Object.keys(replicate_params).forEach((key) => {
      let hir = key;
      let prev_hir_values = replicate_params[key];
      prev_hir_values.map((val) => {
        prev_val.push(hir.concat(",", val));
      });
    });

    replicatevalueSelection.forEach((obj) => {
      let add_flag = current_causal_params.some((causal_param) => {
        return (
          causal_param.causalvalueSelection === obj.name &&
          causal_param.causalhierarchySelection ===
          replicatehierarchySelection.value
        );
      });
      if (!add_flag) {
        let final_str = replicatehierarchySelection.value.concat(",", obj.name);
        if (
          prev_val !== undefined &&
          prev_val.filter((val) => val == final_str).length > 0
        ) {
          const text = "Some combination already exists in selection!!";
          this.props.togglePopup(text);
          return;
        } else {
          newhierArr.push(obj.name);
          newreplicateObj[replicatehierarchySelection.value] = newhierArr;
        }
      } else {
        const text =
          "Duplicate seelction not allowed. Selection already Exists.";
        this.props.togglePopup(text);
        return;
      }
    });
    if (newhierArr.length > 0) {
      let estimate_replicate_selection = {};
      estimate_replicate_selection[selected_estimation_task] = {
        value: replicatevalueSelection,
        hierarchy: replicatehierarchySelection.value,
      };
      replicate_params = { ...replicate_params, ...newreplicateObj };
      this.setState({
        estimate_replicate_selection,
        causal_result_replicate_params: replicate_params,
      });
    }
  };

  handlecausalResultReplicateAllResults = () => {
    let current_replicate_params = this.state.causal_result_replicate_params;
    let current_causal_params = _loadash.cloneDeep(this.props.causal_params);

    let replicate_for_hier = this.state.causal_result_hier_label;
    let replicate_for_value = this.state.causal_result_value_label;
    let task_id = this.state.selected_causal_graph;
    let replicate_map_flag = false;
    let causal_algo;
    let params_causal;

    let replicate_from;
    let row_index = 0;

    current_causal_params.forEach((obj) => {
      if (
        replicate_for_hier === obj.causalhierarchySelection &&
        replicate_for_value === obj.causalvalueSelection
      ) {
        causal_algo = obj.causal_algo;
        params_causal = obj.params_causal;
        replicate_from =
          obj.causalhierarchySelection + "," + obj.causalvalueSelection;
        return (replicate_map_flag = true);
      }
    });
    if ((replicate_map_flag = true)) {
      Object.keys(current_replicate_params).forEach((key) => {
        current_replicate_params[key].forEach((valObj) => {
          row_index =
            parseInt(
              current_causal_params[current_causal_params.length - 1].row_index
            ) + 1;
          let causalhierarchySelection = key;
          let causalvalueSelection = valObj;
          current_causal_params.push({
            causalhierarchySelection,
            causalvalueSelection,
            params_causal,
            causal_algo,
            replicate_from,
            task_id,
            row_index,
          });
        });
      });
    }
    this.props.updateCausalParams(current_causal_params);
    this.props.updateHierarchyValues();
    this.props.updatecausalResulteplicates(
      current_replicate_params,
      replicate_for_hier,
      replicate_for_value
    );
    this.closeCausalResultReplicate();
    let causal_replicate_event = {};
    causal_replicate_event[CAUSAL_EXP_NAME] = this.props.displayCausalRunName;
    sendAmplitudeData(CAUSAL_REPLICATE, causal_replicate_event);
  };
  removeResultReplicateSelection = (val) => {
    let creplicate_params = this.state.causal_result_replicate_params;
    delete creplicate_params[val];
    this.setState({
      causal_result_replicate_params: creplicate_params,
    });
  };
  viewCausalGraph = () => {
    this.setState({
      showGraph: !this.state.showGraph,
    });
  };
  closeShowGrpah = () => {
    this.setState({
      showGraph: false,
    });
  };

  kpiValuesOnChange = (value) => {
    this.setState({
      valueskpiSelection: value,
      kpiLagValList: {},
      kpiAutoCorr: [],
    });
  };

  handleUploadCausalEdges = (value) => {
    let selected_file = value.target.files[0] || null;
    if (!selected_file) return;
    const Papa = require("papaparse/papaparse.min.js");
    Papa.parse(selected_file, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: this.updateFileData,
    });
  };
  updateFileData = (result) => {
    let data = result.data;
    let currentDiscardEdges = this.state.causalDiscardEdges;
    let currentFixedEdges = this.state.causalFixedEdges;
    if (this.state.valueskpiSelection.length > 0) {
      var selectedKpis = this.state.valueskpiSelection.map((obj) => {
        return obj.name;
      });
    } else {
      const text = "Kpi list is empty, Please Select KPI's!";
      this.props.togglePopup(text);
      return;
    }
    let fromKpis = data.map((obj) => {
      return obj.from_kpi;
    });
    let toKpis = data.map((obj) => {
      return obj.to_kpi;
    });
    let uploadedKpis = Array.from(new Set(fromKpis.concat(toKpis)));
    if (
      uploadedKpis.length === selectedKpis.length &&
      uploadedKpis.every((e) => selectedKpis.includes(e))
    ) {
      if (currentDiscardEdges.length > 0) {
        data = this.filterCommonEdges(data, currentDiscardEdges);
      }
      if (currentFixedEdges.length > 0) {
        data = this.filterCommonEdges(data, currentFixedEdges);
      }
      this.setState({
        causaltempEdges: data,
      });
    } else {
      const text =
        "Uploaded file doesn't match to selected KPI's, Please upload correct file!";
      this.props.togglePopup(text);
      return;
    }
  };

  filterCommonEdges = (data, edges) => {
    const text = "few of the combinations does not allow!";
    this.props.togglePopup(text);
    let result = data.filter((obj1) => {
      return !edges.some((obj2) => {
        return obj1.from_kpi === obj2.from_kpi && obj1.to_kpi === obj2.to_kpi;
      });
    });
    return result;
  };

  handleBulkLoadOption = (value) => {
    let bulk_load_flag = this.state.bulk_load_flag;
    if (value === "yes") {
      bulk_load_flag = true;
    } else {
      bulk_load_flag = false;
    }
    this.setState({
      bulk_load_flag,
    });
  };

  clearAllSelections = () => {
    let isClearAllModalOpen = this.state.isClearAllModalOpen;
    this.setState({
      isClearAllModalOpen: !isClearAllModalOpen,
    });
  };
  closeDelAllCausalParamSelectionModal = () => {
    this.setState({
      isClearAllModalOpen: false,
    });
  };

  confirmDeleteAllCausalParamSelections = () => {
    this.props.updateCausalParams([]);
    this.props.updateCausalResults({});
    this.setState({
      isClearAllModalOpen: false,
    }, () => {
      this.props.saveProgress(this.props.match.params.run_id)
    }
    );
  };

  render() {
    const decoded_auth_token =  validate_decoded_auth_token()
    const {
      causalExpName,
      selectedEdge,
      valuesofCausalHierarchyselection,
      kpiList,
      discardFixedEdgesShow,
      userGraphEdgesShow,
      causalhierarchySelection,
      confirmDelCausalParamSelectionModalIsOpen,
      selectedValuesOfCausalHierarchy,
      hierarchyfileNames,
      causalGraphData,
      closeDelCausalParamSelectionModal,
      handleCausalHierarchyChange,
      saveProgress,
      multiSelectionOfCausalHierarchyValues,
      confirmDeleteCausalParamSelection,
    } = this.props;
    let hierarchyfileNamesList = hierarchyfileNames.map((item, i) => {
      return {
        value: item,
        label: item,
      };
    });

    let displaycausalUserEdges = this.state.causalUserEdges.map(
      (obj, index) => {
        return <div>{JSON.stringify(obj, null, "    ")}</div>;
      }
    );
    let displaycausalDiscardEdges = this.state.causalDiscardEdges.map(
      (obj, index) => {
        return <div>{JSON.stringify(obj)}</div>;
      }
    );

    let displaycausalFixedEdges = this.state.causalFixedEdges.map(
      (obj, index) => {
        return <div>{JSON.stringify(obj)}</div>;
      }
    );
    let causalAlgoLists = this.state.causalAlgoList.map((item, i) => {
      return {
        value: item.algorithm_name,
        label: item.display_name,
      };
    });

    if (this.state.valueskpiSelection.length > 0) {
      var fromkpisDropdown = this.state.valueskpiSelection.map((item) => ({
        value: item.name,
        label: item.name,
      }));
    }
    if (this.state.selected_kpi_Obj) {
      var fromkpisDropdownForGraph = Object.keys(
        this.state.selected_kpi_Obj
      ).map((item) => ({
        value: item,
        label: item,
      }));

      if (this.state.toKpiListForGraph.length > 0) {
        var tokpisDropdownForGraph = this.state.toKpiListForGraph.map(
          (item) => ({ value: item, label: item })
        );
      } else {
        var tokpisDropdownForGraph = Object.keys(
          this.state.selected_kpi_Obj
        ).map((item) => ({
          value: item,
          label: item,
        }));
      }
    }

    if (this.state.toKpiList.length > 0) {
      var tokpisDropdown =
        this.state.toKpiList.length > 0 &&
        this.state.toKpiList.map((item) => ({ value: item, label: item }));
    } else {
      var tokpisDropdown =
        kpiList.length > 0 &&
        this.state.valueskpiSelection.map((item) => ({
          value: item.name,
          label: item.name,
        }));
    }

    if (kpiList.length > 0) {
      var kpiValueOptions = kpiList.map((obj, i) => {
        return { id: i, name: obj };
      });
    }

    if (this.state.valueskpiSelection.length > 0) {
      var causalKpiList = this.state.valueskpiSelection.map((item, i) => {
        var lagVal = {};
        if (Object.keys(this.state.kpiLagValList).length > 0) {
          let selectedKpi = Object.keys(this.state.kpiLagValList).filter(
            (kpi) => {
              return kpi === item.name;
            }
          );
          if (selectedKpi.length > 0) {
            lagVal[`kpi`] = selectedKpi[0];
            lagVal[`value`] = this.state.kpiLagValList[selectedKpi[0]];
          }
        } else {
          lagVal[`kpi`] = item;
          lagVal[`value`] = " ";
        }
        return (
          <tr key={i} value={item.name}>
            <td className="kpi">{item.name}</td>
            <td className="lag-val">
              {" "}
              <DebounceInput
                type="text"
                value={lagVal.value}
                debounceTimeout={2000}
                onChange={(e) =>
                  this.handlekpiLagVal(item.name, e.target.value)
                }
              />
            </td>
            <td className="checkbox">
              <input
                type="checkbox"
                value={i}
                onChange={(e) => this.handlekpiAutoCorrelation(item, i)}
              />
            </td>
          </tr>
        );
      });
    }

    let displayCausalTempEdges =
      this.state.causaltempEdges.length > 0 &&
      this.state.causaltempEdges.map((obj, i) => {
        return obj ? (
          <tr key="index">
            <td>{obj["from_kpi"]}</td>
            <td>{obj["to_kpi"]}</td>
            <td>{obj["lag"]}</td>
            <td>
              <a onClick={(e) => this.handleDelSelectedEdge(i)}>
                <i className="far fa-trash-alt"></i>
              </a>
            </td>
          </tr>
        ) : (
            ""
          );
      });
    let selected_causal_graph_result_rows = this.state.selected_causal_graph_result.map(
      (obj, index) => {
        return obj ? (
          <tr key="index">
            <td>{obj["from_kpi"]}</td>
            <td>{obj["to_kpi"]}</td>
            <td>{obj["lag"]}</td>
            <td>
              {obj["update_code"] == 1
                ? "Add"
                : obj["update_code"] == 2
                  ? "Changed Direction"
                  : obj["update_code"] == -1
                    ? "Delete"
                    : ""}
            </td>
            {/* <td>{obj["update_code"]}</td> */}
            <td>
              <a onClick={(e) => this.handleSwapKPis(index)}>
                <i className="fa fa-exchange"></i>
              </a>
            </td>
            <td>
              <a onClick={(e) => this.removeFromToKPi(index)}>
                <i className="far fa-trash-alt"></i>
              </a>
            </td>
          </tr>
        ) : (
            ""
          );
      }
    );

    let display_hier_val_rows = Object.keys(
      this.state.causal_result_replicate_params
    ).map((key) => {
      return (
        <tr>
          <td>{key}</td>
          <td>{this.state.causal_result_replicate_params[key].join()}</td>
          <td>
            <a onClick={(e) => this.removeResultReplicateSelection(key)}>
              <i className="far fa-trash-alt"></i>
            </a>
          </td>
        </tr>
      );
    });
    const causal_results_csv_columns = [
      { displayName: "from_kpi", id: "from_kpi" },
      { displayName: "to_kpi", id: "to_kpi" },
      { displayName: "lag", id: "lag" },
    ]

    return (
      <React.Fragment>
        <button
          onClick={(e) => this.props.backtocausalRuns()}
          className="btn btn-sm btn-primary back-btn"
        >
          <i className="fa fa-arrow-left"></i> Back
        </button>
        <div className="causal_run_heading">
          <h4>
            <span>Project : {localStorage.getItem("causalExpName")}</span>
            <span></span>(<span>Data Source:</span>
            <span>{localStorage.getItem("dataSourceName")})</span> / &nbsp;
            Experiment : {this.props.displayCausalRunName}
          </h4>
        </div>
        <React.Fragment>
          <div className="causal_estimate_upload_links">
            <a
              onClick={(e) =>
                this.uploadCausalScreen(
                  this.props.match.params.exp_id,
                  this.props.match.params.run_id
                )
              }
            >
              Causal Upload
            </a>
            <a
              onClick={(e) =>
                this.loadCausalEstimation(
                  this.props.match.params.exp_id,
                  this.props.match.params.run_id
                )
              }
            >
              Causal Estimation
            </a>
          </div>
        </React.Fragment>
        <React.Fragment>
          <div className="causal_hier_kpi_fex">
            <div className="hier_val">
              <HierarchyValueDropDown
                hierarchies={hierarchyfileNamesList}
                hierarchyValues={selectedValuesOfCausalHierarchy}
                hierarchyValueOptions={valuesofCausalHierarchyselection}
                hierarchyValuesOnChange={multiSelectionOfCausalHierarchyValues}
                hierarchyOnchange={handleCausalHierarchyChange}
                valueSelected={causalhierarchySelection}
              />
            </div>
          </div>
          <div></div>
          <fieldset>
            <legend>Select Kpi Lag & Auto correlation:</legend>
            <div className="kpi-auto-container">
              <div className="kpi-list">
                <label>Select KPI's</label>
                <CustomSelectBox
                  selectedValues={this.state.valueskpiSelection}
                  multiSelectOptions={kpiValueOptions}
                  onChangeValues={this.kpiValuesOnChange}
                  isOpen={false}
                  valueKey="id"
                  labelKey="name"
                  isMultiple={true}
                  includeSelectAll={true}
                  includeFilter={true}
                  selectBoxHeight={300}
                  isDisabled={false}
                />
              </div>
              <div className="kpi_lag_auto">
                <table className="fixed_header">
                  <thead>
                    <tr>
                      <th>KPI</th>
                      <th>
                        Lag &nbsp;
                        <a>
                          <i
                            className="qtip tip-right"
                            data-tip="Lag value should be an 'integer value' or range for ex:4 (or) [1,4]"
                          >
                            <i className="fa fa-question"></i>
                          </i>
                        </a>
                      </th>
                      <th>Auto Correlation</th>
                    </tr>
                  </thead>
                  <tbody>{causalKpiList}</tbody>
                </table>
              </div>
            </div>
          </fieldset>
        </React.Fragment>
        <React.Fragment>
          <fieldset>
            <legend>Select Causal Algorithm & Parameters</legend>
            <div className="causal_algo_flex_container">
              <div className="algo_values">
                <label>Causal Algorithm </label> &nbsp;
                <div className="algo-select">
                  <Select
                    options={causalAlgoLists}
                    onChange={this.handleCausalAlgoChange}
                    value={this.state.causal_algo_display_name}
                  />
                </div>
              </div>
              <div className="causal_algo_param">
                <Form
                  schema={this.state.causalAlgoParam}
                  formData={this.state.causalAlgoData}
                  onChange={this.handleCausalParamChange}
                >
                  <div>
                    <button type="submit" className="hidden">
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </fieldset>
        </React.Fragment>
        <React.Fragment>
          {kpiList &&
            kpiList.length > 1 &&
            this.state.valueskpiSelection.length > 1 ? (
              <fieldset>
                <legend>Select Causal Edges:</legend>
                <div className="causal_edge_container">
                  <div className="causal_radio_btns">
                    <input
                      type="radio"
                      name="edges"
                      value="discard"
                      onChange={(e) => this.handlediscardChange(e.target.value)}
                      checked={this.state.discardFixedEdgesShow}
                    />{" "}
                    <span> Discard/Fixed Edges </span> <br />
                    <input
                      type="radio"
                      name="edges"
                      value="usergraph"
                      onClick={(e) => this.handleuserGraphChange(e.target.value)}
                      checked={this.state.userGraphEdgesShow}
                    />{" "}
                    <span>User Graph Edges</span> <br />
                  </div>
                  <br />
                  {this.state.discardFixedEdgesShow && (
                    <React.Fragment>
                      <div className="discard_fixed_container">
                        <div id="discard-edges" className="discard-edges">
                          <input
                            type="button"
                            className="btn btn-sm btn-success"
                            value="Discard Edges"
                            onClick={(e) =>
                              this.handleDiscardEdge(e.target.value)
                            }
                          />{" "}
                          <br />
                          <div className="selected-discard-edges">
                            {displaycausalDiscardEdges}
                          </div>
                        </div>
                        <div id="fixed-edges" className="fixed-edges">
                          <input
                            type="button"
                            className="btn btn-sm btn-success"
                            value="Fixed Edges"
                            onClick={(e) => this.handleFixedEdge(e.target.value)}
                          />{" "}
                          <br />
                          <div className="selected-fixed-edges">
                            {displaycausalFixedEdges}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.userGraphEdgesShow && (
                    <div className="user-grpah-edges">
                      <label>User Graph Edges</label>
                      <br />
                      <div className="selected-user-edges">
                        {displaycausalUserEdges}
                      </div>
                    </div>
                  )}
                </div>
              </fieldset>
            ) : (
              ""
            )}
          <React.Fragment>
            <div className="bulk-load-option">
              <div className="causal_radio_btns">
                <label>
                  You want to load Bulk Load of all the hierarchy Values ?
                </label>
                <input
                  type="radio"
                  name="bulk"
                  value="yes"
                  onChange={(e) => this.handleBulkLoadOption(e.target.value)}
                />{" "}
                <span>Yes</span> <br />
                <input
                  type="radio"
                  name="bulk"
                  value="no"
                  onClick={(e) => this.handleBulkLoadOption(e.target.value)}
                />{" "}
                <span>No</span> <br />
              </div>
            </div>
          </React.Fragment>
          <React.Fragment>
            <div className="causal-run-btn">
              <div className="clear-all">

              </div>
              <div className="add">
                {this.props.causal_params &&
                  this.props.causal_params.length > 0 ? (
                    <div className="clear-all">
                      <button
                        className="btn btn-sm btn-primary save_progress"
                        onClick={() =>
                          this.props.saveProgress(
                            this.props.match.params.run_id
                          )
                        }
                      >
                        <i className="fa fa-save"></i> Save Progress
                    </button>
                    </div>
                  ) : (
                    ""
                  )}
                <input
                  type="button"
                  className="btn btn-sm btn-success "
                  onClick={(e) => this.addCausalParamSelection(e)}
                  value="Add Selection"
                />
              </div>
            </div>
          </React.Fragment>
          <div className="causal_result_table_heading">
            <div className="heading_label">
              <span><b>Causal Algorithm Selection</b></span>
            </div>
            <div class="delete_all">
              {this.props.causal_params &&
                this.props.causal_params.length > 0 ? (
                  <button
                    type="button"
                    className="btn btn-sm btn-alert"
                    onClick={() => this.clearAllSelections()}
                    disabled={this.props.user_uuid !== decoded_auth_token || this.props.isAdminUser}
                  >
                    Clear All
                  </button>
                ) : (
                  ""
                )}
            </div>
          </div>
          <CausalReactTableComponent
            causal_params={this.props.causal_params}
            handleDelCausalParams={this.props.handleDelCausalParams}
            getCausalResults={this.getCausalResults}
            exp_id={this.props.match.params.exp_id}
            run_id={this.props.match.params.run_id}
            user_uuid={this.props.user_uuid}
            isAdminUser={this.props.isAdminUser}
          />

          <div className="causal-run">
            {this.props.match.params.run_id &&
              this.props.causal_params &&
              this.props.causal_params.length > 0 ? (
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-success"
                    onClick={() =>
                      this.props.handleExecuteRun(
                        this.props.match.params.exp_id,
                        this.props.match.params.run_id,
                        this.state.causalRunName,
                        this.state.kpiAutoCorr,
                        this.state.kpiLagValList
                      )
                    }
                    disabled={this.props.user_uuid !== decoded_auth_token || this.props.isAdminUser}
                  >
                    Execute Run
                </button>
                  <input
                    type="button"
                    onClick={(e) => this.runCausal()}
                    className="btn btn-sm btn-primary "
                    value="Save as"
                  />
                </div>
              ) : (
                ""
              )}
          </div>
        </React.Fragment>
        <Modal
          isOpen={this.state.showCommonEdgeModal}
          onRequestClose={this.closeCommonEdgeModal}
          style={edgesModal}
          shouldCloseOnOverlayClick={true}
          contentLabel={this.state.selectedEdge}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4"> {this.state.selectedEdge}</div>
              <button
                type="button"
                className="close"
                onClick={this.closeCommonEdgeModal}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="causal_edges_table">
                <table className="edges_table">
                  <tbody>
                    <tr>
                      <th>From</th>
                      <th>To</th>
                      <th>Lag</th>
                    </tr>
                    <tr className="select-edges-tr">
                      <td>
                        <CustomSelectBox
                          selectedValues={this.state.selectedFromEdge}
                          multiSelectOptions={fromkpisDropdown}
                          onChangeValues={this.handleFromKpiChange}
                          isOpen={false}
                          valueKey="value"
                          labelKey="label"
                          isMultiple={false}
                          includeFilter={true}
                          selectBoxHeight={250}
                        />
                      </td>
                      <td>
                        <CustomSelectBox
                          selectedValues={this.state.selectedToEdge}
                          multiSelectOptions={tokpisDropdown}
                          onChangeValues={this.handleToKpiChange}
                          isOpen={false}
                          valueKey="value"
                          labelKey="label"
                          isMultiple={false}
                          includeFilter={true}
                          selectBoxHeight={250}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          autoComplete={"off"}
                          onChange={(e) => this.handleLagVal(e)}
                        />
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={(e) => this.handlefromToKpituple()}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        {" "}
                        <div className="or-container">
                          <hr className="hr-text" data-content="OR" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <label>Upload from file :</label> &nbsp;
                        <input
                          type="file"
                          accept=".xls,.xlsx,.csv"
                          onChange={(e) => this.handleUploadCausalEdges(e)}
                        ></input>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <React.Fragment>
                  <table id="workflow">
                    <tbody>
                      <tr>
                        <th>From KPI</th>
                        <th>To KPI</th>
                        <th>Lag</th>
                        <th>Delete</th>
                      </tr>
                      {displayCausalTempEdges}
                    </tbody>
                  </table>
                </React.Fragment>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={this.closeCommonEdgeModal}
              >
                Close
              </button>
              {this.state.causaltempEdges.length > 0 ? (
                <input
                  type="button"
                  className="btn btn-sm add-btn"
                  onClick={(e) => this.handlesubmitEdges()}
                  value="submit"
                ></input>
              ) : (
                  ""
                )}
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showCausalRun}
          onRequestClose={this.closeCausalRunModal}
          style={causalResult}
          shouldCloseOnOverlayClick={true}
          contentLabel="Add New Run"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4">
                <span>
                  <b>Hierarchy:</b>
                  {this.state.causal_result_hier_label}
                </span>
                &nbsp; (
                <span>
                  <b>Value:</b>
                  {this.state.causal_result_value_label}
                </span>
                )
              </div>
              <button
                type="button"
                className="close"
                onClick={this.closeCausalRunModal}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="causal_result_content">
                <div className="csv-add-new-options">
                  <div>
                    {this.state.selected_causal_graph_result &&
                      this.state.selected_causal_graph_result.length > 0 ? (
                        <div>
                          <ReactCsvDownloader
                            csvFileName={`${this.state.causal_result_hier_label}_${this.state.causal_result_value_label}_causal.csv`}
                            target="_blank"
                            separator=","
                            csvColumns={causal_results_csv_columns}
                            data={this.state.selected_causal_graph_result.map(
                              (obj) => {
                                return {
                                  from_kpi: obj["from_kpi"],
                                  to_kpi: obj["to_kpi"],
                                  lag: obj["lag"],
                                };
                              }
                            )}
                            csvLabelText="Download as CSV"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                  </div>
                  <div>
                    <a
                      className="add-new-kpi-link"
                      onClick={(e) => this.handleNewFromToKpi()}
                    >
                      <span>Add New</span>&nbsp;
                      <span>
                        <i className="fa fa-plus"></i>
                      </span>
                    </a>
                  </div>
                </div>

                <div className="causal_result_table">
                  <table id="workflow">
                    <tbody>
                      <tr>
                        <th>From KPI</th>
                        <th>To KPI</th>
                        <th>Lag</th>
                        <th>Action</th>
                        <th>Direction</th>
                        <th>Delete</th>
                      </tr>
                      {selected_causal_graph_result_rows}
                    </tbody>
                  </table>
                </div>
                <div className="graph-btns-container">
                  <input
                    type="button"
                    className="btn btn-sm add-btn"
                    value="Update Graph"
                    onClick={(e) =>
                      this.updateCausalGraphEdge(
                        this.props.match.params.exp_id,
                        this.props.match.params.run_id
                      )
                    }
                  ></input>
                  <input
                    type="button"
                    className="btn btn-sm add-btn"
                    value="Replicate Results"
                    onClick={(e) => this.showCausalResultReplicate()}
                  ></input>
                  <input
                    type="button"
                    className="btn btn-sm add-btn"
                    value="View Graph"
                    onClick={(e) => this.viewCausalGraph()}
                  ></input>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={this.closeCausalRunModal}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showGraph}
          onRequestClose={this.closeShowGrpah}
          style={causalGraph}
          shouldCloseOnOverlayClick={true}
          contentLabel="View Graph"
        >
          <div className="modal-content">
            <div class="modal-header">
              <div class="modal-title h4">
                <span>
                  {" "}
                  <b>Causal Relation Graph</b>{" "}
                </span>{" "}
                &nbsp;
                <span>
                  <b>Hierarchy:</b>
                  {this.state.causal_result_hier_label}
                </span>
                &nbsp; (
                <span>
                  <b>Value:</b>
                  {this.state.causal_result_value_label}
                </span>
                )
              </div>
              <button type="button" class="close" onClick={this.closeShowGrpah}>
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <React.Fragment>
                {this.state.selected_causal_graph_result.length > 0 ? (
                  <div className="svg-dag">
                    <DAG
                      label={null}
                      data={
                        this.generateDAGPayload(
                          this.state.selected_causal_graph_result
                        )[0]
                      }
                    />
                  </div>
                ) : (
                    ""
                  )}
              </React.Fragment>
            </div>
            <div className="modal-footer"></div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showNewFromToKPi}
          onRequestClose={this.closeCausalFromToKPIModal}
          style={kpiRowAdd}
          shouldCloseOnOverlayClick={true}
          contentLabel="Add New kpi"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4"> Add Kpi</div>
              <button
                type="button"
                className="close"
                onClick={this.closeCausalFromToKPIModal}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="result_kpi_edges_conent">
                <table className="edges_table">
                  <tbody>
                    <tr>
                      <th>From</th>
                      <th>To</th>
                      <th>Lag</th>
                    </tr>
                    <tr>
                      <td>
                        <CustomSelectBox
                          selectedValues={this.state.from_kpi_selction}
                          multiSelectOptions={fromkpisDropdownForGraph}
                          onChangeValues={this.handleFromKpiChangeForGraph}
                          isOpen={false}
                          valueKey="value"
                          labelKey="label"
                          isMultiple={false}
                          includeFilter={true}
                          selectBoxHeight={250}
                        />
                      </td>
                      <td>
                        <CustomSelectBox
                          selectedValues={this.state.to_kpi_selection}
                          multiSelectOptions={tokpisDropdownForGraph}
                          onChangeValues={this.handleToKpiChangeForGraph}
                          isOpen={false}
                          valueKey="value"
                          labelKey="label"
                          isMultiple={false}
                          includeFilter={true}
                          selectBoxHeight={250}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          autoComplete={"off"}
                          onChange={(e) => this.handleLagValForResultGrpah(e)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <React.Fragment>
                  <input
                    type="button"
                    className="btn btn-sm add-btn"
                    onClick={(e) => this.handleAddKpiRowForGrpah()}
                    value="Add"
                  ></input>
                </React.Fragment>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={this.closeCausalFromToKPIModal}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showCausalSaveRun}
          //onRequestClose={this.closeshowCausalSaveRun}
          style={causalRun}
          shouldCloseOnOverlayClick={true}
          contentLabel="Add New Run"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4">Save Experiment</div>
              <button
                type="button"
                className="close"
                onClick={this.closeshowCausalSaveRun}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="anomaly_result_table">
                <ul className="pipeline-form">
                  <li className="form-row">
                    <label htmlFor="pipeline_name">
                      <span>&#10033;</span>Experiment Name:
                    </label>
                    <input
                      type="text"
                      autoComplete={"off"}
                      onChange={(e) => this.handleNewCausalRun(e)}
                    />
                  </li>
                  <li className="form-row"></li>
                  <li className="error-row">
                      <label></label>
                      <div className="error-msg">{this.state.nameError}</div>
                </li>
                </ul>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={() => this.handleSaveCausalRun()}
              >
                <i className="fa fa-save"></i> Save
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={() => this.closeshowCausalSaveRun()}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <React.Fragment>
          <Modal
            isOpen={this.state.showCausalResultReplicateModal}
            onRequestClose={this.closeCausalResultReplicate}
            shouldCloseOnOverlayClick={true}
            contentLabel="Replicate"
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                transform: "translate(-50%, -50%)",
                width: "65%",
                height: "80%",
                padding: "0px",
              },
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4">
                  <span>
                    <b>Hierarchy:</b>
                    {this.state.causal_result_hier_label}
                  </span>
                  &nbsp; (
                  <span>
                    <b>Value:</b>
                    {this.state.causal_result_value_label}
                  </span>
                  )
                </div>
                <button
                  type="button"
                  className="close"
                  onClick={this.closeCausalResultReplicate}
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="causal_result_replicate_content">
                  <div className="causal_result_replicate_container">
                    <div className="causal_result_replicate_hier">
                      <HierarchyValueDropDown
                        hierarchies={hierarchyfileNamesList}
                        hierarchyValues={selectedValuesOfCausalHierarchy}
                        hierarchyValueOptions={valuesofCausalHierarchyselection}
                        hierarchyValuesOnChange={
                          multiSelectionOfCausalHierarchyValues
                        }
                        hierarchyOnchange={handleCausalHierarchyChange}
                        valueSelected={causalhierarchySelection}
                      />
                    </div>
                    <div className="add_replicate_btn">
                      <input
                        type="button"
                        onClick={(e) =>
                          this.addToCausalResultReplicateSelection()
                        }
                        className="btn btn-sm add-btn"
                        value="Add To Replicate Selection"
                      ></input>
                    </div>
                  </div>
                  <h4>Selected List:</h4>
                  <div className="causal_replicate_run_selection_list">
                    <table id="workflow">
                      <tbody>
                        <tr>
                          <th>Hierarchy</th>
                          <th>Values</th>
                          <th></th>
                        </tr>
                        {display_hier_val_rows}
                      </tbody>
                    </table>
                  </div>
                  {Object.keys(this.state.causal_result_replicate_params)
                    .length > 0 ? (
                      <input
                        type="button"
                        onClick={(e) =>
                          this.handlecausalResultReplicateAllResults()
                        }
                        className="btn btn-sm add-btn"
                        value="Replicate Results"
                      ></input>
                    ) : (
                      ""
                    )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-alert"
                  onClick={() => this.closeCausalResultReplicate()}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>
        </React.Fragment>
        <React.Fragment>
          <Modal
            isOpen={confirmDelCausalParamSelectionModalIsOpen}
            onRequestClose={closeDelCausalParamSelectionModal}
            style={confirmDelete}
            contentLabel="Delete Pipeline"
            ariaHideApp={false}
          >
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4"> Causal Selection</div>
                <button
                  type="button"
                  className="close"
                  onClick={closeDelCausalParamSelectionModal}
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-body">
                <ul className="pipeline-form">
                  <li className="form-column">
                    <p className="text">
                      Are you sure want to delete the Selection ?
                    </p>
                  </li>
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={confirmDeleteCausalParamSelection}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-alert"
                  onClick={closeDelCausalParamSelectionModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </React.Fragment>
        <React.Fragment>
          <Modal
            isOpen={this.state.isClearAllModalOpen}
            onRequestClose={this.closeDelAllCausalParamSelectionModal}
            style={confirmDelete}
            contentLabel="Clear All"
            ariaHideApp={false}
          >
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4"> Remove All Selections</div>
                <button
                  type="button"
                  className="close"
                  onClick={this.closeDelAllCausalParamSelectionModal}
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-body">
                <ul className="pipeline-form">
                  <li className="form-column">
                    <p className="text">
                      Are you sure want to delete all the Selections ?
                    </p>
                  </li>
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={this.confirmDeleteAllCausalParamSelections}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-alert"
                  onClick={this.closeDelAllCausalParamSelectionModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

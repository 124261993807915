import React, { Component } from 'react';
let userList = [];
export class Profiles extends Component {
  componentDidMount() {
    userList = this.props.getUsers()
  }
  render() {
    return (
      <div>
        <div className="hierarchySet_flex-container">
          <div className="set_input_main">
            <div className="set_input">
              <label>Group</label>
              <input type='text' value={1} />
            </div>
            <div className="set_input">
              <label>Hierarchy</label>
              <input type='text' value={1} />
            </div>
            <div className="set_input">
              <label>KPI</label>
              <input type='text' value={1} />
            </div>
            <div className="set_input">
              <label>Hierarchy Values</label>
              <input type='text' value={1} />
            </div>
          </div>
        </div>
        <div className="hierarchySet_flex-container">
          <div className="set_input_main">
          </div>
        </div>
      </div>
    )
  }
}

import React from "react";
import { userService } from "../_services";
import "./login.css";
import { handleErrors } from "../pages/ui_utils";
import Modal from "react-modal";
import { useHistory } from "react-router-dom"
import logo from "../assets/logo/Acc_Logo_White_Purple_RGB.png";
import mslogo from "../assets/logo/ms-symbollockup_signin_light.png"
var md5 = require('md5');


class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    userService.logout();

    this.state = {
      loading: false,
      error: "",
    };

  }

  


  handleMicrosoftLogin = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    return fetch(`/app/api/auth/login-redirect`, requestOptions)
      .then((response) => handleErrors(response))
      .then((resp) => {
        if (resp) {
          let url = resp.auth_url
          let encoded_url = encodeURI(url)
          let decoded_url = decodeURI(encoded_url);
          window.location.replace(decoded_url)
        }
      })
      .catch((error) => {
        return error
      });
  }

  render() {
    const {loading, error } = this.state;
    return (
      <div className="layout">
        <div className="chart">
          <div className="wt-text">
            AI enabled monitoring system for improved decision making
          </div>
        </div>
        <div className="login-form">
          <div className="acc-logo">
            <img src={logo} />
          </div>
          <div id="title-heading"  className="logo">KPI Watchtower</div>
          <div className="ms-logo">
            <button onClick={this.handleMicrosoftLogin}>
              <img src={mslogo} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export { LoginPage };

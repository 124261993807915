import { authHeader } from "../_helpers";
import { LOGIN_MS } from "../consts";
import { toast } from "react-toastify";
import { handleErrors } from "../pages/ui_utils";
import { setAmplitudeUserId } from "../pages/util/amplitude";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const userService = {
  logout,
  getAll,
};


function logout() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  }
  localStorage.clear()
  cookies.remove("Authorization")
  cookies.remove("session")
  return fetch(`/app/api/auth/logout`, requestOptions).then(response => { 
  })
  .catch(error => {
    console.log("Request Failed");
  });
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`/app/api/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
      }
      const error = (data && data.message) || "Unable to Connect to server";
      return Promise.reject(error);
    }

    return data;
  });
}

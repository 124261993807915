import React, { Component } from 'react';
import Select from 'react-select';
import { CustomSelectBox } from './CustomSelectBox';
import Form from 'react-jsonschema-form';

export class ActionRecommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priority_file_name: '',
      business_file_name: '',
      constraint_file_name: '',
      isPriorityFileUploaded: false,
      isBusinessFileUploaded: false,
      isConstraintFileUploaded: false
    };
  }
  componentDidMount = () => {
    this.props.fetchDataSources();
    this.props.fetchAnomalyExperiments();
    this.props.fetchCausalExperiments();
    this.props.getAllAnomalyAlgorithms();
    this.props.resetRecommendationParams()
  };
  handlePriorityFile = (value) => {
    let selected_file = value.target.files[0] || null;
    if (selected_file) {
      let uploaded_file_name = selected_file.name;
      const filenameRegex = /^[a-z0-9A-Z_\s()-]{1,60}\.csv$/gm;
      if (!filenameRegex.test(uploaded_file_name)) {
        value.target.value = '';
        this.setState({
          priority_file_name: '',
          isPriorityFileUploaded: false
        });
        this.props.togglePopup('Invalid file name');
        return;
      }
      const Papa = require('papaparse/papaparse.min.js');
      Papa.parse(selected_file, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: this.updatePriorityFile
      });
    }
    this.setState({
      priority_file_name: value.target.value,
      isPriorityFileUploaded: true
    });
  };
  handleBusinessFile = (value) => {
    let selected_file = value.target.files[0] || null;
    if (selected_file) {
      let uploaded_file_name = selected_file.name;
      const filenameRegex = /^[a-z0-9A-Z_\s()-]{1,60}\.csv$/gm;
      if (!filenameRegex.test(uploaded_file_name)) {
        value.target.value = '';
        this.setState({
          business_file_name: '',
          isBusinessFileUploaded: false
        });
        this.props.togglePopup('Invalid file name');
        return;
      }
      const Papa = require('papaparse/papaparse.min.js');
      Papa.parse(selected_file, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: this.updateBusinessFile
      });
    }
    this.setState({
      business_file_name: value.target.value,
      isBusinessFileUploaded: true
    });
  };

  handleConstraintFile = (value) => {
    let selected_file = value.target.files[0] || null;
    if (selected_file) {
      let uploaded_file_name = selected_file.name;
      const filenameRegex = /^[a-z0-9A-Z_\s()-]{1,60}\.csv$/gm;
      if (!filenameRegex.test(uploaded_file_name)) {
        value.target.value = '';
        this.setState({
          constraint_file_name: '',
          isConstraintFileUploaded: false
        });
        this.props.togglePopup('Invalid file name');
        return;
      }
      const Papa = require('papaparse/papaparse.min.js');
      Papa.parse(selected_file, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: this.updateConstraintFile
      });
    }
    this.setState({
      constraint_file_name: value.target.value,
      isConstraintFileUploaded: true
    });
  };

  updatePriorityFile = (result, file) => {
    this.props.updatePriorityKpiModel(result)
    this.setState({
      priority_model: result,
      priority_file_name: file.name,
      isPriorityFileUploaded: true
    });
  };
  updateBusinessFile = (result, file) => {
    this.props.updateBusinessModel(result)
    this.setState({
      business_model: result,
      business_file_name: file.name,
      isBusinessFileUploaded: true
    });
  };
  updateConstraintFile = (result, file) => {
    this.props.updateConstraintModel(result)
    this.setState({
      constraint_model: result,
      constraint_file_name: file.name,
      isConstraintFileUploaded: true
    });
  };
  handlePriorityFileChange = () => {
    this.props.updatePriorityKpiModel("")
    this.setState({
      priority_file_name: '',
      priority_model: '',
      isPriorityFileUploaded: false
    });
  };
  handleBusinessFileChange = () => {
    this.props.updateBusinessModel("")
    this.setState({
      business_file_name: '',
      business_model: '',
      isBusinessFileUploaded: false
    });
  };
  handleConstraintFileChange = () => {
    this.props.updateConstraintModel("")
    this.setState({
      constraint_file_name: '',
      constraint_model: '',
      isConstraintFileUploaded: false
    });
  };

  render() {
    const {
      datasourcesList,
      rcaDisplayCausalProjects,
      causalRunsRCA,
      anomalyRunsRCA,
      handleSelectAnomalyRunRCA,
      handleCausalExpCallRCA,
      handleAnomalyExpCallRCA,
      handleSelectCausalRunRCA,
      rcaDisplayAnomalyProjects
    } = this.props;
    const priorityFileStyle = this.state.isPriorityFileUploaded
      ? { display: 'none' }
      : {};
    const uploadedPriorityFile = this.state.isPriorityFileUploaded
      ? {}
      : { display: 'none' };
    const businessFileStyle = this.state.isBusinessFileUploaded ? { display: 'none' } : {};
    const uploadedBusinessFile = this.state.isBusinessFileUploaded ? {} : { display: 'none' };

    const constraintFileStyle = this.state.isConstraintFileUploaded ? { display: 'none' } : {};
    const uploadedConstraintFile = this.state.isConstraintFileUploaded ? {} : { display: 'none' };

    const display_datasourcesList =
      datasourcesList &&
      datasourcesList
        .filter((ds) => ds.data_source_status == 'INITIAL INGESTION SUCCESS')
        .map((ds) => {
          return {
            value: ds.display_name,
            label: ds.display_name
          };
        });
    let anomalyExperimentsList = [];
    if (rcaDisplayAnomalyProjects && rcaDisplayAnomalyProjects.length > 0) {
      anomalyExperimentsList = rcaDisplayAnomalyProjects.map((obj, i) => {
        return {
          value: obj.experiment_id,
          label: obj.experiment_name
        };
      });
    }
    let causalExperiments_display = [];
    if (rcaDisplayCausalProjects && rcaDisplayCausalProjects.length > 0) {
      causalExperiments_display =
        rcaDisplayCausalProjects.length > 0 &&
        rcaDisplayCausalProjects.map((obj, i) => {
          return {
            value: obj.experiment_id,
            label: obj.experiment_name
          };
        });
    }
    let anomalyRunsList = [];
    if (anomalyRunsRCA && anomalyRunsRCA.length > 0) {
      anomalyRunsList = anomalyRunsRCA.map((obj, i) => {
        return {
          value: obj.run_id,
          label: obj.run_name
        };
      });
    }
    let causal_runs_display = [];
    if (causalRunsRCA && causalRunsRCA.length > 0) {
      causal_runs_display = causalRunsRCA.map((obj, i) => {
        return {
          value: obj.run_id,
          label: obj.run_name
        };
      });
    }
    let predictiveAlgos =
      this.props.anomaly_algorithms &&
      this.props.anomaly_algorithms.filter(
        (obj) => obj.algorithm_category === 'Recommendation'
      );
    let recommendationAlgosList =
      predictiveAlgos &&
      predictiveAlgos.map((item, i) => {
        return {
          value: item.algorithm_name,
          label: item.display_name
        };
      });
    return (
      <div className='feedback_mining'>
        <div className='rca_heading'>Action Recommendation</div>
        <fieldset className='DS-dropdown'>
          <legend>Select Data Source :</legend>
          <div className='exp_run_container'>
            <div className='exp_select'>
              <label>Data Source</label> &nbsp;
              <div className='experiment-select'>
                <Select
                  options={
                    display_datasourcesList && [
                      { value: '', label: 'Select....' },
                      ...display_datasourcesList
                    ]
                  }
                  //value={selected_rca_anomaly_label}
                  onChange={this.props.handleDataSourceChangeRecommendation}
                />
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Select Anomaly Project & Experiment:</legend>
          <div className='exp_run_container'>
            <div className='exp_select'>
              <label>Anomaly Project</label> &nbsp;
              <div className='experiment-select'>
                <Select
                  options={[
                    { value: '', label: 'Select....' },
                    ...anomalyExperimentsList
                  ]}
                  value={this.props.selected_rca_anomaly_label}
                  onChange={handleAnomalyExpCallRCA}
                />
              </div>
            </div>
            <div className='run_select'>
              <label>Anomaly Experiment</label> &nbsp;
              <div className='experiment-select'>
                <Select
                  options={[
                    { value: '', label: 'Select....' },
                    ...anomalyRunsList
                  ]}
                  value={this.props.selected_anomaly_run}
                  onChange={handleSelectAnomalyRunRCA}
                />
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Select Causal Project & Experiment:</legend>
          <div className='exp_run_container'>
            <div className='exp_select'>
              <label>Causal Project</label> &nbsp;
              <div className='experiment-select'>
                <Select
                  options={[
                    { value: '', label: 'Select....' },
                    ...causalExperiments_display
                  ]}
                  value={this.props.selected_rca_causal_exp_label}
                  onChange={handleCausalExpCallRCA}
                />
              </div>
            </div>
            <div className='run_select'>
              <label>Causal Experiment</label> &nbsp;
              <div className='experiment-select'>
                <Select
                  options={[
                    { value: '', label: 'Select....' },
                    ...causal_runs_display
                  ]}
                  value={this.props.selected_causal_run}
                  onChange={handleSelectCausalRunRCA}
                />
              </div>
            </div>
          </div>
        </fieldset>
        {
          this.props.recommendationKpiList && this.props.recommendationKpiList.length>0 &&
          <fieldset>
          <legend>Select KPIs:</legend>
            <div className="predictive_kpi_section_main">
              <CustomSelectBox
                selectedValues={this.props.selectedRecommenationKpis}
                multiSelectOptions={this.props.recommendationKpiList}
                onChangeValues={this.props.handleRecommendationKpiChange}
                isOpen={true}
                valueKey="value"
                labelKey="label"
                isMultiple={true}
                includeSelectAll={true}
                includeFilter={true}
                //selectBoxHeight={300}
                isDisabled={false}
                id="kpi_predictive"
              />
            </div> 
        </fieldset>
        }
        <fieldset>
          <legend>Upload Priority and Percentage Data:</legend>
          <div class='upload-anomaly-file'>
            <div class='upload-field' style={businessFileStyle}>
              <div className='m-10'>
                <label>Business Direction file :</label>{' '}
                <input
                  key={this.state.business_file_name}
                  onChange={(e) => this.handleBusinessFile(e)}
                  type='file'
                />
              </div>
            </div>
            <div className='uploaded_csv' style={uploadedBusinessFile}>
              <span>
                Uploaded Business direction File:{' '}
                <b> {this.state.business_file_name} </b>{' '}
              </span>
              <span>
                <a
                  className='change-uploadfile'
                  onClick={this.handleBusinessFileChange}
                >
                  Change/edit
                </a>
              </span>
            </div>
            <div class='upload-or-container'></div>
            <div class='upload-field' style={constraintFileStyle}>
              <div className='m-10'>
                <label>Constraint file :</label>{' '}
                <input
                  key={this.state.constraint_file_name}
                  onChange={(e) => this.handleConstraintFile(e)}
                  type='file'
                />
              </div>
            </div>
            <div className='uploaded_csv' style={uploadedConstraintFile}>
              <span>
                Uploaded Constraint direction File:{' '}
                <b> {this.state.constraint_file_name} </b>{' '}
              </span>
              <span>
                <a
                  className='change-uploadfile'
                  onClick={this.handleConstraintFileChange}
                >
                  Change/edit
                </a>
              </span>
            </div>
          </div>
          <div class='upload-anomaly-file'>
            <div class='upload-field' style={priorityFileStyle}>
              <div className='m-10'>
                <label>KPI Priority file :</label>{' '}
                <input
                  key={this.state.priority_file_name}
                  onChange={(e) => this.handlePriorityFile(e)}
                  type='file'
                />
              </div>
            </div>
            <div className='uploaded_csv' style={uploadedPriorityFile}>
              <span>
                Uploaded KPI Priority File:{' '}
                <b> {this.state.priority_file_name} </b>{' '}
              </span>
              <span>
                <a
                  className='change-uploadfile'
                  onClick={this.handlePriorityFileChange}
                >
                  Change/edit
                </a>
              </span>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Algorithm:</legend>
          <div className='exp_run_container'>
            <div className='exp_select'>
              <label>Select Algorithm</label> &nbsp;
              <div className='experiment-select'>
                <Select
                  options={recommendationAlgosList}
                  onChange={this.props.handleRecommendationAlgoChange}
                  value={this.props.selected_recommendation_algo}
                  maxMenuHeight={100}
                />
              </div>
            </div>
            <div className='run_select'>
              {this.props.recommendationAlgoParam &&
                this.props.recommendationAlgoParamData && (
                  <>
                    <label>Algo Model Params</label> &nbsp;
                    <div className='experiment-select'>
                      <div className='anomaly_algo_param predictive-params'>
                        <Form
                          schema={this.props.recommendationAlgoParam}
                          formData={this.props.recommendationAlgoParamData}
                          onChange={
                            this.props.handleRecommendationParamChange
                          }
                          liveValidate={true}
                          showErrorList={false}
                        >
                          <div>
                            <button type='submit' className='hidden'>
                              Submit
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </fieldset>
        <div className='run_rca_btn'>
          <button
            type='button'
            className='btn btn-sm btn-primary'
            onClick={() =>
              this.props.run_action_recommendation(
                this.props.recommendation_ds_id
              )
            }
          >
            Run
          </button>
        </div>
      </div>
    );
  }
}

import React from "react";
import "./HierarchyDataMap.css";
import "./BatchUpload.css"

export class BatchUpload extends React.Component {
    render() {
        const { hierarchyDataMap, handleUploadBatchData } = this.props
        return (
            <div className="batch-upload-container">
                <div className="batch-heading"><b>Auto/Batch Upload</b></div>
                <div className="datamap-container">
                    <div className="hierarchy-data table-header">
                        <div className="h-row">
                            <b>Hierarchy Files</b>
                        </div>
                    </div>
                    {hierarchyDataMap.map((obj, i) => {
                        return (
                            <div className="hierarchy-data" key={i}>
                                <div className="h-row">
                                    {obj.actual_label} [ {obj.label} ]
                            </div>
                            </div>
                        );
                    })}
                </div>
                <div className="batch-upload-btns">
                    <button
                        className="btn btn-sm btn-primary"
                        onClick={(e) => handleUploadBatchData()}
                    >
                        Aggregate & Upload
                    </button>
                </div>
               
            </div>
        );
    }
}

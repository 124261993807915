import React from 'react';
import { SetHierarchyPanel } from '../UploadSteps/SetHierarchyPanel';
import './HierarchySet.css'
import { Popup } from '../util/popup/Popup';
import { INGESTION_STATUS, INGESTION_TYPE } from "../../consts";
var _loadash = require("lodash");


export class HierarchySet extends React.Component {
  componentDidMount() {
    this.props.onloadHierarchySet();
  }
  render() {
    const { onDragEndHir, jumptoConfigureRatio, hierarchySetKeyObjData, fileData, noOfSets, handleSetCreation, ingestion_type,
      handleNumberofSets, hierarchySetValues, index, hierarchySetPanelsOrder, hierarchySetPanels, ingestion_status } = this.props;
    const ingestionStatusAndType = false //ingestion_status === INGESTION_STATUS && ingestion_type === INGESTION_TYPE
    return (
      <div>
        <div className='row'>
          <div className="hierarchySet_flex-container">
            <div className="set_input">
              <label># Sets</label>
              <div className="create-set">
                <input className="create-set" type="text" pattern="[0-9]*" onInput={e => handleSetCreation(e)} value={noOfSets}
                  disabled={ingestionStatusAndType} />
              </div>
              <div className="submit-btn">
                <input className="btn btn-sm btn-primary" type="submit" value="Create" onClick={handleNumberofSets}
                  disabled={ingestionStatusAndType} />
              </div>
            </div>
          </div>

          <div className="hierarchySet_Creation_flex-container">
            <div className="set_creation_main">
              {
                hierarchySetPanels &&
                <SetHierarchyPanel onDragEndHir={onDragEndHir}
                  index={index}
                  hierarchySetPanelsOrder={hierarchySetPanelsOrder}
                  hierarchySetPanels={hierarchySetPanels}
                  hierarchySetKeyObjData={hierarchySetKeyObjData}
                  fileData={fileData}
                  renderhierarchySetVal={hierarchySetValues[index]}
                  ignoreMasterHierarchy={false}
                  disableDrag={ingestionStatusAndType}
                  disableDrop={ingestionStatusAndType}
                />
              }
            </div>
          </div>
        </div>
        <div>
          <button className="btn btn-sm btn-primary" onClick={() => this.props.jumpToStep(0)}>Previous</button>
          <button className="btn btn-sm btn-primary" onClick={() => jumptoConfigureRatio(this.props)}>Next</button>
        </div>
      </div >
    )
  }
}

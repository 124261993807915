import React from "react";
import Select from "react-select";
import { SUMMERIZATION_STATE } from "../consts";
import { CustomSelectBox } from "./CustomSelectBox";
import { fetchCall } from "./util/util";
import { toast } from "react-toastify";
import { handleErrors } from "./ui_utils";
import AppContext from "./context/AppContext";

export class Summerization extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = SUMMERIZATION_STATE
    }
    static contextType = AppContext
    componentDidMount = () => {
        this.props.fetchDataSources();
        this.props.fetchCausalExperiments();
        this.props.fetchAnomalyExperiments();
    };
   
    handleDataSourceChange = (data_source) => {
        let data_source_id_obj = this.props.datasourcesList && this.props.datasourcesList.filter((item) => {
            return item.display_name == data_source.label
        })
        this.props.handleDataSourceChangeRCA(data_source)
        if(data_source.value == ""){
            this.setState({
                driverKpiTableRows:[]
            })
        }
        this.setState({
            kpi_selected_panel_values: data_source && data_source.value.length > 0 ? data_source_id_obj[0].upload_state.kpi_selected_panel_values : [],
            summarization_ds_id: data_source && data_source.value.length > 0 ? data_source_id_obj[0].pipeline_uuid : null,
        })
    }
    handleMaxPercentageThreshold = (evt) => {
        const maxPercentageThreshold = evt.target.validity.valid
            ? evt.target.value
            : this.state.maxPercentageThreshold;
        this.setState({
            maxPercentageThreshold: maxPercentageThreshold
        });
    }
    handleTopValueSelections = (evt) => {
        const topValueSelections = evt.target.validity.valid
            ? evt.target.value
            : this.state.topValueSelections;
        this.setState({
            topValueSelections: topValueSelections
        });
    }
    handlePersistantAlertWindow = (evt) => {
        const persistantAlertWindow = evt.target.validity.valid
            ? evt.target.value
            : this.state.persistantAlertWindow;
        this.setState({
            persistantAlertWindow: persistantAlertWindow
        });
    }
    handleMinPercentageThreshold = (evt) => {
        const minPercentageThreshold = evt.target.validity.valid
            ? evt.target.value
            : this.state.minPercentageThreshold;
        this.setState({
            minPercentageThreshold: minPercentageThreshold
        });
    }
    getArrayDifference = (array1, array2) => {
        return array1.filter(object1 => {
            return !array2.some(object2 => {
                return object1.value === object2.value
            });
        });
    }
    validateSummarizatonValues = (ds_id) => {
        if (!ds_id || !this.props.selected_anomaly_experiment_id || !this.props.selected_anomaly_run_id || !this.props.selected_causal_experiment_id ||
            !this.props.selected_causal_run_id) {
            const text = "Please select all values";
            this.props.togglePopup(text);
            return false
        }else{
            return true
        }
    }
    handleAddObjectiveDriverKPIRow = () => {
        let ds_id = this.state.summarization_ds_id
        
        if(!this.validateSummarizatonValues(ds_id)){
            return
        }

        let driverKpiTableRows = JSON.parse(JSON.stringify(this.state.driverKpiTableRows))
        let tableObjectiveKpis = driverKpiTableRows && driverKpiTableRows.length > 0 && driverKpiTableRows.map((item) => {
            return item.selectedDriverObjectiveKPI
        })

        let updated_objective_kpis = tableObjectiveKpis && tableObjectiveKpis.length > 0 &&
            this.getArrayDifference(this.state.kpi_selected_panel_values, tableObjectiveKpis)
        if (tableObjectiveKpis && tableObjectiveKpis.length > 0 && tableObjectiveKpis.length == this.state.kpi_selected_panel_values.length) {
            const text = "Added all Objective KPIs.";
            this.props.togglePopup(text);
            return
        }
        let rowObj = {
            objectiveKpis: tableObjectiveKpis && tableObjectiveKpis.length > 0 ? updated_objective_kpis : this.state.kpi_selected_panel_values,
            driverKpis: this.state.kpi_selected_panel_values,
            selectedDriverObjectiveKPI: "",
            selectedDriverKPIList: ""
        }
        this.setState({
            driverKpiTableRows: [...this.state.driverKpiTableRows, rowObj]
        });
    }
    handleDriverObjectiveKPIChange = (option, idx) => {
        let currentDriverKPITableRows = JSON.parse(JSON.stringify(this.state.driverKpiTableRows))
        if(idx <= currentDriverKPITableRows.length){
            let isObjKpiExists = false
            currentDriverKPITableRows.forEach((row)=>{
                if(row.selectedDriverObjectiveKPI.value == option.value){
                    isObjKpiExists = true
                }
            })
            if(isObjKpiExists){
                const text = "Selection already exist";
                this.props.togglePopup(text);
                return
            }
        }
        if(currentDriverKPITableRows[idx].selectedDriverKPIList.length > 0){
            let previous_selected_objective_kpi = currentDriverKPITableRows[idx].selectedDriverObjectiveKPI
            currentDriverKPITableRows[idx].selectedDriverKPIList = []
            currentDriverKPITableRows[idx].selectedDriverObjectiveKPI = option
            let updatedDriverKPIs = currentDriverKPITableRows[idx].driverKpis.filter((item) => {
                return option.value !== item.value
            })
            updatedDriverKPIs.push(previous_selected_objective_kpi)
            currentDriverKPITableRows[idx].driverKpis = updatedDriverKPIs
        }else{
            currentDriverKPITableRows[idx].selectedDriverObjectiveKPI = option
            let updatedDriverKPIs = currentDriverKPITableRows[idx].driverKpis.filter((item) => {
                return option.value !== item.value
            })
            currentDriverKPITableRows[idx].driverKpis = updatedDriverKPIs           
            currentDriverKPITableRows = currentDriverKPITableRows
        }
        this.setState({
            driverKpiTableRows: currentDriverKPITableRows,

        })
    }
    handleDriverKPIChange = (options, idx) => {
        let driverKPITableRows = JSON.parse(JSON.stringify(this.state.driverKpiTableRows))
        if(driverKPITableRows[idx].selectedDriverObjectiveKPI.value == "" || driverKPITableRows[idx].selectedDriverObjectiveKPI.label == undefined){
            driverKPITableRows[idx].selectedDriverObjectiveKPI = ""
            const text = "Please select Objective KPI";
            this.props.togglePopup(text);
            return
        }
        driverKPITableRows[idx].selectedDriverKPIList = options
        this.setState({
            driverKpiTableRows: driverKPITableRows
        })
    }
    handleDeleteDriverKPI = (option, index) => {
        let driverKPITableRows = JSON.parse(JSON.stringify(this.state.driverKpiTableRows))
        driverKPITableRows = driverKPITableRows.filter((item, i) => {
            if (item.selectedDriverObjectiveKPI == "" || item.selectedDriverObjectiveKPI == ""
            ) {
                return i !== index
            } else {
                return item.selectedDriverObjectiveKPI.value !== option.selectedDriverObjectiveKPI.value
            }
        })
        this.setState({
            driverKpiTableRows: driverKPITableRows
        })
    }
    runSummarization = () => {
        if(!this.validateSummarizatonValues(this.state.summarization_ds_id)){
            return
        }
        console.log(this.state.maxPercentageThreshold)
        console.log(this.state.minPercentageThreshold)
        console.log()
        if(this.state.maxPercentageThreshold > 1){
            const text = "Max Percentage threshold value should be 0-1";
            this.props.togglePopup(text);
            return
        }
        if(this.state.minPercentageThreshold > 1){
            const text = "Min Percentage threshold value should be 0-1";
            this.props.togglePopup(text);
            return
        }
        if(this.state.maxPercentageThreshold < this.state.minPercentageThreshold){
            const text = "Min Percentage threshold value should be less than Max Percentage threshold";
            this.props.togglePopup(text);
            return
        }

        let isEmptyValues = false
        let driverKpiTableRowsObjList = this.state.driverKpiTableRows && this.state.driverKpiTableRows.length > 0 ?
            this.state.driverKpiTableRows.map((item, index) => {
                let driverKPIsObj = {}
                let driverObjectiveKPI = item.selectedDriverObjectiveKPI ? item.selectedDriverObjectiveKPI.value : ""
                let driverKPIvalues = item.selectedDriverKPIList && item.selectedDriverKPIList.length>0 ? item.selectedDriverKPIList.map((item) => {
                    return item.value
                }) : []
                if(driverObjectiveKPI == "" || driverKPIvalues.length == 0){
                    isEmptyValues = true
                }
                driverKPIsObj[driverObjectiveKPI] = driverKPIvalues
                return driverKPIsObj
            }) : []

        if (!driverKpiTableRowsObjList || (driverKpiTableRowsObjList && driverKpiTableRowsObjList.length == 0) || isEmptyValues) {
                const text = "Please add driver KPIs";
                this.props.togglePopup(text);
                return false
        }
        
        let toastId = null;
        toastId = toast("Running Summarization", {
            type: toast.TYPE.INFO,
            autoClose: true,
        });
        const data = JSON.stringify({
            user_uuid: JSON.parse(this.context.store_user_uuid),
            data_source_id: this.state.summarization_ds_id,
            anomaly_project_id:this.props.selected_anomaly_experiment_id,
            anomaly_experiment_id: this.props.selected_anomaly_run_id,
            causal_project_id: this.props.selected_causal_experiment_id,
            causal_experiment_id: this.props.selected_causal_run_id,
            maxPercentageThreshold: this.state.maxPercentageThreshold,
            minPercentageThreshold: this.state.minPercentageThreshold,
            topValueSelections: this.state.topValueSelections,
            peristant_alert_window: this.state.persistantAlertWindow,
            DriverKPIs: driverKpiTableRowsObjList
        })
        fetchCall(`/app/api/graph/alert_summarization/run`, "POST", data)
            .then((response) => handleErrors(response))
            .then((resultData) => {
                this.setState({
                    Summarization_OP: resultData,
                });
                toast.update(toastId, {
                    render: "Initiated Alert Summarization",
                    type: toast.TYPE.INFO,
                    autoClose: true,

                });
            })
            .catch((error) => {
                toast.update(toastId, {
                    render: "ERROR: " + error,
                    type: toast.TYPE.ERROR,
                });
            });

    }
    render() {
        const { datasourcesList,rcaDisplayAnomalyProjects,selected_rca_anomaly_label,handleAnomalyExpCallRCA ,handleSelectAnomalyRunRCA,
            anomalyRunsRCA,rcaDisplayCausalProjects,causalRunsRCA,handleSelectCausalRunRCA, handleCausalExpCallRCA} = this.props
        const display_datasourcesList = datasourcesList && datasourcesList.filter(ds => ds.data_source_status == 'INITIAL INGESTION SUCCESS').
            map(ds => {
                return {
                    value: ds.display_name,
                    label: ds.display_name,
                };
            });
        let anomalyExperimentsList = []
        if (rcaDisplayAnomalyProjects && rcaDisplayAnomalyProjects.length > 0) {
            anomalyExperimentsList = rcaDisplayAnomalyProjects.map((obj, i) => {
                return {
                    value: obj.experiment_id,
                    label: obj.experiment_name,
                    };
                });
        }

        let anomalyRunsList = []
        if (anomalyRunsRCA && anomalyRunsRCA.length>0) {
            anomalyRunsList =
                anomalyRunsRCA.map((obj, i) => {
                    return {
                        value: obj.run_id,
                        label: obj.run_name,
                    };
                });
        }
        let causalExperiments_display =[]
        if (rcaDisplayCausalProjects && rcaDisplayCausalProjects.length > 0) {
            causalExperiments_display =
                rcaDisplayCausalProjects.length > 0 &&
                rcaDisplayCausalProjects.map((obj, i) => {
                    return {
                        value: obj.experiment_id,
                        label: obj.experiment_name,
                    };
                });
        }
        let causal_runs_display = []
        if (causalRunsRCA &&
            causalRunsRCA.length > 0) {
            causal_runs_display =
                causalRunsRCA.map((obj, i) => {
                    return {
                        value: obj.run_id,
                        label: obj.run_name,
                    };
                });
        }

        let displayObjectiveDriverKpisTable =
            this.state.driverKpiTableRows && this.state.driverKpiTableRows.length > 0 ? this.state.driverKpiTableRows.map((item, idx) => {
                return (
                    <tr id="addr0" key={idx} style={{ width: "10%" }}>
                        <td>{idx}</td>
                        <td style={{ width: "45%" }}>
                            <Select
                                options={item.objectiveKpis.length == 0 ? [{ value: "", label: 'Select....' }, ...this.state.kpi_selected_panel_values] : [{ value: "", label: 'Select....' }, ...item.objectiveKpis]}
                                value={item.selectedDriverObjectiveKPI}
                                onChange={(options) => this.handleDriverObjectiveKPIChange(options, idx)}
                            //isDisabled={ingestionStatusAndType}
                            />
                        </td>
                        <td style={{ width: "45%" }}>
                            <CustomSelectBox
                                selectedValues={item.selectedDriverKPIList && item.selectedDriverKPIList.length > 0 ? item.selectedDriverKPIList : []}
                                multiSelectOptions={item.driverKpis.length == 0 ? this.state.kpi_selected_panel_values : item.driverKpis}
                                onChangeValues={(options) => this.handleDriverKPIChange(options, idx)}
                                isOpen={false}
                                valueKey="value"
                                labelKey="label"
                                isMultiple={true}
                                includeSelectAll={true}
                                includeFilter={true}
                                selectBoxHeight={300}
                            //isDisabled={ingestionStatusAndType}
                            />
                        </td>
                        <td>
                            <div className="delete">
                                {/* className={ingestionStatusAndType ? "disabled" : ""}  */}
                                <a onClick={(e) =>
                                    this.handleDeleteDriverKPI(item, idx)
                                }
                                >
                                    <i className="far fa-trash-alt"></i>
                                </a>
                            </div>
                        </td>
                    </tr>
                )
            }
            ) :
                <tr><td colSpan="4">No data found, Please create new one</td></tr>
        return (
            <React.Fragment>
                <div className="rca_body">
                    <div className="rca_heading">Alert Summarization</div>
                    <fieldset className="DS-dropdown">
                        <legend>Select Data Source :</legend>
                        <div className="exp_run_container">
                            <div className="exp_select">
                                <label>Data Source</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={display_datasourcesList && [{ value: "", label: 'Select....' }, ...display_datasourcesList]}
                                        //value={selected_rca_anomaly_label}
                                        onChange={this.handleDataSourceChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Select Anomaly Project & Experiment:</legend>
                        <div className="exp_run_container">
                            <div className="exp_select">
                                <label>Anomaly Project</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={[{ value: "", label: 'Select....' }, ...anomalyExperimentsList]}
                                        value={selected_rca_anomaly_label}
                                        onChange={handleAnomalyExpCallRCA}
                                    />
                                </div>
                            </div>
                            <div className="run_select">
                                <label>Anomaly Experiment</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={[{ value: "", label: 'Select....' }, ...anomalyRunsList]}
                                        value={this.props.selected_anomaly_run}
                                        onChange={handleSelectAnomalyRunRCA}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Select Causal Project & Experiment:</legend>
                        <div className="exp_run_container">
                            <div className="exp_select">
                                <label>Causal Project</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={[{ value: "", label: 'Select....' }, ...causalExperiments_display]}
                                        value={this.props.selected_rca_causal_exp_label}
                                        onChange={handleCausalExpCallRCA}
                                    />
                                </div>
                            </div>
                            <div className="run_select">
                                <label>Causal Experiment</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={[{ value: "", label: 'Select....' }, ...causal_runs_display]}
                                        value={this.props.selected_causal_run}
                                        onChange={handleSelectCausalRunRCA}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="alert-sum-fieldset">
                        <legend><b>Alert Summarization Params:</b></legend>
                        <div className="alert-sum-params-container">
                            <div className="alert-sum-block">
                                <label>Max percentage threshold: </label>
                                <div className="info-container">
                                    <div className="ds-tooltip sum-tooltip">
                                        <i className="fa fa-info-circle"></i>
                                        <span class="ds-tooltiptext">Value should be between 0-1. For ex: 0.5</span>
                                    </div>
                                </div>

                                <div className="alert-sum-selection">
                                    <input
                                        type="text"
                                        pattern="^\d*(\.\d{0,2})?"
                                        onInput={(e) => this.handleMaxPercentageThreshold(e)}
                                        value={this.state.maxPercentageThreshold}
                                    //disabled={ingestionStatusAndType}
                                    />
                                </div>
                            </div>
                            <div className="alert-sum-block">
                                <label className="text-right">Top value selections: </label>
                                <div className="info-container">
                                    <div className="ds-tooltip sum-tooltip">
                                        <i className="fa fa-info-circle"></i>
                                        <span class="ds-tooltiptext">Value should be an integer. For ex: 3</span>
                                    </div>
                                </div>
                                <div className="alert-sum-selection">
                                    <input
                                        type="text"
                                        onInput={(e) => this.handleTopValueSelections(e)}
                                        value={this.state.topValueSelections}
                                    //disabled={ingestionStatusAndType}
                                    />
                                </div>
                            </div>
                            <div className="alert-sum-block">
                                <label className="text-right">Persistant Alert window: </label>
                                <div className="info-container">
                                    <div className="ds-tooltip sum-tooltip">
                                        <i className="fa fa-info-circle"></i>
                                        <span class="ds-tooltiptext">Value should be an integer. For ex: 2</span>
                                    </div>
                                </div>
                                <div className="alert-sum-selection">
                                    <input
                                        type="text"
                                        onInput={(e) => this.handlePersistantAlertWindow(e)}
                                        value={this.state.persistantAlertWindow}
                                    //disabled={ingestionStatusAndType}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="alert-sum-params-container">
                            <div className="alert-sum-block">
                                <label>Min percentage threshold: </label>
                                <div className="info-container">
                                    <div className="ds-tooltip sum-tooltip">
                                        <i className="fa fa-info-circle"></i>
                                        <span class="ds-tooltiptext">Value should be less than Max percentage and between 0-1</span>
                                    </div>
                                </div>
                                <div className="alert-sum-selection">
                                    <input
                                        type="text"
                                        pattern="^\d*(\.\d{0,2})?"
                                        onInput={(e) => this.handleMinPercentageThreshold(e)}
                                        value={this.state.minPercentageThreshold}
                                    //disabled={ingestionStatusAndType}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <div className="add-row">
                                <button
                                    onClick={(e) => this.handleAddObjectiveDriverKPIRow()}
                                //disabled={ingestionStatusAndType}
                                >
                                    <span>Add New</span>&nbsp;
                                    <span>
                                        <i className="fa fa-plus"></i>
                                    </span>
                                </button>
                            </div>
                            <table id="workflow">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Objective KPI</th>
                                        <th>Driver KPIs</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                {displayObjectiveDriverKpisTable}
                            </table>
                        </div>
                    </fieldset>
                    <div className="run_rca_btn">
                        <button type="button"
                            className="btn btn-sm btn-primary pull-right" onClick={() => this.runSummarization()}>
                            Execute Alert summarization
                        </button>
                    </div>
                </div>

            </React.Fragment >
        );
    }
}

export default Summerization;

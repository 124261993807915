import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js';

const cookies = new Cookies();

export const groupBy = (xs, key, hierarchy) => {
  return xs.reduce(function (rv, row) {
    rv[row[key]] = rv[row[key]] || [new Set(), 0];
    const h_v = row[hierarchy] || null;
    rv[row[key]] = [rv[row[key]][0].add(h_v), rv[row[key]][1] + 1] || [
      new Set(),
      0,
    ];
    return rv;
  }, {});
};

export const roundOffDecimal = (value) => {
  return Math.round(value * 100) / 100;
};

export const groupByAll = (objectArray, property) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};
export const groupByData = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};
export const find_level_parents_flatten = (level, hierarchy_sets) => {
  let parents = [];
  if (level.split("-**-").length == 1) {
    if (level.toLowerCase() == "overall") {
      return new Array().push(level);
    }
    for (const [the_key, the_set] of Object.entries(hierarchy_sets)) {
      let arr = the_set.findIndex(
        (item) => level.toLowerCase() === item.toLowerCase()
      );
      if (arr != -1) {
        const sliced = the_set.splice(0, arr + 1);
        parents = [...parents, ...sliced];
      }
    }
  } else {
    level.split("-**-").forEach((level_i) => {
      for (const [the_key, the_set] of Object.entries(hierarchy_sets)) {
        let arr = the_set.findIndex(
          (item) => level_i.toLowerCase() === item.toLowerCase()
        );
        if (arr != -1) {
          const sliced = the_set.splice(0, arr + 1);
          parents = [...parents, ...sliced];
        }
      }
    });
  }
  return parents;
};

export const compareTwoArrayObjects = (otherArray) => {
  return function (current) {
      return otherArray.filter(function (other) {
          return other.value == current.value && other.display == current.display
      }).length == 0;
  }
}

export const fetchCall = (apiURL,method,data) => {
  // const token = localStorage.getItem("Authorization")
  const token = cookies.get('Authorization')
  return fetch(apiURL, {
    method: method,
    headers: {
      "Authorization": `${token}`,
      "Content-Type": "application/json",
    },
    body:data
  })
}

export const validate_decoded_auth_token = () => {

  if (
    JSON.parse(localStorage.getItem('uid'))
  ){
    let user_uuid = JSON.parse(localStorage.getItem('uid'))
    return user_uuid
  }
}

export const handleLogout = () => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  }
  // localStorage.removeItem("user");
  localStorage.removeItem("is_auth");
  localStorage.removeItem("Authorization");
  localStorage.removeItem("email");
  localStorage.removeItem("username");
  localStorage.removeItem("uid");
  cookies.remove("Authorization")
  cookies.remove("session")
  return fetch(`/app/api/auth/logout`, requestOptions).then(response => {
    window.location.href = '/' 
  })
  .catch(error => {
    console.log("Request Failed");
  });
}
export const authHeader = () => {
  // return authorization header with basic auth credentials
  // let user = JSON.parse(localStorage.getItem('user'));
  let user = cookies.get('Authorization')
  if (user ) {
      return { 'Authorization': user };
  } else {
      return {};
  }
}
// helps to add hexdigest to the request body
export const requestWithHashDigest = (request_body) => {
  const encodedPayload = CryptoJS.enc.Utf8.parse(JSON.stringify(request_body))
  const encrypted_payload = CryptoJS.SHA512(encodedPayload);
  const hexdigest = encrypted_payload.toString(CryptoJS.enc.Hex);

  request_body['digest'] = hexdigest

  return request_body
}


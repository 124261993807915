import React from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { CONFIG_MS } from "../../consts";
import ReactDynamicTable from "../ReactDynamicTable";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import { handleErrors } from "../ui_utils";
import Select from "react-select";
import "./AnomalyExperiment.css";
import LoaderComponent from "../util/Loader";
import { ConsolidatedDataSourceView } from "../util/Modal/ConsolidatedDataSourceView";
import { validate_decoded_auth_token } from "../util/util";
var _loadash = require("lodash");


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
const confirmDeleteModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
export class AnomalyExperiment extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchAnomalyExpDataSourcesandResetParams();
  }


  render() {
    const decoded_auth_token =  validate_decoded_auth_token()
    const columns = [
      {
        Header: "S No",
        accessor: "serialNumber",
        width:"5%",
        disableFilters: true,
      },
      {
        Header: "Project Name",
        accessor: "experimentName",
        width:"20%",
      },
      {
        Header: "Data Source",
        accessor: "datasourceName",
        width:"20%",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view">
              <a onClick={() => this.props.handleDataSourceView(value.data_source_id)}>
                  {value.data_source_name}
              </a>
            </div>
          );
        }
      },
      {
        Header: "Type",
        accessor: "experimentType",
        width:"20%",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view">
              <a>
                  {value.experiment_type}
              </a>
            </div>
          );
        }
      },
      {
        Header: "Created On",
        accessor: "createdDate",
        width:"15%",
      },
      {
        Header: "Status",
        accessor: "status",
        width:"10%",
      },
      {
        Header: "View",
        accessor: "view",
        disableFilters: true,
        width:"5%",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view text-center">
              <a onClick={() => this.props.handleAnomalyExpCall(value.experiment_id, value.experiment_name,
                value.data_source_id, value.data_source_name, this.props.location.pathname)}>
                <i className="fa fa-eye"></i>
              </a>
            </div>
          );
        }
      },
      {
        Header: "Delete",
        accessor: "delete",
        width:"5%",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="delete text-center">
              {
                 value.user_id == decoded_auth_token || this.props.isAdminUser ?
                 <a onClick={(e) =>
                  this.props.handleAnomalyExperimentDelete(value.experiment_id)
                }
                >
                   <i className="far fa-trash-alt"></i>
                </a> :
                 <div className="ds-tooltip">
                    <a className="disabled" onClick={(e) =>
                      this.props.handleAnomalyExperimentDelete(value.experiment_id)
                    }
                    >
                      <i className="far fa-trash-alt"></i>
                    </a>
                    <span class="ds-tooltiptext">You don't have permission to remove this Project</span>
                  </div>
              }
            
            </div>
          );
        }
      }
    ]
    const pathName = this.props.location.pathname == "/app/anomaly/ds/experiment" ? 'Descriptive Summary' : 'Anomaly'

    let filteredDSExperiments = null
    if(pathName == "Descriptive Summary"){
       filteredDSExperiments = this.props.anomalyExperiments && this.props.anomalyExperiments.filter((item)=>{
        return item.experiment_type == 'Descriptive Summary'
      })
    }else{
       filteredDSExperiments = this.props.anomalyExperiments && this.props.anomalyExperiments.filter((item)=>{
        return item.experiment_type !== 'Descriptive Summary'
      })
    }
    
    const data = 
    filteredDSExperiments &&
    filteredDSExperiments.map((item, index) => {
        return {
          serialNumber: index + 1,
          experimentName: item.experiment_name,
          datasourceName: item,
          experimentType: item,
          createdDate: item.created_date.slice(0, 16),
          status: item.experiment_status,
          view: item,
          delete: item
        }
      })
    const {
      pipelines,
      anomalyExperiments,
      closeAnomalyExperimentModal,
      showAddAnomalyExp,
      handleAddAnomalyExperimentToggle,
      saveAnomalyExperiment,
      handleNewAnomalyExperiment,
      handleAnomalyExpCall,
      handleAnomalyExperimentDelete,
      handleAnomalyPipelineMap,
      isDeleteAnomalyExp,
      cancelDeleteAnomalyExpSelection,
      confirmAnomalyExperimentDelete,
    } = this.props;

    if (this.props.dataSourcesList && this.props.dataSourcesList.length > 0) {
      var current_datasources =
        this.props.dataSourcesList.length > 0 &&
        this.props.dataSourcesList.filter((val) => {
          return val.data_source_status == "INCREMENTAL INGESTION SUCCESS" || val.data_source_status == "INITIAL INGESTION SUCCESS";
        });
    }

    if (current_datasources && current_datasources.length > 0) {
      var datasource_option =
        current_datasources.length > 0 &&
        current_datasources.map((datasource, i) => {
          return {
            value: datasource.pipeline_uuid,
            label: datasource.display_name,
          };
        });
    }
    let display_linked_anomaly_runs = this.props.anomalyRunsList && this.props.anomalyRunsList.map((item)=>{
      return (<div>{item.run_name}</div>)
    }) 
                                           

    return (
      <div>
        <div className="row">
          <div className="text-btn-align">
            <span className="heading">{pathName} Projects</span>
            <input
              type="button"
              className="btn btn-sm add-btn"
              onClick={() => handleAddAnomalyExperimentToggle()}
              value="Add Project"
            ></input>
          </div>
          <ReactDynamicTable
            columns={columns}
            data={data}
          />
        </div>
        <Modal
          isOpen={showAddAnomalyExp}
          onRequestClose={closeAnomalyExperimentModal}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          contentLabel="Add New Project"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4"> Add {pathName} Project</div>
              <button
                type="button"
                className="close"
                onClick={closeAnomalyExperimentModal}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li className="form-row">
                  <label htmlFor="pipeline_name">
                    <span>&#10033;</span>Project Name:
                  </label>
                  <input
                    type="text"
                    autoComplete={"off"}
                    onChange={(e) => handleNewAnomalyExperiment(e)}
                  />
                </li>
                <li className="form-row">
                  <label htmlFor="pipeline_list">
                    <span>&#10033;</span>Data Source:
                  </label>
                  <div className="data-source-select">
                    <Select
                      maxMenuHeight={100}
                      options={datasource_option}
                      onChange={handleAnomalyPipelineMap}
                    />
                  </div>
                </li>
                <li className="error-row">
                      <label></label>
                      <div className="error-msg">{this.props.nameError}</div>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={(e) => saveAnomalyExperiment(pathName)}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={() => closeAnomalyExperimentModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={isDeleteAnomalyExp}
          style={confirmDeleteModal}
          onRequestClose={cancelDeleteAnomalyExpSelection}
          contentLabel="Result"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div class="modal-header">
              <div class="modal-title h4">Delete Project</div>
              <button
                type="button"
                class="close"
                onClick={cancelDeleteAnomalyExpSelection}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li className="form-column">
                  <p className="text">
                    Are you sure want to delete the selected project ? <br/>
                    {this.props.anomalyRunsList && this.props.anomalyRunsList.length > 0 ?<>
                       <p>
                       Following Experiments are linked to this project!
                     </p>
                      <div className="ds-linked-projects">
                        <div className="anomaly-projects margin-auto">
                          <div class="text-left">
                           <p><b>Anomaly Experiments:</b></p>
                            {display_linked_anomaly_runs}
                          </div>
                        </div>
                      </div>
                      </>
                      : ""
                    }
                  </p>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={confirmAnomalyExperimentDelete}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={cancelDeleteAnomalyExpSelection}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <LoaderComponent isLoaderLoading={this.props.isLoaderLoading}/>
        <ConsolidatedDataSourceView 
            isDSConsolidatedViewOpen={this.props.isDSConsolidatedViewOpen}
            closeDSConsolidatedModal={this.props.closeDSConsolidatedModal}
            consolidatedDSDetails={this.props.consolidatedDSDetails}
        />
      </div>
    );
  }
}
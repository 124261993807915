import React from "react";
import "./CausalResult.css";
import Modal from "react-modal";
import Form from "react-jsonschema-form";
import { toast } from "react-toastify";
import { CAUSAL_ESTIMATE_STATE } from "../../consts";
import { HierarchyValueDropDown } from "../HierarchyValueDropDown";
import { handleErrors } from "../ui_utils";
import { DAG } from "./DAG";
import Select from "react-select";
import "./CausalEstimation.css";
import ReactDynamicTable from "../ReactDynamicTable";
import { sendAmplitudeData } from "../util/amplitude";
import { v4 as uuidv4 } from 'uuid';

import {
  EXECUTE_CAUSAL_ESTIMATION,
  ESTIMATE_REPLICATE,
  CAUSAL_EXP_NAME,
  CAUSAL_ESTIMATIONS,
  TOTAL_CAUSAL_ESTIMATE_PARAMS,
} from "../util/consts_amplitude";
import { ReactCsvDownloader } from "../CsvDownloader/ReactCsvDownloader";
import { fetchCall } from "../util/util";
var _loadash = require("lodash");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "70%",
    padding: "0px",
  },
};
const causalGraph = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "88%",
    padding: "0px",
  },
};

const confirmDeleteModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
export class CausalEstimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = CAUSAL_ESTIMATE_STATE;
  }
  componentDidMount() {
    if (this.props.match.params.run_id === "undefined") {
      alert("Please save the run");
      this.props.history.push(
        `/app/causal/experiment/${this.props.match.params.exp_id}/run/`
      );
    } else {
      this.props.getRun(this.props.match.params.run_id);
      this.getAllCausalEstimations();
      this.props.loadDataSourceData(this.props.match.params.exp_id);
    }
  }
  getAllCausalEstimations = () => {
    fetchCall(`/app/api/algorithm/estimation`,'GET')
      .then((result) => result.json())
      .then((resultData) => {
        this.setState({ causalEstimations: resultData });
      })
      .catch((error) => {
        toast("ERROR: " + error.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  runEstimation = async (experiment_id, run_id) => {
    let toastId = null;
    toastId = toast("Running Estimation", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    await this.props.saveProgress(run_id)
    const estimate_params = _loadash.cloneDeep(this.props.estimate_params);
    const data = JSON.stringify({
      estimate_params,
      experiment_id,
      hierarchy: this.props.hierarchyfileNames.map((hierarchy) => hierarchy),
      variable_type: this.props.kpiTypeMap,
      replicate_index_map: this.props.replicate_index_map,
      user_id: JSON.parse(localStorage.getItem("uid")),
    })
    fetchCall(`/app/api/estimation/run/${run_id}/execute`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({});
        this.props.updateHierarchyValues();
        let current_estimate_params = _loadash.cloneDeep(estimate_params);
        let algoArr = [];
        current_estimate_params.forEach((obj) => {
          if (algoArr.length == 0) {
            algoArr.push(obj.causal_estimation);
          } else if (
            algoArr.length > 0 &&
            !algoArr.includes(obj.causal_estimation)
          ) {
            algoArr.push(obj.causal_estimation);
          }
          return algoArr;
        });
        let causal_estimation_execute_event = {};
        causal_estimation_execute_event[CAUSAL_ESTIMATIONS] = algoArr.join(",");
        causal_estimation_execute_event[TOTAL_CAUSAL_ESTIMATE_PARAMS] =
          current_estimate_params.length;
        causal_estimation_execute_event[
          CAUSAL_EXP_NAME
        ] = this.props.displayCausalRunName;
        sendAmplitudeData(
          EXECUTE_CAUSAL_ESTIMATION,
          causal_estimation_execute_event
        );
        toast.update(toastId, {
          render: "Running.. Please click on View to see results",
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
        // this.props.saveProgress(run_id)
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  /*  handleCausalEstimateChange = label => {
     let paramObj = this.state.causalEstimations.filter(
       obj => obj.algorithm_name === label
     );
     this.setState({
       causalEstimateParam: JSON.parse(paramObj[0].algorithm_parameters),
       causalEstimationData: JSON.parse(paramObj[0].algorithm_defaults),
       current_causal_estimation: paramObj[0].algorithm_name
     });
   }; */
  handleCausalEstimateChange = (label) => {
    let paramObj = this.state.causalEstimations.filter(
      (obj) => obj.algorithm_name === label.value
    );
    this.setState({
      causalEstimateParam: JSON.parse(paramObj[0].algorithm_parameters),
      causalEstimationData: JSON.parse(paramObj[0].algorithm_defaults),
      current_causal_estimation: paramObj[0].algorithm_name,
      display_causal_estimation: label.label,
    });
  };
  handleEstimationParamChange = (form_event) => {
    if (
      Object.keys(form_event.formData).includes("file") &&
      typeof form_event.formData.file === "string"
    ) {
      let selected_file = form_event.formData.file;
      const Papa = require("papaparse/papaparse.min.js");
      Papa.parse(selected_file, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: (result, file = file) => {
          let formData = form_event.formData;
          formData["file"] = result.data;
          let causalFileData = formData;
          this.setState({
            causalEstimationData: causalFileData,
          });
        },
      });
    } else {
      this.setState({
        causalEstimationData: form_event.formData,
      });
    }
  };
  handleCausalstrenthChange = (label) => {
    let paramObj = this.state.strength.filter((obj) => obj.name === label);
    this.setState({
      current_strength: paramObj[0].name,
    });
  };
  addCausalEstimateSelection = () => {

    let estimate_params = _loadash.cloneDeep(this.props.estimate_params);

    let calculated_row_index = 0;
    let estimatehierarchySelection = this.props.causalhierarchySelection.value;
    let estimatevalueSelection = this.props.selectedValuesOfCausalHierarchy;
    let causalEstimation = this.state.current_causal_estimation;
    let display_causal_estimation = this.state.display_causal_estimation;
    let current_strength = this.state.current_strength;
    let params = this.state.causalEstimationData;
    let current_causal_params = this.props.causal_params;

    if (Object.keys(params).length === 0) {
      const text = "Please select the File or Params  ";
      this.props.togglePopup(text);
      return;
    }
    if (estimate_params !== null && estimate_params.length > 0) {
      let set = estimate_params.map((obj, i) => {
        return obj.estimatehierarchySelection.concat(
          ",",
          obj.estimatevalueSelection,
          ",",
          obj.causal_estimation
        );
      });
      var cur_val = set;
    }
    let prev_val = cur_val;
    let bulk_load_flag = this.state.bulk_load_flag;
    if (bulk_load_flag === "") {
      const text = "Please select type of load  ";
      this.props.togglePopup(text);
      return;
    }
    if (bulk_load_flag === true) {
      let hierarchyValues = estimatevalueSelection.map((item) => {
        return item.name;
      });
      let is_exist = false;
      let bulk_comb_values = estimatehierarchySelection.concat(
        ",",
        hierarchyValues.join(",")
      );

      let filteredParams = current_causal_params.filter((paramObj) => {
        if (paramObj.bulk_load === false) {
          let valuesArr = hierarchyValues.join(",").split(",");
          let match = false;
          valuesArr.forEach((item) => {
            let existing_hier_value_comb = paramObj.causalhierarchySelection.concat(
              ",",
              paramObj.causalvalueSelection
            );
            let current_hier_value_comb = estimatehierarchySelection.concat(
              ",",
              item
            );
            if (existing_hier_value_comb === current_hier_value_comb) {
              match = true;
            }
          });
          return match;
        } else {
          let value_match = false;
          let existing_hierarchy = paramObj.causalhierarchySelection;
          let current_hierarchy = estimatehierarchySelection;
          if (existing_hierarchy === current_hierarchy) {
            let existing_bulk_values = paramObj.causalvalueSelection;
            let valArray = existing_bulk_values.split("||");
            let cur_values = hierarchyValues;
            let booleanArr = [];
            cur_values.forEach((item) => {
              if (valArray.includes(item)) {
                booleanArr.push(true);
              } else {
                booleanArr.push(false);
              }
            });
            if (booleanArr.includes(false)) {
              return (value_match = false);
            } else {
              return (value_match = true);
            }
          } else {
            return value_match;
          }
        }
      });
      let existing_estimates = estimate_params.filter((obj) => {
        return obj.bulk_load == false;
      });
      if (existing_estimates.length > 0) {

        existing_estimates.forEach((params_obj) => {
          let combination_to_compare = [
            params_obj.estimatehierarchySelection,
            params_obj.estimatevalueSelection,
          ];

          let valuesArr = bulk_comb_values.split("||");
          valuesArr.map((item) => {
            let hier_value_comb = params_obj.estimatehierarchySelection.concat(
              ",",
              item
            );
            if (hier_value_comb.indexOf(combination_to_compare) >= 0) {
              const text = "Some combination already exists in selection!";
              is_exist = true;
              this.props.togglePopup(text);
              return;
            }
          });
        });
        if (is_exist) {
          return;
        }
      }
      let existing_bulk_estimates = estimate_params.filter((obj) => {
        return obj.bulk_load == true;
      });
      if (existing_bulk_estimates.length > 0) {

        existing_bulk_estimates.forEach((params_obj) => {
          let combination_to_compare = [
            params_obj.estimatehierarchySelection,
            params_obj.estimatevalueSelection.split("||"),
          ];
          let combination_to_compare_str = combination_to_compare.join(",");
          combination_to_compare_str = combination_to_compare_str.split(",").sort().join(",")
          let sorted_bulk_comb_values = bulk_comb_values.split(",").sort().join(",")
          if (combination_to_compare_str.indexOf(sorted_bulk_comb_values) >= 0) {

            const text = "Some combination already exists in selection!";
            is_exist = true;
            this.props.togglePopup(text);
            return;
          }
        });
      }
      if (is_exist) {
        return;
      } else {

        if (filteredParams.length == 0) {
          const text =
            "Few combinations doesnt have Causal Graph created. Please add those selections in Causal Graph and Re-run it";
          this.props.togglePopup(text);
          return;
        } else if (filteredParams.length === 1) {
          if (estimate_params.length > 0) {
            calculated_row_index =
              parseInt(estimate_params[estimate_params.length - 1].index) +
              1;
          }
          estimate_params.push({
            estimatehierarchySelection: estimatehierarchySelection,
            estimatevalueSelection: hierarchyValues.join("||"),
            params_estimate: JSON.stringify(params),
            causal_estimation: causalEstimation,
            display_causal_estimation: display_causal_estimation,
            current_strength: current_strength,
            replicate_from: "",
            task_id: filteredParams[0].task_id,
            bulk_load: bulk_load_flag,
            row_index: uuidv4(),
            index: calculated_row_index
          });
          this.props.updateEstimateParams(estimate_params);
        } else {

          let filteredValues = filteredParams.map((obj) => {
            return obj.causalvalueSelection;
          });
          hierarchyValues = filteredValues;
          if (estimate_params.length >= 0) {
            if (estimate_params.length > 0 &&
              estimate_params.some((ob) => ob.estimatevalueSelection.split("||").join(",") === hierarchyValues.join(","))) {
              const text = "Some combination already exists in selection!";
              this.props.togglePopup(text);
              return;
            } else {
              if (estimate_params.length > 0) {
                calculated_row_index = parseInt(
                  estimate_params[estimate_params.length - 1].index
                ) + 1;
              }
              estimate_params.push({
                estimatehierarchySelection: estimatehierarchySelection,
                estimatevalueSelection: hierarchyValues.join("||"),
                params_estimate: JSON.stringify(params),
                causal_estimation: causalEstimation,
                display_causal_estimation: display_causal_estimation,
                current_strength: current_strength,
                replicate_from: "",
                task_id: filteredParams[0].task_id,
                bulk_load: bulk_load_flag,
                row_index: uuidv4(),
                index: calculated_row_index
              });
              this.props.updateEstimateParams(estimate_params);
            }
          }
        }
      }

      if (is_exist) {
        return;
      }
    } else {
      estimatevalueSelection.forEach((valueObj) => {
        let is_exist = false;
        let final_str = estimatehierarchySelection.concat(",", valueObj.name);

        var estimate_task_key = estimatehierarchySelection
          .concat("_", valueObj.name)
          .toLowerCase();

        let filteredParams = current_causal_params.filter((paramObj) => {

          let result_task_key = " ";
          if (paramObj.bulk_load === false) {
            result_task_key = paramObj.causalhierarchySelection
              .concat("_", paramObj.causalvalueSelection)
              .toLowerCase();
            return result_task_key == estimate_task_key;
          } else {
            let valuesArr = paramObj.causalvalueSelection.split("||");
            let match = false;
            let current_values = final_str.split(",");
            current_values.forEach((item) => {
              if (valuesArr.includes(item)) {
                match = true;
              }
            });
            return match;
          }
        });

        let bulk_existing_estimates = estimate_params.filter((obj) => {
          return obj.bulk_load == true;
        });
        if (bulk_existing_estimates.length > 0) {

          bulk_existing_estimates.forEach((obj) => {
            let valuesArr = obj.estimatevalueSelection.split("||");
            let filterdValues = filteredParams[0].causalvalueSelection.split(
              "||"
            );
            valuesArr.forEach((item) => {
              if (filterdValues.includes(item)) {
                const text = "Some combination already exists in selection!";
                is_exist = true;
                this.props.togglePopup(text);
                return;
              }
            });
          });
        }
        let existing_estimates = estimate_params.filter((obj) => {
          return obj.bulk_load == false;
        });
        if (existing_estimates.length > 0) {
          estimate_params.forEach((params_obj) => {
            let combination_to_compare = [
              params_obj.estimatehierarchySelection,
              params_obj.estimatevalueSelection,
            ];
            let combination_to_compare_str = combination_to_compare.join(",");
            if (combination_to_compare_str.indexOf(final_str) >= 0) {
              const text = "Some combination already exists in selection!";
              is_exist = true;
              this.props.togglePopup(text);
              return;
            }
          });
        }
        if (is_exist) {
          return;
        } else {
          if (filteredParams.length == 0) {
            const text =
              "Few combinations doesnt have Causal Graph created. Please add those selections in Causal Graph and Re-run it";
            this.props.togglePopup(text);
            return;
          } else {
            var task_id = filteredParams[0].task_id;
          }
        }
        if (is_exist) {
          return;
        }
        if (
          prev_val !== undefined &&
          prev_val.filter((val) => val == final_str).length > 0
        ) {

          const text = "Some combination already exists in selection!!";
          this.props.togglePopup(text);
          return;
        } else {
          if (estimate_params.length > 0) {
            calculated_row_index =
              parseInt(estimate_params[estimate_params.length - 1].index) +
              1;
          }
          estimate_params.push({
            estimatehierarchySelection: estimatehierarchySelection,
            estimatevalueSelection: valueObj.name,
            params_estimate: JSON.stringify(params),
            causal_estimation: causalEstimation,
            display_causal_estimation: display_causal_estimation,
            current_strength: current_strength,
            replicate_from: "",
            task_id: task_id,
            bulk_load: bulk_load_flag,
            row_index: uuidv4(),
            index: calculated_row_index
          });
          this.props.updateEstimateParams(estimate_params);
        }
      });
    }
  };

  getEstimationResults = (exp_id, run_id, rowObj, index) => {
    let flag = this.state.showEstimateResults;
    let task_id = rowObj.task_id + "_" + index;
    let toastId = null;
    toastId = toast("Fetching Estimation Results", {
      type: toast.TYPE.INFO,
      autoClose: 3000,
    });
    const data = JSON.stringify({
      task_id,
      experiment_id: exp_id,
      user_id: JSON.parse(localStorage.getItem("uid")),
    })
    fetchCall(`/app/api/causal/result/${exp_id}/${run_id}`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.setState({
          selected_estimation_task: task_id,
          showEstimateResults: !flag,
          display_estimate_hierarchy: rowObj.estimatehierarchySelection,
          display_estimate_value: rowObj.estimatevalueSelection,
          selected_causal_estimation_result: JSON.parse(resultData.payload),
          selected_result_index: index,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };

  closeEstimateResults = () => {
    this.setState({
      showEstimateResults: false,
    });
  };
  showReplicate = (hier, value) => {
    let flag = this.state.showReplicateModal;
    this.setState({
      showReplicateModal: !flag,
    });
  };
  closeReplicateModal = () => {
    this.setState({
      showReplicateModal: false,
      replicate_params: {},
    });
    this.props.updateHierarchyValues();
  };
  addToReplicateSelection = () => {
    let replicate_params = this.state.replicate_params;
    let estimate_params = this.props.estimate_params;
    let replicatehierarchySelection = this.props.causalhierarchySelection;
    let replicatevalueSelection = this.props.selectedValuesOfCausalHierarchy;
    if (
      replicatehierarchySelection === null ||
      replicatehierarchySelection === undefined
    ) {
      const text = "Please select Hierarchy";
      this.props.togglePopup(text);
      return;
    }
    if (replicatevalueSelection.length === 0) {
      const text = "Please select Values";
      this.props.togglePopup(text);
      return;
    }
    let newreplicateObj = {};
    let newhierArr = [];
    const selected_estimation_task = this.state.selected_estimation_task;
    var prev_val = [];

    Object.keys(replicate_params).forEach((key) => {
      let hir = key;
      let prev_hir_values = replicate_params[key];
      prev_hir_values.map((val) => {
        prev_val.push(hir.concat(",", val));
      });
    });
    replicatevalueSelection.forEach((obj) => {
      let add_flag = estimate_params.some((estimate_param) => {
        return (
          estimate_param.estimatevalueSelection === obj.name &&
          estimate_param.estimatehierarchySelection ===
          replicatehierarchySelection.value
        );
      });

      if (!add_flag) {
        let final_str = replicatehierarchySelection.value.concat(",", obj.name);
        if (
          prev_val !== undefined &&
          prev_val.filter((val) => val == final_str).length > 0
        ) {
          const text = "Some combination already exists in selection!!";
          this.props.togglePopup(text);
          return;
        } else {
          newhierArr.push(obj.name);
          newreplicateObj[replicatehierarchySelection.value] = newhierArr;
        }
      } else {
        const text =
          "Duplicate seelction not allowed. Selection already Exists.";
        this.props.togglePopup(text);
        return;
      }
    });

    let estimate_replicate_selection = {};
    estimate_replicate_selection[selected_estimation_task] = {
      value: replicatevalueSelection,
      hierarchy: replicatehierarchySelection.value,
    };
    replicate_params = { ...replicate_params, ...newreplicateObj };
    this.setState({
      estimate_replicate_selection,
      replicate_params: replicate_params,
    });
  };
  handlecausalEstimateReplicateAllResults = () => {
    let current_replicate_params = this.state.replicate_params;
    let current_estimate_params = _loadash.cloneDeep(
      this.props.estimate_params
    );

    let replicate_index_map = this.props.replicate_index_map || {};
    let replicate_for_hier = this.state.display_estimate_hierarchy;
    let replicate_for_value = this.state.display_estimate_value;
    let replicate_map_flag = false;
    let causal_estimation;
    let params_estimate;
    let task_id;
    let replicate_from;
    let calculated_index = 0;
    current_estimate_params.map((obj) => {
      if (
        replicate_for_hier === obj.estimatehierarchySelection &&
        replicate_for_value === obj.estimatevalueSelection
      ) {
        causal_estimation = obj.causal_estimation;
        params_estimate = obj.params_estimate;
        replicate_from =
          obj.estimatehierarchySelection + "," + obj.estimatevalueSelection;
        replicate_index_map[replicate_from] = this.state.selected_result_index;
        task_id = obj.task_id;
        return (replicate_map_flag = true);
      }
    });
    if ((replicate_map_flag = true)) {
      Object.keys(current_replicate_params).forEach((key) => {
        current_replicate_params[key].forEach((valObj) => {
          calculated_index = parseInt(current_estimate_params[current_estimate_params.length - 1].index) + 1;
          let estimatehierarchySelection = key;
          let estimatevalueSelection = valObj;
          current_estimate_params.push({
            estimatehierarchySelection,
            estimatevalueSelection,
            params_estimate,
            causal_estimation,
            replicate_from,
            task_id: task_id,
            row_index:uuidv4(),
            index:calculated_index
          });
          this.props.updateEstimateParams(current_estimate_params);
        });
      });
    }
    this.props.updateHierarchyValues();
    this.props.updateEstimateReplicates(
      current_replicate_params,
      replicate_for_hier,
      replicate_for_value,
      replicate_index_map
    );
    let causal_estimate_event = {};
    causal_estimate_event[CAUSAL_EXP_NAME] = this.props.displayCausalRunName;
    sendAmplitudeData(ESTIMATE_REPLICATE, causal_estimate_event);
    this.closeReplicateModal();
  };
  removeEstimateReplicateSelection = (val) => {
    let creplicate_params = this.state.replicate_params;
    delete creplicate_params[val];
    this.setState({
      replicate_params: creplicate_params,
    });
  };
  loadCausalRun = (exp_id, run_id) => {
    this.props.history.push(`/app/causal/experiment/${exp_id}/run/${run_id}/`);
  };
  generateDAGPayload = (data) => {
    let nodes = new Set();
    let links = [];
    data.forEach((element, index) => {
      nodes.add(element.from_kpi);
      nodes.add(element.to_kpi);
      links.push({
        source: element.from_kpi,
        target: element.to_kpi,
        type: "Causal",
        lag: element.lag,
        strength: element.strength,
      });
    });
    let kpi_id = {};
    const node_obj = Array.from(nodes).map((obj, index) => {
      kpi_id[obj] = index;
      return { name: obj, label: obj, id: index };
    });
    links = links.map((obj, index) => {
      let source = obj.source;
      let target = obj.target;
      return {
        source: kpi_id[source],
        target: kpi_id[target],
        type: `Causal / Lag: ${obj.lag}`,
        lag: obj.lag,
        strength: obj.strength,
      };
    });
    return [{ nodes: node_obj, links: links }];
  };
  estimationResultReplicate = (exp_id, run_id) => {
    this.setState({
      isEstimateExportModalOpen: true,
      export_exp_id: exp_id,
      export_run_id: run_id,
    });
  };
  confirmExportEstimationResultReplicate = () => {
    let exp_id = this.state.export_exp_id;
    let run_id = this.state.export_run_id;
    let toastId = null;
    toastId = toast("Replicating All Results", {
      type: toast.TYPE.INFO,
      autoClose: 3000,
    });
    const data = JSON.stringify({
      experiment_id: this.props.match.params.exp_id,
      run_id: this.props.match.params.run_id,
      user_id: JSON.parse(localStorage.getItem("uid")),
      estimate_params: this.props.estimate_params,
      replicate_index_map: this.props.replicate_index_map,
    })
    fetchCall(`/app/api/causal/replicate/${exp_id}/${run_id}`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        toast.update(toastId, {
          render: "Replicated Successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
        this.setState({
          isEstimateExportModalOpen: false,
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  };
  cancelExportEstimationResultReplicate = () => {
    this.setState({
      isEstimateExportModalOpen: false,
    });
  };
  viewCausalEstimateGraph = () => {
    this.setState({
      showCausalEstimateGraph: !this.state.showCausalEstimateGraph,
    });
  };
  closeCausalEstimateGraph = () => {
    this.setState({
      showCausalEstimateGraph: false,
    });
  };
  handleCausalBulkLoadOption = (value) => {
    let bulk_load_flag = this.state.bulk_load_flag;
    if (value === "yes") {
      bulk_load_flag = true;
    } else {
      bulk_load_flag = false;
    }
    this.setState({
      bulk_load_flag,
    });
  };
  clearAllSelections = () => {
    let isClearAllEstimateModalOpen = this.state.isClearAllEstimateModalOpen;
    this.setState({
      isClearAllEstimateModalOpen: !isClearAllEstimateModalOpen,
    });
  };
  closeDelAllEstimateParamSelectionModal = () => {
    this.setState({
      isClearAllEstimateModalOpen: false,
    });
  };

  confirmClearAllEstimateParamSelection = () => {
    this.setState({
      isClearAllEstimateModalOpen: false,
    });
    this.props.updateEstimateParams([]);
  };
  render() {
    const columns = [
      {
        Header: "No",
        accessor: "rowIndex",
        disableFilters: true,
      },
      {
        Header: "Hierarchy",
        accessor: "hierarchySelection"
      },
      {
        Header: "Values",
        accessor: "valueSelection",
        Cell: ({ cell: { value } }) => {
          return (
            <pre className="params_column">
              {
                value
              }
            </pre>
          );
        },
      },
      {
        Header: "EStimation",
        accessor: "estimation"
      },
      {
        Header: "Parameters",
        accessor: "estimateParams",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="params_column">
              {
                value
              }
            </div>
          );
        },
        disableFilters: true,
      },
      {
        Header: "Replicated From",
        accessor: "replicateFrom",
        disableFilters: true,
      },
      {
        Header: "View",
        accessor: "view",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="view">
              {
                value.task_id ? <a onClick={(e) =>
                  this.getEstimationResults(this.props.match.params.exp_id,
                    this.props.match.params.run_id, value, value.row_index)
                }>
                  <i className="fa fa-eye"></i>
                </a> : "-"
              }

            </div>
          );
        }
      },
      {
        Header: "Delete",
        accessor: "delete",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return (
            <div className="delete">
              <a onClick={(e) => this.props.handleDelEstimateParams(value, this.props.match.params.run_id)}>
                <i className="far fa-trash-alt"></i>
              </a>
            </div>
          );
        }
      },

    ]
    const data = this.props.estimate_params && this.props.estimate_params.map((item, index) => {
      return {
        rowIndex: item.index,
        taskId: item.task_id,
        hierarchySelection: item.estimatehierarchySelection,
        valueSelection: item.estimatevalueSelection,
        estimation: item.display_causal_estimation,
        estimateParams: item.params_estimate,
        replicateFrom: item.replicate_from,
        view: item,
        delete: index

      }
    })
    const causal_estimation_csv_columns = [
      { displayName: "from_kpi", id: "from_kpi" },
      { displayName: "to_kpi", id: "to_kpi" },
      { displayName: "lag", id: "lag" },
      { displayName: "estimate", id: "estimate" },
      { displayName: "strength", id: "strength" },
    ]
    const {
      display_estimate_hierarchy,
      hierarchyfileNames,
      handleCausalHierarchyChange,
      valuesofCausalHierarchyselection,
      confirmDelEStimateParamSelectionModalIsOpen,
      confirmDeleteEstimateParamSelection,
      closeDelEstimateParamSelectionModal,
    } = this.props;

    let hierarchyfileNamesList = hierarchyfileNames.map((item, i) => {
      return {
        value: item,
        label: item,
      };
    });
    /* let estimationList = this.state.causalEstimations.map((item, i) => {
     return (
       <option key={i} value={item.algorithm_name}>
         {item.display_name}
       </option>
     );
   });  */
    let estimationList = this.state.causalEstimations.map((item) => {
      return {
        value: item.algorithm_name,
        label: item.display_name,
      };
    });
    let selected_causal_estimation_result_rows = this.state.selected_causal_estimation_result.map(
      (obj, index) => {
        return obj ? (
          <tr key="index">
            <td>{obj["from_kpi"]}</td>
            <td>{obj["to_kpi"]}</td>
            <td>{obj["lag"]}</td>
            <td>{obj["estimate"]}</td>
            <td>{obj["strength"]}</td>
          </tr>
        ) : (
            ""
          );
      }
    );
    let display_hier_val_rows = Object.keys(this.state.replicate_params).map(
      (key) => {
        return (
          <tr>
            <td>{key}</td>
            <td>{this.state.replicate_params[key].join()}</td>
            <td>
              <a onClick={(e) => this.removeEstimateReplicateSelection(key)}>
                <i className="far fa-trash-alt"></i>
              </a>
            </td>
          </tr>
        );
      }
    );

    return (
      <React.Fragment>
        <button
          onClick={(e) =>
            this.loadCausalRun(
              this.props.match.params.exp_id,
              this.props.match.params.run_id
            )
          }
          className="btn btn-sm btn-primary back-btn"
        >
          <i className="fa fa-arrow-left"></i> Back
        </button>
        <div className="estimate-heading">
          <span className="heading">Causal Estimation</span>
          <h4>
            <span>Project : {localStorage.getItem("causalExpName")}</span>
            <span></span>(<span>Data Source:</span>
            <span>{localStorage.getItem("dataSourceName")})</span> / &nbsp;
            Experiment : {this.props.displayCausalRunName}
          </h4>
        </div>
        <div className="causal_estimate_main">
          <div className="causal_estimate_hier">
            <HierarchyValueDropDown
              hierarchies={hierarchyfileNamesList}
              hierarchyValues={this.props.selectedValuesOfCausalHierarchy}
              hierarchyValueOptions={valuesofCausalHierarchyselection}
              hierarchyValuesOnChange={
                this.props.multiSelectionOfCausalHierarchyValues
              }
              hierarchyOnchange={handleCausalHierarchyChange}
              valueSelected={this.props.causalhierarchySelection}
            />
          </div>
          <fieldset>
            <legend>Select Causal Algorithm & Parameters</legend>
            <div className="causal_algo_container">
              <div className="causal_estimation">
                <label>Estimation </label>
                <Select
                  options={estimationList}
                  onChange={this.handleCausalEstimateChange}
                />
              </div>
              <div className="causal_algo_param">
                <label></label>
                <Form
                  schema={this.state.causalEstimateParam}
                  formData={this.state.causalEstimationData}
                  onChange={this.handleEstimationParamChange}
                >
                  <button type="submit" className="hidden">
                    Submit
                  </button>
                </Form>
              </div>
            </div>
          </fieldset>
          <React.Fragment>
            <div className="bulk-load-option">
              <div className="causal_radio_btns">
                <label>
                  You want to load Bulk Load of all the hierarchy Values ?
                </label>
                <input
                  type="radio"
                  name="bulk"
                  value="yes"
                  onChange={(e) =>
                    this.handleCausalBulkLoadOption(e.target.value)
                  }
                />{" "}
                <span>Yes</span> <br />
                <input
                  type="radio"
                  name="bulk"
                  value="no"
                  onClick={(e) =>
                    this.handleCausalBulkLoadOption(e.target.value)
                  }
                />{" "}
                <span>No</span> <br />
              </div>
            </div>
          </React.Fragment>
          <React.Fragment>
            <div className="causal-run-btn">
              <div className="clear-all">

              </div>
              <div className="add">
                {this.props.causal_params &&
                  this.props.causal_params.length > 0 ? (
                    <div>
                      <button
                        className="btn btn-sm btn-primary save_progress"
                        onClick={() =>
                          this.props.saveProgress(
                            this.props.match.params.run_id
                          )
                        }
                      >
                        <i className="fa fa-save"></i> Save Progress
                    </button>
                    </div>
                  ) : (
                    ""
                  )}
                <input
                  type="button"
                  className="btn btn-sm btn-success btn-add-selection "
                  onClick={(e) => this.addCausalEstimateSelection(e)}
                  value="Add Selection"
                />
              </div>
            </div>
          </React.Fragment>
        </div>
        <div className="causal_result_table_heading">
          <div className="heading_label">
            <span><b>Estimation Selections</b></span>
          </div>
          <div class="delete_all">
            {this.props.causal_params &&
              this.props.causal_params.length > 0 ? (
                <button
                  type="button"
                  className="btn btn-sm btn-alert"
                  onClick={() => this.clearAllSelections()}
                >
                  Clear All Selections
                  </button>
              ) : (
                ""
              )}
          </div>
        </div>
        <ReactDynamicTable
          columns={columns}
          data={data}
        />
        {/* <EstimationReactTableComponent
          estimate_params={this.props.estimate_params}
          handleDelEstimateParams={this.props.handleDelEstimateParams}
          getEstimationResults={this.getEstimationResults}
          exp_id={this.props.match.params.exp_id}
          run_id={this.props.match.params.run_id}
        /> */}
        {
          this.props.estimate_params.length > 0 ? (
            <div className="causal-run">
              <input
                type="button"
                onClick={(e) =>
                  this.estimationResultReplicate(
                    this.props.match.params.exp_id,
                    this.props.match.params.run_id
                  )
                }
                className="btn btn-sm btn-primary "
                value="Export Causal Model"
              />
              <input
                type="button"
                onClick={(e) =>
                  this.runEstimation(
                    this.props.match.params.exp_id,
                    this.props.match.params.run_id
                  )
                }
                className="btn btn-sm btn-success "
                value="Run"
              />
            </div>
          ) : " "
        }


        <Modal
          isOpen={this.state.showEstimateResults}
          onRequestClose={this.closeEstimateResults}
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          contentLabel="Estimation Results"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4">
                {" "}
                <span>
                  <b>Hierarchy:</b>
                  {this.state.display_estimate_hierarchy}
                </span>{" "}
                &nbsp; (
                <span>
                  <b>Value:</b>
                  {this.state.display_estimate_value}
                </span>
                )
              </div>
              <button
                type="button"
                className="close"
                onClick={this.closeEstimateResults}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="estimate_result_content">
                <div className="causal_result_table">
                  {this.state.selected_causal_estimation_result &&
                    this.state.selected_causal_estimation_result.length > 0 ? (
                      <ReactCsvDownloader
                        csvFileName={`${this.state.display_estimate_hierarchy}_${this.state.display_estimate_value}.csv`}
                        separator=","
                        wrapColumnChar="'"
                        csvColumns={causal_estimation_csv_columns}
                        data={this.state.selected_causal_estimation_result}
                        csvLabelText="Download as CSV" />
                    ) : (
                      <></>
                    )}
                  <table id="workflow">
                    <tbody>
                      <tr>
                        <th>From KPI</th>
                        <th>To KPI</th>
                        <th>Lag</th>
                        <th>Estimate</th>
                        <th>Strength</th>
                      </tr>
                      {selected_causal_estimation_result_rows}
                    </tbody>
                  </table>
                </div>
                <React.Fragment>
                  <input
                    type="button"
                    onClick={(e) =>
                      this.showReplicate(
                        this.state.display_estimate_hierarchy,
                        this.state.display_estimate_value
                      )
                    }
                    className="btn btn-sm add-btn"
                    value="Replicate"
                  ></input>
                  <input
                    type="button"
                    className="btn btn-sm add-btn"
                    onClick={(e) => this.viewCausalEstimateGraph()}
                    value="View Graph"
                  ></input>
                </React.Fragment>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={() => this.closeEstimateResults()}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showCausalEstimateGraph}
          onRequestClose={this.closeCausalEstimateGraph}
          style={causalGraph}
          shouldCloseOnOverlayClick={true}
          contentLabel="View Graph"
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4"> Causal Estimation Graph</div>
              <button
                type="button"
                className="close"
                onClick={this.closeCausalEstimateGraph}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <React.Fragment>
                {this.state.selected_causal_estimation_result.length > 0 ? (
                  <div className="svg">
                    <DAG
                      data={
                        this.generateDAGPayload(
                          this.state.selected_causal_estimation_result
                        )[0]
                      }
                    />
                  </div>
                ) : (
                    ""
                  )}
              </React.Fragment>
            </div>
            <div className="modal-footer"></div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showReplicateModal}
          onRequestClose={this.closeReplicateModal}
          shouldCloseOnOverlayClick={true}
          contentLabel="Replicate"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              transform: "translate(-50%, -50%)",
              width: "65%",
              height: "80%",
              padding: "0px",
            },
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4">
                {" "}
                <span>
                  <b>Hierarchy:</b>
                  {this.state.display_estimate_hierarchy}
                </span>{" "}
                &nbsp; (
                <span>
                  <b>Value:</b>
                  {this.state.display_estimate_value}
                </span>
                )
              </div>
              <button
                type="button"
                className="close"
                onClick={this.closeReplicateModal}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="estimate_replicate_contet">
                <div className="causal_result_replicate_hier">
                  <HierarchyValueDropDown
                    hierarchies={hierarchyfileNamesList}
                    hierarchyValues={this.props.selectedValuesOfCausalHierarchy}
                    hierarchyValueOptions={valuesofCausalHierarchyselection}
                    hierarchyValuesOnChange={
                      this.props.multiSelectionOfCausalHierarchyValues
                    }
                    hierarchyOnchange={handleCausalHierarchyChange}
                    valueSelected={this.props.causalhierarchySelection}
                  />
                </div>
                <React.Fragment>
                  <div className="replicate_add_btn">
                    <input
                      type="button"
                      onClick={(e) => this.addToReplicateSelection()}
                      className="btn btn-sm add-btn"
                      value="Add"
                    ></input>
                  </div>
                </React.Fragment>
                <React.Fragment>
                  <h4>Selected List:</h4>
                  <div className="causal_estimation_replicate">
                    <table id="workflow">
                      <tbody>
                        <tr>
                          <th>Hierarchy</th>
                          <th>Values</th>
                          <th></th>
                        </tr>
                        {display_hier_val_rows}
                      </tbody>
                    </table>
                  </div>
                  {Object.keys(this.state.replicate_params).length > 0 ? (
                    <input
                      type="button"
                      onClick={(e) =>
                        this.handlecausalEstimateReplicateAllResults()
                      }
                      className="btn btn-sm add-btn"
                      value="Replicate Results"
                    ></input>
                  ) : (
                      ""
                    )}
                </React.Fragment>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={() => this.closeReplicateModal()}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.isEstimateExportModalOpen}
          style={confirmDeleteModal}
          onRequestClose={this.cancelExportEstimationResultReplicate}
          contentLabel="Export Confimation"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4">Export Confimation</div>
              <button
                type="button"
                className="close"
                onClick={this.cancelExportEstimationResultReplicate}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li className="form-column">
                  <p className="text">
                    Are you sure want to export all the selections ?
                  </p>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={this.confirmExportEstimationResultReplicate}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={this.cancelExportEstimationResultReplicate}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={confirmDelEStimateParamSelectionModalIsOpen}
          onRequestClose={closeDelEstimateParamSelectionModal}
          style={confirmDeleteModal}
          contentLabel="Delete Estimation"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4"> Estimation Selection</div>
              <button
                type="button"
                className="close"
                onClick={closeDelEstimateParamSelectionModal}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li className="form-column">
                  <p className="text">
                    Are you sure want to delete the Selection ?
                  </p>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={confirmDeleteEstimateParamSelection}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={closeDelEstimateParamSelectionModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.isClearAllEstimateModalOpen}
          onRequestClose={this.closeDelAllEstimateParamSelectionModal}
          style={confirmDeleteModal}
          contentLabel="Delete Estimation"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4"> Remove All Selections</div>
              <button
                type="button"
                className="close"
                onClick={this.closeDelAllEstimateParamSelectionModal}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li className="form-column">
                  <p className="text">
                    Are you sure want to delete all the Selections ?
                  </p>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={this.confirmClearAllEstimateParamSelection}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={this.closeDelAllEstimateParamSelectionModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

import React from "react";
import Select from "react-select";
import "./KpiRatioConfiguration.css";
import { compareTwoArrayObjects } from "../util/util";
import { INGESTION_STATUS, INGESTION_TYPE } from "../../consts";
import { CustomSelectBox } from "../CustomSelectBox";
import Modal from "react-modal";


const alertModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0x",
  },
};
export class KpiRatioConfiguration extends React.Component {
  componentDidMount() {
    this.updateKpiRatios();
  }

  updateKpiRatios = () => {
    let current_source_kpi_ratios = this.props.source_kpi_ratios;
    let selectedMasterValues = this.props.hierarchy_selected_panel_values;
    let allMasterValues = this.props.hierarchy_options;
    if (Object.values(current_source_kpi_ratios).length == 0) {
      return
    }
    var remainingMasterValues = allMasterValues.filter(
      compareTwoArrayObjects(selectedMasterValues)
    );

    let combinedKpiValues = [];
    if (remainingMasterValues.length > 0) {
      combinedKpiValues = [
        ...this.props.kpi_selected_panel_values,
        ...remainingMasterValues,
      ];
    } else {
      combinedKpiValues = this.props.kpi_selected_panel_values;
    }
    const uniqueSet = new Set(combinedKpiValues.map(JSON.stringify));
    const uniqueCombinedKpiValues = Array.from(uniqueSet).map(JSON.parse);
    uniqueCombinedKpiValues.map((item) => {
      if (Object.keys(this.props.ratio_kpis).includes(item.label)) {
        if (
          Object.keys(current_source_kpi_ratios).length > 0 &&
          current_source_kpi_ratios[item.label] !== undefined &&
          current_source_kpi_ratios[item.label].numerator !== " " &&
          current_source_kpi_ratios[item.label].denominator !== " "
        ) {
          current_source_kpi_ratios[item.label] =
            current_source_kpi_ratios[item.label];
        } else {
          let valObj = {};
          valObj[`numerator`] = "";
          valObj[`denominator`] = "";
          current_source_kpi_ratios[item.label] = valObj;
        }
      } else {
        return
      }
    });
    this.props.updateSourceKpiRatio(current_source_kpi_ratios);
  };

  handleRatioKpiChange = (e, kpi, type) => {
    let current_source_kpi_ratios = this.props.source_kpi_ratios;

    if (type === "numerator") {
      current_source_kpi_ratios[kpi].numerator = e.value;
    } else {
      current_source_kpi_ratios[kpi].denominator = e.value;
    }
    this.props.updateSourceKpiRatio(current_source_kpi_ratios);
  };

  render() {
    const {
      ratio_kpis,
      source_kpi_ratios,
      selected_objective_Kpi,
      jumptoHybridHierarchyMap,
      handleObjectiveKpiChange,
      handleThresholdValue,
      threshold_value,
      benchmark_prefix,
      filefirstRow,
      handleBenchmarkPrefixFieldChange, ingestion_status, ingestion_type,
      handleDriverKPIChange,
      driverKpiTableRows,
      handleMaxPercentageThreshold, maxPercentageThreshold,
      handleMinPercentageThreshold, minPercentageThreshold,
      handleTopValueSelections, topValueSelections,
      handleDriverObjectiveKPIChange, handleDeleteDriverKPI, handleAddObjectiveDriverKPIRow,
      handlePersistantAlertWindow, persistantAlertWindow
    } = this.props;
    const ingestionStatusAndType = ingestion_status === INGESTION_STATUS && ingestion_type === INGESTION_TYPE

    let display_objective_kpi = []
    let combinedKpiValues = [];
    if (this.props.data_source_type == "BATCH") {
      display_objective_kpi = this.props.kpi_options
    } else {
      let selectedMasterValues = this.props.hierarchy_selected_panel_values;
      let allMasterValues = this.props.hierarchy_options;
      var remainingMasterValues = allMasterValues.filter(
        compareTwoArrayObjects(selectedMasterValues)
      );
      if (remainingMasterValues.length > 0) {
        combinedKpiValues = [
          ...this.props.kpi_selected_panel_values,
          ...remainingMasterValues,
        ];
      } else {
        combinedKpiValues = this.props.kpi_selected_panel_values;
      }
      const uniqueSet = new Set(combinedKpiValues.map(JSON.stringify));
      const uniqueCombinedKpiValues = Array.from(uniqueSet).map(JSON.parse);
      display_objective_kpi = uniqueCombinedKpiValues && uniqueCombinedKpiValues.filter((item) => {
        let selectedItem = filefirstRow[item.value];
        let notNumeric = isNaN(selectedItem)
        return !notNumeric
      })
    }
    let current_ratio_kpis = ratio_kpis;
    let checked_ratio_kpis = {};
    Object.keys(current_ratio_kpis).map((item) => {
      if (current_ratio_kpis[item] === true) {
        checked_ratio_kpis[item] = true;
      }
    });
    var display_source_kpi_table = Object.keys(checked_ratio_kpis).map(
      (item, i) => {
        if (source_kpi_ratios && source_kpi_ratios[item] !== undefined) {
          if (Object.keys(source_kpi_ratios[item]).length > 0) {
            var optionNumeratorObj = {};
            var denominatorObj = {};
            if (source_kpi_ratios[item].numerator == "") {
              optionNumeratorObj[`label`] = "Select...";
              optionNumeratorObj[`value`] = "";
            } else {
              optionNumeratorObj[`value`] = source_kpi_ratios[item].numerator;
              optionNumeratorObj[`label`] = source_kpi_ratios[item].numerator;
            }
            if (source_kpi_ratios[item].denominator == "") {
              denominatorObj[`label`] = "Select...";
              denominatorObj[`value`] = "";
            } else {
              denominatorObj[`value`] = source_kpi_ratios[item].denominator;
              denominatorObj[`label`] = source_kpi_ratios[item].denominator;
            }
          }
        }

        return (
          <tr>
            <td>{item}</td>
            <td>
              <CustomSelectBox
                selectedValues={optionNumeratorObj}
                multiSelectOptions={display_objective_kpi.filter((obj) => {
                  return obj.value !== item;
                })}
                onChangeValues={(e) =>
                  this.handleRatioKpiChange(e, item, "numerator")
                }
                isOpen={false}
                valueKey="value"
                labelKey="label"
                isMultiple={false}
                includeFilter={true}
                selectBoxHeight={300}
                isDisabled={ingestionStatusAndType}
              />
            </td>
            <td>
              <CustomSelectBox
                selectedValues={denominatorObj}
                multiSelectOptions={display_objective_kpi.filter((obj) => {
                  return obj.value !== item;
                })}
                onChangeValues={(e) =>
                  this.handleRatioKpiChange(e, item, "denominator")
                }
                isOpen={false}
                valueKey="value"
                labelKey="label"
                isMultiple={false}
                includeFilter={true}
                selectBoxHeight={300}
                isDisabled={ingestionStatusAndType}
              />
            </td>
          </tr>
        );
      }
    );

    let displayObjectiveDriverKpisTable =
      driverKpiTableRows && driverKpiTableRows.length > 0 ? driverKpiTableRows.map((item, idx) => {
        return (
          <tr id="addr0" key={idx} style={{ width: "10%" }}>
            <td>{idx}</td>
            <td style={{ width: "45%" }}>
              <Select
                options={item.objectiveKpis.length == 0 ? [{ value: "", label: 'Select....' }, ...this.props.kpi_selected_panel_values] : [{ value: "", label: 'Select....' }, ...item.objectiveKpis]}
                value={item.selectedDriverObjectiveKPI}
                onChange={(options) => handleDriverObjectiveKPIChange(options, idx)}
                isDisabled={ingestionStatusAndType}
              />
            </td>
            <td style={{ width: "45%" }}>
              <CustomSelectBox
                selectedValues={item.selectedDriverKPIList && item.selectedDriverKPIList.length > 0 ? item.selectedDriverKPIList : []}
                multiSelectOptions={item.driverKpis.length == 0 ? this.props.kpi_selected_panel_values : item.driverKpis}
                onChangeValues={(options) => handleDriverKPIChange(options, idx)}
                isOpen={false}
                valueKey="value"
                labelKey="label"
                isMultiple={true}
                includeSelectAll={true}
                includeFilter={true}
                selectBoxHeight={300}
                isDisabled={ingestionStatusAndType}
              />
            </td>
            <td>
              <div className="delete">
                <a className={ingestionStatusAndType ? "disabled": ""} onClick={(e) =>
                  handleDeleteDriverKPI(item, idx)
                }
                >
                  <i className="far fa-trash-alt"></i>
                </a>
              </div>
            </td>
          </tr>
        )
      }
      ) : 
      <tr><td colSpan="4">No data found, Please create new one</td></tr>
      

    return (
      <div>
        <div className="objectie-kpi-threshold">
          <div className="objectie-kpi">
            <label>Objective KPI: </label>
            <div className="objective-select">
              <Select
                options={[{ value: "", label: 'Select....' }, ...display_objective_kpi]}
                value={selected_objective_Kpi}
                onChange={handleObjectiveKpiChange}
                isDisabled={ingestionStatusAndType}
              />
            </div>
          </div>

          <div className="threshold">
            <label>Objective KPI Threshold: </label>
            <div>
              <input
                type="text"
                pattern="^\d*(\.\d{0,2})?"
                onInput={(e) => handleThresholdValue(e)}
                value={threshold_value}
                disabled={ingestionStatusAndType}
              />
            </div>
          </div>
          <div className="other-columns">
            <label>Benchmark Column: </label>
            <div className="objective-select">
              <input
                type="text"
                onInput={(e) => handleBenchmarkPrefixFieldChange(e)}
                value={benchmark_prefix}
              />
            </div>
          </div>
        </div>
        <div className="source-kpi-container">
          {Object.keys(checked_ratio_kpis).length > 0 ? (
            <div>
              <label>
                <b>Ratio KPI's:</b>
              </label>
              <table id="workflow">
                <thead>
                  <tr>
                    <th>Source KPI</th>
                    <th>Numerator</th>
                    <th>Denominator</th>
                  </tr>
                </thead>
                <tbody>{display_source_kpi_table}</tbody>
              </table>
            </div>
          ) : (
            " "
          )}
        </div>
        

        {/* <fieldset className="alert-sum-fieldset">
          <legend><b>Alert Summerization Params:</b></legend>
          <div className="alert-sum-params-container">
            <div className="alert-sum-block">
              <label>Max percentage threshold: </label>
              <div className="info-container">
                <div className="ds-tooltip sum-tooltip">
                  <i className="fa fa-info-circle"></i>
                  <span class="ds-tooltiptext">Value should be between 0-1. For ex: 0.5</span>
                </div>
              </div>
              
              <div className="alert-sum-selection">
                <input
                  type="text"
                  pattern="^\d*(\.\d{0,2})?"
                  onInput={(e) => handleMaxPercentageThreshold(e)}
                  value={maxPercentageThreshold}
                  disabled={ingestionStatusAndType}
                />
              </div>
            </div>
            <div className="alert-sum-block">
              <label className="text-right">Top value selections: </label>
              <div className="info-container">
                <div className="ds-tooltip sum-tooltip">
                  <i className="fa fa-info-circle"></i>
                  <span class="ds-tooltiptext">Value should be an integer. For ex: 3</span>
                </div>
              </div>
              <div className="alert-sum-selection">
                <input
                  type="text"
                  onInput={(e) => handleTopValueSelections(e)}
                  value={topValueSelections}
                  disabled={ingestionStatusAndType}
                />
              </div>
            </div>
            <div className="alert-sum-block">
              <label className="text-right">Persistant Alert window: </label>
              <div className="info-container">
                <div className="ds-tooltip sum-tooltip">
                  <i className="fa fa-info-circle"></i>
                  <span class="ds-tooltiptext">Value should be an integer. For ex: 2</span>
                </div>
              </div>
              <div className="alert-sum-selection">
                <input
                  type="text"
                  onInput={(e) => handlePersistantAlertWindow(e)}
                  value={persistantAlertWindow}
                  disabled={ingestionStatusAndType}
                />
              </div>
            </div>
          </div>
          <div className="alert-sum-params-container">
            <div className="alert-sum-block">
              <label>Min percentage threshold: </label>
              <div className="info-container">
                <div className="ds-tooltip sum-tooltip">
                  <i className="fa fa-info-circle"></i>
                  <span class="ds-tooltiptext">Value should be less than Max percentage and between 0-1</span>
                </div>
              </div>
              <div className="alert-sum-selection">
                <input
                  type="text"
                  pattern="^\d*(\.\d{0,2})?"
                  onInput={(e) => handleMinPercentageThreshold(e)}
                  value={minPercentageThreshold}
                  disabled={ingestionStatusAndType}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "15px" }}>
            <div className="add-row">
              <button
                onClick={(e) => handleAddObjectiveDriverKPIRow()}
                disabled={ingestionStatusAndType}
              >
                <span>Add New</span>&nbsp;
                <span>
                  <i className="fa fa-plus"></i>
                </span>
              </button>
            </div>
            <table id="workflow">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Objective KPI</th>
                  <th>Driver KPIs</th>
                  <th>Delete</th>
                </tr>
              </thead>
              {displayObjectiveDriverKpisTable}
            </table>
          </div>


        </fieldset> */}

        {
          this.props.isEmptyObjectiveKpiOrThreshold ?
            <Modal
              isOpen={true}
              onRequestClose={() => this.props.closeObjectiveConfirmationModal()}
              style={alertModalStyles}
              contentLabel="alert"
              ariaHideApp={false}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div className="modal-title h4"> Alert</div>
                  <button
                    type="button"
                    className="close"
                    onClick={() => this.props.closeObjectiveConfirmationModal()}
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="alert-content">
                    <h4>
                      Are you sure, want to continue without Objective KPI or Threshold values ?
                    </h4>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    autoFocus onClick={() => this.props.continueToHybridHierarchyMap(this.props)}
                  >
                    Continue
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-alert"
                    onClick={() => this.props.closeObjectiveConfirmationModal()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal> : ''}
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => this.props.jumpToStep(1)}
          >
            Previous
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => jumptoHybridHierarchyMap(this.props)} autoFocus
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

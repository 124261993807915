import React from 'react';
import Modal from "react-modal";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        width: "50%",
        padding: "0x",
    },
};


export class IdleTimeOutModal extends React.Component {
    render() {
        const { showModal, handleClose, handleLogout } = this.props;
        return (
            <Modal
                isOpen={showModal}
                style={customStyles}
                contentLabel="Session TimeOUt"
                ariaHideApp={false}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4"> Session Time Out</div>
                    </div>
                    <div className="modal-body">
                        <div className="alert-content">
                            You Will Get Timed Out. You want to stay?
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={handleClose}
                        >
                            Extend my session
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-alert"
                            onClick={() => handleLogout()}
                        >
                            Logout
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default IdleTimeOutModal;
export const CONFIG_MS = process.env.REACT_APP_CONFIG_MS_ROOT;
export const LOGIN_MS = process.env.REACT_APP_LOGIN_MS_ROOT;
export const DASHBOARD_MS = process.env.REACT_APP_DASHBOARD_MS_ROOT;
//Home
export const INITIAL_STATE = {
  RCA_OP: null,
  pipeline: [],
  showPopup: false,
  popuptext: "",
  current_pipeline_state: null,
  users: [],
  pipeline_user_map: [],
  pipelineName: "",
  selected_rca_experiment_id: "",
  selected_rca_run_id: "",
  traversal_kpis:[],
  isPrivacyPolicyModalOpen:false,
  selectedPredictiveKpis:[],
  isRcaOptions:false,
  isSummerizationOptions:false,
  isDsSummaryPage:false,
  whatIf_upload_or_model:'model',
  rcaMaxHopsSchema: {
    type: "object",
    required: ["rca_max_hops", "impact_max_hops"],
    properties: {
      rca_max_hops: {
        type: "integer",
        title: "RCA Max Hops",
      },
      impact_max_hops: {
        type: "integer",
        title: "Impact Max Hops",
      },
    },
  },
  maxHopsData: { rca_max_hops: "2", impact_max_hops: "2" },
  lookup_range:null,
  predictiveKPIList:null,
  fwd_lookup_limit: 2,
  backward_lookup_limit: 2,
  selected_driver_kpis:[],
  selectedRecommenationKpis:[],
  recommendationKpiList:[],
  recommendation_ds_id:""
};
//Data Source
export const DATASOURCE_INIT_STATE = {
  isLoaderLoading:false,
  showAddDatasource: false,
  datasourceName: "",
  dataSources: [],
  delDataSourceIsOpen: false,
  delDataSourceId: null,
  currentDataSourceName: "",
  currentDataSourceId: null,
  showAddIngestionModal: false,
  captchaSuccess: false,
  isAdminUser:false,
  nameError:""
};
export const DESCRIPTIVE_SUMMARY_STATE = {
  showAnomalySaveRun: false,
  anomalyRunName: "",
  manualPaginationFlag:false,
  nameError:""
};
export const SUMMERIZATION_STATE = {
  maxPercentageThreshold: 0.5,
  minPercentageThreshold: 0.2,
  topValueSelections: 1,
  persistantAlertWindow: 2,
  driverKpiTableRows:[]
};
export const DATA_INGESTION = {
  currentDataSourceName: "",
  currentDataSourceId: null,
  current_datasource_state: null,
  dataIngestions:[],
  showAddIngestionModal:false,
  delDataIngestionIsOpen:false,
  nameError:""
};
export const UPLOAD_INITIALSTATE = {
  hierarchy_location: null,
  isLoaderLoading:false,
  kpiTypeMap: {},
  kpiSectionvalues: [
    { id: "1", name: "c" },
    { id: "2", name: "o" },
  ],
  frequency: [
    { value: "Year", label: "Years" },
    { value: "Quarters", label: "Quarters" },
    { value: "Month", label: "Months" },
    { value: "Week", label: "Weeks" },
    { value: "Day", label: "Days" },
  ],
  fileData: [],
  renderSets: [],
  hierarchySetValues: {},
  hierarchySetPanels: {
    hierarchySetMasterPanel: {
      id: "hierarchySetMasterPanel",
      title: "Hierarchy Values",
      panelObj: [],
    },
  },
  hierarchySets: {
    hybridHierarchy: {
      id: "hybridHierarchy",
      title: "hybridHierarchy Set Values",
      panelObj: [],
    },
  },
  hierarchyDataPanels: {
    hybridHierarchy: {
      id: "hybridHierarchy",
      title: "hybridHierarchy Set Values",
      panelObj: [],
    },
  },
  hierarchySetPanelsOrder: ["hierarchySetMasterPanel"],
  hierarchyDataMap: [{ split_hierarchy: "Overall", actual: "Overall" }],
  driverKpiTableRows:[],
  // driverKpiTableRows: [{
  //   objectiveKpis: [],
  //   driverKpis: [],
  //   selectedDriverObjectiveKPI: "",
  //   selectedDriverKPIList: ""
  // }],
  uploadstepPanelObjContent: [],
  modalIsOpen: false,
  uploadstepHierarchyValues: [],
  hierarchySetMasterPanelValues: [],
  hybridHierarchy: "",
  hybridHierarchy2: {},
  selectedfileName: "",
  selectedFileLabel: "",
  pipeline: [],
  pipelineName: "",
  showAddPipeline: false,
  showDeletePipeline: false,
  hierarchyfileNames: [],
  hierarchySelection: "",
  kpi_selection: "",
  hierarchyModelIsOpen: false,
  currentDataSourceId: null,
  showPopup: false,
  isShowNavigation: false,
  popuptext: "",
  showHierarchyAlert: false,
  overall_file_loaded: false,
  current_frequency: {},
  current_window: "",
  uploadedFiles: [],
  current_pipeline_state: null,
  filefirstRow: {},
  incrementalIsOpen: false,
  closedeletepipeline: false,
  deletepipelineIsOpen: false,
  deletepipelineId: "",
  uploadedGranularFile: "",
  IsuploadedGranularFile: false,
  isUploadedJsonConfigFile:false,
  kpiList: [],
  hierarchyCombination: [],
  current_uploaded_file: {},
  isRemoveHybridHierarchyOpen: false,
  ratio_kpis: {},
  source_kpi_ratios: {},
  threshold_value: "",
  time_options:[],
  kpi_selected_panel_values:[],
  hierarchy_selected_panel_values:[],
  kpi_options:[],
  hierarchy_options:[],
  time_panel_value:[],
  isEmptyObjectiveKpiOrThreshold:false,
  objective_kpi:"",
  maxPercentageThreshold: "",
  minPercentageThreshold: "",
  topValueSelections: "",
  persistantAlertWindow:""
};
export const UPLOAD_INCREMENTAL_STATE = {
  hierarchyDataMap: [],
};

//Anomaly
export const ANOMALY_INIT_STATE = {
  isLoaderLoading:false,
  dataSourcesList:[],
  anomalyTaskIds: [],
  anomalyExperiments: [],
  anomalyRuns: [],
  anomalyexp_id: null,
  anomalyPipeline: null,
  showAddAnomalyExp: false,
  anomalyExperimentName: "",
  anomalyhierarchySelection: {},
  valuesofAnomalyHierarchyselection: [],
  valuesAnomalySelection: [],
  anomaly_algorithms: [],
  anomaly_algo_types: [],
  algoType: {},
  userAlgo: [],
  algoData: null,
  algoParam: {},
  current_algo: "",
  pipelineName: "",
  hierarchyfileNames: [],
  hierarchySelection: "",
  current_algo_id: null,
  kpi_selection: [],
  AnomalyResultModelIsOpen: false,
  AnomalyKPI: [],
  algorithms: [],
  algo_types: [],
  anomaly_params: [],
  set_valid: {},
  algoResult: {},
  chartResult: {},
  isdeleteParamSel: false,
  delParmIndex: "",
  delParampObj: {},
  selectedAnomalyHierarchyVal: "",
  kpiList: [],
  lagVal: "",
  hierarchyCombination: [],
  valuesofHierarchyselection: [],
  display_exp_name: "",
  anomalyResults: {},
  anomalyExpName: "",
  isDeleteAnomalyExp: false,
  isDeleteAnomalyRun: false,
  selected_anomaly_kpi: "",
  algo_status: {},
  rerun_all: false,
  isAutoGenerateParamOpen: false,
  isClearAllSelectionModalOpen:false,
  client_side_anomaly_load:[],
  kpi_options:[],
  anomaly_pages:0,
  selected_anomaly_kpis:[],
  isAdminUser:false,
  nameError:"",
  valuesDSFilterSelection:[],
  level_hierarcy_values_obj_selectedArr:{},
  dsHierarchySelection:"",
  valuesofDSHierarchyselection:[],
  dsSummaryResultAllData:[],
  isExportAllModelOpen:false
};
export const ANOMALY_RESULT_STATE = {
  showAnomalySaveRun: false,
  anomalyRunName: "",
  manualPaginationFlag:false,
  nameError:""
};
//Causal
export const CAUSAL_INIT_STATE = {
  isLoaderLoading:false,
  replicate_index_map: {},
  causalGraphData: [],
  hierarchyDataMap: [{ split_hierarchy: "Overall", actual: "Overall" }],
  causalExperiments: [],
  hybridHierarchy: "",
  pipeline: [],
  hierarchyfileNames: [],
  hierarchySelection: "",
  hierarchyCombination: [],
  algoType: "",
  current_algo: "",
  current_algo_id: null,
  kpi_selection: "",
  valuesofHierarchyselection: [],
  current_causal: null,
  selectedValuesOfCausalHierarchy: [],
  algorithms: [],
  algoParam: {},
  causalPipeline: null,
  algoResult: {},
  causalData: [],
  current_causal_model: null,
  causal_overall: [],
  causal_models: [],
  causal_hierarchy: [],
  deletepipelineId: "",
  causalExpName: "",
  showCausalRun: false,
  kpiList: [],
  selectCausalEdgeValue: {},
  causaltempEdges: [],
  toKpiList: [],
  lagVal: "",
  causalRuns: [],
  selectedRun: {},
  causalAlgoList: [],
  causalexp_id: "",
  causalrun_id: "",
  causalhierarchySelection: {},
  causalvalueSelection: [],
  valuesofCausalHierarchyselection: [],
  showCommonEdgeModal: false,
  selectedEdge: "",
  causalUserEdges: [],
  causalDiscardEdges: [],
  causalFixedEdges: [],
  causal_params: [],
  estimate_params: [],
  subKpiLag: [],
  kpiLagList: [],
  causalAlgoParam: {},
  causalAlgoData: {},
  causalEstimateParam: {},
  causalEstimationData: {},
  showCausalSaveRun: false,
  causalRunName: "",
  selected_causal_graph_result: [],
  showNewFromToKPi: false,
  causalhierarchyfileNames: [],
  strength: [],
  causalEstimations: [],
  showEstimateResults: false,
  showReplicateModal: false,
  valuesofReplicateHierarchyselection: [],
  replicatehierarchySelection: "",
  replicatevalueSelection: "",
  replicate_params: {},
  discardFixedEdgesShow: true,
  userGraphEdgesShow: false,
  selected_causal_estimation_result: [],
  display_estimate_hierarchy: "",
  display_estimate_value: "",
  confirmDelCausalParamSelectionModalIsOpen: false,
  isDeleteCausalExp: false,
  isDeleteCausalRun: false,
  confirmDelEStimateParamSelectionModalIsOpen: false,
  causal_models: [],
  causalData: [],
  isAdminUser:false,
  nameError:""
};
export const CAUSAL_PROJECT_STATE = {
  fileData: [],
  hierarchySetKeyObjData: [],
  timeValues: [],
  kpiValues: [],
  hierarchyValues: [],
  hierarchySetValues: {},
  draggedValues: {},
  hirerachySets: [],
  renderSets: [],
  modalIsOpen: false,
  hybridHierarchy: "",
  hybridHierarchy2: {},
  selectedfileName: "",
  selectedFileLabel: "",
  pipeline: [],
  pipelineName: "",
  showAddPipeline: false,
  showDeletePipeline: false,
  hierarchySelection: "",
  algoType: "",
  current_algo_id: null,
  current_algo: "",
  kpi_selection: "",
  hierarchyModelIsOpen: false,
  AnomalyResultModelIsOpen: false,
  currentPipeline: null,
  showPopup: false,
  isShowNavigation: false,
  
  popuptext: "",
  showHierarchyAlert: false,
  algorithms: [],
  userAlgo: [],
  algoParam: {},
  algo_types: [],
  anomaly_params: [],
  causalPipeline: null,
  algoResult: {},
  chartResult: {},
  causalData: [],
  current_causal_model: null,
  causal_overall: [],
  causal_models: [],
  causal_hierarchy: [],
  overall_file_loaded: false,
  uploadedFiles: [],
  current_pipeline_state: null,
  RCA_OP: null,
  filefirstRow: {},
  incrementalIsOpen: false,
  closedeletepipeline: false,
  deletepipelineIsOpen: false,
  deletepipelineId: "",
  isdeleteParamSel: false,
  delParmIndex: "",
  delParampObj: {},
  selectedAnomalyHierarchyVal: "",
  causalExpName: "",
  showCausalRun: false,
  kpiList: [],
  selectCausalEdgeValue: {},
  causaltempEdges: [],
  toKpiList: [],
  lagVal: "",
  causalRuns: [],
  selectedRun: {},
  causalAlgoList: [],
  causalexp_id: "",
  causalrun_id: "",
  causalhierarchySelection: {},
  causalvalueSelection: [],
  valuesofCausalHierarchyselection: [],
  showCommonEdgeModal: false,
  selectedEdge: "",
  causalUserEdges: [],
  causalDiscardEdges: [],
  causalFixedEdges: [],
  causal_params: [],
  subKpiLag: [],
  kpiLagList: [],
  causalAlgoParam: {},
  causalAlgoData: {},
  causalEstimateParam: {},
  causalEstimationData: {},
  showCausalSaveRun: false,
  causalRunName: "",
  selected_causal_graph_result: [],
  showNewFromToKPi: false,
  causalhierarchyfileNames: [],
  strength: [],
  causalEstimations: [],
  showEstimateResults: false,
  showReplicateModal: false,
  valuesofReplicateHierarchyselection: [],
  replicatehierarchySelection: "",
  replicatevalueSelection: "",
  replicate_params: {},
  discardFixedEdgesShow: true,
  userGraphEdgesShow: false,
  selected_causal_estimation_result: [],
  display_estimate_hierarchy: "",
  display_estimate_value: "",
};
export const CAUSAL_RESULT_STATE = {
  selected_causal_grpah_rowIndx: null,
  selectedDropDownOption: null,
  selectedToEdge: null,
  selectedFromEdge: null,
  selectedEdgeLagVal: null,
  causaltempEdges: [],
  frequency: [],
  fileData: [],
  hierarchySetKeyObjData: [],
  timeValues: [],
  kpiValues: [],
  hierarchyValues: [],
  hierarchySetValues: {},
  draggedValues: {},
  hirerachySets: [],
  renderSets: [],
  modalIsOpen: false,
  hybridHierarchy: "",
  hybridHierarchy2: {},
  selectedfileName: "",
  selectedFileLabel: "",
  pipeline: [],
  pipelineName: "",
  showAddPipeline: false,
  showDeletePipeline: false,
  hierarchySelection: "",
  algoType: "",
  current_algo_id: null,
  current_algo: "",
  kpi_selection: "",
  hierarchyModelIsOpen: false,
  AnomalyResultModelIsOpen: false,
  currentPipeline: null,
  showPopup: false,
  isShowNavigation: false,
  
  popuptext: "",
  showHierarchyAlert: false,
  algorithms: [],
  userAlgo: [],
  algoParam: {},
  algo_types: [],
  anomaly_params: [],
  causalPipeline: null,
  algoResult: {},
  chartResult: {},
  causalData: [],
  current_causal_model: null,
  causal_overall: [],
  causal_models: [],
  causal_hierarchy: [],
  overall_file_loaded: false,

  uploadedFiles: [],
  current_pipeline_state: null,
  RCA_OP: null,
  filefirstRow: {},
  incrementalIsOpen: false,
  closedeletepipeline: false,
  deletepipelineIsOpen: false,
  deletepipelineId: "",
  isdeleteParamSel: false,
  delParmIndex: "",
  delParampObj: {},
  selectedAnomalyHierarchyVal: "",
  causalExpName: "",
  showCausalRun: false,
  kpiList: [],
  selectCausalEdgeValue: {},
  causaltempEdges: [],
  toKpiList: [],
  lagVal: "",
  causalRuns: [],
  selectedRun: {},
  causalAlgoList: [],
  causalexp_id: "",
  causalrun_id: "",
  causalhierarchySelection: {},
  causalvalueSelection: [],
  valuesofCausalHierarchyselection: [],
  showCommonEdgeModal: false,
  selectedEdge: "",
  causalUserEdges: [],
  causalDiscardEdges: [],
  causalFixedEdges: [],
  causal_params: [],
  subKpiLag: [],
  kpiLagValList: {},
  causalAlgoParam: {},
  causalAlgoData: {},
  causalEstimateParam: {},
  causalEstimationData: {},
  showCausalSaveRun: false,
  causalRunName: "",
  selected_causal_graph_result: [],
  showNewFromToKPi: false,
  causalhierarchyfileNames: [],
  strength: [],
  causalEstimations: [],
  showEstimateResults: false,
  showReplicateModal: false,
  valuesofReplicateHierarchyselection: [],
  replicatehierarchySelection: "",
  replicatevalueSelection: "",
  replicate_params: {},
  discardFixedEdgesShow: true,
  userGraphEdgesShow: false,
  selected_causal_estimation_result: [],
  display_estimate_hierarchy: "",
  display_estimate_value: "",
  kpiAutoCorr: [],
  causal_result_replicate_params: {},
  showCausalResultReplicateModal: false,
  valuesofCausalResultReplicateHierarchyselection: [],
  replicatecausalResulthierarchySelection: "",
  replicatecausalResultvalueSelection: [],
  showGraph: false,
  causal_algo_display_name: "",
  selected_causal_algo_label: "",
  selected_kpi_lag: "",
  showlagRules: false,
  valueskpiSelection: [],
  lagValueSelected: {},
  bulk_load_flag: "",
  selected_kpi_Obj:{},
  from_to_kpi_obj_for_graph:{},
  toKpiListForGraph:[],
  to_kpi_selection: null,
  from_kpi_selction: null,
  isClearAllModalOpen:false,
  nameError:""
};
export const CAUSAL_EXPERIMENT_STATE = {
  frequency: [],
  fileData: [],
  hierarchySetKeyObjData: [],
  timeValues: [],
  kpiValues: [],
  hierarchyValues: [],
  hierarchySetValues: {},
  draggedValues: {},
  hirerachySets: [],
  renderSets: [],
  modalIsOpen: false,
  hybridHierarchy: "",
  hybridHierarchy2: {},
  selectedfileName: "",
  selectedFileLabel: "",
  pipeline: [],
  pipelineName: "",
  showAddPipeline: false,
  showDeletePipeline: false,
  hierarchySelection: "",
  algoType: "",
  current_algo_id: null,
  current_algo: "",
  kpi_selection: "",
  hierarchyModelIsOpen: false,
  AnomalyResultModelIsOpen: false,
  currentPipeline: null,
  showPopup: false,
  isShowNavigation: false,
  
  popuptext: "",
  showHierarchyAlert: false,
  algorithms: [],
  userAlgo: [],
  algoParam: {},
  algo_types: [],
  anomaly_params: [],
  causalPipeline: null,
  algoResult: {},
  chartResult: {},
  causalData: [],
  current_causal_model: null,
  causal_overall: [],
  causal_models: [],
  causal_hierarchy: [],
  overall_file_loaded: false,
  uploadedFiles: [],
  current_pipeline_state: null,
  RCA_OP: null,
  filefirstRow: {},
  incrementalIsOpen: false,
  closedeletepipeline: false,
  deletepipelineIsOpen: false,
  deletepipelineId: "",
  isdeleteParamSel: false,
  delParmIndex: "",
  delParampObj: {},
  causalExpName: "",
  showCausalRun: false,
  kpiList: [],
  selectCausalEdgeValue: {},
  causaltempEdges: [],
  toKpiList: [],
  lagVal: "",
  causalRuns: [],
  selectedRun: {},
  causalAlgoList: [],
  causalexp_id: "",
  causalrun_id: "",
  causalhierarchySelection: {},
  causalvalueSelection: [],
  valuesofCausalHierarchyselection: [],
  showCommonEdgeModal: false,
  selectedEdge: "",
  causalUserEdges: [],
  causalDiscardEdges: [],
  causalFixedEdges: [],
  causal_params: [],
  subKpiLag: [],
  kpiLagList: [],
  causalAlgoParam: {},
  causalAlgoData: {},
  causalEstimateParam: {},
  causalEstimationData: {},
  showCausalSaveRun: false,
  causalRunName: "",
  selected_causal_graph_result: [],
  showNewFromToKPi: false,
  causalhierarchyfileNames: [],
  strength: [],
  causalEstimations: [],
  showEstimateResults: false,
  showReplicateModal: false,
  valuesofReplicateHierarchyselection: [],
  replicatehierarchySelection: "",
  replicatevalueSelection: "",
  replicate_params: {},
  discardFixedEdgesShow: true,
  userGraphEdgesShow: false,
  selected_causal_estimation_result: [],
  display_estimate_hierarchy: "",
  display_estimate_value: "",
};
export const CAUSAL_ESTIMATE_STATE = {
  valuesofEstimateHierarchyselection: [],
  estimatehierarchySelection: "",
  estimatevalueSelection: [],
  selected_estimate_replicates: {},
  fileData: [],
  hierarchySetKeyObjData: [],
  timeValues: [],
  kpiValues: [],
  hierarchyValues: [],
  hierarchySetValues: {},
  draggedValues: {},
  hirerachySets: [],
  renderSets: [],
  modalIsOpen: false,
  selectedfileName: "",
  selectedFileLabel: "",
  pipeline: [],
  pipelineName: "",
  showAddPipeline: false,
  showDeletePipeline: false,
  hierarchySelection: "",
  algoType: "",
  current_algo_id: null,
  current_algo: "",
  current_algo_id: null,
  kpi_selection: "",
  hierarchyModelIsOpen: false,
  currentPipeline: null,
  showPopup: false,
  isShowNavigation: false,
  
  popuptext: "",
  showHierarchyAlert: false,
  algorithms: [],
  userAlgo: [],
  algoParam: {},
  algo_types: [],
  anomaly_params: [],
  causalPipeline: null,
  algoResult: {},
  chartResult: {},
  causalData: [],
  current_causal_model: null,
  causal_overall: [],
  causal_models: [],
  causal_hierarchy: [],
  overall_file_loaded: false,
  current_frequency: 1,
  uploadedFiles: [],
  current_pipeline_state: null,
  RCA_OP: null,
  filefirstRow: {},
  incrementalIsOpen: false,
  closedeletepipeline: false,
  deletepipelineIsOpen: false,
  deletepipelineId: "",
  isdeleteParamSel: false,
  delParmIndex: "",
  delParampObj: {},
  causalExpName: "",
  showCausalRun: false,
  kpiList: [],
  selectCausalEdgeValue: {},
  causaltempEdges: [],
  toKpiList: [],
  lagVal: "",
  causalRuns: [],
  selectedRun: {},
  causalAlgoList: [],
  causalexp_id: "",
  causalrun_id: "",
  causalhierarchySelection: {},
  causalvalueSelection: [],
  valuesofCausalHierarchyselection: [],
  showCommonEdgeModal: false,
  selectedEdge: "",
  causalUserEdges: [],
  causalDiscardEdges: [],
  causalFixedEdges: [],
  causal_params: [],
  subKpiLag: [],
  kpiLagList: [],
  causalAlgoParam: {},
  causalAlgoData: {},
  causalEstimateParam: {},
  causalEstimationData: {},
  showCausalSaveRun: false,
  causalRunName: "",
  selected_causal_graph_result: [],
  showNewFromToKPi: false,
  causalhierarchyfileNames: [],
  strength: [],
  causalEstimations: [],
  showEstimateResults: false,
  showReplicateModal: false,
  valuesofReplicateHierarchyselection: [],
  replicatehierarchySelection: "",
  replicatevalueSelection: "",
  replicate_params: {},
  discardFixedEdgesShow: true,
  userGraphEdgesShow: false,
  selected_causal_estimation_result: [],
  display_estimate_hierarchy: "",
  display_estimate_value: "",
  showCausalEstimateGraph: false,
  isEstimateExportModalOpen: false,
  bulk_load_flag: "",
};

export const PAGINATION_RANGE =[50,100, 200, 300,400, 500]
export const DYNAMIC_TABLE_PAGINATION_RANGE = [10,50,100,200,300,400,500]
export const INGESTION_STATUS = "STATIC INGESTION COMPLETED" 
export const INGESTION_TYPE =  "HISTORICAL"
export const NAME_ERROR = "Please check input. Only AlphaNumeric with _ , max 30 characters without space in between allowed."
import React from "react";
import { DAG } from "./DAG";
import "./CausalModel.css";
import { toast } from "react-toastify";
import { CONFIG_MS } from "../../consts";
import { HierarchyValueDropDown } from "../HierarchyValueDropDown";
import { handleErrors } from "../ui_utils";
import Modal from "react-modal";
import { fetchCall } from "../util/util";
var _loadash = require("lodash");

const confirmDeleteModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
const confirmClearAllOption = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "0px",
  },
};
export class CausalUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValuesOfCausalHierarchy: [],
      isCausalModalGraphOpen: false,
      isClearAllSelecionOpen: false,
      causal_file_result:"",
      causal_file:"",
      overall_causal_filename:"",
      causal_filename:""
    };
  }
  componentDidMount() {
    if (this.props.match.params.run_id === "undefined") {
      alert("Please save the run");
      this.props.history.push(
        `/app/causal/experiment/${this.props.match.params.exp_id}/run/`
      );
    } else {
      this.props.getRun(this.props.match.params.run_id);
      this.props.loadDataSourceData(this.props.match.params.exp_id);
    }
  }
  handleCausalFileUpload = (value) => {
    let selected_file = value.target.files[0] || null;
    if (selected_file){ 
      let uploaded_file_name = selected_file.name
      const filenameRegex = /^[a-z0-9A-Z_\s()-]{1,60}\.csv$/gm
      if(!filenameRegex.test(uploaded_file_name)){
        value.target.value = ""
        this.setState({
          selected_causal_relation: "",
          isCausalUploaded:false,
          causal_filename: ""
        });
        this.props.togglePopup("Invalid file name");
        return
      }
      const Papa = require("papaparse/papaparse.min.js");
      Papa.parse(selected_file, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: this.updateCausalFileUpload,
      });
    }
    this.setState({
      selected_causal_relation: value.target.value,
      isCausalUploaded:true,
      causal_filename: value.target.value
    });
  };
  updateCausalFileUpload = (result,file) => {
    if(this.handleMoreKpisUpload(result.data)){
      this.props.togglePopup("Uploaded file have more kpis than DataSource Kpis");
      this.setState({
        isCausalUploaded:false,
        selected_causal_relation: "",
        causal_filename: ""
      });
      return
    }
    let isColumnsError = this.validateOverallCausalColumns(result.data)
    if(isColumnsError){
      const text = `Please check if all these columns are present in causal file ${[
        "from_kpi",
        "to_kpi",
        "strength",
        "type",
        "hierarchy",
        "value",
        "lag",
        "estimate",
      ]}`;
      this.props.togglePopup(text);
      this.setState({
        causal_filename:"",
        causal_file_result: "",
        causal_file: "",
        isCausalUploaded:false
      });
      return
    }else{
      this.setState({
        causal_file_result: result,
        causal_file:file,
        isCausalUploaded:true,
        causal_filename:file.name
      });
    }
  }
  addCausalGraph = () => {
    let result = this.state.causal_file_result
    let file  = this.state.causal_file
    this.renderCausal(result,file)
  };
  loadCausalRun = (exp_id, run_id) => {
    this.props.history.push(`/app/causal/experiment/${exp_id}/run/${run_id}/`);
  };
  validateOverallCausalColumns = (data) => {
    if (
      !Object.keys(data[0]).every((val) =>
        [
          "from_kpi",
          "to_kpi",
          "type",
          "hierarchy",
          "value",
          "lag",
          "estimate",
          "strength"
        ].includes(val)
      )
    ) {
      return true;
    }
  }
  validateCausalRelationColumn = (data) => {
    if (
      !Object.keys(data[0]).every((val) =>
        [
          "from_kpi",
          "to_kpi",
          "type",
          "hierarchy",
          "value",
          "lag",
          "estimate",
          "strength"
        ].includes(val)
      )
    ) {
      const text = `Please check if all these columns are present in causal file ${[
        "from_kpi",
        "to_kpi",
        "strength",
        "type",
        "hierarchy",
        "value",
        "lag",
        "estimate",
      ]}`;
      this.props.togglePopup(text);
      return;
    }
  }
  addFilePathBasedCausal = (file_path) => {
    if (!this.props.causalhierarchySelection || this.props.selectedValuesOfCausalHierarchy.length == 0) {
      const text = "Please select all the fields";
      this.props.togglePopup(text);
      return;
    }
    let hierarchySelection = this.props.causalhierarchySelection.value;
    let valueSelection = this.props.selectedValuesOfCausalHierarchy;
    let causal_models = this.props.causal_models;
    let causalData = _loadash.cloneDeep(this.props.causalData);
    let duplicateFlag = false;
    let prev_val = [];
    let causal_hierarchy = this.state.causal_hierarchy || [];
    causal_hierarchy = causal_hierarchy.concat(null);
    valueSelection.forEach((obj) => {
      let final_str = hierarchySelection.concat(",", obj.name);

      if (prev_val !== undefined && prev_val.indexOf(final_str) >= 0) {
        return (duplicateFlag = true);
      } else {
        if (!causal_models.some((ob) => ob.valueSelection === obj.name)) {
          causalData.push({ nodes: [], links: [] });
          causal_models.push({
            hierarchySelection: hierarchySelection,
            valueSelection: obj.name,
            causal: file_path,
            payload: null,
          });
        }
      }
      if (duplicateFlag != undefined && duplicateFlag === true) {
        const text = "Some combination already exists in selection!!";
        this.props.togglePopup(text);
        return;
      }
    });
    this.setState({
      causal_hierarchy: causal_hierarchy,
    });
    this.props.updateCausalUploadState(causalData, causal_models,this.props.match.params.run_id);
  }
  saveOverallCausalFilePath = (path) => {
    this.setState({ overall_causal_files_path: path });
  }
  saveCausalFilePath = (path) => {
    this.setState({ causal_files_path: path });
  }
  handleOverallCausalPathUpload = () => {
    let overall_causal_files_path = this.state.overall_causal_files_path
    if (!overall_causal_files_path) {
      const text = "Please select file path";
      this.props.togglePopup(text);
      return;
    }
    let toastId = null;
    toastId = toast("uploading anomaly csv file", { type: toast.TYPE.INFO, autoClose: true });
   
    const data = JSON.stringify({
      user_uuid: JSON.parse(localStorage.getItem("uid")),
      overall_causal_files_path: overall_causal_files_path
    })
    fetchCall(`/app/api/causal/overall/upload/`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        if (resultData == null) {
          toast.update(toastId, {
            render: "File uploaded successfully, Please refresh the page to view results",
            type: toast.TYPE.SUCCESS,
            autoClose: 4000,
          });
        } else {
          toast.update(toastId, {
            render: "ERROR: " + resultData.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  }
  handleCausalPathUpload = () => {
    let causal_files_path = this.state.causal_files_path
    if (!causal_files_path) {
      const text = "Please select file path";
      this.props.togglePopup(text);
      return;
    }
    let toastId = null;
    toastId = toast("uploading anomaly csv file", { type: toast.TYPE.INFO, autoClose: true });
   
    const data = JSON.stringify({
      user_uuid: JSON.parse(localStorage.getItem("uid")),
      causal_files_path: causal_files_path
    })
    fetchCall(`/app/api/causal/upload/`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        if (resultData == null) {
          toast.update(toastId, {
            render: "File uploaded successfully, Please refresh the page to view results",
            type: toast.TYPE.SUCCESS,
            autoClose: 4000,
          });
        } else {
          toast.update(toastId, {
            render: "ERROR: " + resultData.message,
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.update(toastId, {
          render: "ERROR: " + error.message,
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      });
  }
  renderCausal = (result, file) => {
    if (!result || !file || !this.props.causalhierarchySelection || this.props.selectedValuesOfCausalHierarchy.length == 0
      || !this.state.current_causal_model) {
      const text = "Please select all the fields";
      this.props.togglePopup(text);
      return;
    }
    const data = result.data;
    //this.validateCausalRelationColumn(data)
    
    let hierarchySelection = this.props.causalhierarchySelection.value;
    let valueSelection = this.props.selectedValuesOfCausalHierarchy;

    let causal_models = this.props.causal_models;
    let file_name = file.name;
    let prev_val = [];
    if (causal_models.length > 0) {
      prev_val = causal_models.map((obj, i) => {
        return obj.hierarchySelection.concat(",", obj.valueSelection);
      });
    }

    let causal_hierarchy = this.state.causal_hierarchy || [];
    causal_hierarchy = causal_hierarchy.concat(data);

    let causalData = _loadash.cloneDeep(this.props.causalData);

    let duplicateFlag = false;

    const { node_obj, links } = this.generateDAGPayload(data);
    valueSelection.forEach((obj) => {
      let final_str = hierarchySelection.concat(",", obj.name);

      if (prev_val !== undefined && prev_val.indexOf(final_str) >= 0) {
        return (duplicateFlag = true);
      } else {
        if (!causal_models.some((ob) => ob.valueSelection === obj.name)) {
          causalData.push({ nodes: node_obj, links: links });
          causal_models.push({
            hierarchySelection: hierarchySelection,
            valueSelection: obj.name,
            causal: file_name,
            payload: data,
          });
        }
      }
    });
    if (duplicateFlag != undefined && duplicateFlag === true) {
      const text = "Some combination already exists in selection!!";
      this.props.togglePopup(text);
      return;
    }
    this.props.updateCausalUploadState(causalData, causal_models,this.props.match.params.run_id);
    this.setState({
      causal_hierarchy: causal_hierarchy,
    });
  };
  generateDAGPayload = (data) => {
    let nodes = new Set();
    let links = [];
    data.forEach((element, index) => {
      nodes.add(element.to_kpi);
      nodes.add(element.from_kpi);
      links.push({
        source: element.from_kpi,
        target: element.to_kpi,
        type: "Causal",
        lag: element.lag,
        strength: element.strength,
      });
    });
    let kpi_id = {};
    const node_obj = Array.from(nodes).map((obj, index) => {
      kpi_id[obj] = index;
      return { name: obj, label: obj, id: index };
    });
    links = links.map((obj, index) => {
      let source = obj.source;
      let target = obj.target;
      return {
        source: kpi_id[source],
        target: kpi_id[target],
        type: `Causal / Lag: ${obj.lag}`,
        lag: obj.lag,
        strength: obj.strength,
      };
    });
    return { node_obj, links };
  };
  handleOverallCausal = (value) => {
    let selected_file = value.target.files[0] || null;
    if (selected_file){ 
      let uploaded_file_name = selected_file.name
      const filenameRegex = /^[a-z0-9A-Z_\s()-]{1,60}\.csv$/gm
      if(!filenameRegex.test(uploaded_file_name)){
        value.target.value = ""
        this.setState({
          current_causal_model: "",
          overall_causal_filename: "",
          isOverallCausalUploaded:false
        });
        this.props.togglePopup("Invalid file name");
        return
      }
      const Papa = require("papaparse/papaparse.min.js");
      Papa.parse(selected_file, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: this.updateOverallCausal,
      });
    }
    this.setState({
      current_causal_model: value.target.value,
      overall_causal_filename: value.target.value,
      isOverallCausalUploaded:true
    });
  };
  updateOverallCausal = (result, file) => {
    if(this.handleMoreKpisUpload(result.data)){
      this.props.togglePopup("Uploaded File have more kpis than DataSource KPIs");
      this.setState({
        isOverallCausalUploaded:false,
        current_causal_model: "",
        overall_causal_filename: ""
      });
      return
    }
    let isColumnsError = this.validateOverallCausalColumns(result.data)
    if(isColumnsError){
      const text = `Please check if all these columns are present in causal file ${[
        "from_kpi",
        "to_kpi",
        "strength",
        "type",
        "hierarchy",
        "value",
        "lag",
        "estimate",
      ]}`;
      this.props.togglePopup(text);
      this.setState({
        isOverallCausalUploaded:false,
        current_causal_model: "",
        overall_causal_filename: ""
      });
      return
    }else{
      this.setState({
        current_causal_model: result,
        overall_causal_filename: file.name,
        isOverallCausalUploaded:true
      });
    }
  };
  handleMoreKpisUpload = (data) => {
    let from_kpis = data.map((item)=>{
      return item.from_kpi
    })
    from_kpis = _loadash.uniq(from_kpis);
    if(from_kpis.length > this.props.kpiList.length){
      return true
    }
  }
  addCausalSelection = () => {
    const causal_models = this.props.causal_models;
    const current_causal_model = this.state.current_causal_model;
    let valueSelection = this.props.selectedValuesOfCausalHierarchy;
    const exp_id = this.props.match.params.exp_id;
    const run_id = this.props.match.params.run_id;
    let toastId = null;
    toastId = toast("Exporting MODEL", {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    const data = JSON.stringify({
      causal_model: causal_models,
      causal_overall: current_causal_model,
      user_uuid: JSON.parse(localStorage.getItem("uid")),
      valueSelection: valueSelection,
    })
    fetchCall(`/app/api/causal/export/${exp_id}/${run_id}`,'POST',data)
      .then((response) => handleErrors(response))
      .then((resultData) => {
        this.props.updateHierarchyValues();
        toast.update(toastId, {
          render: "Causal Relation created Successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
        this.props.saveProgress(run_id);
      })
      .catch((error) => {
        toast.update(toastId, {
          render: `Upload Failed ${error}`,
          type: toast.TYPE.ERROR,
        });
        console.log("Error in Fetching Result", error);
      });
  };
  filter_causal_data = (index, Obj) => {
    let flag = this.state.isCausalModalGraphOpen;
    this.setState({
      current_causal: index,
      selected_model_Obj: Obj,
      isCausalModalGraphOpen: !flag,
    });
  };
  removeCausalSel = (index, Obj) => {
    let causal_models = _loadash.cloneDeep(this.props.causal_models);

    let causalData = _loadash.cloneDeep(this.props.causalData);

    let filtered_causal_models = causal_models.filter((obj) => {
      return obj.valueSelection !== Obj.valueSelection;
    });
    let filtered_causal_data = causalData.filter((obj) => {
      delete causalData[index];
      return obj;
    });
    this.props.updateCausalUploadState(
      filtered_causal_data,
      filtered_causal_models,
      this.props.match.params.run_id
    )
  };
  clearAllSelections = () => {
    this.setState({
      isClearAllSelecionOpen: true
    })

  }
  cancelClearAllSelecion = () => {
    this.setState({
      isClearAllSelecionOpen: false
    })
  }
  continueClearAllSelecion = () => {
    this.props.updateCausalUploadState([], [],this.props.match.params.run_id);
    this.setState({
      isClearAllSelecionOpen: false
    })
  }
  cancelCausalModalGraph = () => {
    this.setState({
      isCausalModalGraphOpen: false,
    });
  };
  handleChangeOverallCausalFile = () => {
    this.setState({
      current_causal_model:"",
      overall_causal_filename:"",
      isOverallCausalUploaded:false

    })
  }
  handleChangeCausalFile = () => {
    this.setState({
      isCausalUploaded:false,
      causal_filename:"",
      selected_causal_relation:"",
      causal_file:"",
      causal_file_result:""
    })
  }

  render() {
    const {
      hierarchyfileNames,
      valuesofCausalHierarchyselection,
      handleCausalHierarchyChange,
      current_causal,
      causal_models,
      causalData,
    } = this.props;
    const overallCausalStyle = this.state.isOverallCausalUploaded ? { display: "none" } : {};
    const uploadedOverallCausal = this.state.isOverallCausalUploaded ? {} : { display: "none" };

    const causalStyle = this.state.isCausalUploaded ? { display: "none" } : {};
    const uploadedCausal = this.state.isCausalUploaded ? {} : { display: "none" };

    let hierarchyfileNamesList = hierarchyfileNames.map((item, i) => {
      return {
        value: item,
        label: item,
      };
    });
    return (
      <div>
        <React.Fragment>
          <button
            onClick={(e) =>
              this.loadCausalRun(
                this.props.match.params.exp_id,
                this.props.match.params.run_id
              )
            }
            className="btn btn-sm btn-primary back-btn"
          >
            <i className="fa fa-arrow-left"></i> Back
          </button>
        </React.Fragment>
        <div className="causal_relation_heading">
          <h4>Upload Causal Relations</h4>
          <h4>
            <span>Project : {localStorage.getItem("causalExpName")}</span>
            <span></span>(<span>Data Source:</span>
            <span>{localStorage.getItem("dataSourceName")})</span> / &nbsp;
            Experiment : {this.props.displayCausalRunName}
          </h4>
        </div>
        <div className="over_all_causal">
          <fieldset>
            <legend>Overall Causal relation:</legend>
            <div class="upload-anomaly-file">
              <div class="upload-field" style={overallCausalStyle}>
                <div className="m-10">
                  <label>Upload selection file :</label>
                  {" "}
                  <input key={this.state.overall_causal_filename} onChange={(e)=>this.handleOverallCausal(e)} type="file" />
                </div>
              </div>
              <div className="uploaded_csv" style={uploadedOverallCausal}>
                  <span>
                    Uploaded overall Causal File: <b> {this.state.overall_causal_filename} </b>{" "}
                  </span>
                  <span>
                    <a className="change-uploadfile" onClick={this.handleChangeOverallCausalFile}>
                      Change/edit
                    </a>
                  </span>
              </div>
              <div class="upload-or-container">
                <hr className="hr-text" data-content="OR" />
              </div>
              <div class="upload-path">
                <div class="m-10 upload-input-field">
                  <input placeholder="Overall causal file is located at!"  onChange={(e) => this.saveOverallCausalFilePath(e.target.value)} type="text" />
                  <button className="upload-btn"
                    onClick={(e) => this.handleOverallCausalPathUpload()}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <div className="causal_upload_hier">
            <HierarchyValueDropDown
              hierarchies={hierarchyfileNamesList.filter(
                (obj) => obj.label.toLowerCase() != "overall"
              )}
              hierarchyValues={this.props.selectedValuesOfCausalHierarchy}
              hierarchyValueOptions={valuesofCausalHierarchyselection}
              hierarchyValuesOnChange={
                this.props.multiSelectionOfCausalHierarchyValues
              }
              hierarchyOnchange={handleCausalHierarchyChange}
              valueSelected={this.props.causalhierarchySelection}
            />
          </div>

          <fieldset>
            <legend>Select Causal File:</legend>
            <div class="upload-anomaly-file">
              <div class="upload-field" style={causalStyle}>
                <div className="m-10">
                  <label>Upload selection file :</label>
                  {" "}
                  <input key={this.state.causal_filename} onChange={this.handleCausalFileUpload} type="file" />
                </div>
              </div>
              <div className="uploaded_csv" style={uploadedCausal}>
                  <span>
                    Uploaded Causal File: <b>{this.state.causal_filename}</b>{" "}
                  </span>
                  <span>
                    <a className="change-uploadfile" onClick={this.handleChangeCausalFile}>
                      Change/edit
                    </a>
                  </span>
              </div>
              <div class="upload-or-container">
                <hr className="hr-text" data-content="OR" />
              </div>
              <div class="upload-path">
                <div class="m-10 upload-input-field">
                  <input placeholder="Causal file is located at!" onChange={(e) => this.saveCausalFilePath(e.target.value)} type="text" />
                  <button className="upload-btn"
                    onClick={(e) => this.handleCausalPathUpload()}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <div className="causal_save_add_btns">
            {
              this.props.causalData && this.props.causalData.length > 0 ? <button onClick={(e) =>
                this.clearAllSelections()
              } className="btn btn-sm btn-alert save_progress">
                Clear All
              </button> : ' '
            }

            <button
              className="btn btn-sm btn-primary save_progress"
              onClick={(e) =>
                this.props.saveProgress(
                  this.props.match.params.run_id
                )
              }
            >
              <i className="fa fa-save"></i> Save Progress
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={this.addCausalGraph}
            >
              Add Causal Relations
            </button>
          </div>
        </div>
        <table id="workflow">
          <tbody>
            <tr>
              <th>Hierarchy</th>
              <th>Values</th>
              <th>Causal Model file/path</th>
              <th>View</th>
              <th>Delete</th>
            </tr>
            {this.props.causal_models.length > 0 ? (
              this.props.causal_models.map((pObj, index) => {
                return (
                  <tr key={index}>
                    <td>{pObj.hierarchySelection}</td>
                    <td>{pObj.valueSelection}</td>
                    <td>{pObj.causal}</td>
                    <td>
                      <svg
                        onClick={(e) => this.filter_causal_data(index, pObj)}
                        width="24"
                        height="24"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26.8146 7.89142C25.0582 7.89142 23.6293 9.32034 23.6293 11.0767C23.6293 11.7173 23.82 12.3139 24.1467 12.8139L20.7054 16.2552C20.2053 15.9285 19.6088 15.7378 18.9682 15.7378C18.3044 15.7378 17.6874 15.9422 17.1766 16.291L13.6998 12.8136C14.0264 12.3137 14.217 11.7172 14.217 11.0767C14.217 9.32028 12.7881 7.89136 11.0317 7.89136C9.27531 7.89136 7.84639 9.32028 7.84639 11.0767C7.84639 11.7172 8.03706 12.3135 8.3636 12.8135L4.9221 16.255C4.42218 15.9284 3.82575 15.7378 3.18533 15.7378C1.42892 15.7378 0 17.1668 0 18.9232C0 20.6796 1.42892 22.1085 3.18533 22.1085C4.94173 22.1085 6.37065 20.6796 6.37065 18.9232C6.37065 18.2826 6.17993 17.686 5.85321 17.186L9.29459 13.7446C9.79463 14.0713 10.3912 14.2621 11.0318 14.2621C11.6723 14.2621 12.2689 14.0713 12.7688 13.7446L16.2649 17.2411C15.9599 17.7297 15.7829 18.306 15.7829 18.9231C15.7829 20.6795 17.2118 22.1085 18.9682 22.1085C20.7246 22.1085 22.1536 20.6795 22.1536 18.9231C22.1536 18.2826 21.9629 17.6863 21.6363 17.1864L25.0779 13.7448C25.5779 14.0714 26.1743 14.262 26.8147 14.262C28.5711 14.262 30 12.8331 30 11.0767C30 9.32022 28.571 7.89142 26.8146 7.89142ZM3.18533 20.7919C2.1549 20.7919 1.3166 19.9536 1.3166 18.9232C1.3166 17.8928 2.1549 17.0545 3.18533 17.0545C4.21569 17.0545 5.05405 17.8928 5.05405 18.9232C5.05405 19.9536 4.21575 20.7919 3.18533 20.7919ZM11.0318 12.9454C10.0014 12.9454 9.16305 12.1071 9.16305 11.0767C9.16305 10.0463 10.0013 9.20796 11.0318 9.20796C12.0622 9.20796 12.9005 10.0463 12.9005 11.0767C12.9005 12.1071 12.0622 12.9454 11.0318 12.9454ZM18.9682 20.7919C17.9378 20.7919 17.0994 19.9536 17.0994 18.9231C17.0994 17.8927 17.9377 17.0544 18.9682 17.0544C19.9985 17.0544 20.8369 17.8927 20.8369 18.9231C20.8368 19.9536 19.9985 20.7919 18.9682 20.7919ZM26.8146 12.9454C25.7842 12.9454 24.9459 12.1071 24.9459 11.0767C24.9459 10.0463 25.7842 9.20796 26.8146 9.20796C27.845 9.20796 28.6833 10.0463 28.6833 11.0767C28.6833 12.1071 27.845 12.9454 26.8146 12.9454Z"
                          fill="grey"
                        />
                        <path
                          d="M10.3579 15.9794C10.1008 15.7224 9.68398 15.7224 9.42687 15.9794L8.08777 17.3185C7.83072 17.5756 7.83072 17.9924 8.08783 18.2495C8.21638 18.3781 8.38484 18.4423 8.5533 18.4423C8.72175 18.4423 8.89027 18.378 9.01876 18.2495L10.3579 16.9104C10.615 16.6534 10.615 16.2365 10.3579 15.9794Z"
                          fill="grey"
                        />
                        <path
                          d="M21.9118 11.7736C21.6547 11.5165 21.238 11.5165 20.9807 11.7737L19.6416 13.1128C19.3846 13.3698 19.3846 13.7866 19.6416 14.0437C19.7703 14.1722 19.9387 14.2365 20.1072 14.2365C20.2757 14.2365 20.4441 14.1722 20.5726 14.0437L21.9117 12.7046C22.1688 12.4475 22.1688 12.0306 21.9118 11.7736Z"
                          fill="grey"
                        />
                      </svg>
                    </td>
                    <td>
                      <a
                        className=""
                        onClick={(e) => this.removeCausalSel(index, pObj)}
                      >
                        <i className="far fa-trash-alt"></i>
                      </a>
                    </td>
                  </tr>
                );
              })
            ) : (
                <tr>
                  <td colSpan={5}>Causal Model not selected.!</td>
                </tr>
              )}
          </tbody>
        </table>
        <div className="causal_upload_run_flex">
          {
            this.props.causal_models.length > 0 ? (
              <div className="upload_causal">
                <input
                  type="button"
                  onClick={(e) => this.addCausalSelection()}
                  className="btn btn-sm btn-primary"
                  value="Upload Causal"
                />
              </div>
            ) : " "
          }

        </div>

        <Modal
          isOpen={this.state.isCausalModalGraphOpen}
          style={confirmDeleteModal}
          onRequestClose={this.cancelCausalModalGraph}
          contentLabel="Result"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4">Causal Model Graph</div>
              <button
                type="button"
                className="close"
                onClick={this.cancelCausalModalGraph}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              {this.props.causalData.length > 0 ? (
                <div className="svg">
                  <DAG
                    label={this.state.selected_model_Obj}
                    data={this.props.causalData[this.state.current_causal]}
                  />
                </div>
              ) : (
                  ""
                )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={this.cancelCausalModalGraph}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.isClearAllSelecionOpen}
          style={confirmClearAllOption}
          onRequestClose={this.cancelClearAllSelecion}
          contentLabel="Result"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4">Causal Model Graph</div>
              <button
                type="button"
                className="close"
                onClick={this.cancelClearAllSelecion}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <div className="modal-body">
              <ul className="pipeline-form">
                <li className="form-column">
                  <p className="text">
                    Are you sure want to clear all the selections ?
                  </p>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={this.continueClearAllSelecion}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-sm btn-alert"
                onClick={this.cancelClearAllSelecion}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

import React, { Component, PureComponent } from "react";
import { useTable, usePagination, useFilters, useGlobalFilter } from 'react-table';
import './ReactDynamicTable.css'
import { PAGINATION_RANGE } from "../consts";

var _loadash = require("lodash");

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}

function Table({ columns, data, getAnomalyParams, exp_id, controlledPageCount, anomalyPageSize, manualPaginationFlag }) {

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )
    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: anomalyPageSize },
        defaultColumn,
        filterTypes,
        manualPagination: true,
        autoResetPage: false,
        pageCount: controlledPageCount,
    },
        useFilters,
        useGlobalFilter,
        usePagination,
    )
    let filteredData = page.slice(pageIndex * pageSize, (pageIndex * pageSize) + pageSize)
    React.useEffect(() => {
        getAnomalyParams(exp_id, pageIndex, pageSize, pageCount)
    }, [getAnomalyParams, pageIndex, pageSize, pageCount])

    return (
        <div className="table-container">
            <table className="react-table" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <React.Fragment key={index}>
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th className="heading" width={column.width} {...column.getHeaderProps()}>
                                        <div className="header-label">
                                            {column.render('Header')}
                                        </div>
                                        <div className="filter">
                                            {column.canFilter ? column.render('Filter') : null}
                                        </div>

                                    </th>
                                ))}
                            </tr>
                        </React.Fragment>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.length > 0 ? page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    }) : <tr><td colSpan="7">No data found, Please create new one</td></tr>}
                </tbody>
            </table>
            {
                page && page.length > 0 ?
                    <div className="pagination">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </button>{' '}
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </button>{' '}
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </button>{' '}
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </button>{' '}
                        <span>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageCount}
                            </strong>{' '}
                        </span>
                        <span>
                            | Go to page:{' '}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{ width: '100px' }}
                            />
                        </span>{' '}
                        <select
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {PAGINATION_RANGE.map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div> : ""
            }

        </div>
    )
}

export class AnomalyResultTableComponent extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        if (_loadash.isEqual(nextProps.data, this.props.data) && (nextProps.controlledPageCount == this.props.controlledPageCount)) {
            return false;
        }
        return true;
    }
    render() {
        const { columns, data, getAnomalyParams, manualPaginationFlag, run_id, anomalyPageSize, controlledPageCount } = this.props;
        return (
            <div>
                <Table columns={columns} data={data} controlledPageCount={controlledPageCount}
                    getAnomalyParams={getAnomalyParams} manualPaginationFlag={manualPaginationFlag}
                    anomalyPageSize={anomalyPageSize} exp_id={run_id}
                />
            </div>
        );
    }
}

export default AnomalyResultTableComponent;
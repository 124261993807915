import React, { Component } from "react";
import Form from "react-jsonschema-form";
import "./rca.css";
import { toast } from "react-toastify";
import { handleErrors } from "./ui_utils";
import { CONFIG_MS } from "../consts";
import Select from "react-select";
import { CustomSelectBox } from "./CustomSelectBox";
import AlgoComponent from "./util/AlgoComponent/AlgoComponent";


export class PredictiveComponent extends Component {
    componentDidMount = () => {
        this.props.fetchCausalExperiments();
        this.props.fetchAnomalyExperiments();
        this.props.getAllAnomalyAlgorithms();
        this.props.fetchDataSources();
    };
    constructor(props) {
        super(props);
        this.state = {
            isPredictivePage: false,
            kpiSectionvalues: [
                { id: "1", name: "F" },
                { id: "2", name: "NF" },
            ],
            predictiveFrequencyList: [
                { value: "D", label: "day frequency" },
                { value: "W", label: "weekly frequency" },
                { value: "M", label: "month  frequency" },
                { value: "Y", label: "Year frequency" },
                { value: "H", label: " Hourly frequency" },
                { value: "Q", label: "quarter end frequency" }
            ],
            isTargetKpiFileUploaded:false,
            target_kpi_filename:""
        }
    }

    handleRCAOrPredictiveChange = (value) => {
        if (value == 'predictive') {
            this.setState({
                isPredictivePage: true
            })
        } else {
            this.setState({
                isPredictivePage: false
            })
        }

    }
    handleTargetKpiFile = (value) => {
        let selected_file = value.target.files[0] || null;
        if (selected_file){ 
          let uploaded_file_name = selected_file.name
          const filenameRegex = /^[a-z0-9A-Z_\s()-]{1,60}\.csv$/gm
          if(!filenameRegex.test(uploaded_file_name)){
            value.target.value = ""
            this.setState({
              target_kpi_filename: "",
              isTargetKpiFileUploaded:false
            });
            this.props.togglePopup("Invalid file name");
            return
          }
          const Papa = require("papaparse/papaparse.min.js");
          Papa.parse(selected_file, {
            header: true,
            download: true,
            skipEmptyLines: true,
            complete: this.updateTargetKpis,
          });
        }
        this.setState({
          target_kpi_filename: value.target.value,
          isTargetKpiFileUploaded:true
        });
      };

      updateTargetKpis = (result, file) => {
        this.props.updateTargetKpiModel(result)
        this.setState({
            target_kpi_model: result,
            target_kpi_filename: file.name,
            isTargetKpiFileUploaded:true
          });
      };

      handleChangeTargetFile = () => {
        this.props.updateTargetKpiModel("")
        this.setState({
            target_kpi_model:"",
            target_kpi_filename:"",
            isTargetKpiFileUploaded:false
    
        })
      }


    render() {
        const {
            run_rca,
            run_predictive_alert,
            handlePredictiveKpiType,
            predictiveKpiTypeMap,
            handlePredictiveKpiChange,
            handleTargetKpiChange,
            handleForeCastingLength,
            forecasting_length,
            handlePredictivePeriod,
            period_predictive,
            handleUnivariateAlgoChange,
            handleMultiVariateAlgoChange,
            handleCausalExpCallRCA,
            handleAnomalyExpCallRCA,
            causalRunsRCA,
            anomalyRunsRCA,
            anomalyExprimentsRCA,
            handleSelectCausalRunRCA,
            handleSelectAnomalyRunRCA,
            rcaMaxHopsSchema,
            handleRcaMaxHopsChange,
            selected_rca_anomaly_label,
            selected_rca_causal_exp_label,
            rcaDisplayAnomalyProjects,
            rcaDisplayCausalProjects,
            fwd_lookup_limit,
            handleForwardLookupLimit,
            backward_lookup_limit,handleBackwardLookupLimit,
            lookup_range, traversal_kpis_list,
            handleRcaKpiSelection, traversal_kpis, datasourcesListRCA, predictiveKPIList
        } = this.props;
        const targetKpiStyle = this.state.isTargetKpiFileUploaded ? { display: "none" } : {};
        const uploadedTargetFile = this.state.isTargetKpiFileUploaded ? {} : { display: "none" };

        let anomalyExperimentsList = []
        if (rcaDisplayAnomalyProjects && rcaDisplayAnomalyProjects.length > 0) {
            anomalyExperimentsList = rcaDisplayAnomalyProjects.map((obj, i) => {
                return {
                    value: obj.experiment_id,
                    label: obj.experiment_name,
                };
            });
        }
        let anomalyKpiOptions = traversal_kpis_list && traversal_kpis_list.length > 0 &&
            traversal_kpis_list.map((kpi, index) => {
                return {
                    id: index,
                    name: kpi
                }
            })
        let anomalyRunsList = []
        if (anomalyRunsRCA && anomalyRunsRCA.length>0) {
            anomalyRunsList =
                anomalyRunsRCA.map((obj, i) => {
                    return {
                        value: obj.run_id,
                        label: obj.run_name,
                    };
                });
        }

        let causalExperiments_display =[]
        if (rcaDisplayCausalProjects && rcaDisplayCausalProjects.length > 0) {
            causalExperiments_display =
                rcaDisplayCausalProjects.length > 0 &&
                rcaDisplayCausalProjects.map((obj, i) => {
                    return {
                        value: obj.experiment_id,
                        label: obj.experiment_name,
                    };
                });
        }
        let causal_runs_display = []
        if (causalRunsRCA &&
            causalRunsRCA.length > 0) {
            causal_runs_display =
                causalRunsRCA.map((obj, i) => {
                    return {
                        value: obj.run_id,
                        label: obj.run_name,
                    };
                });
        }
        let AnomalyalgoTypes = this.props.anomaly_algo_types && this.props.anomaly_algo_types.map((item) => {
            return {
                value: item,
                label: item,
            };
        });
        let userAlgoList = this.props.userAlgo && this.props.userAlgo.map((item, i) => {
            return {
                value: item.algorithm_name,
                label: item.display_name,
            };
        });
        const display_datasourcesListRCA = datasourcesListRCA && datasourcesListRCA.filter(ds => ds.data_source_status == 'INITIAL INGESTION SUCCESS').
            map(ds => {
                return {
                    value: ds.display_name,
                    label: ds.display_name,
                };
            });

        var kpiSectionvaluesList =
            this.state.kpiSectionvalues.length > 0 &&
            this.state.kpiSectionvalues.map((item, i) => {
                return (
                    <option key={i} value={item.name}>
                        {item.name}
                    </option>
                );
            }, this);

        var predictiveKpiOptionsList = predictiveKPIList && predictiveKPIList.length > 0 &&
            predictiveKPIList.map((item, index) => {
                return { "label": item, "value": item }
            });

        if (predictiveKPIList && predictiveKPIList.length > 0) {
            var display_kpi_type_map = predictiveKPIList.map((item, index) => {
                return (
                    <div className="custom_row" key={index}>
                        <div className="kpi_name">

                            {item}

                        </div>
                        <div className="kpi_type">
                            <select
                                value={predictiveKpiTypeMap[item]}
                                onChange={(e) => handlePredictiveKpiType(item, e.target.value)}
                            >
                                {kpiSectionvaluesList}
                            </select>
                        </div>
                    </div>
                );
            });
        }
        let predictiveAlgos = this.props.anomaly_algorithms && this.props.anomaly_algorithms.filter(
            (obj) => obj.algorithm_category === 'Predictive Alert'
        );
        let predictiveAlgosList = predictiveAlgos && predictiveAlgos.map((item, i) => {
            return {
                value: item.algorithm_name,
                label: item.display_name,
            };
        });
        let predictiveMultiVariateAlgos = this.props.anomaly_algorithms && this.props.anomaly_algorithms.filter(
            (obj) => obj.algorithm_category === 'Multivariate'
        );
        let predictiveMultiVariateList = predictiveMultiVariateAlgos && predictiveMultiVariateAlgos.length > 0 && predictiveMultiVariateAlgos.map((item, i) => {
            return {
                value: item.algorithm_name,
                label: item.display_name,
            };
        });


        return (
            <>
                <div className="rca_body">
                    <div className="rca_heading">Predictive Alert</div>
                    <fieldset className="DS-dropdown">
                        <legend>Select Data Source :</legend>
                        <div className="exp_run_container">
                            <div className="exp_select">
                                <label>Data Source</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={display_datasourcesListRCA && [{ value: "", label: 'Select....' }, ...display_datasourcesListRCA]}
                                        //value={selected_rca_anomaly_label}
                                        onChange={this.props.handleDataSourceChangePredictive}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Select Anomaly Project & Experiment:</legend>
                        <div className="exp_run_container">
                            <div className="exp_select">
                                <label>Anomaly Project</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={[{ value: "", label: 'Select....' }, ...anomalyExperimentsList]}
                                        value={selected_rca_anomaly_label}
                                        onChange={handleAnomalyExpCallRCA}
                                    />
                                </div>
                            </div>
                            <div className="run_select">
                                <label>Anomaly Experiment</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={[{ value: "", label: 'Select....' }, ...anomalyRunsList]}
                                        value={this.props.selected_anomaly_run}
                                        onChange={handleSelectAnomalyRunRCA}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Select Causal Project & Experiment:</legend>
                        <div className="exp_run_container">
                            <div className="exp_select">
                                <label>Causal Project</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={[{ value: "", label: 'Select....' }, ...causalExperiments_display]}
                                        value={selected_rca_causal_exp_label}
                                        onChange={handleCausalExpCallRCA}
                                    />
                                </div>
                            </div>
                            <div className="run_select">
                                <label>Causal Experiment</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={[{ value: "", label: 'Select....' }, ...causal_runs_display]}
                                        value={this.props.selected_causal_run}
                                        onChange={handleSelectCausalRunRCA}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    {
                        predictiveKPIList && predictiveKPIList.length > 0 &&
                        <fieldset>
                            <legend>Select Forecastable  KPIs:</legend>
                            <div className="predictive_kpi_section_main">
                                    <CustomSelectBox
                                        selectedValues={this.props.selectedPredictiveKpis}
                                        multiSelectOptions={predictiveKpiOptionsList}
                                        onChangeValues={handlePredictiveKpiChange}
                                        isOpen={true}
                                        valueKey="value"
                                        labelKey="label"
                                        isMultiple={true}
                                        includeSelectAll={true}
                                        includeFilter={true}
                                        //selectBoxHeight={300}
                                        isDisabled={false}
                                        id="kpi_predictive"
                                    />
                                </div>                        </fieldset>
                        
                    }
                    { 
                            <fieldset>
                            <legend>Upload Target KPIs:</legend>
                            <div class="upload-anomaly-file">
                              <div class="upload-field" style={targetKpiStyle}>
                                <div className="m-10">
                                  <label>Upload file :</label>
                                  {" "}
                                  <input key={this.state.target_kpi_filename} onChange={(e)=>this.handleTargetKpiFile(e)} type="file" />
                                </div>
                              </div>
                              <div className="uploaded_csv" style={uploadedTargetFile}>
                                  <span>
                                    Uploaded File: <b> {this.state.target_kpi_filename} </b>{" "}
                                  </span>
                                  <span>
                                    <a className="change-uploadfile" onClick={this.handleChangeTargetFile}>
                                      Change/edit
                                    </a>
                                  </span>
                              </div>
                              <div class="upload-or-container">
                                <hr className="hr-text" data-content="OR" />
                              </div>
                              <div class="upload-path">
                                <div class="m-10 upload-input-field">
                                  <input placeholder="Target kpi file located at!" type="text" 
                                    onChange={(e) => this.props.saveTargetFilesPath(e.target.value)}/>
                                  {/* <button className="upload-btn"
                                  >
                                    Upload
                                  </button> */}
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        }

                    <fieldset>
                        <legend>Predictive Alert Parameter Configuration:</legend>
                        <div className="exp_run_container">
                            <div className="exp_select">
                                <label>Forecasting Length</label> &nbsp;
                                <div className="experiment-select">
                                    <div className="lookup-range-input">
                                        <input
                                            type="text"
                                            onChange={(e) => handleForeCastingLength(e.target.value)}
                                            value={forecasting_length}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="run_select">
                                <label>Select Period</label> &nbsp;
                                <div className="experiment-select">
                                    <input
                                        type="text"
                                        onChange={(e) => handlePredictivePeriod(e.target.value)}
                                        value={period_predictive}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="exp_run_container">
                            <div className="exp_select">
                                <label>Frequency</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={this.state.predictiveFrequencyList && [{ value: "", label: 'Select....' }, ...this.state.predictiveFrequencyList]}
                                        value={this.props.selectedPredictiveFrequency}
                                        onChange={this.props.handlePredictiveFrequency}
                                    />
                                </div>
                            </div>
                            <div className="run_select">
                            </div>
                        </div>
                        <div className="exp_run_container">
                            <div className="exp_select">
                                <label>Univariate Analysis Model</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={predictiveAlgosList}
                                        onChange={handleUnivariateAlgoChange}
                                        value={this.props.selected_univariate_algo}
                                        maxMenuHeight={100}
                                    />
                                </div>
                            </div>
                            <div className="run_select">
                                {this.props.uniVariateAlgoParam && this.props.uniVariateAlgoParamData && <>
                                    <label>Univariate Model Params</label> &nbsp;
                                    <div className="experiment-select">
                                        <div className="anomaly_algo_param predictive-params">

                                            <Form
                                                schema={this.props.uniVariateAlgoParam}
                                                formData={this.props.uniVariateAlgoParamData}
                                                onChange={this.props.handleUnivariateParamChange}
                                                liveValidate={true}
                                                showErrorList={false}
                                            >
                                                <div>
                                                    <button type="submit" className="hidden">
                                                        Submit
                                                    </button>
                                                </div>
                                            </Form>

                                        </div>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                        <div className="exp_run_container">
                            <div className="exp_select">
                                <label>Multi Variate Analysis Model</label> &nbsp;
                                <div className="experiment-select">
                                    <Select
                                        options={predictiveMultiVariateList}
                                        onChange={handleMultiVariateAlgoChange}
                                        value={this.props.selected_multivariate_algo}
                                        maxMenuHeight={100}
                                    />
                                </div>
                            </div>
                            <div className="run_select">
                                {this.props.multiVariateAlgoParam && this.props.multiVariateAlgoParamData && (<>
                                    <label>MultiVariate Model Params</label>
                                    <div className="experiment-select">
                                        <div className="anomaly_algo_param predictive-params">
                                            <Form
                                                schema={this.props.multiVariateAlgoParam}
                                                formData={this.props.multiVariateAlgoParamData}
                                                onChange={this.props.handleMultiVariateParamChange}
                                                liveValidate={true}
                                                showErrorList={false}
                                            >
                                                <div>
                                                    <button type="submit" className="hidden">
                                                        Submit
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </>
                                )
                                }

                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>Root Cause Parameters</legend>
                        <div className="exp_run_container">
                            <div className="exp_select">
                                <label>Forward lookup limit</label> &nbsp;
                                <div className="experiment-select">
                                    <input
                                        type="text"
                                        pattern="[0-9]*"
                                        onInput={(e) => handleForwardLookupLimit(e)}
                                        value={fwd_lookup_limit}
                                    />
                                </div>
                            </div>
                            <div className="run_select">
                                <label>Backward lookup limit</label> &nbsp;
                                <div className="experiment-select">
                                    <input
                                        type="text"
                                        pattern="[0-9]*"
                                        onInput={(e) => handleBackwardLookupLimit(e)}
                                        value={backward_lookup_limit}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <React.Fragment>
                        <div className="run_rca_btn">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={(e) => run_predictive_alert(e)}
                            >
                                Execute Predictive Alert
                            </button>
                        </div>
                    </React.Fragment>
                </div>
            </>

        );
    }
}

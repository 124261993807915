import React from 'react';
import Loader from 'react-loader-spinner'
import './Loader.css'
export class LoaderComponent extends React.Component {
    render() {
        return (
           this.props.isLoaderLoading && 
            <div className="custom-loading">
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                />
            </div>
        )
    }
}

export default LoaderComponent;